import { CIcon } from '~/icons/types'
export const ciPlot: CIcon = {
  name: 'plot',
  type: 'brand',
  width: 170,
  height: 200,
  paths: [
    {
      d:
        'M169.854 75.433c-.282-1.136-1.324-1.897-2.085-2.649A37217.758 37217.758 0 0 0 88.533 1.683a6.752 6.752 0 0 0-8.81-.094c-7.947 7.195-15.902 14.296-23.926 21.584V15.85a4.443 4.443 0 0 0-4.358-4.17H41.45a4.426 4.426 0 0 0-4.324 4.272v24.165C24.062 52.285 14.74 61.351 1.538 73.536.803 74.22-.068 75.048.12 76.185s1.512 1.23 2.461 1.333h8.152c2.751.085 6.161 1.709 6.571 5.024-.051 4.862 0 5.529.043 9.169-.051 8.331 0 19.508 0 31.684v52.551c0 4.272-.291 11.108 4.819 16.192a26.637 26.637 0 0 0 19.029 7.861c14.765 0 23.926-12.663 23.926-23.857V162.47h82.552a5.895 5.895 0 0 0 5.981-5.776V83.098a5.759 5.759 0 0 1 5.212-5.682c2.273-.094 4.546 0 6.716 0 1.136 0 2.469.197 3.597-.47.567-.269.853-.911.675-1.513zm-30.104 30.01a55.683 55.683 0 0 1-4.17 19.217c-3.213 7.861-8.331 14.526-14.577 18.653a41.262 41.262 0 0 1-11.484 5.187c-1.077.299-2.17.564-3.281.778a66.476 66.476 0 0 1-12.219 1.042c-1.709 0-3.503 0-5.58-.094-8.049-.479-15.628-.393-22.627-2.187l-6.938-1.82-1.017-.291v6.759c0 1.615.085 5.195 0 9.75v14.38c0 7.844-6.434 16.722-16.782 16.722a18.644 18.644 0 0 1-13.338-5.511c-3.068-3.068-3.358-7.015-3.375-9.955v-16.962c0-22.097.068-46.74.154-63.574.128-2.119.214-4.102.222-5.922a44.396 44.396 0 0 1 1.23-10.604 47.155 47.155 0 0 1 12.877-22.336 59.224 59.224 0 0 1 10.194-8.203 65.422 65.422 0 0 1 20.576-8.545 60.493 60.493 0 0 1 13.535-1.521c4.762.007 9.502.656 14.09 1.931a59.702 59.702 0 0 1 41.092 41.87c1.7 6.18 2.17 13.186 1.418 21.236z',
      fill: 'currentColor'
    },
    {
      d:
        'M93.549 77.04H72.81a10.34 10.34 0 0 0-9.562 9.938v20.644a10.366 10.366 0 0 0 9.852 9.844h20.508a10.553 10.553 0 0 0 10.032-9.938V87.08c-.154-5.435-4.794-10.04-10.091-10.04z',
      fill: 'currentColor'
    }
  ]
}
