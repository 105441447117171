import { CIcon } from '~/icons/types'
export const ciPower: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M512 178h-33v264h33v66H0v-66h33V178H0v-67h50V62h82v49h248V62h82v49h50v67zm-17 280H17v33h478v-33zm-33-16V178H50v264h412zm17-281h16v-33h-49V78h-50v50H116V78H66v50H17v33h462zM157 95V78h198v17H157zm45 330 15-41H66v-17h157v2l13-35h-59l41-82H66v-17h160l8-16h90l-15 16h137v17H295l-29 33h76l-83 82h187v17H243zm1-107h57l-16 41 58-58h-72l57-66h-42zM66 37V21h50v16H66zm347 17V37h-17V21h17V4h16v17h17v16h-17v17h-16zM66 211v-17h17v17H66zm33 0v-17h17v17H99zM66 425v-16h17v16H66zm363-214v-17h17v17h-17zm-33 0v-17h17v17h-17z',
      fill: 'currentColor'
    }
  ],
  name: 'power',
  type: 'light'
}
