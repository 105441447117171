import { GoogleAd } from '~/models/ads'

/* Here are all the ads that are used in search (car & plot) except for row-ads (check rows folder for those)  */

export const carSearchDesktopBottomFullAd: GoogleAd = {
  path: '/297119706/gen_search_dt_btm_gal',
  id: 'div-gpt-ad-1616575316251-0',
  sizes: [
    [970, 90],
    [970, 250],
    [728, 90]
  ],
  sizesMappings: [
    [
      [1344, 768],
      [
        [970, 250],
        [970, 90]
      ]
    ],
    [[768, 576], [[728, 90]]],
    [[0, 0], []]
  ]
}

// this will probably never get used (plot only has map-search)
export const plotSearchDesktopBottomFullAd: GoogleAd = {
  path: '/297119706/plot_search_dt_btm_gal',
  id: 'div-gpt-ad-1631534458828-0',
  sizes: [728, 90]
}

export const carSearchMobileBottomAd: GoogleAd = {
  path: '/297119706/gen_search_mb_btm_gal',
  id: 'div-gpt-ad-1616575433500-0',
  sizes: [
    [360, 300],
    [336, 280],
    [300, 250]
  ],
  sizesMappings: [
    [[360, 300], [[360, 300]]],
    [[336, 280], [[336, 280]]],
    [[300, 250], [[300, 250]]],
    [[0, 0], []]
  ]
}

// this will probably never get used (plot only has map-search)
export const plotSearchMobileBottomAd: GoogleAd = {
  path: '/297119706/plot_search_mb_btm_gal',
  id: 'div-gpt-ad-1631534526996-0',
  sizes: [
    [360, 300],
    [336, 280],
    [300, 250]
  ],
  sizesMappings: [
    [[360, 300], [[360, 300]]],
    [[336, 280], [[336, 280]]],
    [[300, 250], [[300, 250]]],
    [[0, 0], []]
  ]
}

export const carSearchBottomGalleryAd1: GoogleAd = {
  path: '/297119706/gen_search_dt_btm1_gal',
  id: 'div-gpt-ad-1616574592241-0',
  sizes: [300, 250]
}

// this will also get used in map search for plot
export const plotSearchBottomGalleryAd1: GoogleAd = {
  path: '/297119706/plot_search_dt_btm1_gal',
  id: 'div-gpt-ad-1631534179208-0',
  sizes: [300, 250]
}

export const carSearchBottomGalleryAd2: GoogleAd = {
  path: '/297119706/gen_search_dt_btm2_gal',
  id: 'div-gpt-ad-1616574622611-0',
  sizes: [300, 250]
}

// this will also get used in map search for plot
export const plotSearchBottomGalleryAd2: GoogleAd = {
  path: '/297119706/plot_search_dt_btm2_gal',
  id: 'div-gpt-ad-1631534204634-0',
  sizes: [300, 250]
}

export const carMapSearchDesktopBottomFullAd: GoogleAd = {
  path: '/297119706/gen_search_dt_btm_plot',
  id: 'div-gpt-ad-1625655540476-0',
  sizes: [[728, 90]],
  sizesMappings: [
    [[768, 576], [[728, 90]]],
    [[0, 0], []]
  ]
}

export const plotMapSearchDesktopBottomFullAd: GoogleAd = {
  path: '/297119706/plot_search_dt_btm',
  id: 'div-gpt-ad-1631534153553-0',
  sizes: [728, 90]
}
