import { CIcon } from '~/icons/types'
export const ciDriverLicense: CIcon = {
  name: 'driver-license',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    { d: 'M150.042 280a15 15 0 1 0 30 0 15 15 0 1 0-30 0' },
    {
      d:
        'M211.238 361c-5.684-20.168-24.234-35-46.196-35s-40.512 14.831-46.196 35z'
    },
    {
      d:
        'M127.928 305.411a44.738 44.738 0 0 1-7.886-25.41c0-24.813 20.187-45 45-45s45 20.187 45 45a44.738 44.738 0 0 1-7.886 25.41c18.238 9.908 32.084 26.887 37.886 47.224V211h-150v141.637c5.802-20.338 19.648-37.318 37.886-47.226z'
    },
    {
      d:
        'M467 61H45C20.187 61 0 81.187 0 106v300c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45V106c0-24.813-20.187-45-45-45zM270.042 376c0 8.284-6.716 15-15 15h-180c-8.284 0-15-6.716-15-15V196c0-8.284 6.716-15 15-15h180c8.284 0 15 6.716 15 15zm29.999-60.999c0-8.284 6.716-15 15-15h74c8.284 0 15 6.716 15 15s-6.716 15-15 15h-74c-8.284 0-15-6.716-15-15zm136.88 76h-41.88c-8.284 0-15-6.716-15-15s6.716-15 15-15h41.88c8.284 0 15 6.716 15 15s-6.716 15-15 15zm.12-121h-122c-8.284 0-15-6.716-15-15s6.716-15 15-15h122c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60.001h-122c-8.284 0-15-6.716-15-15s6.716-15 15-15h122c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-59H74.959c-8.284 0-15-6.716-15-15s6.716-15 15-15h362.082c8.284 0 15 6.716 15 15s-6.716 15-15 15z'
    }
  ]
}
