
















import { ciFilterCategoriesV2 } from '~/icons/source/regular/filter-categories-v2'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { FloatingCornerElementName, Corner } from '~/models/layout'
import { REMOVE_CORNER_ELEMENT } from '~/store/modules/shared/page/mutation-types'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    analyticsEventNamespace: {
      type: String,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    /**
     *  The type of prop is Element.
     *  Use null because the type Element
     *  is undef on component init
     */
    scrollContainer: {
      type: null,
      default: null
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showLabel: false,
      lastScrollTop: 0
    }
  },
  computed: {
    ...mapGetters(PAGE_NS, ['bottomRightCornerElements']),
    icons: () => ({
      facets: ciFilterCategoriesV2
    })
  },
  watch: {
    lastScrollTop(val, oldVal) {
      if (val > 5) {
        this.showLabel = val <= oldVal
      } else {
        this.showLabel = true
      }
    }
  },
  mounted() {
    this.setLastScrollTop()
    this.getScrollContainer().addEventListener(
      'scroll',
      this.setLastScrollTop,
      { passive: true }
    )

    this.showLabel = this.lastScrollTop < 5

    this.updateCornerRegistrationByWindowWidth()
    window.addEventListener(
      'resize',
      this.updateCornerRegistrationByWindowWidth
    )
  },
  beforeDestroy() {
    this.getScrollContainer().removeEventListener(
      'scroll',
      this.setLastScrollTop
    )
    window.removeEventListener(
      'resize',
      this.updateCornerRegistrationByWindowWidth
    )
    this.removeCornerElement({
      name: FloatingCornerElementName.FACETS_BUTTON,
      corner: Corner.BOTTOM_RIGHT
    })
  },
  methods: {
    ...mapActions(PAGE_NS, ['addCornerElement']),
    ...mapMutations(PAGE_NS, {
      removeCornerElement: REMOVE_CORNER_ELEMENT
    }),
    setLastScrollTop() {
      const container = this.getScrollContainer()
      if (window instanceof container.constructor) {
        this.lastScrollTop =
          container.pageYOffset || document.documentElement.scrollTop
      } else {
        this.lastScrollTop = container.scrollTop
      }
    },
    handleFloatingButtonClick() {
      if (this.analyticsEventNamespace) {
        this.$analytics.recordEvent({
          namespace: this.analyticsEventNamespace,
          action: 'click'
        })
      }

      this.$emit('click')
    },
    getScrollContainer(): Element | Window {
      if (this.scrollContainer) {
        return this.scrollContainer
      }

      return window
    },
    updateCornerRegistrationByWindowWidth() {
      const widthLimit = 992

      if (
        window.innerWidth >= widthLimit &&
        this.bottomRightCornerElements.has(name)
      ) {
        this.removeCornerElement({
          name: FloatingCornerElementName.FACETS_BUTTON,
          corner: Corner.BOTTOM_RIGHT
        })
      } else if (
        window.innerWidth < widthLimit &&
        !this.bottomRightCornerElements.has(name)
      ) {
        this.addCornerElement({
          name: FloatingCornerElementName.FACETS_BUTTON,
          corner: Corner.BOTTOM_RIGHT
        })
      }
    }
  }
})
