





















import { Facet } from '~/models/search/types'
import { mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import FacetCollapseContainer from '~/components/car/classifieds/search/facets/FacetCollapseContainer.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ClassifiedSearchFacetsComponentsService from '~/services/search/ClassifiedSearchFacetsComponentsService'
import SearchService from '~/services/search/SearchService'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { FacetCollapseContainer },
  props: {
    facet: {
      type: Object as PropType<Facet>,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      loadingFacets: state => state.loading.facets
    }),
    ...mapDeps({
      classifiedSearchFacetsComponentsService: ClassifiedSearchFacetsComponentsService,
      searchService: SearchService
    }),
    component() {
      return this.classifiedSearchFacetsComponentsService.getFacetComponent(
        this.type
      )
    }
  }
})
