import { CIcon } from '~/icons/types'
export const ciMotorcycle: CIcon = {
  width: 512,
  height: 285.1,
  paths: [
    {
      d:
        'M180 202.2c.1 5.4-.4 11-1.3 16.5l-3.8 21.8c-.5 2.9.2 5.9 2.1 8.3 1.8 2.3 4.6 3.8 7.5 4L333.7 263h.7c2.7 0 5.4-1.1 7.3-3 2.1-2.1 3.3-5 3.1-8 0 0-7-34.6-5.4-56.7 2-27.6 15.6-52.4 39.7-65.7 2.7-1.5 5.4-2.8 8.2-4l1.1-.4c6.2-2.4 12.5-4.3 19-5.6l.3-.1c5.7-1 11.6-1.5 17.4-1.4l20.2.4c7.2.1 13.7-3.5 17.5-9.6 3.7-6.1 4-13.5.6-19.9l-4.9-9.1c-10.8-20.7-27.5-37.7-48.4-49L360.7 1.5c-5-3-11.4-1.3-14.4 3.7-1 1.7-1.5 3.5-1.5 5.4 0 3.6 1.8 7.1 5.1 9l36.5 21.7-32.5 25.9c-13.4-13.4-31.2-22.1-50.1-24.1l-9-1h-.6c-11.6-.6-40.6-.1-58.8 18.9l-13.6 14.2h-43.7L168 61.8c-10.5-13.5-27-21.1-44.1-20.3l-86.2 4c-4.6.2-8.5 3.4-9.7 7.9-1.1 4.5.8 9.2 4.7 11.6-3.3-2.6 66.3 33.1 102.4 56.6 19.4 12.7 35.5 29.9 42.1 59.7l.2.7c1.4 6.1 2.3 12.3 2.6 18.5v1.7z'
    }
  ],
  ellipses: [
    { cx: '77.1', cy: '208.3', rx: '77.1', ry: '76.8' },
    { cx: '436.6', cy: '210.1', rx: '75.4', ry: '75' }
  ],
  name: 'motorcycle',
  type: 'solid'
}
