





















import { defineComponent } from '@nuxtjs/composition-api'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import CMakeLogo from '~/components/shared/configurable/make/CMakeLogo.vue'

export default defineComponent({
  components: { CMakeLogo },
  props: {
    makeId: {
      type: Number,
      required: true
    },
    make: {
      type: String,
      required: true
    },
    model: {
      type: String,
      default: ''
    },
    yearFrom: {
      type: [String, Number],
      default: ''
    },
    yearTo: {
      type: [String, Number],
      default: ''
    }
  },
  setup() {
    return { faBell }
  }
})
