import { Merge } from 'type-fest'
import { Searchable } from '~/models/search/types'

export enum MakeLogoType {
  CARS = 'cars',
  BIKES = 'bikes'
}

export type IndexedSearchable = Merge<
  {
    key?: string
  },
  Searchable
>
