import { CIcon } from '~/icons/types'
export const ciVehicle: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M370 28q5 0 8 3t3 8v11q0 5-3 8t-8 3h-50v19l4-2h5q12 0 16 12 4 16-12 21l-58 15v21l27-7-19-2q-4 0-3-3 0-4 4-3l32 4 8-2h5q12 0 16 12 4 16-12 20l-58 16v21l27-7-19-2q-4 0-3-4 0-3 4-2l32 4 8-2q2-1 5-1 12 0 16 12 4 16-12 21l-35 10v18l26-7q2-1 5-1 12 0 16 12 4 16-12 21l-35 9v28h44q3 0 5.5 1.5t4 4 1.5 5.5v11q0 5-3 8t-8 3h-33v77q0 12-11 15l-3 2q-3 0-5 1h2l-16 34q4 6 4 13 0 10-7 17.5t-17 7.5-17.5-7.5T231 487q0-8 5-15l-15-32h1q-3-1-6-1l-2-2q-11-3-11-15v-77h-33q-5 0-8-3t-3-8v-11q0-5 3-8t8-3h4q-5-4-6-10-4-16 11-20l35-10v-18l-26 7q-2 1-4 1-13 0-16-13-4-15 11-20l58-16v-21l-32 9 26 3q3 0 2 3-1 2-3 2l-39-4h-3q-2 1-4 1-13 0-16-12-4-16 11-21l58-15v-21l-32 8 26 3q3 0 2 3t-3 3l-39-5-3 1h-4q-13 0-16-12-4-16 11-21l58-15V83h-39q-6 0-6-5V61h-50q-5 0-8-3t-3-8V39q0-5 3-8t8-3h5V17q0-6 6-6t6 6v11h94V6q0-6 5.5-6t5.5 6v22h89V17q0-6 6-6t6 6v11h5zM256 502q6 0 10.5-4.5T271 487t-4.5-10.5T256 472t-10.5 4.5T241 487q0 4 2 7.5t5.5 5.5 7.5 2zm22-58h-3q-9 1-19 1t-20-1h-1l10 21q5-3 10-3 7 0 13 4zM248 83v16l16-4V83h-16zm-52 229h18v-5zm98 115q4-1 4-5v-25h-1l-4 1q-19 7-37 7-20 0-38-7l-3-1h-1v25q0 4 4 5h2q35 13 71 1zm4-37v-45h-84v45q2 1 3 2h2q36 13 73 1l3-1q2-1 3-2zm44-56v-11H170v11h172zm-55-22v-25l-62 17v8h62zm40-59-145 39q-5 1-4 7t7 4l145-39q5-1 4-7-1-4-5-4h-2zm-40 0v-18l-62 16v18zm47-49q-1-4-5-4h-2l-145 39q-5 1-4 7t7 4l145-39q5-1 4-7zm-70 2v-21l-16 4v21zm70-57q-1-4-5-4h-2l-145 39q-5 1-4 6t7 4l145-39q5-1 4-6zm-70 1v-21l-16 5v21zm63-61-145 39q-5 1-4 7t7 4l145-39q5-1 4-7-1-4-5-4h-2zm-52 3 31-9h-31v9zm34-20V61H203v11h106zm61-22V39H142v11h228z',
      fill: 'currentColor'
    }
  ],
  name: 'vehicle',
  type: 'light'
}
