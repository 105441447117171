import { CIcon } from '~/icons/types'
export const ciToilet: CIcon = {
  name: 'toilet',
  type: 'light',
  width: 290.627,
  height: 290.627,
  paths: [
    {
      d:
        'M65.626 70.313h9.375c0-15.511 12.614-28.125 28.125-28.125v-9.375c-15.511 0-28.125-12.615-28.125-28.125h-9.375c0 15.511-12.614 28.125-28.125 28.125v9.375c15.511 0 28.125 12.614 28.125 28.125zm4.688-47.485A37.756 37.756 0 0 0 84.986 37.5a37.789 37.789 0 0 0-14.672 14.672A37.769 37.769 0 0 0 55.642 37.5a37.769 37.769 0 0 0 14.672-14.672zm220.312 136.547V18.75h-.239C289.206 8.231 280.36 0 269.532 0h-28.125c-10.828 0-19.673 8.231-20.855 18.75h-.239v101.044c-2.77-1.612-5.944-2.606-9.375-2.606h-38.602c6.881-8.559 17.414-14.063 29.227-14.063V93.75c-20.677 0-37.5-16.823-37.5-37.5h-9.375c0 20.677-16.823 37.5-37.5 37.5v9.375c11.813 0 22.35 5.503 29.227 14.063H18.751c-10.341 0-18.75 8.409-18.75 18.75 0 9.694 7.425 17.602 16.87 18.558 4.275 45.933 40.233 82.734 85.748 88.411-1.027 4.28-4.134 10.219-13.556 10.219H60.939c-11.348 0-32.813 6.858-32.813 32.813v4.688h262.5v-4.688c0-25.955-21.464-32.813-32.813-32.813-.384 0-9.375-.159-9.375-14.063V210.52c11.873-1.659 42.188-9.947 42.188-51.145zM229.688 21.094c0-6.459 5.255-11.719 11.719-11.719h28.125c6.464 0 11.719 5.259 11.719 11.719v11.719h-51.563V21.094zm-92.076 77.344a47.178 47.178 0 0 0 21.764-21.764 47.133 47.133 0 0 0 21.764 21.764 47.178 47.178 0 0 0-21.764 21.764 47.155 47.155 0 0 0-21.764-21.764zM18.751 145.313c-5.17 0-9.375-4.205-9.375-9.375s4.205-9.375 9.375-9.375h133.177a37.246 37.246 0 0 1 2.761 14.063h9.375c0-4.973.994-9.717 2.761-14.063h44.114c5.17 0 9.375 4.205 9.375 9.375s-4.205 9.375-9.375 9.375H18.751zm7.533 9.375h177.117c-4.697 44.719-42.614 79.688-88.556 79.688s-83.86-34.97-88.561-79.688zm231.529 107.813c.886 0 20.292.23 23.1 18.75H37.834c2.691-18.042 20.897-18.731 23.105-18.75h28.125c15.459 0 21.417-10.65 22.973-18.891.942.023 1.861.141 2.808.141 51.178 0 93.319-39.272 97.973-89.255 9.445-.956 16.87-8.864 16.87-18.558v-93.75h51.563v117.188c0 41.488-35.981 42.177-37.5 42.187h-4.688v37.5c0 18.539 12.263 23.438 18.75 23.438z'
    },
    {
      d:
        'M42.189 164.063h9.375v9.375h-9.375zm18.75 0h9.375v9.375h-9.375zm18.75 0h9.375v9.375h-9.375z'
    }
  ]
}
