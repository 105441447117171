import { CIcon } from '~/icons/types'
export const ciCar: CIcon = {
  width: 512,
  height: 221.3,
  paths: [
    {
      d:
        'M459.9 77.3c-25-9.8-58.6-15.5-99.8-17.2-5.8-4.3-11.3-8.6-16.7-12.8C312.2 22.9 282.8 0 211.9 0c-78.2 0-107 19.9-140.3 43.1-4.9 3.4-9.9 6.8-15.1 10.3l-25.2 7.5c-13.1 3.9-22.8 16.7-24 32-.3 3.6-.3 7.4-.4 11.4 0 2 0 4-.1 6-5 6-6.5 14.7-6.8 19.9v22.4c0 20 11.8 33.4 29.3 33.4h23.2c-.6-3.4-.9-6.8-.9-10.4 0-33 27.2-59.9 60.7-59.9s60.7 26.8 60.7 59.9c0 3.5-.3 7-.9 10.4H336c-.6-3.4-.9-6.8-.9-10.4 0-33 27.2-59.9 60.7-59.9s60.7 26.8 60.7 59.9c0 3.5-.3 7-.9 10.3 6.5-.2 15.9-.8 24.9-2.4 7.8-1.4 13.8-3.2 18.5-5.5 10.9-5.4 12.5-13.2 12.5-17.3v-23c0-18.4-8.9-43.9-51.6-60.4zM156.4 66.1c-9.8 0-19.5-4.4-27.2-12.4L116.8 41c20.2-12.5 44.9-21 85.6-22.1l6.4 47.3h-52.4zm73.7-.1-6.4-47.2C275.8 21.4 299.2 41 325.9 66h-95.8z'
    },
    {
      d:
        'M395.8 128.8c-25.3 0-45.9 21.4-45.9 46.7s20.6 45.8 45.8 45.8c25.3 0 45.8-20.5 45.8-45.8s-20.5-46.7-45.7-46.7zM112.1 128.6c-25.3 0-46 21.5-46 46.8s20.6 45.9 45.9 45.9c25.3 0 45.9-20.5 45.9-45.9s-20.5-46.8-45.8-46.8z'
    }
  ],
  name: 'car',
  type: 'solid'
}
