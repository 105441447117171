








































import KeyFeatureIcon from '~/components/car/classifieds/view/partials/key-features/KeyFeatureIcon.vue'
import { ciCategory } from '~/icons/source/light/category'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { KeyFeatureIcon },
  props: {
    classifiedId: {
      validator: prop => typeof prop === 'string' || typeof prop === 'number',
      required: true
    },
    keyFeatures: {
      type: Array,
      required: true
    },
    getKeyFeatureIcon: {
      type: Function,
      required: true
    },
    category: {
      type: [String, Number],
      required: true
    },
    inFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    wantedParts: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    keyFeaturesStr() {
      return this.keyFeatures.map(feature => feature.value).join(', ')
    },
    categoryIcon() {
      return ciCategory
    },
    wantedPartsClasses() {
      if (this.wantedParts) {
        return `!tw-text-grey-600 !tw-bg-grey-100 !tw-rounded !tw-px-2 !tw-py-1 !tw-mr-1 !tw-mb-1 !tw-border-l-0`
      }
      return ''
    }
  }
})
