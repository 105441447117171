












































import { computed, defineComponent, ref, toRefs } from '@nuxtjs/composition-api'
import {
  faStar as faStarSolid,
  faStarHalfAlt
} from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import CCount from '~/components/shared/configurable/CCount.vue'
import { useI18n } from '~/compositions/i18n'

export default defineComponent({
  components: { CCount },
  extends: BaseSearchHandler,
  setup(props) {
    const { handler } = toRefs(props)
    const { t } = useI18n()

    const selectedValue = ref(null)

    const googleRatings = computed(() => {
      if (handler.value.searchables?.ranges) {
        return handler.value.searchables.ranges
          .map(r => {
            const hasHalfStar = r.v.match(/\./)
            const starsWithoutSymbol = r.v.replace('>', '')
            const stars = Math.round(parseInt(starsWithoutSymbol))

            return {
              label: `${t('from')} ${starsWithoutSymbol}`,
              stars,
              hasHalfStar,
              value: r.v,
              count: r.c
            }
          })
          .reverse()
      }
    })

    const icons = computed(() => ({
      halfStar: faStarHalfAlt,
      solidStar: faStarSolid,
      star: faStar
    }))

    return {
      icons,
      googleRatings,
      selectedValue
    }
  },
  methods: {
    onClear() {
      this.emitParams([{ name: this.defaultUrlArgName, value: null }])
    },
    onBackendValues({ selected }) {
      this.selectedValue = `>${selected.low}`
    },
    handleValueChange(value: string) {
      this.emitParams([{ name: this.defaultUrlArgName, value }])
    }
  }
})
