import { CIcon } from '~/icons/types'
export const ciGearshift1: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M447 430q13 9 13 25 0 15-13 25-44 32-101 32H166q0-1-1-1-56 0-100-32-13-9-13-25 0-15 13-25 11-8 23-14-2-4-2-9 0-12 9-21 14-13 27-20-2-5-2-11 0-11 7-20 15-18 32-26h1q18-10 50-15l1-11q2-12 10.5-19.5T241 255h7V136q-25-3-42.5-22.5T188 68q0-28 20-48t48.5-20T305 20t20 48q0 17-8 32t-21.5 24.5T265 136v119h6q11 0 19.5 7.5T301 282l1 10q33 6 52 16 19 9 33 25 7 8 7 21 0 5-2 11 10 6 25 19 9 8 9 21 0 5-2 11 13 7 23 14zM205 68q0 21 15 36t36.5 15 36.5-15 15-36-15-36-36.5-15T220 32t-15 36zm37 205q-11 0-13 12l-4 42q0 5 3 10 5 4 10 4h38q5 0 9-4t4-10l-4-42q-2-12-13-12h-30zm-102 72q-3 4-3 9.5t3 9.5q23 29 55 29h122q7 0 13-2 2 0 4-.5t4-1.5q3-1 6.5-3t7.5-4q11-7 20-18 3-4 3-9.5t-3-9.5q-11-13-25-21-1 0-1-1-19-9-42-13l2 15q1 14-8 23-10 10-22 10h-39q-13 0-22-10-9-9-8-23l1-15q-22 4-40 13-1 1-2 1-16 8-26 21zm-33 51q-3 2-3 9 0 3 3 8 32 29 73 29h154q42 0 72-29 4-2 4-8 0-4-4-9-10-10-23-18-18 19-39 26-1 1-2 1t-2 1h-1.5l-1.5.5-1.5.5H334q-1 0-1.5.5t-1.5.5h-4q-1 0-2 1H195q-37 0-64-31-10 6-24 18zm331 69q6-3 6-11 0-7-6-11-12-9-23-14-35 31-81 31H176q-39-1-71-25-3-3-7-6-8 4-23 14-6 4-6 11t6 11q39 29 91 29h181q52 0 91-29zM265 77h-17v17q0 8-8.5 8t-8.5-8V43q0-9 8.5-9t8.5 9v17h17V43q0-9 8.5-9t8.5 9v51q0 8-8.5 8t-8.5-8V77z',
      fill: 'currentColor'
    }
  ],
  name: 'gearshift-1',
  type: 'light'
}
