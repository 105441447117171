import { CIcon } from '~/icons/types'
export const ciTvCamera: CIcon = {
  width: 512,
  height: 386.13,
  paths: [
    {
      d:
        'M511.9 12.75v-.53A12.36 12.36 0 0 0 499.71 0H32.07c-6.31 0-12.64-.07-19 0h-.83A12.37 12.37 0 0 0 .1 12.22v272.46c0 12.49-.22 25 0 37.5v.53a12.36 12.36 0 0 0 12.19 12.19h467.64c6.31 0 12.64.07 18.95 0h.83c6.38 0 12.49-5.61 12.19-12.19V50.25c0-12.49.22-25.01 0-37.5Zm-24.38 30.46v241.47c0 8.61-.09 17.23-.09 25.84H24.48V50.25c0-8.6.15-17.22.14-25.84h462.9Zm-83.69 318.53H108.17a12.56 12.56 0 0 0-8.61 3.57 12.33 12.33 0 0 0-3.56 8.62c.29 6.6 5.35 12.19 12.18 12.19h295.65a12.56 12.56 0 0 0 8.61-3.57 12.35 12.35 0 0 0 3.57-8.62c-.29-6.6-5.35-12.19-12.18-12.19Z'
    },
    {
      d:
        'M336.36 164.11a10.32 10.32 0 0 0-1.19-2.8 13.88 13.88 0 0 0-4.56-4.49L319 149.11l-28.34-18.74-34.37-22.73-29.6-19.57c-4.78-3.17-9.52-6.43-14.36-9.5l-.2-.13c-3.58-2.37-8.7-2.11-12.3 0a12.27 12.27 0 0 0-6 10.52v137.76c0 6.33-.08 12.66 0 19v.28a12.28 12.28 0 0 0 6 10.53c3.6 2.11 8.72 2.36 12.3 0l11.85-7.84 28.34-18.74 34.37-22.73 29.6-19.57c4.78-3.17 9.6-6.3 14.36-9.5l.2-.13.26-.19a11.42 11.42 0 0 0 4.11-4.18c.08-.15.15-.31.22-.46a11.27 11.27 0 0 0 1-2.45 10.94 10.94 0 0 0 .28-1.45 10.47 10.47 0 0 0-.28-5Zm-58.05 19.41-34.37 22.73-25.82 17.07V111.61Q229 118.84 240 126.06l34.37 22.73 28.24 18.68Z'
    }
  ],
  name: 'tv-camera',
  type: 'regular'
}
