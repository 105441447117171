export const emptyLeasingParams = [
  { name: 'lease-from', value: null },
  { name: 'lease-to', value: null },
  { name: 'lease-downpayment-from', value: null },
  { name: 'lease-downpayment-to', value: null },
  { name: 'with-vat', value: null },
  { name: 'duration', value: null },
  { name: 'yearly-mileage', value: null },
  { name: 'leasing-extra', value: null }
]

export const emptyInstallmentsParams = [
  { name: 'downpayment', value: null },
  { name: 'installment-from', value: null },
  { name: 'installment-to', value: null },
  { name: 'installments', value: null }
]
