import { CIcon } from '~/icons/types'
export const ciFamilySilhouette: CIcon = {
  name: 'family-silhouette',
  type: 'solid',
  width: 98.666,
  height: 98.666,
  paths: [
    {
      d:
        'M39.035 53.557a10.297 10.297 0 1 0 20.594 0 10.297 10.297 0 1 0-20.594 0'
    },
    {
      d:
        'M53.7 64.556h-8.737c-7.269 0-13.183 5.916-13.183 13.184v10.688l.027.166.735.229c6.937 2.168 12.965 2.892 17.927 2.892 9.688 0 15.303-2.764 15.65-2.938l.688-.351.071.002V77.739c.005-7.267-5.907-13.183-13.178-13.183z'
    },
    {
      d:
        'M11.701 23.563a16.611 16.611 0 1 0 33.222 0 16.611 16.611 0 1 0-33.222 0'
    },
    {
      d:
        'M70.35 40.174c9.174 0 16.609-7.44 16.609-16.613 0-9.17-7.438-16.609-16.609-16.609-9.176 0-16.61 7.437-16.61 16.609s7.434 16.613 16.61 16.613zM41.258 62.936c-2.637-2.274-4.314-5.632-4.314-9.378 0-4.594 2.519-8.604 6.243-10.743a21.112 21.112 0 0 0-7.826-1.511H21.266C9.54 41.303 0 50.847 0 62.571v17.241l.043.269 1.187.369c10.982 3.432 20.542 4.613 28.458 4.656v-7.367c0-7.144 4.935-13.14 11.57-14.803zm36.14-21.633H63.305c-2.765 0-5.398.546-7.824 1.511 3.727 2.139 6.246 6.147 6.246 10.743 0 3.744-1.678 7.102-4.313 9.376a15.167 15.167 0 0 1 7.088 4.008 15.18 15.18 0 0 1 4.478 10.8v7.365c7.916-.043 17.477-1.225 28.457-4.656l1.187-.369.044-.269V62.571c-.004-11.724-9.544-21.268-21.27-21.268z'
    }
  ]
}
