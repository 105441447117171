










import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { CBadge },
  props: {
    rounded: {
      type: Boolean,
      default: false
    }
  }
})
