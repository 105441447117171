import { CIcon } from '~/icons/types'
export const ciCar: CIcon = {
  name: 'car',
  type: 'regular',
  width: 512,
  height: 221.3,
  paths: [
    {
      d:
        'M459.8 78.1c-25-9.9-58.5-15.7-99.7-17.4-5.9-4.4-11.4-8.7-16.8-13C312.3 23.2 282.9 0 212.2 0c-78 0-106.7 20.1-139.9 43.4-4.9 3.4-9.9 6.9-15.2 10.5l-25.2 7.6c-13.1 3.9-22.8 17-24 32.4-.3 3.5-.3 7.2-.4 11.1 0 2.1-.1 4.3-.1 6.6-4.7 5.5-6.5 13.6-6.5 20.1v22.7c0 20.2 11.8 33.8 29.3 33.8h32.3c8.5 19.5 27.9 33.1 50.5 33.1 22.5 0 41.9-13.6 50.5-33h181.9c8.5 19.4 27.9 33 50.5 33s42-13.6 50.5-33.1c.5.1 1 .1 1.5.1 2.9 0 18.3-.1 32.6-2.6 7.7-1.3 31-5.5 31-23v-23.3c-.3-18.7-9.2-44.5-51.7-61.3zm-257.3-57 6.4 39.8h-52.4c-9.7 0-19.4-3.7-27.1-10.4L117 39.8c20.3-10.6 44.8-17.7 85.5-18.7zm27.7 39.8-6.4-39.7c52.2 2.1 75.5 18.6 102.3 39.7h-95.9zM112.9 200.2c-18.8 0-34.1-15.3-34.1-34.1S94.1 132 112.9 132s34.1 15.3 34.1 34.1-15.3 34.1-34.1 34.1zm282.8 0c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.1 34.1-34.1 34.1 15.3 34.1 34.1-15.3 34.1-34.1 34.1zm94.5-39.4c-1.7 1-6.5 3.2-18.2 4.8-8.5 1.2-16.8 1.5-21.3 1.6v-1.1c0-30.4-24.7-55.1-55.1-55.1s-55.1 24.7-55.1 55.1v1.1H167.9v-1.1c0-30.4-24.7-55.1-55.1-55.1s-55.1 24.7-55.1 55.1v1H30.1c-6.9 0-8.3-7-8.3-12.8v-22.7c0-2.6.8-5.2 1.3-6.2 2.9-1.8 4.8-4.9 5-8.3.3-4.3.3-8.3.4-11.9 0-3.5.1-6.8.3-9.7.5-6.8 4.3-12.5 9.1-14l26.7-8c1-.3 1.9-.7 2.8-1.3 6-4 11.6-7.9 17-11.7 4.7-3.3 9.2-6.5 13.8-9.5l17.6 15.3c11.6 10 26.1 15.6 40.9 15.6h199.8c.7 0 1.4-.1 2.1-.2 41.5 1.7 131.8 10.8 131.8 57.6v21.5h-.2z',
      fill: 'currentColor'
    }
  ]
}
