import { useNamespacedStore } from '~/compositions/store'
import { SNACKBAR_NS } from '~/store/modules/shared/snackbar/state'
import { SnackbarOptions } from '~/models/app/snackbar'
import { TranslateResult } from 'vue-i18n'
import { useI18n } from '~/compositions/i18n'

export function useSnackbar() {
  const { dispatch: snackbarDispatch } = useNamespacedStore(SNACKBAR_NS)
  const { t } = useI18n()

  function show(message: string | TranslateResult, options: SnackbarOptions) {
    snackbarDispatch('show', { message, options })
  }

  function info(
    message: string | TranslateResult,
    options: SnackbarOptions = {}
  ) {
    show(message, { variant: 'info', ...options })
  }

  function success(
    message: string | TranslateResult,
    options: SnackbarOptions = {}
  ) {
    show(message, { variant: 'success', ...options })
  }

  function error(
    message: string | TranslateResult,
    options: SnackbarOptions = {}
  ) {
    show(message, { variant: 'danger', ...options })
  }

  function savedChanges() {
    success(t('success_saved_changes'), {
      timeout: 1600
    })
  }

  function saved() {
    success(t('saved::verb'), {
      timeout: 1600
    })
  }

  return { show, info, success, error, saved, savedChanges }
}
