





































import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    showAllButton: {
      type: Boolean,
      required: true
    },
    allAreSelected: {
      type: Boolean,
      required: true
    },
    multiSelect: {
      type: Boolean,
      required: true
    },
    searchable: {
      type: Boolean,
      required: true
    },
    disabledClearAllBtn: {
      type: Boolean,
      required: true
    },
    q: {
      type: String,
      required: true
    },
    searchPlaceholder: {
      type: String,
      required: true
    }
  },
  computed: {
    icons: () => ({
      search: faSearch
    })
  },
  methods: {
    async focusInput() {
      await this.$nextTick()
      this.$refs.searchInput.focus()
    },
    handleSearchInput(searchValue) {
      this.$emit('search', searchValue)
    },
    handleSelectAllChange(selectAllValue) {
      this.$emit('select-all-change', selectAllValue)
    },
    handleClearAllButtonClick() {
      this.$emit('clear-all')
    }
  }
})
