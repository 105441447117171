









import CCount from '~/components/shared/configurable/CCount.vue'
import CMakeLogo from '~/components/shared/configurable/make/CMakeLogo.vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { MakeLogoType } from '~/models/make'

export default defineComponent({
  components: { CMakeLogo, CCount },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      required: false,
      default: 0
    },
    logoType: {
      type: String as PropType<MakeLogoType>,
      default: MakeLogoType.CARS
    },
    showCount: {
      type: Boolean,
      default: true
    }
  }
})
