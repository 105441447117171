import { CIcon } from '~/icons/types'
export const ciSofa: CIcon = {
  width: 1020,
  height: 1000,
  paths: [
    {
      d:
        'M937 362v-73q0-62-39-95-33-27-83-27H185q-50 0-83 27-38 33-38 95v73q-28 7-46 29.5T0 443v257q0 18 13 31t31 13h21v49q0 17 11.5 28.5T104 833h44q16 0 27.5-11.5T187 793v-49h626v49q0 17 11.5 28.5T852 833h44q16 0 27.5-11.5T935 793v-49h21q18 0 31-13t13-31V443q0-29-18-51.5T937 362zm-835-72q1-47 28-68 21-16 55-16h626q34 0 55 16 28 21 28 68v73q-27 7-45.5 29.5T830 443v23H165v-23q0-28-18-50.5T102 363v-73zm728 215v76H513v-76h317zm-357 0v76H165v-76h308zM147 793h-44v-49h44v49zm747 0h-44v-49h44v49zm64-93q0 5-4 5H44q-2 0-3.5-1.5T39 700V443q0-18 13-31t31-13 31 13 13 31v158q0 8 6 13.5t14 5.5h704q8 0 14-5.5t6-13.5V443q0-18 13-31t31-13 30.5 13 12.5 31v257zM279 279q-25 0-42.5 17T219 338.5t17.5 43T279 399t43-17.5 18-42.5-18-42.5-43-17.5zm0 81q-8 0-14-6t-6-15 5.5-15 14-6 15 6 6.5 15-6.5 15-14.5 6zm429-81q-26 0-43.5 17.5t-17 42.5 18 42.5T708 399t42.5-17.5T768 339t-17.5-42.5T708 279zm0 81q-8 0-14.5-6t-6.5-15 6.5-15 14.5-6 14 6 6 15-5.5 15-14.5 6z',
      fill: 'currentColor'
    }
  ],
  name: 'sofa',
  type: 'regular'
}
