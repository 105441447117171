import { CIcon } from '~/icons/types'
export const ciTire: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M495 254v2q0 24-3 48v2q-8 57-32 103t-59 73-76 30H196q-74 0-126.5-75T17 256q0-115 61-192 1-1 2-3Q130 0 196 0h130q65 5 112 70 54 72 57 177v7zM213 390q18 45 47 73t63 32q65-5 110-74t45-165-45-165-110-74q-57 7-96 76t-39 163q0 72 24 132 0 1 1 2zM45 341q5 21 14 41l12-13q6-5 12 0l19 20 20-20q6-5 12 0l18 18 11-17q5-6 11-2l15 7q-7-21-11-43l-14-13-21 21q-6 6-12 0l-20-21-20 20q-6 6-12 0l-18-18-11 17q-3 2-5 3zm-11-81q1 31 6 60l12-18q3-3 7-3t7 2l19 20 20-20q6-6 12 0l20 20 19-20q5-5 11-1l7 6q-2-18-3-36l-33-20-20 21q-6 5-12 0l-21-20-19 20q-5 4-11 1zm28-138q-11 25-18 53l10-10q2-3 5.5-3t6.5 3l19 19 20-19q6-6 12 0l20 19 19-19q6-6 12 0l11 10q4-21 11-41l-27-13-21 15q-5 3-10 0l-21-15-21 14q-4 3-9 0zm28-46q-11 14-20 31l15 11 21-14q5-3 9 0l21 14 20-14q4-2 9-1l31 16q10-26 24-47l-24-11-21 14q-5 3-9 0l-23-14zm51 156 30 18q0-28 4-55-1 0-2-1l-11-11-19 19q-6 6-12 0l-20-19-20 19q-6 6-12 0l-19-19-19 19q-1 1-3 2-3 19-4 38l24 13 21-21q6-6 12 0l20 19 20-19q4-5 10-2zM274 17h-78q-45 0-83 34l30-8q5 0 7 1l21 14 20-14q4-2 9 0l30 15q20-27 44-42zM66 398q16 31 37 53l21-14q4-3 9 0l21 14 20-14q5-3 9 0l21 14 16-11q-12-19-22-42l-25-12-13 19q-1 5-6 5-4 0-6-3l-20-20-20 20q-6 6-12 0l-19-20zm130 97h79q-25-15-45-41l-22 14q-4 4-9 0l-20-14-20 14q-5 3-10 0l-21-14-13 9q38 32 81 32zM333 68q43 0 72.5 54.5T435 256t-29.5 133.5T333 444q-33 0-59-33.5T237 323v-1q-1-3-1-6l-.5-2-.5-4q-1-2-1-3 0-2-1-4v-8q-1-1-1-3v-7q-1-2-1-5v-16q-1 0-1-1v-14.5l1-.5v-16q0-2 1-5v-7l1-3v-8q1-2 1-4 0-1 1-3v-5l1-1q0-3 1-6v-1q11-54 37-87.5T333 68zm-84 215q3 35 13 65 20-34 20-92t-20-92q-14 40-14 87-1 3-1 5t1 6v19.5l.5 1zm84 144q34 0 59.5-51T418 256t-25.5-120T333 85q-38 0-64 60 30 36 30 111t-30 111q26 60 64 60z',
      fill: 'currentColor'
    }
  ],
  name: 'tire',
  type: 'light'
}
