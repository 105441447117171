import { CIcon } from '~/icons/types'
export const ciShelving: CIcon = {
  name: 'shelving',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M0 0h80v512H0zm432 0h80v512h-80zM110 201h292v30H110zm0 241h292v30H110zm85-160v54.845l20 8V282z'
    },
    { d: 'M245 282v107.155l-80-32V282h-55v130h190V282zm1-241v54.845l20 8V41z' },
    { d: 'M296 41v107.155l-80-32V41h-55v130h190V41z' }
  ]
}
