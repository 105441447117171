

















import { Suggestion } from './Suggestion'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    focused: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      required: true
    },
    suggestion: {
      type: Object as PropType<Suggestion>,
      required: true
    }
  }
})
