import { CIcon } from '~/icons/types'
export const ciWeight: CIcon = {
  name: 'weight',
  type: 'solid',
  width: 299.799,
  height: 299.799,
  paths: [
    {
      d:
        'm287.2 282.152-31.829-177.579a15 15 0 0 0-14.765-12.353h-52.935c9.925-9.776 16.093-23.357 16.093-38.356C203.764 24.163 179.601 0 149.9 0S96.035 24.163 96.035 53.864c0 14.999 6.168 28.58 16.093 38.356H59.193a15 15 0 0 0-14.765 12.353L12.599 282.152a14.998 14.998 0 0 0 14.765 17.646h245.071a15 15 0 0 0 14.765-17.646zM149.9 30c13.159 0 23.864 10.705 23.864 23.864S163.059 77.729 149.9 77.729s-23.865-10.706-23.865-23.865C126.035 40.705 136.741 30 149.9 30zm-3.92 202.126a4.468 4.468 0 0 1-3.927 2.337h-7.076a4.47 4.47 0 0 1-3.856-2.21l-17.512-29.895-11.199 11.432v13.767a6.906 6.906 0 0 1-13.812 0V172.96a6.906 6.906 0 0 1 13.812 0v23.471l26.579-28.932a4.468 4.468 0 0 1 3.29-1.445h6.073a4.469 4.469 0 0 1 3.213 7.573l-18.436 19.07 22.67 34.861a4.47 4.47 0 0 1 .181 4.568zm68.043-9.11c0 1.358-.62 2.648-1.681 3.495-2.756 2.199-6.387 4.173-10.893 5.921-5.492 2.131-11.053 3.196-16.683 3.196-7.156 0-13.391-1.5-18.713-4.503-5.318-3.001-9.317-7.294-11.992-12.879-2.676-5.584-4.013-11.657-4.013-18.222 0-7.123 1.493-13.455 4.48-18.991 2.986-5.538 7.357-9.783 13.113-12.74 4.384-2.27 9.845-3.407 16.378-3.407 8.493 0 15.126 1.782 19.902 5.344a23.318 23.318 0 0 1 5.247 5.396 7.047 7.047 0 0 1 .737 6.696 7.045 7.045 0 0 1-5.222 4.258l-.016.003a6.83 6.83 0 0 1-7.037-3.077 14.09 14.09 0 0 0-3.648-3.898c-2.661-1.944-5.982-2.916-9.963-2.916-6.036 0-10.835 1.914-14.395 5.739-3.562 3.828-5.343 9.505-5.343 17.033 0 8.12 1.803 14.209 5.413 18.269 3.608 4.059 8.336 6.089 14.186 6.089 2.892 0 5.793-.567 8.702-1.703 2.907-1.136 5.404-2.513 7.489-4.129v-8.681h-10.055a5.762 5.762 0 1 1 0-11.524h19.539a4.468 4.468 0 0 1 4.468 4.468v20.763z',
      fill: 'currentColor'
    }
  ]
}
