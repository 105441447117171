import { CIcon } from '~/icons/types'
export const ciNonsmoker: CIcon = {
  width: 512,
  height: 447.44,
  paths: [
    {
      d:
        'M499.9 204.59H232.52l-14.28-14.83q-12.7-13.19-25.4-26.39L169 138.56l-22.08-22.94-19.61-20.32L110.66 78 97.38 64.21l-9.29-9.64-4.85-5-.61-.63c-4.39-4.57-12.74-4.76-17.1 0a12.33 12.33 0 0 0 0 17.1c.84.87 1.67 1.74 2.51 2.6l7.23 7.52 11.38 11.78 15 15.62 18.3 19 20.87 21.68q11.56 12 23.14 24l24.67 25.62L199 204.59H31.9c-6.33 0-12.65-.07-19 0h-.83A12.28 12.28 0 0 0 0 216.69v80.63a12.27 12.27 0 0 0 12.09 12.09h287.76q9.66 10 19.31 20.06l23.89 24.81 22.08 22.94 19.56 20.32 16.65 17.29 13.28 13.8 9.29 9.64 4.85 5 .61.63c4.39 4.57 12.74 4.76 17.1 0a12.33 12.33 0 0 0 0-17.1c-.84-.87-1.67-1.74-2.51-2.6l-7.23-7.52-11.38-11.78-15-15.62-18.3-19-20.87-21.68q-11.56-12-23.14-24l-14.58-15.14h146.63c6.33 0 12.65.07 19 0h.83c6.32 0 12.38-5.56 12.09-12.09v-80.68a12.27 12.27 0 0 0-12.11-12.1Zm-235.13 80.63H24.19v-56.44h198.05L241 248.31l26.56 27.58 9 9.33Zm113.68 0h-68.31l-12.61-13.09L271 244.54q-7.58-7.89-15.16-15.76h122.61Zm54.42 0h-29.22v-56.44h29.22Zm54.93 0h-29.73v-56.44h29.71Z'
    },
    {
      d:
        'M285.67 75.09a66.41 66.41 0 0 0 5.65 13.58 58.31 58.31 0 0 0 8.19 11.33 57 57 0 0 0 10.49 8.81 62.22 62.22 0 0 0 12.65 6.07 73.51 73.51 0 0 0 16.16 3.29 80.5 80.5 0 0 0 15 .07h.18a102.52 102.52 0 0 1 18.2.7 46.23 46.23 0 0 1 10.07 3.14 33.18 33.18 0 0 1 4.7 3.33 35 35 0 0 1 2.85 3.5 47 47 0 0 1 3 6.5 94.25 94.25 0 0 1 3.19 15.5c.48 4.9.75 9.82.89 14.75a18.67 18.67 0 0 0 3.74 11.13c2.22 2.73 5.85 4.79 9 4.61a11.83 11.83 0 0 0 9-4.61 18 18 0 0 0 3.74-11.13 195 195 0 0 0-2.8-29.61 82.43 82.43 0 0 0-2.45-10 75.36 75.36 0 0 0-4.62-11.84 50 50 0 0 0-14.48-17.47 47.52 47.52 0 0 0-17.75-7.93 103.34 103.34 0 0 0-26.87-2.09c-.41 0-.82 0-1.23.11a76.86 76.86 0 0 1-15.61-.46 52.74 52.74 0 0 1-12.06-3.85 42.83 42.83 0 0 1-7.25-5.13 42.59 42.59 0 0 1-4.25-5.22 49.23 49.23 0 0 1-3-6.3 61.48 61.48 0 0 1-2-9.56 88.36 88.36 0 0 1 .13-13.9 136.61 136.61 0 0 1 5-22.53 14.49 14.49 0 0 0 .23-6.28 13.61 13.61 0 0 0-1.51-5.85c-1.55-3.27-4.59-6.38-7.64-7.24a10.81 10.81 0 0 0-9.84 1.59l-2.58 2.46a16.91 16.91 0 0 0-3.29 7 141.4 141.4 0 0 0-6.15 34.71 106 106 0 0 0 .85 17.17 61 61 0 0 0 2.47 11.65Zm111.75-29.42c2.56.14 5.1.36 7.64.66a51.73 51.73 0 0 1 9.75 2.44 28.43 28.43 0 0 1 3.64 2.07 22.71 22.71 0 0 1 1.9 1.9 25.12 25.12 0 0 1 1.57 2.71 39.19 39.19 0 0 1 1.86 7.31 96 96 0 0 1 .22 14.78 12.09 12.09 0 0 0 12.1 12.1q.65 0 1.29-.12a15.9 15.9 0 0 0 2.34.31c1.37.07 2.75.19 4.12.34a44.34 44.34 0 0 1 7.94 2.06 30.42 30.42 0 0 1 4.32 2.49 31.08 31.08 0 0 1 3.09 3.09 38.93 38.93 0 0 1 3.55 6.21 77.17 77.17 0 0 1 4 16.15c1.5 11.83 1.56 23.89 1.25 35.83-.12 4.44-.31 8.88-.53 13.32a12.08 12.08 0 0 0 20.64 8.55 13.07 13.07 0 0 0 3.54-8.55c.61-11.69.95-23.42.45-35.12a148.52 148.52 0 0 0-3.54-28.51c-3.32-13.86-10.83-27.55-24.05-34.11a55.83 55.83 0 0 0-16.24-5c-.36-8.1-1.82-16.13-5.5-23.42-4.89-9.68-14.42-15.8-24.6-18.68a94.75 94.75 0 0 0-20.74-3 12.09 12.09 0 0 0-8.55 20.64 13.11 13.11 0 0 0 8.54 3.55Z'
    }
  ],
  name: 'nonsmoker',
  type: 'regular'
}
