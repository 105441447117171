import { CIcon } from '~/icons/types'
export const ciBoat: CIcon = {
  width: 512,
  height: 288.1,
  paths: [
    {
      d:
        'm334.3 113-26.6-29.3c-9.7-10.8-23.5-16.9-37.9-16.9h-75.7c-11.5 0-21.9 6.3-27.2 16.5L151.5 113h182.8zm-101.4 21H117.3l-19 33.1 74.6-2.2z',
      fill: '#91D4F3'
    },
    {
      d:
        'm24.2 225.5 9.7 41.6h203.6l80.1-6.2c39-3 86.5-30.5 115.7-66.8l45-60.2H278.8l-98.3 50.6c-1.4.8-2.9 1.2-4.5 1.2l-121.9 3.8-29.9 36zm205.3-35.4h140.2c5.8 0 10.5 4.7 10.5 10.5s-4.7 10.5-10.5 10.5H229.5c-5.8 0-10.5-4.7-10.5-10.5s4.8-10.5 10.5-10.5z',
      fill: '#91D4F3'
    },
    {
      d:
        'M48.7 168.6c-3 .1-5.8 1.5-7.8 3.8L4.7 216.1c-2.1 2.5-2.9 5.9-2.1 9.1L15.4 280c1.1 4.8 5.3 8.1 10.2 8.1h210c1.1 0 2.4 0 3.8-.1l79.9-6.2c22.8-1.7 47.6-10.2 71.9-24.5 22.6-13.3 43.5-31.2 58.7-50.2.1-.1.1-.2.2-.3l57.6-77.1c2.3-3.1 2.7-7.4.9-11s-5.4-5.8-9.4-5.8H362.6l-39.4-43.5a72.348 72.348 0 0 0-53.5-23.7h-61.5l-8.4-37.5c-1.3-5.7-6.9-9.2-12.5-7.9-5.7 1.3-9.2 6.9-7.9 12.5l7.5 33.4c-16.4 2.3-30.8 12.3-38.6 27.4l-20.4 39.3H39.5c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h53.6l-19.5 33.9-24.9.8zm118.2-85.3c5.3-10.2 15.7-16.5 27.2-16.5h75.7c14.4 0 28.2 6.1 37.9 16.9l26.6 29.3H151.5l15.4-29.7zM117.3 134h115.6l-60 30.9-74.6 2.2 19-33.1zm58.7 51.7c1.6 0 3.1-.4 4.5-1.2l98.3-50.6h199.5l-45 60.2c-29.2 36.3-76.7 63.8-115.7 66.8l-80.1 6.2H33.9l-9.7-41.6 29.9-36 121.9-3.8z',
      fill: '#1976D2'
    },
    {
      d:
        'M229.5 211.1h140.2c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5H229.5c-5.7 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5z',
      fill: '#1976D2'
    }
  ],
  name: 'boat',
  type: 'duotone'
}
