import { CIcon } from '~/icons/types'
export const ciSnowmobile: CIcon = {
  width: 512,
  height: 249.4,
  paths: [
    {
      d:
        'M137.6 34c-9.1-3.4-19.4-1.5-26.4 4.7-6 5.4-8.4 13.1-6.4 20.6.3 1 .7 2 1.2 2.9l2.2 3.6h120.6L137.6 34z'
    },
    {
      d:
        'm509 207-3.4-8c-2.3-5.3-8.4-7.8-13.8-5.6-5.3 2.3-7.8 8.4-5.6 13.8l3.4 8c1 2.4.8 4.8-.8 7.1-1.9 2.9-6.4 6.1-13.1 6.1h-20.8l-51.4-68.5h65.1c11 0 20.5-6.3 24.8-16.3 4.3-10.1 2.3-21.3-5.3-29.3l-.6-.6-124-111c-4.3-3.9-11-3.5-14.8.8-3.9 4.3-3.5 11 .8 14.8l42.2 37.8-74.6 24.7-3.2 1H110.6C85 85.9 63 100.5 52.5 121.5c-.2.4-.4.8-.5 1.3l-8.6 24.5h194.4c32.3 0 58.8 25 61.4 56.6h47.7c3 0 5.8-1.3 7.8-3.5l28.9-32.3 45.3 60.3H319.1c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h156.7c12.7 0 24.1-5.8 30.6-15.4 5.4-8.3 6.4-18.1 2.6-27z'
    },
    {
      d:
        'M237.8 168.2H41.3C18.8 168.2.7 186.5.7 208.8s18.3 40.6 40.6 40.6h196.5c22.5 0 40.6-18.3 40.6-40.6s-18.3-40.6-40.6-40.6z'
    }
  ],
  name: 'snowmobile',
  type: 'solid'
}
