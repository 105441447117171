







import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    address: {
      type: [String, null],
      default: null,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    icons: {
      mapMarker: faMapMarkerAlt
    }
  })
})
