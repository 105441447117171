var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full c-progress-bar",class:{
    'tw-flex tw-justify-between tw-items-center': _vm.progressPositionRight
  }},[_c('div',{ref:"pBarRef",staticClass:"tw-w-full tw-bg-grey-200 tw-h-full",class:[
      {
        'tw-rounded tw-min-h-4': !_vm.linear,
        'tw-h-1': _vm.linear
      } ].concat( _vm.barClasses
    ),on:{"&touchmove":function($event){return _vm.handleProgressChange($event)},"&mousedown":function($event){return _vm.startDrag($event)},"click":_vm.handleProgressChange}},[_c('div',{staticClass:"tw-flex tw-items-center progress-bar-transition tw-h-full tw-min-w-9",class:[
        _vm.variantBgColor,

        {
          'tw-rounded tw-min-h-4': !_vm.linear,
          'tw-min-h-1': _vm.linear,
          stripes: _vm.striped,
          animate: _vm.animated,
          'tw-justify-center': !_vm.progressPositionRight
        },
        _vm.progressClass
      ],style:(_vm.progressStyle)},[(_vm.$slots.default)?[_vm._t("default")]:[(_vm.showProgress && !_vm.progressPositionRight && _vm.value !== 0)?_c('span',{staticClass:"tw-leading-4",class:[_vm.variantTextColor]},[_vm._v("\n          "+_vm._s(_vm.value)+"%\n        ")]):_vm._e()]],2)]),_vm._v(" "),(_vm.showProgress && _vm.progressPositionRight)?_c('span',{staticClass:"tw-leading-4 tw-ml-3 tw-w-9"},[_vm._v("\n    "+_vm._s(_vm.value)+"%\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }