






import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import ColorValueSearchHandler from '~/components/car/classifieds/search/facets/handlers/ColorValueSearchHandler.vue'
import SearchService from '~/services/search/SearchService'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { ColorValueSearchHandler },
  extends: BaseSearchHandler,
  computed: {
    blackCheckmarksColorsIds() {
      return ['white', 'turquoise', 'yellow', 'light_blue']
    },
    bindings() {
      return this.$dep(SearchService).getFacetComponentBindings(this.handler)
    }
  },
  methods: {
    hasBlackCheckmark(optionId: string): boolean {
      return this.blackCheckmarksColorsIds.includes(Number(optionId))
    }
  }
})
