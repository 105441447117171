import { CIcon } from '~/icons/types'
export const ciLightBulbOn: CIcon = {
  width: 640,
  height: 512,
  paths: [
    {
      d:
        'M112 192a24 24 0 0 0-24-24H24a24 24 0 0 0 0 48h64a24 24 0 0 0 24-24Zm-4.92 95.22-55.42 32a24 24 0 1 0 24 41.56l55.42-32a24 24 0 0 0-24-41.56Zm24-232-55.42-32a24 24 0 1 0-24 41.56l55.42 32a24 24 0 1 0 24-41.56ZM520.94 100a23.8 23.8 0 0 0 12-3.22l55.42-32a24 24 0 0 0-24-41.56l-55.42 32a24 24 0 0 0 12 44.78ZM616 168h-64a24 24 0 0 0 0 48h64a24 24 0 0 0 0-48Zm-27.66 151.23-55.42-32a24 24 0 1 0-24 41.56l55.42 32a24 24 0 0 0 24-41.56ZM320 0C217.72 0 144 83 144 176a175 175 0 0 0 43.56 115.78c16.63 19 42.75 58.8 52.41 92.16v.06h48v-.12a47.67 47.67 0 0 0-2.13-14.07C280.25 352 263 305.06 223.66 260.15a127.48 127.48 0 0 1-31.6-84.15C191.84 102.36 251.72 48 320 48a127.91 127.91 0 0 1 96.34 212.15c-39.09 44.61-56.4 91.47-62.09 109.46a56.78 56.78 0 0 0-2.25 14.31v.08h48c9.69-33.37 35.78-73.18 52.41-92.15A175.93 175.93 0 0 0 320 0Zm0 80a96.11 96.11 0 0 0-96 96 16 16 0 0 0 32 0 64.08 64.08 0 0 1 64-64 16 16 0 0 0 0-32Zm-79.94 379.19a16 16 0 0 0 2.69 8.84l24.5 36.83a16 16 0 0 0 13.31 7.14h78.85a16 16 0 0 0 13.34-7.14l24.5-36.86a16.2 16.2 0 0 0 2.69-8.84L400 416H240Z',
      fill: 'currentColor'
    }
  ],
  name: 'lightbulb-on',
  type: 'regular'
}
