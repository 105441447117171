import { CIcon } from '~/icons/types'
export const ciTaxi: CIcon = {
  width: 512,
  height: 244.9,
  paths: [
    {
      d:
        'M481.4 102.5c-17.5-11-39.3-12.8-78-14.4-5.9-4.4-14.8-12.6-20.2-16.9C358 51.3 334 32.3 288.1 25.8L277.5 5.6c-1.8-3.4-5.2-5.5-9-5.6s-7.3 1.8-9.3 5.1l-11.1 18.4C160.5 23.7 121 29 99.4 38.7c-.4.2-.9.3-1.3.5-.1.1-.3.2-.4.2-11.9 5.7-18.2 12.9-24.5 21.4-4.3 5.8-8.3 11.1-16.3 16.6l-25.2 7.5c-13.1 4-22.8 17-24 32.5-.3 3.5-.3 7.2-.4 11.1 0 2.1-.1 4.3-.1 6.6-4.7 5.5-6.5 13.6-6.5 20.1V178c0 20.2 11.8 33.8 29.3 33.8h15.9c-.5-3.1-.7-6.3-.7-9.5 0-35.1 28.5-63.6 63.6-63.6s63.6 28.5 63.6 63.6c0 3.2-.2 6.4-.7 9.5h151c-.5-3.1-.7-6.3-.7-9.5 0-35.1 28.5-63.6 63.6-63.6s63.6 28.5 63.6 63.6c0 3.2-.2 6.4-.7 9.5h.1c2.9 0 17.3-.1 31.6-2.6 7.7-1.3 31-5.5 31-23v-23.3c-.1-28.1-9.8-47.8-29.8-60.4zM130.6 90s-21.2-31.2-20.9-31.4c7.8-4.3 18.7-6.9 27.5-8.3 18.4-2.9 43.2-4.6 75.6-5.4l7.9 45.1h-90.1zm171.6 68.6H192.7c-5.8 0-10.5-4.7-10.5-10.5s4.7-10.5 10.5-10.5h109.6c5.7 0 10.4 4.7 10.4 10.5s-4.7 10.5-10.5 10.5zM241.8 90 234 44.6c5.8-.1 11.8-.1 18.1-.1 60.6 0 91 22.9 119.7 45.5h-130z',
      fill: ' #FBC02D'
    },
    {
      d:
        'M108.8 159.7c-23.5 0-42.6 19.1-42.6 42.6 0 3.3.4 6.4 1.1 9.5 4.3 18.9 21.3 33.1 41.5 33.1s37.2-14.2 41.5-33.1c.7-3.1 1.1-6.2 1.1-9.5 0-23.5-19.1-42.6-42.6-42.6zm276.8 0c-23.5 0-42.6 19.1-42.6 42.6 0 3.3.4 6.4 1.1 9.5 4.3 18.9 21.3 33.1 41.5 33.1s37.2-14.2 41.5-33.1c.7-3.1 1.1-6.2 1.1-9.5 0-23.5-19.1-42.6-42.6-42.6z',
      fill: ' #FBC02D'
    }
  ],
  name: 'taxi',
  type: 'solid'
}
