



































import { defineComponent } from '@nuxtjs/composition-api'
import { useI18n } from '~/compositions/i18n'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()

    return {
      t
    }
  },
  i18n: defineComponentTranslations({
    make_products_title: {
      en: 'Transform your classifieds to products',
      el: 'Μετάτρεψε τις αγγελίες σου σε προϊόντα'
    },
    make_products_step_1: {
      en: 'Choose one of your classifieds below',
      el: 'Διάλεξε μια από τις αγγελίες σου παρακάτω'
    },
    make_products_step_2: {
      en:
        'In the {eshop} tab of the classified edit page, set the product details (such as availability, shipping method, etc.)',
      el:
        'Στην καρτέλα {eshop} της επεξεργασίας όρισε τα στοιχεία του προϊόντος (όπως διαθεσιμότητα, τρόπος αποστολής κλπ)'
    },
    make_products_step_3: {
      en: 'Happy selling!',
      el: 'Καλές πωλήσεις!'
    }
  })
})
