import { CIcon } from '~/icons/types'
export const ciPiston: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M506 117q6 7 6 17t-6 17l-1 1-12 12q-6 6-12 0l-6-6-11 12 6 6q6 6 0 12-2 3-5.5 3t-6.5-3l-6-6-13 12 12 12q6 6 0 12L306 363q0 3-5 3-3 0-6-3l-9-8q-9-9-9-21.5t9-21.5l18-18q-1-11-4-21L165 409q7 19 1 35l18 18q2 3 2 6.5t-2 5.5l-36 35q-3 3-6 3t-6-3l-18-17q-20 7-42 2t-37-20q-16-16-21-37.5t2-41.5L2 377q-2-2-2-5.5t3-5.5l36-36q6-6 12 0l17 17q18-5 36 1l136-135q-10-3-20-4l-18 18q-9 9-21.5 9t-21.5-9l-8-8q-6-6 0-12L296 62q1-3 5-3 3 0 6 3l12 12 13-13-6-6q-6-6 0-12t12 0l6 6 11-11-6-6q-3-3-3-6.5t3-5.5l12-12q8-8 18.5-8T397 8l108 108zM150 401l143-143q-13-24-36-37L113 364q-4 4-9 2-19-8-34-1-5 3-9-2l-16-15-24 24 16 15q4 4 2 10-7 16-3.5 34.5T51 462q29 29 66 13 1-1 3-1 3 0 6 3l16 14 24-23-15-15q-5-4-2-9 6-14-1-34-3-5 2-9zm151-56 133-133-12-12L313 91l-12-12-133 133 3 3q9 9 19 0l20-21q3-3 6-3 22 1 42 11 17 9 30 22l1 1 2 2q12 13 19 28 1 1 1 2 9 19 10 40 0 4-2 6l-21 21q-9 9 0 19zm127-163 12-12-96-97-13 12zM356 61l96 97 13-12-97-97zm138 79q5-6 0-13L386 19q-6-6-12 0l-6 6 6 6 106 106q2 1 3 2l6 6zM247 255q6-6 12 0t0 12l-81 81q-3 2-6.5 2t-5.5-2q-6-6 0-12zM63 391q13-13 30.5-13t30.5 13q13 12 13 30t-13 29q-14 13-31 13-18 0-30-13-12-12-12-29 0-19 12-30zm48 47q8-7 8-17 0-12-8-18-6-8-18-8-10 0-17.5 7.5T68 421t7 17q7 8 18 8t18-8zm214-285q14 0 24 10t10 24-10 24-24 10-24-10-10-24 10-24 24-10zm0 51q7 0 12-5t5-12-5-12-12-5-12 5-5 12 5 12 12 5z',
      fill: 'currentColor'
    }
  ],
  name: 'piston',
  type: 'light'
}
