import { CIcon } from '~/icons/types'
export const ciGokart: CIcon = {
  name: 'gokart',
  type: 'regular',
  width: 512,
  height: 230.6,
  paths: [
    {
      d:
        'm465.2 137.7-2.2-4.5c-13.6-29.4-36.9-52.9-65.6-66.2 0 0-.1-.1-.2-.1-1-.4-1.9-.9-3-1.3l-45.6-19.8c-5.3-2.3-11.5.1-13.8 5.4-2.3 5.3.1 11.5 5.4 13.8L386 84.9c.1 0 .2.1.3.1.8.3 1.6.7 2.4 1 22.7 10.5 41.5 28.8 53.2 51.6h-17.4c-10.3-11.9-25.5-19.5-42.5-19.5-9.9 0-19.2 2.6-27.3 7.1L303.6 64l14.3-14.3c4.1-4.1 4.1-10.7 0-14.8-4.1-4.1-10.7-4.1-14.8 0l-42.2 42.2c-4.1 4.1-4.1 10.7 0 14.8 2.1 2.1 4.7 3.1 7.4 3.1s5.4-1 7.4-3.1l13-13 49 58.7H228c-.1-.1-.2-.2-.3-.2-4.3-3.4-8.8-7.2-13.8-11.7C184 99 158.1 67.4 136.8 31.6L121.1 5.1c-2.4-4.1-7.2-6-11.8-4.8-4.6 1.2-7.7 5.4-7.7 10.1V62h-8.5C69.3 62 50 77.5 50 96.6v22.6C24 124.1 4.2 147 4.2 174.4c0 31 25.2 56.2 56.2 56.2 27.6 0 50.6-20 55.3-46.2l20.3 23.1c2 2.3 4.9 3.6 7.9 3.6h195.6c10.3 11.9 25.5 19.5 42.5 19.5s32.2-7.6 42.5-19.5h72.8c5.8 0 10.5-4.7 10.5-10.5v-20.3c0-23.5-19.1-42.6-42.6-42.6zm-269.4 0h-73.2V73.9c.1-.5.1-.9.1-1.4s0-.9-.1-1.4V48.7c20.6 33.4 45.2 63.2 73.2 89zM93.1 83h8.5v53.2c-8-8.6-18.6-14.7-30.6-17V96.6C71 90.2 80.1 83 93.1 83zM60.4 209.6c-19.4 0-35.2-15.8-35.2-35.2s15.8-35.2 35.2-35.2 35.2 15.8 35.2 35.2-15.8 35.2-35.2 35.2zm88.3-19.5-27.6-31.4h207c-1.5 5-2.2 10.3-2.2 15.7s.8 10.7 2.2 15.7H148.7zM382 209.6c-11.8 0-22.2-5.8-28.6-14.7-.1-.2-.3-.4-.4-.6-3.9-5.7-6.2-12.5-6.2-19.9s2.3-14.2 6.2-19.9c.1-.2.3-.4.4-.6 6.4-8.9 16.8-14.7 28.6-14.7 19.4 0 35.2 15.8 35.2 35.2s-15.8 35.2-35.2 35.2zm104.8-19.5H436c1.5-5 2.2-10.3 2.2-15.7s-.8-10.7-2.2-15.7h29.2c11.9 0 21.6 9.7 21.6 21.6v9.8z',
      fill: 'currentColor'
    }
  ]
}
