import { CIcon } from '~/icons/types'
export const ciCloth: CIcon = {
  name: 'cloth',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M147.598 433.598H39.199C17.582 433.598 0 451.184 0 472.797 0 494.414 17.586 512 39.2 512h108.398c21.617 0 39.199-17.586 39.199-39.203 0-21.613-17.582-39.2-39.2-39.2zm0-433.598H39.199C17.582 0 0 17.586 0 39.2v376.612c11.152-7.69 24.656-12.214 39.2-12.214h108.398c14.547 0 28.05 4.523 39.199 12.215V39.199c0-21.613-17.582-39.199-39.2-39.199zm334.234 54.2H216.797v418.597c0 14.547-4.52 28.05-12.215 39.203h277.25c16.633 0 30.164-13.531 30.164-30.164V84.367c0-16.633-13.531-30.168-30.164-30.168zm0 0',
      fill: 'currentColor'
    }
  ]
}
