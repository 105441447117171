import { CIcon } from '~/icons/types'
export const ciUsb: CIcon = {
  width: 306.65,
  height: 512,
  paths: [
    {
      d:
        'M241.52 248.54h-32.11a9.27 9.27 0 0 0-9.24 9.25v32.11a9.26 9.26 0 0 0 9.24 9.24h5.28v11.14l-15.56 5.19-33.53 11.17v-96.88h15.65a9.33 9.33 0 0 0 7.53-14.85l-27.18-37.06a9.34 9.34 0 0 0-15 0l-27.17 37.06a9.33 9.33 0 0 0 7.52 14.85h14.65v122.6l-9.17-3.06-38.91-12.95h-.06v-19.51a25.79 25.79 0 1 0-24-.26V345c0 5.52 3.78 9.91 8.83 11.59l24.4 8.14 38.85 13h.06v27.75a32.07 32.07 0 1 0 24 0V352l16.57-5.52L221 333.49l8.88-3a12.36 12.36 0 0 0 8.83-11.59v-19.76h2.78a9.27 9.27 0 0 0 9.24-9.24v-32.11a9.27 9.27 0 0 0-9.21-9.25Z'
    },
    {
      d:
        'M282.43 129.26h-19.85V24.19A26.07 26.07 0 0 0 260 13.4a21.69 21.69 0 0 0-4.38-6.22 21.66 21.66 0 0 0-6-4.41A25.55 25.55 0 0 0 238.44 0H68.24a25.13 25.13 0 0 0-11.75 3.06c-5.39 2.88-9 7.9-11.13 13.51-1.71 4.61-1.22 10.2-1.22 15.07v97.62h-17.7c-1.19 0-2.38 0-3.56.06-8.41.76-16.62 5.28-20.23 13.25a26 26 0 0 0-2.59 10.92v327.05c0 2.7-.14 5.45 0 8.15.52 8.46 5.06 16.67 12.86 20.52A25.47 25.47 0 0 0 24.33 512H282.6a24.47 24.47 0 0 0 24-24.2V153.31a24.46 24.46 0 0 0-24.17-24.05ZM68.19 24.14v-.05h.11c1.36-.05 2.74 0 4.09 0h166.12v92.06c0 4.3-.06 8.61 0 12.91a1.21 1.21 0 0 1 0 .19H68.19V36.31c0-4.05-.04-8.11 0-12.17ZM282.6 162v325.9H24.14v-.07c-.15-2.84 0-5.74 0-8.56V153.36h.09c2.14-.09 4.32 0 6.45 0h251.9v.08c.18 2.82.02 5.72.02 8.56Z'
    }
  ],
  name: 'usb',
  type: 'regular'
}
