import { CIcon } from '~/icons/types'
export const ciCorner: CIcon = {
  name: 'corner',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M501.259 0H10.821C5.299 0 .822 4.477.821 9.998l-.08 492a10.004 10.004 0 0 0 10 10.002h281.244c5.523 0 10-4.478 10-10V301.243h199.274c5.523 0 10-4.478 10-10V10c0-5.522-4.477-10-10-10zM141.363 492H20.743l.076-472h470.439v120.622H193.225v-22.703c0-5.522-4.477-10-10-10H117.82c-5.523 0-10 4.478-10 10v65.405c0 5.522 4.477 10 10 10h23.543V492zm31.862-364.081v45.405H127.82v-45.405h45.405zm318.034 153.324H291.985c-5.523 0-10 4.478-10 10V492H161.363V193.324h21.862c5.523 0 10-4.478 10-10v-22.702h298.034v120.621z',
      fill: 'currentColor'
    }
  ]
}
