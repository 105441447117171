import { CIcon } from '~/icons/types'
export const ciSemitrailer: CIcon = {
  name: 'semitrailer',
  type: 'regular',
  width: 512,
  height: 302.8,
  paths: [
    {
      d:
        'M499.4 0H12.6C6.8 0 2.1 4.7 2.1 10.5v238.9c0 5.8 4.7 10.5 10.5 10.5H49c4.4 24.4 25.7 42.9 51.3 42.9s46.9-18.5 51.3-42.9h15.2c4.4 24.4 25.7 42.9 51.3 42.9 26.1 0 47.9-19.4 51.5-44.5l44.3-18.6h155.4v30h-2.6c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h26.2c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-2.6v-30h9.2c5.8 0 10.5-4.7 10.5-10.5V10.5C509.9 4.7 505.2 0 499.4 0zM100.3 281.8c-17.1 0-31.1-14-31.1-31.1s14-31.1 31.1-31.1 31.1 14 31.1 31.1-14 31.1-31.1 31.1zm117.7 0c-17.1 0-31.1-14-31.1-31.1s14-31.1 31.1-31.1 31.1 14 31.1 31.1-14 31.1-31.1 31.1zm270.9-63.1H311.7c-1.4 0-2.8.3-4.1.8L268 236.2c-6.3-21.7-26.3-37.6-50-37.6-24.7 0-45.4 17.2-50.7 40.3H151c-5.4-23.1-26.1-40.3-50.7-40.3s-45.4 17.2-50.7 40.3H23.1V21h465.8v197.7z',
      fill: 'currentColor'
    }
  ]
}
