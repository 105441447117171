import axios, { AxiosInstance, CancelTokenSource } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import {
  SearchbarLegacySuggestion,
  SearchbarSuggestion
} from '~/models/search-bar'
import { invalidBodyError } from '~/services/errors'
import { toCamelCase } from '~/utils/object'
import VueI18n from 'vue-i18n'
import { CategoryId } from '~/models/category/types'
import qs from 'qs'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import { resolveCategory } from '~/utils/category'
import { getRandomArrayItem, shuffleArray } from '~/utils/array'
import { getRandomNumberBetween } from '~/utils/number'

@containerScoped()
export default class SearchbarService {
  private cancelSource: CancelTokenSource
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(VueI18n) private i18n: VueI18n,
    @inject(Store) private store: Store<RootState>
  ) {
    this.cancelSource = axios.CancelToken.source()
  }

  async getAutocompleteSuggestions(
    q: string | null,
    category: number,
    context?: string | null
  ): Promise<SearchbarSuggestion[]> {
    const response = await this.http.get(
      '/api/classifieds/text-search/autocomplete/',
      {
        cancelToken: this.cancelSource.token,
        params: {
          q,
          category,
          context
        }
      }
    )

    const body = response.data

    if (!(body && body.data && body.data.suggestions)) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data.suggestions)
  }

  private getLegacyAutocompleteUrl(q: string | null, category: number) {
    let baseUrl

    const config = this.store.state.classifieds.search?.config?.settings
      ?.searchbar
    const userOwnsSearch = this.store.state.classifieds.search?.userOwnsSearch

    if (userOwnsSearch) {
      baseUrl = '/api/classifieds/my/query/'
    } else if (config?.autocompleteUrl) {
      baseUrl = config.autocompleteUrl
    } else if (config?.autocompleteEndpoint) {
      baseUrl = config?.autocompleteEndpoint
    } else {
      baseUrl = '/api/classifieds/query/'
    }

    const profileId =
      this.store.state.classifieds.search?.params?.profile || null

    const dsiteWebsiteId =
      this.store.state.classifieds.search?.config?.settings?.sellerWebsiteId ||
      null

    const extraParams = {
      category,
      q,
      profile: profileId,
      dsite: dsiteWebsiteId
    }

    return `${baseUrl}?${qs.stringify(extraParams, {
      arrayFormat: 'repeat',
      encode: false,
      skipNulls: true
    })}`
  }

  async getLegacySuggestions(
    q: string | null,
    category: number
  ): Promise<SearchbarLegacySuggestion[]> {
    const url = this.getLegacyAutocompleteUrl(q, category)

    const response = await this.http.get(url, {
      cancelToken: this.cancelSource.token
    })

    const body = response.data

    if (!(body && body.data && body.data.suggestions)) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data.suggestions)
  }

  async getQuerySuggest(q: string | null, category: number): Promise<any> {
    const response = await this.http.get(
      '/api/classifieds/text-search/query-suggest/',
      {
        cancelToken: this.cancelSource.token,
        params: {
          q,
          category
        }
      }
    )

    const body = response.data

    if (
      !(
        body &&
        body.data &&
        (body.data.suggestions || body.data.classified || body.data.fallback)
      )
    ) {
      throw invalidBodyError(body)
    }
    return {
      classified: body.data.classified || null,
      fallback: body.data.fallback || null,
      suggestions: toCamelCase(body.data.suggestions) || []
    }
  }

  async removeAutocompleteSuggestion(id: string): Promise<number> {
    const response = await this.http.delete(
      '/api/classifieds/text-search/autocomplete/',
      { data: { id } }
    )

    const body = response.data

    if (!(body && body.data && body.data.id)) {
      throw invalidBodyError(body)
    }
    return body.data.id
  }

  async clearAllHistory(): Promise<any> {
    const response = await this.http.delete(
      '/api/classifieds/text-search/autocomplete/clear/'
    )

    const body = response.data

    if (!(body && body.data && body.data.deleted)) {
      throw invalidBodyError(body)
    }
    return body.data.deleted
  }

  async getSuggestedClssifieds(
    q: string | null,
    category: number
  ): Promise<any> {
    const response = await this.http.get('/api/classifieds/text-search/raw/', {
      cancelToken: this.cancelSource.token,
      params: { q, category }
    })

    const body = response.data

    if (!(body && body.data)) {
      throw invalidBodyError(body)
    }
    return body.data
  }

  postSuggestionForHistory(
    category: number,
    suggestedUrl: string,
    q?: string,
    uqtId?: number | string,
    context?: string | null
  ) {
    // no await here
    this.http.post('/api/classifieds/text-search/autocomplete/', {
      url: suggestedUrl || null,
      q: q || null,
      category,
      uqtId: uqtId || null,
      context
    })
  }

  async cancelRequests() {
    await this.cancelSource.cancel()
    this.cancelSource = axios.CancelToken.source()
  }

  public getCategoryPlaceholders(category: CategoryId) {
    return category === CategoryId.VEHICLES
      ? this.getRandomVehiclePlaceholders()
      : this.getRandomPlaceholderArrayForCategory(category, false)
  }

  private getRandomVehiclePlaceholders() {
    let randomVehiclePlaceholders = []
    try {
      const unshuffledPlaceholders = Array.prototype.concat.apply(
        [],
        [
          this.getRandomPlaceholderArrayForCategory(CategoryId.CARS, false),
          this.getRandomPlaceholderArrayForCategory(CategoryId.BIKES, false),
          this.getRandomPlaceholderArrayForCategory(CategoryId.BOATS, false),
          this.getRandomPlaceholderArrayForCategory(CategoryId.BICYCLES, true),
          this.getRandomPlaceholderArrayForCategory(CategoryId.TRUCKS, true),
          this.getRandomPlaceholderArrayForCategory(CategoryId.TAXIS, true),
          this.getRandomPlaceholderArrayForCategory(CategoryId.CARAVANS, true),
          this.getRandomPlaceholderArrayForCategory(
            CategoryId.MOTORHOMES,
            true
          ),
          this.getRandomPlaceholderArrayForCategory(
            CategoryId.RADIOCONTROLS,
            true
          ),
          this.getRandomPlaceholderArrayForCategory(CategoryId.GOKARTS, true),
          this.getRandomPlaceholderArrayForCategory(
            CategoryId.WATERSPORTS,
            true
          ),
          this.getRandomPlaceholderArrayForCategory(CategoryId.AIRSPORTS, true),
          this.getRandomPlaceholderArrayForCategory(CategoryId.VANS, true),
          this.getRandomPlaceholderArrayForCategory(CategoryId.BUSES, true),
          this.getRandomPlaceholderArrayForCategory(CategoryId.BUILDERS, true),
          this.getRandomPlaceholderArrayForCategory(
            CategoryId.SEMITRUCKS,
            true
          ),
          this.getRandomPlaceholderArrayForCategory(
            CategoryId.SEMITRAILERS,
            true
          ),
          this.getRandomPlaceholderArrayForCategory(CategoryId.TRACTORS, true),
          this.getRandomPlaceholderArrayForCategory(CategoryId.FORKLIFTS, true)
        ]
      )
      // remove first entry so that it always is a random car
      const firstCarEntry = unshuffledPlaceholders.shift()
      randomVehiclePlaceholders = shuffleArray(unshuffledPlaceholders)
      randomVehiclePlaceholders.unshift(firstCarEntry)
    } catch (_e) {}

    return randomVehiclePlaceholders
  }

  public getRandomPlaceholderArrayForCategory(
    category: CategoryId,
    showCategory: boolean = true
  ) {
    const selectedCategory = resolveCategory(
      this.categoryPlaceholderExampleTexts(),
      [category]
    )

    if (!selectedCategory) {
      return []
    }

    return shuffleArray(
      selectedCategory.entries.map((e: any) =>
        this.getPlaceholderTextForEntry(
          e,
          showCategory ? e.category || selectedCategory.category : undefined
        )
      )
    )
  }

  public getPlaceholderTextForEntry(selectedEntry: any, prefix?: string) {
    const finalTextArray = []

    if (prefix) {
      finalTextArray.push(prefix)
    }

    if (selectedEntry.make) {
      finalTextArray.push(selectedEntry.make)
    }

    if (selectedEntry.model) {
      finalTextArray.push(selectedEntry.model)
    }

    if (
      selectedEntry.registrationRange?.min &&
      selectedEntry.registrationRange?.max &&
      this.diceFavored()
    ) {
      finalTextArray.push(
        getRandomNumberBetween(
          selectedEntry.registrationRange.min,
          selectedEntry.registrationRange.max
        )
      )
    }

    if (
      selectedEntry.facets &&
      (this.diceFavored() || finalTextArray.length === 0)
    ) {
      let randomFacet = getRandomArrayItem(selectedEntry.facets)
      if (finalTextArray.length) {
        randomFacet = randomFacet.toLowerCase()
      }
      finalTextArray.push(randomFacet)
    }

    return finalTextArray.join(' ')
  }

  private diceFavored(): boolean {
    return getRandomNumberBetween(1, 100) < 70
  }

  private categoryPlaceholderExampleTexts() {
    const fuelGas = this.i18n.t('gas::aerio')
    const fuelGasoline = this.i18n.t('gasoline')
    const fuelElectric = this.i18n.t('electric')
    const fuelDiesel = this.i18n.t('diesel')
    const conditionNew = (this.i18n.t('new') as string).toLowerCase()

    return Object.freeze({
      [CategoryId.CARS]: {
        category: this.i18n.t('car::vehicles'),
        entries: [
          {
            make: 'Toyota',
            model: 'Yaris',
            registrationRange: {
              min: 2007,
              max: 2021
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Volkswagen',
            model: 'Golf',
            registrationRange: {
              min: 2007,
              max: 2019
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Bmw',
            model: '316',
            registrationRange: {
              min: 2011,
              max: 2015
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Mercedes',
            model: 'A180',
            registrationRange: {
              min: 2012,
              max: 2017
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Audi',
            model: 'A4',
            registrationRange: {
              min: 2008,
              max: 2018
            }
          },
          {
            make: 'Opel',
            model: 'Corsa',
            registrationRange: {
              min: 2010,
              max: 2022
            },
            facets: [fuelGasoline, fuelDiesel, fuelGas]
          },
          {
            make: 'Ford',
            model: 'Fiesta',
            registrationRange: {
              min: 2014,
              max: 2022
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Nissan',
            model: 'Qashqai',
            registrationRange: {
              min: 2010,
              max: 2022
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Fiat',
            model: 'Punto',
            registrationRange: {
              min: 2010,
              max: 2018
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Peugeot',
            model: '208',
            registrationRange: {
              min: 2012,
              max: 2021
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Hyundai',
            model: 'i20',
            registrationRange: {
              min: 2011,
              max: 2022
            },
            facets: [fuelDiesel]
          },
          {
            make: 'Suzuki',
            model: 'Swift',
            registrationRange: {
              min: 2008,
              max: 2022
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Citroen',
            model: 'C3',
            registrationRange: {
              min: 2010,
              max: 2022
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Smart',
            model: 'ForTwo',
            registrationRange: {
              min: 2007,
              max: 2021
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Honda',
            model: 'Civic',
            registrationRange: {
              min: 2008,
              max: 2020
            },
            facets: [fuelGasoline]
          },
          {
            make: 'Seat',
            model: 'Ibiza',
            registrationRange: {
              min: 2010,
              max: 2022
            },
            facets: [fuelGasoline, fuelDiesel]
          },
          {
            make: 'Mazda',
            model: 'RX 8',
            registrationRange: {
              min: 2005,
              max: 2009
            }
          }
        ]
      },
      [CategoryId.BIKES]: {
        category: this.i18n.t('bike::vehicles'),
        entries: [
          {
            make: 'Yamaha',
            model: 'Crypton x135'
          },
          {
            make: 'Yamaha',
            model: 'Tracer 900'
          },
          {
            make: 'Honda',
            model: 'GTR 150'
          },
          {
            make: 'Piaggio',
            model: 'Beverly'
          },
          {
            make: 'Suzuki',
            model: 'V Storm 650'
          },
          {
            make: 'Kawasaki',
            model: 'Z 750'
          },
          {
            make: 'Ducati',
            model: 'Multistrada 1200 S'
          },
          {
            make: 'Triumph',
            model: 'Tiger 250'
          }
        ]
      },
      [CategoryId.BOATS]: {
        category: this.i18n.t('boat::vehicles'),
        entries: [
          {
            make: 'Jet Ski'
          },
          {
            make: this.i18n.t('boat::vehicles'),
            model: 'Nireus'
          },
          {
            make: `${this.i18n.t('inflatable')} ${(this.i18n.t(
              'boat::vehicles'
            ) as string).toLowerCase()}`
          }
        ]
      },
      [CategoryId.BICYCLES]: {
        category: this.i18n.t('bicycle::vehicles'),
        entries: [
          {
            facets: [fuelElectric]
          },
          {
            make: 'Ideal',
            model: 'Mountain',
            facets: [conditionNew]
          },
          {
            make: 'Orient',
            model: 'Mountain'
          },
          {
            facets: [this.i18n.t("children's")]
          }
        ]
      },
      [CategoryId.TRUCKS]: {
        category: this.i18n.t('truck::fortigo'),
        entries: [
          {
            category: this.i18n.t('dump van::vehicles:329'),
            make: 'Volvo'
          },
          {
            category: this.i18n.t('chassis::vehicles:334'),
            make: 'Scania'
          },
          {
            make: 'Man'
          },
          {
            category: this.i18n.t('truck trailer::vehicles'),
            make: 'Mercedes'
          },
          {
            category: this.i18n.t('truck trailer::vehicles'),
            make: 'Mercedes'
          },
          {
            facets: [this.i18n.t('refrigerator::vehicles')]
          }
        ]
      },
      [CategoryId.TAXIS]: {
        category: this.i18n.t('taxi::vehicles'),
        entries: [
          {
            make: 'Mercedes'
          },
          {
            make: 'Skoda',
            model: 'Octavia',
            facets: [fuelGas]
          }
        ]
      },
      [CategoryId.CARAVANS]: {
        category: this.i18n.t('caravan::vehicles'),
        entries: [
          {
            make: 'Knaus'
          },
          {
            make: 'Tabbert',
            facets: [conditionNew]
          }
        ]
      },
      [CategoryId.MOTORHOMES]: {
        category: this.i18n.t('motorhome::vehicles'),
        entries: [
          {
            make: 'Volkswagen'
          },
          {
            make: 'Fiat',
            facets: [conditionNew]
          }
        ]
      },
      [CategoryId.VANS]: {
        category: this.i18n.t('van::vehicles'),
        entries: [
          {
            category: this.i18n.t('paddy wagon::vehicles:74'),
            make: 'Ford',
            model: 'Transit'
          },
          {
            category: this.i18n.t('paddy wagon::vehicles:74'),
            make: 'Mercedes',
            model: 'Vito'
          },
          {
            category: this.i18n.t('paddy wagon::vehicles:74'),
            make: 'Opel'
          }
        ]
      },
      [CategoryId.BUSES]: {
        category: this.i18n.t('bus::vehicles'),
        entries: [
          {
            make: 'Mercedes'
          },
          {
            make: 'Setra'
          },
          {
            make: 'Scania'
          },
          {
            make: 'Neoplan'
          }
        ]
      },
      [CategoryId.BUILDERS]: {
        category: this.i18n.t('builder::vehicles'),
        entries: [
          {
            category: this.i18n.t('excavator::vehicles'),
            model: 'JCB'
          },
          {
            category: this.i18n.t('excavator::vehicles'),
            make: 'CAT'
          },
          {
            category: this.i18n.t('skid steer::vehicles:113'),
            make: 'Bobcat'
          },
          {
            category: this.i18n.t('excavator::vehicles'),
            model: 'Liebherr'
          },
          {
            category: this.i18n.t('excavator::vehicles')
          }
        ]
      },
      [CategoryId.SEMITRUCKS]: {
        category: this.i18n.t('semitruck::vehicles'),
        entries: [
          {
            make: 'Scania'
          },
          {
            make: 'Volvo'
          },
          {
            make: 'Daf'
          }
        ]
      },
      [CategoryId.SEMITRAILERS]: {
        category: this.i18n.t('semitrailer::vehicles'),
        entries: [
          {
            make: 'Schmitz'
          },
          {
            make: 'Schwarzmüller'
          },
          {
            make: 'Kögel'
          },
          {
            make: 'Krone'
          }
        ]
      },
      [CategoryId.TRACTORS]: {
        category: this.i18n.t('tractor::vehicles'),
        entries: [
          {
            category: this.i18n.t('tractor::trakter'),
            make: 'John Deere'
          },
          {
            category: this.i18n.t('subsoiler::vehicles'),
            make: 'Maschio'
          },
          {
            category: this.i18n.t('subsoiler::vehicles'),
            make: 'Graecus'
          },
          {
            category: this.i18n.t('tractor::trakter'),
            make: 'Fiat'
          },
          {
            category: this.i18n.t('plough::vehicles'),
            make: 'Kverneland'
          },
          {
            category: this.i18n.t('tanker truck::vehicles:94'),
            make: 'Mitsubishi'
          },
          {
            category: this.i18n.t('sprinkle::vehicles')
          }
        ]
      },
      [CategoryId.FORKLIFTS]: {
        category: this.i18n.t('forklift::vehicles'),
        entries: [
          {
            facets: [fuelGasoline]
          },
          {
            facets: [fuelElectric]
          },
          {
            make: 'Toyota',
            facets: [fuelElectric]
          },
          {
            make: 'Mitsubishi'
          },
          {
            category: this.i18n.t('pallet truck::vehicles'),
            make: 'Liftex'
          }
        ]
      },
      [CategoryId.JOBS]: {
        category: this.i18n.t('job'),
        entries: [
          {
            facets: [this.i18n.t('mechanic') as string]
          },
          {
            facets: [this.i18n.t('seller') as string]
          },
          {
            facets: [this.i18n.t('waiter') as string]
          },
          {
            facets: [this.i18n.t('electrician') as string]
          }
        ]
      }
    })
  }
}
