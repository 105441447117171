import { CIcon } from '~/icons/types'
export const ciAngleLeftLight: CIcon = {
  name: 'angleLeft',
  type: 'solid',
  width: 192,
  height: 512,
  paths: [
    {
      d:
        'm25.1 247.5 117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z',
      fill: 'currentColor'
    }
  ]
}
