import { containerScoped } from '~/decorators/dependency-container'
import {
  Facet,
  FacetDeselectProps,
  InstallmentParamsEnum,
  PurchaseTypesEnum
} from '~/models/search/types'
import { inject } from 'tsyringe'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'

import {
  PREVIOUS_PROMOTIONS_PARAM_NAME,
  PROMOTION_PARAM_NAME,
  PROMOTION_TIER_PARAM_NAME
} from '~/constants/promoted'
import {
  MAKE_IDS_SEARCH_PARAM,
  MAKE_MODELS_REGISTRATION_SEARCH_PARAM,
  MAKE_SEARCH_PARAM,
  MAKES_IDS_SEARCH_PARAM,
  MAKES_SEARCH_PARAM,
  MODEL_IDS_SEARCH_PARAM,
  MODEL_SEARCH_PARAM,
  MODELS_IDS_SEARCH_PARAM,
  MODELS_SEARCH_PARAM,
  SERIES_SEARCH_PARAM,
  VARIANT_SEARCH_PARAM
} from '~/constants/makemodels'
import {
  COUNTRY_CODE_SEARCH_PARAM,
  DISTANCE_SEARCH_PARAM,
  POSTCODE_SEARCH_PARAM
} from '~/constants/postcode'
import {
  DURATION_SEARCH_PARAM,
  LEASE_DOWNPAYMENT_FROM_SEARCH_PARAM,
  LEASE_DOWNPAYMENT_TO_SEARCH_PARAM,
  LEASE_FROM_SEARCH_PARAM,
  LEASE_TO_SEARCH_PARAM,
  WITH_VAT_SEARCH_PARAM,
  YEARLY_MILEAGE_SEARCH_PARAM
} from '~/constants/leasing'
import { emptyLeasingParams } from '~/constants/search/facets'
import { createSearchGeolocationString } from '~/utils/geolocation'
import { GeocodingSearchLocation } from '~/models/location/geocoding'

@containerScoped()
export default class SearchFacetsDeselectService {
  constructor(@inject(Store) private store: Store<RootState>) {}

  public deselectFacet({
    handler,
    paramValue,
    paramUrlArg
  }: {
    handler: Facet
    paramValue: any
    paramUrlArg: string
  }): void {
    const urlArg = paramUrlArg || handler.urlArgNames[0]
    const value = paramValue
    switch (handler.type) {
      case 'KeyValueSearchHandler':
      case 'BlacklistReasonSearchHandler':
      case 'TermSearchHandler':
      case 'StatesSearchHandler':
      case 'ConditionSearchHandler':
      case 'SellerTypeSearchHandler':
      case 'VehicleKteoSearchHandler':
      case 'MediaTypesSearchHandler':
      case 'VehicleFeaturesSearchHandler':
      case 'CrashedSearchHandler':
      case 'BooleanSearchHandler':
      case 'JobExtrasSearchHandler':
      case 'DatetimeSearchHandler':
      case 'ModifiedSearchHandler':
      case 'DeletedDateSearchHandler':
      case 'DiscountConditionSearchHandler':
      case 'DiscountSellerTypeSearchHandler':
      case 'UserTypeSearchHandler':
      case 'JobTypeSearchHandler':
      case 'OfferTypeSearchHandler':
      case 'FuelTypeSearchHandler': {
        return this.deselectKeyValueSearchHandler({ handler, urlArg, value })
      }
      case 'BatteryRangeSearchHandler':
      case 'BatteryChargeTimeSearchHandler':
      case 'BatteryQuickChargeTimeSearchHandler':
      case 'BatteryCapacitySearchHandler':
      case 'EngineSizeSearchHandler':
      case 'EnginePowerSearchHandler':
      case 'LengthSearchHandler':
      case 'WeightSearchHandler':
      case 'VehicleRegistrationSearchHandler':
      case 'FloorSearchHandler':
      case 'PriceSearchHandler':
      case 'KilowattSearchHandler':
      case 'ElectricEnginePowerSearchHandler': {
        return this.deselectRangeSearchHandler({ handler, urlArg, value })
      }
      case 'Location2SearchHandler':
      case 'DiscountLocation2SearchHandler':
      case 'LocationSearchHandler':
      case 'DiscountCategorySearchHandler':
      case 'CategoryTreeSearchHandler':
      case 'DeletedCategorySearchHandler':
      case 'AdminCategorySearchHandler': {
        return this.deselectTreeSearchHandler({ handler, urlArg, value })
      }
      case 'ShopKeyValueSearchHandler':
      case 'DiscountShopKeyValueSearchHandler': {
        return this.deselectShopKeyValueSearchHandler({
          handler,
          urlArg,
          value
        })
      }
      case 'ExteriorColorSearchHandler':
      case 'InteriorColorSearchHandler':
      case 'ClothesColorFieldSearchHandler':
      case 'ShoesColorFieldSearchHandler':
      case 'ClothesSizeFieldSearchHandler':
      case 'ShoesSizeFieldSearchHandler':
      case 'LeasingExtrasSearchHandler':
      case 'SearchableKeyValueSearchHandler': {
        // these facets are of type multiselect and can all be deselected the same way
        return this.deselectByFilteringOut({ handler, urlArg, value })
      }
      case 'FinanceOptionsSearchHandler': {
        return this.deselectFinanceOptionsSearchHandler()
      }
      case 'PromotedSearchHandler': {
        return this.deselectPromotedSearchHandler({ handler, urlArg, value })
      }
      case 'ForMakeModelSearchHandler': {
        return this.deselectForMakeModelSearchHandler({
          handler,
          urlArg,
          value
        })
      }
      case 'FreeTextMakeModelSearchHandler': {
        return this.deselectFreeTextMakeModelSearchHandler({
          handler,
          urlArg,
          value
        })
      }
      case 'MakeModelSearchHandler': {
        return this.deselectMakeModelSearchHandler({ handler, urlArg, value })
      }
      case 'PostcodeDistanceSearchHandler': {
        return this.deselectPostcodeDistanceSearchHandler()
      }
      case 'LeasingOptionsSearchHandler': {
        return this.deselectLeasingOptionsSearchHandler({
          handler,
          urlArg,
          value
        })
      }
      case 'RangeSearchHandler': {
        return this.deselectRangeSearchHandler({ handler, urlArg, value })
      }
      case 'VisibilitySearchHandler': {
        // TODO: this is not very future-proof, in case of multiple VisibilitySearchHandlers
        return this.changeFacet([{ name: 'dealersonly', value: false }])
      }
      case 'FinanceSearchHandler': {
        return this.deselectFinanceSearchHandler({ handler, urlArg, value })
      }
      case 'RentalPickupLocationSearchHandler': {
        return this.deselectPickupLocationSearchHandler({
          handler,
          urlArg,
          value
        })
      }
      default: {
        return this.defaultFacetDeselect({ handler, urlArg, value })
      }
    }
  }

  private deselectFinanceSearchHandler({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    if (value === PurchaseTypesEnum.LEASING) {
      this.changeFacet([...emptyLeasingParams])
    }
    return this.defaultFacetDeselect({ handler, urlArg, value })
  }

  private deselectLeasingOptionsSearchHandler({ urlArg }: FacetDeselectProps) {
    switch (urlArg) {
      case 'lease': {
        this.changeFacet([
          { name: LEASE_FROM_SEARCH_PARAM, value: null },
          { name: LEASE_TO_SEARCH_PARAM, value: null }
        ])
        break
      }
      case 'lease-downpayment': {
        this.changeFacet([
          { name: LEASE_DOWNPAYMENT_FROM_SEARCH_PARAM, value: null },
          { name: LEASE_DOWNPAYMENT_TO_SEARCH_PARAM, value: null }
        ])
        break
      }
      case 'with-vat': {
        this.changeFacet([{ name: WITH_VAT_SEARCH_PARAM, value: null }])
        break
      }
      case 'duration': {
        this.changeFacet([{ name: DURATION_SEARCH_PARAM, value: null }])
        break
      }
      case 'yearly-mileage': {
        this.changeFacet([{ name: YEARLY_MILEAGE_SEARCH_PARAM, value: null }])
      }
    }
  }

  private deselectKeyValueSearchHandler({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    if (handler.multicheck) {
      this.deselectByFilteringOut({ handler, urlArg, value })
    } else {
      this.changeFacet([{ name: urlArg, value: null }])
    }
  }

  private deselectRangeSearchHandler({ handler, value }: FacetDeselectProps) {
    const name = handler.name
    if (value.startsWith('>') || name.includes('-from')) {
      return this.changeFacet([{ name: `${name}-from`, value: null }])
    } else if (value.startsWith('<') || name.includes('-to')) {
      return this.changeFacet([{ name: `${name}-to`, value: null }])
    } else {
      // remove the entire value cause from and to are the same
      return this.changeFacet([
        { name: `${name}-from`, value: null },
        { name: `${name}-to`, value: null },
        { name, value: null }
      ])
    }
  }

  private deselectTreeSearchHandler({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    const { selected }: any = handler.values
    if (!selected || selected?.length === 0 || !value) {
      return this.changeFacet([{ name: urlArg, value: null }])
    }
    return this.deselectByFilteringOut({ handler, urlArg, value })
  }

  private deselectByFilteringOut({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    const { selected }: any = handler.values
    this.changeFacet([
      {
        name: urlArg,
        value: selected.filter((s: any) => s !== value)
      }
    ])
  }

  private deselectShopKeyValueSearchHandler({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    const { selected }: any = handler.values
    this.changeFacet([
      {
        name: urlArg,
        value: selected.values.filter((v: number) => v !== value)
      }
    ])
  }

  private deselectFinanceOptionsSearchHandler() {
    // this is too much of a brute-force deselection, TODO: change in the future
    this.changeFacet([
      {
        name: InstallmentParamsEnum.INSTALLMENT_FROM,
        value: null
      },
      {
        name: InstallmentParamsEnum.INSTALLMENT_TO,
        value: null
      },
      {
        name: InstallmentParamsEnum.INSTALLMENTS,
        value: null
      },
      {
        name: InstallmentParamsEnum.DOWNPAYMENT,
        value: null
      }
    ])
  }

  deselectPromotedSearchHandler({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    switch (urlArg) {
      case PROMOTION_PARAM_NAME: {
        return this.changeFacet([{ name: PROMOTION_PARAM_NAME, value: null }])
      }
      case PREVIOUS_PROMOTIONS_PARAM_NAME: {
        return this.changeFacet([
          { name: PREVIOUS_PROMOTIONS_PARAM_NAME, value: null }
        ])
      }
      case PROMOTION_TIER_PARAM_NAME: {
        return this.deselectByFilteringOut({ handler, urlArg, value })
      }
      default: {
        // if the urlArg is not matched, deselect everything log the case
        this.changeFacet([
          { name: PROMOTION_TIER_PARAM_NAME, value: null },
          { name: PREVIOUS_PROMOTIONS_PARAM_NAME, value: null },
          { name: PROMOTION_PARAM_NAME, value: null }
        ])
      }
    }
  }

  public deselectForMakeModelSearchHandler({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    const { makes: typedMakes, models: typedModels } = handler.values.typed!
    const {
      makes: untypedMakes,
      models: untypedModels
    } = handler.values.untyped!

    switch (urlArg) {
      case MAKES_IDS_SEARCH_PARAM: {
        return this.changeFacet([
          {
            name: MAKES_IDS_SEARCH_PARAM,
            value: typedMakes.filter(m => m !== value)
          },
          {
            name: MODELS_IDS_SEARCH_PARAM,
            value: []
          }
        ])
      }
      case MAKES_SEARCH_PARAM: {
        return this.changeFacet([
          {
            name: MAKES_SEARCH_PARAM,
            value: untypedMakes.filter(m => m !== value)
          },
          {
            name: MODELS_SEARCH_PARAM,
            value: []
          }
        ])
      }
      case MODELS_IDS_SEARCH_PARAM: {
        return this.changeFacet([
          {
            name: MODELS_IDS_SEARCH_PARAM,
            value: typedModels.filter(m => m !== value)
          }
        ])
      }
      case MODELS_SEARCH_PARAM: {
        return this.changeFacet([
          {
            name: MODELS_SEARCH_PARAM,
            value: untypedModels.filter(m => m !== value)
          }
        ])
      }
      case MAKE_MODELS_REGISTRATION_SEARCH_PARAM: {
        return this.changeFacet([
          { name: MAKE_MODELS_REGISTRATION_SEARCH_PARAM, value: null }
        ])
      }
      case MAKE_IDS_SEARCH_PARAM:
      case MODEL_IDS_SEARCH_PARAM: {
        return this.changeFacet([
          { name: 'makes', value: null },
          { name: 'models', value: null },
          { name: 'makes_ids', value: null },
          { name: 'models_ids', value: null }
        ])
      }
    }
  }

  private deselectFreeTextMakeModelSearchHandler({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    const { makes, models }: any = handler.values.selected
    const { urlArgNames } = handler
    if (makes.includes(value)) {
      return this.changeFacet([
        { name: urlArgNames[1], value: makes.filter((m: any) => m !== value) }
      ])
    } else if (models.includes(value)) {
      return this.changeFacet([
        { name: urlArgNames[0], value: models.filter((m: any) => m !== value) }
      ])
    }
    return this.defaultFacetDeselect({ handler, urlArg, value })
  }

  private deselectMakeModelSearchHandler({
    handler,
    urlArg,
    value
  }: FacetDeselectProps) {
    const { selected }: any = handler.values
    const { makes, models } = selected
    switch (urlArg) {
      case MAKE_SEARCH_PARAM: {
        return this.changeFacet([
          {
            name: MAKE_SEARCH_PARAM,
            value: makes.filter((m: any) => m !== value)
          }
        ])
      }
      case MODEL_SEARCH_PARAM:
      case SERIES_SEARCH_PARAM: {
        return this.changeFacet([
          {
            name: MODEL_SEARCH_PARAM,
            value: models.filter((m: any) => m !== value)
          }
        ])
      }
      case VARIANT_SEARCH_PARAM: {
        return this.changeFacet([{ name: VARIANT_SEARCH_PARAM, value: null }])
      }
    }
  }

  private deselectPostcodeDistanceSearchHandler() {
    return this.changeFacet([
      { name: POSTCODE_SEARCH_PARAM, value: null },
      { name: COUNTRY_CODE_SEARCH_PARAM, value: null },
      { name: DISTANCE_SEARCH_PARAM, value: null }
    ])
  }

  private deselectPickupLocationSearchHandler({
    handler,
    value
  }: FacetDeselectProps) {
    return this.changeFacet([
      {
        name: 'rich-geolocation',
        value: handler.values.selected.geolocations
          .filter((g: GeocodingSearchLocation) => g.name !== value)
          .map(createSearchGeolocationString)
      }
    ])
  }

  private defaultFacetDeselect({ urlArg }: FacetDeselectProps) {
    this.changeFacet([{ name: urlArg, value: null }])
  }

  private changeFacet(params: any) {
    this.store.dispatch(`${CLASSIFIED_SEARCH_NS}/facetChanged`, { params })
  }
}
