import { CIcon } from '~/icons/types'
export const ciJobs: CIcon = {
  width: 512,
  height: 395.6,
  paths: [
    {
      d:
        'M268.4 271.2c-33-6.4-61.9-24.8-81.6-52.1-13.6-19-21.9-40.8-24.2-63.5h29.2c42.2 0 76.5 34.3 76.5 76.5v39.1zM478.8 352.5l-.2-.2-.2-.2-66.8-87.1-8.5-11.1-10.8 8.8c-2.8 2.3-5.2 4.2-7.5 5.8-2.6 1.9-5.2 3.6-7.7 5.1l-12.3 7.4 8 11.9 61.4 90.9.1.2.2.2c4.3 5.8 10.5 9.7 17.6 10.8 1.4.2 2.9.4 4.4.4 5.7 0 11.4-1.8 16-5.2 12-8.7 14.8-25.6 6.3-37.7z'
    },
    {
      d:
        'M229.2 27.6c20-11.3 42.2-16.8 64.1-16.8 40.8 0 81.1 18.9 106.7 54.6 42.3 58.7 29 141.1-29.9 183.4-23.2 16.7-50.3 25.2-78.3 24.8V232c0-49.9-36.7-91.3-84.6-98.8 18.7-16.4 30.4-40.4 30.4-67.1.2-13.9-2.9-26.8-8.4-38.5zM209.5 41.2c3.1 7.7 4.8 16 4.8 24.8 0 31.5-22.2 58-51.8 64.4.3-3.1.7-6.2 1.1-9.3 5.3-31.6 21.4-59.7 45.9-79.9zM82.4 66c0-36.4 29.6-66 66-66 19.1 0 36.4 8.2 48.4 21.3-30.1 24.1-50 57.8-56.2 96.1-.8 4.7-1.3 9.3-1.7 13.9C107 126.8 82.4 99.2 82.4 66zM28.3 232c0-42.2 34.3-76.5 76.5-76.5H139c2.3 27.6 12.1 54.1 28.6 77.3 24.2 33.6 59.9 55.6 100.7 62.3v82.7c0 9.8-7.9 17.8-17.8 17.8H46.1c-9.8 0-17.8-7.9-17.8-17.8V232z'
    }
  ],
  name: 'jobs',
  type: 'solid'
}
