import { CIcon } from '~/icons/types'

export const ciCreditCard: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M20 3.5H4c-1.7 0-3 1.3-3 3v11c0 1.7 1.3 3 3 3h16c1.7 0 3-1.3 3-3v-11c0-1.7-1.3-3-3-3Zm-16 2h16c.6 0 1 .4 1 1v.8H3v-.8c0-.6.4-1 1-1Zm16 13H4c-.6 0-1-.4-1-1V9.2h18v8.2c0 .7-.4 1.1-1 1.1Z'
    },
    {
      d:
        'M19.4 11h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.5-1-1-1Zm-7.8 0h-7c-.6 0-1 .4-1 1s.4 1 1 1h7c.6 0 1-.4 1-1s-.5-1-1-1Zm-4.4 2.8H4.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.4-1-1-1Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'credit-card-regular',
  type: 'regular'
}
