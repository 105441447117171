import { CIcon } from '~/icons/types'
export const ciGearstick: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M471 132q17 0 29 12t12 29v298q0 17-12 29t-29 12H41q-17 0-29-12T0 471V173q0-17 12-29t29-12h168q-27-22-27-58V41q0-17 12-29t29-12h66q17 0 29 12t12 29v33q0 36-27 58h168zM198 74q0 24 17 41t41 17 41-17 17-41q-11 9-25 9V66q10 0 17.5-7t7.5-17.5-7.5-17.5-17.5-7h-66q-10 0-17.5 7T198 41.5t7.5 17.5 17.5 7h50v17h-50q-14 0-25-9zm83 273h82v66q0 10 7.5 17.5T388 438t17.5-7.5T413 413V231q0-10-7.5-17.5T388 206t-17.5 7.5T363 231v66h-66v17q0 17-12 29t-29 12q-11 0-20.5-5.5t-15-15T215 314v-17h-66v-66q0-10-7.5-17.5T124 206t-17.5 7.5T99 231v182q0 10 7.5 17.5T124 438t17.5-7.5T149 413v-66h82v66q0 10 7.5 17.5T256 438t17.5-7.5T281 413v-66zm-50-33q0 10 7.5 17.5T256 339t17.5-7.5T281 314V144q-12 5-25 5t-25-5v170zm264 157V173q0-10-7-17t-17-7H297v132h50v-50q0-17 12-29t29-12 29 12 12 29v182q0 17-12 29t-29 12-29-12-12-29v-50h-50v50q0 17-12 29t-29 12q-11 0-20.5-5.5t-15-15T215 413v-50h-50v50q0 17-12 29t-29 12-29-12-12-29V231q0-17 12-29t29-12 29 12 12 29v50h50V149H41q-10 0-17 7t-7 17v298q0 10 7 17t17 7h430q10 0 17-7t7-17zM58 107V91h99v16H58zm66-33V58h33v16h-33zm264 42V99h83v17h-83zm-25-33V66h58v17h-58zm99 396v-17h17v17h-17zm-33 0v-17h17v17h-17zm-396 0v-17h17v17H33zM91 74V58h16v16H91z',
      fill: 'currentColor'
    }
  ],
  name: 'gearstick',
  type: 'light'
}
