import { CIcon } from '~/icons/types'
export const ciDoorOne: CIcon = {
  name: 'door-one',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M475 492h-29V10c0-5.523-4.477-10-10-10H76c-5.523 0-10 4.477-10 10v482H37c-5.523 0-10 4.478-10 10s4.477 10 10 10h438c5.523 0 10-4.478 10-10s-4.477-10-10-10zm-49 0h-20V50c0-5.523-4.477-10-10-10H116c-5.523 0-10 4.477-10 10v182c0 5.523 4.477 10 10 10s10-4.477 10-10V60h260v432H126V315c0-5.522-4.477-10-10-10s-10 4.478-10 10v177H86V20h340v472z'
    },
    {
      d:
        'M216 90h-40c-16.542 0-30 13.458-30 30v112c0 16.542 13.458 30 30 30h40c16.542 0 30-13.458 30-30V120c0-16.542-13.458-30-30-30zm10 142c0 5.514-4.486 10-10 10h-40c-5.514 0-10-4.486-10-10V120c0-5.514 4.486-10 10-10h40c5.514 0 10 4.486 10 10v112zM336 90h-40c-16.542 0-30 13.458-30 30v112c0 16.542 13.458 30 30 30h40c16.542 0 30-13.458 30-30V120c0-16.542-13.458-30-30-30zm10 142c0 5.514-4.486 10-10 10h-40c-5.514 0-10-4.486-10-10V120c0-5.514 4.486-10 10-10h40c5.514 0 10 4.486 10 10v112zm-130 48h-40c-16.542 0-30 13.458-30 30v122c0 16.542 13.458 30 30 30h40c16.542 0 30-13.458 30-30V310c0-16.542-13.458-30-30-30zm10 152c0 5.514-4.486 10-10 10h-40c-5.514 0-10-4.486-10-10V310c0-5.514 4.486-10 10-10h40c5.514 0 10 4.486 10 10v122zm110-152h-40c-16.542 0-30 13.458-30 30v122c0 16.542 13.458 30 30 30h40c16.542 0 30-13.458 30-30V310c0-16.542-13.458-30-30-30zm10 152c0 5.514-4.486 10-10 10h-10v-58c0-5.522-4.477-10-10-10s-10 4.478-10 10v58h-10c-5.514 0-10-4.486-10-10V310c0-5.514 4.486-10 10-10h40c5.514 0 10 4.486 10 10v122zM123.07 268.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.069-7.07 2.93c-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.069c1.86 1.86 4.44 2.931 7.07 2.931s5.21-1.07 7.07-2.931c1.86-1.859 2.93-4.439 2.93-7.069s-1.07-5.21-2.93-7.07z'
    },
    {
      d:
        'M323.07 340.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.069-7.07 2.93c-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.069c1.86 1.861 4.44 2.931 7.07 2.931s5.21-1.07 7.07-2.931c1.86-1.859 2.93-4.439 2.93-7.069s-1.07-5.21-2.93-7.07z'
    }
  ]
}
