import { CIcon } from '~/icons/types'
export const ciHumidifier: CIcon = {
  name: 'humidifier',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M435 467h-33.5V275.875c0-4.14-3.355-7.5-7.5-7.5s-7.5 3.36-7.5 7.5V362.5H191.832c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5H386.5v89h-261v-89h34.668c4.14 0 7.5-3.36 7.5-7.5s-3.36-7.5-7.5-7.5H125.5v-88.305c8.016-4.172 13.5-12.554 13.5-22.195s-5.484-18.023-13.5-22.195V168c0-10.477 8.523-19 19-19h223c10.477 0 19 8.523 19 19v24.5c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5V168c0-18.746-15.254-34-34-34h-223c-18.746 0-34 15.254-34 34v59.242C98.367 228.95 89 239.402 89 252s9.367 23.05 21.5 24.758V467H77c-12.406 0-22.5 10.094-22.5 22.5S64.594 512 77 512h358c12.406 0 22.5-10.094 22.5-22.5S447.406 467 435 467zM104 252c0-5.516 4.484-10 10-10 .688 0 1.363.07 2.012.203.175.047.351.09.535.125 4.285 1.129 7.453 5.035 7.453 9.672 0 4.633-3.168 8.54-7.453 9.672a5.06 5.06 0 0 0-.535.125A10.04 10.04 0 0 1 114 262c-5.516 0-10-4.484-10-10zm331 245H77c-4.137 0-7.5-3.363-7.5-7.5s3.363-7.5 7.5-7.5h358c4.137 0 7.5 3.363 7.5 7.5s-3.363 7.5-7.5 7.5zm0 0'
    },
    {
      d:
        'M394 216.832a7.5 7.5 0 0 0-7.5 7.5v19.418c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-19.418a7.5 7.5 0 0 0-7.5-7.5zM303.5 52h65c14.336 0 26-11.664 26-26s-11.664-26-26-26h-65c-14.336 0-26 11.664-26 26s11.664 26 26 26zm0-37h65c6.066 0 11 4.934 11 11s-4.934 11-11 11h-65c-6.066 0-11-4.934-11-11s4.934-11 11-11zm-90 73.5c0 14.336 11.664 26 26 26h47c14.336 0 26-11.664 26-26s-11.664-26-26-26h-47c-14.336 0-26 11.664-26 26zm26-11h47c6.066 0 11 4.934 11 11s-4.934 11-11 11h-47c-6.066 0-11-4.934-11-11s4.934-11 11-11zm-81-18h39c14.336 0 26-11.664 26-26s-11.664-26-26-26h-39c-14.336 0-26 11.664-26 26s11.664 26 26 26zm0-37h39c6.066 0 11 4.934 11 11s-4.934 11-11 11h-39c-6.066 0-11-4.934-11-11s4.934-11 11-11zm81.129 383.203a7.501 7.501 0 0 0-10.219 2.852 30.49 30.49 0 0 0-3.91 14.945c0 16.82 13.68 30.5 30.5 30.5 16.816 0 30.5-13.68 30.5-30.5 0-5.238-1.355-10.41-3.91-14.945a7.501 7.501 0 1 0-13.07 7.367 15.417 15.417 0 0 1 1.98 7.578c0 8.547-6.953 15.5-15.5 15.5s-15.5-6.953-15.5-15.5c0-2.664.684-5.285 1.977-7.578a7.503 7.503 0 0 0-2.848-10.219zm0 0'
    },
    {
      d:
        'M263.5 419.5v-25a7.5 7.5 0 1 0-15 0v25a7.5 7.5 0 1 0 15 0zM25 43C11.215 43 0 54.215 0 68s11.215 25 25 25 25-11.215 25-25-11.215-25-25-25zm0 35c-5.516 0-10-4.484-10-10s4.484-10 10-10 10 4.484 10 10-4.484 10-10 10zm462 107c-13.785 0-25 11.215-25 25s11.215 25 25 25 25-11.215 25-25-11.215-25-25-25zm0 35c-5.516 0-10-4.484-10-10s4.484-10 10-10 10 4.484 10 10-4.484 10-10 10zm-55-101c13.785 0 25-11.215 25-25s-11.215-25-25-25-25 11.215-25 25 11.215 25 25 25zm0-35c5.516 0 10 4.484 10 10s-4.484 10-10 10-10-4.484-10-10 4.484-10 10-10zm0 0'
    }
  ]
}
