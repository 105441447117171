import { CIcon } from '~/icons/types'
export const ciTripComputer: CIcon = {
  width: 512,
  height: 384.01,
  paths: [
    {
      d:
        'M427.14 12.41A12.54 12.54 0 0 0 414.78.06h-165c-7.6 0-15.22-.13-22.83 0h-.31a12.54 12.54 0 0 0-12.36 12.35v90.85c0 6.46 5.68 12.66 12.36 12.36S239 110.19 239 103.26V24.77h152.63c3.6 0 7.19 0 10.79.06v87.82h-95.28c-4.51 0-9-.07-13.55 0h-.2a12.69 12.69 0 0 0-8.73 3.62A12.49 12.49 0 0 0 281 125c.3 6.7 5.43 12.36 12.35 12.36h109.07v218.15h-74.77c-6.47 0-12.66 5.69-12.36 12.36s5.43 12.36 12.36 12.36h87.13a12.55 12.55 0 0 0 12.36-12.36V13.05c-.01-.24 0-.44 0-.64Zm84.09 205.27a252.11 252.11 0 0 0-11.08-57.08 226.37 226.37 0 0 0-21.58-47.74c5-7.3 8-19.43 7.43-33-.85-21.67-10.37-38.82-21.25-38.29a10.23 10.23 0 0 0-1.9.27V21.41c0-3.93.08-7.86 0-11.79v-.17c0-4-3.55-7.91-7.72-7.72a7.85 7.85 0 0 0-7.72 7.72v57.81a74.77 74.77 0 0 0-.82 14.5 77.49 77.49 0 0 0 .82 8.54v4.14c0 3.93-.08 7.86 0 11.78v.18a7.83 7.83 0 0 0 1.9 5 9.4 9.4 0 0 0 1.5 4 203.09 203.09 0 0 1 19.38 35.14 230.23 230.23 0 0 1 13.18 44.15q1.14 5.94 2 11.93a244.5 244.5 0 0 1 1.33 48.83q-.48 6.33-1.3 12.63a220.37 220.37 0 0 1-10.05 41.62c-1.21 3.47-2.53 6.89-3.93 10.28a180 180 0 0 1-16.25 29.73 162.5 162.5 0 0 1-4.9 6.8c-.7.89-1.39 1.77-2.11 2.64-2.14 2.6-3.62 5.25-3.62 8.74a12.36 12.36 0 0 0 12.36 12.36c3.09-.14 6.69-1.13 8.74-3.62a188.49 188.49 0 0 0 16.47-23.24 208.08 208.08 0 0 0 12.86-26.12 215.27 215.27 0 0 0 14.13-51.6 255 255 0 0 0 2.13-57.99Z'
    },
    {
      d:
        'm321.43 298.74-.09-.67-8.5-64.41 5.64-.22a32.61 32.61 0 0 0 7.37-1 18.49 18.49 0 0 0 11.17-8 15.52 15.52 0 0 0 0-15.41c-3.51-6.44-10.78-9.29-14.8-10.42-5.75-1.61-12-1.61-18.05-1.61h-4L293 168.47q-.2-.84-.42-1.65c-1-3.6-4.35-7.32-7.32-10.6l-.35-.39a86.22 86.22 0 0 0-16.58-14c-10-6.55-22.37-10.92-36.88-13-19.69-2.79-40-3-59.67-3.12h-.94l-6.61-.06c-14.85-.15-30.21-.32-45.08 1.08-18.37 1.73-31.73 5.3-43.29 11.56A82.83 82.83 0 0 0 58.64 151a84.36 84.36 0 0 0-6.52 6.95c-2.12 2.52-3.57 4.38-4.38 7.15-.06.19-.11.37-.16.56l-.09.36L39 196.9h-3.9a100.6 100.6 0 0 0-15 1.06A27.36 27.36 0 0 0 5 205.18a16.84 16.84 0 0 0-5 11.58 15.74 15.74 0 0 0 7.69 13.32c3.24 2.06 7.1 3 12.92 3.3l5.62.24-7.52 57c-.15 1.19-.32 2.4-.49 3.6-.34 2.38-.66 4.63-.89 6.91-.36 3.44.43 7.72 1.13 11.49l.18 1a57.71 57.71 0 0 0 6.57 17.48 56.68 56.68 0 0 0 19.61 20.5l2.44 1.51v18.95c0 9.26 2.45 11.95 18.64 11.95s18.64-2.69 18.64-11.95v-12.67h170v12.67c0 9.26 2.45 11.95 18.64 11.95s18.64-2.69 18.64-11.95v-18.92l2.42-1.51a57.2 57.2 0 0 0 19.51-20.34 63 63 0 0 0 8-28.67 24.28 24.28 0 0 0-.32-3.88Zm-248.77-124 .85-.93a89 89 0 0 1 8.12-7.69l.3-.23a72.89 72.89 0 0 1 12-7l.35-.15a98 98 0 0 1 19.1-4.88h.18c16.65-2 33.6-1.84 50-1.66l6.64.07h6.85c15.69.09 31.92.19 47.86 2.15h.17a102.82 102.82 0 0 1 20.39 5.16l.34.14a73 73 0 0 1 11.82 6.84l.31.23a84.91 84.91 0 0 1 8.76 8.45l.86 1 8.15 32.76H63.24Zm222.4 130.35-.07.53a54.29 54.29 0 0 1-2.32 8.58l-.21.5a53.55 53.55 0 0 1-4.14 7.1l-.34.44a52 52 0 0 1-5.29 5.31l-.48.37a49.52 49.52 0 0 1-5.33 3.12l-.64.27a43.4 43.4 0 0 1-4.89 1.36l-.38.08h-.39c-.73 0-1.46.08-2.19.08H70.79c-.77 0-1.54 0-2.3-.08h-.39l-.38-.08a44.35 44.35 0 0 1-4.89-1.36l-.64-.27a48.57 48.57 0 0 1-5.33-3.12l-.48-.37a51 51 0 0 1-5.29-5.31l-.34-.44a53.55 53.55 0 0 1-4.14-7.1l-.21-.5a55 55 0 0 1-2.4-8.82l-.06-.49a26 26 0 0 1 .06-5.49l8.43-63.89h234.19L295 299a28.09 28.09 0 0 1 .06 6.06Z'
    },
    {
      d:
        'M112.86 261.24C101.59 258.17 88.08 256 80 256c-2.89 0-5.08.82-6.52 2.45-1.76 2-2.39 5.13-1.88 9.37a28.55 28.55 0 0 0 14.81 21.31c7.69 4.25 15.19 6.49 21.68 6.49 16.26 0 29.15-8.59 29.33-19.57v-1.26l-.53-1.13c-2.66-5.83-16.2-10.29-24.03-12.42Zm152.73-2.77c-1.44-1.63-3.63-2.45-6.52-2.45-8.08 0-21.6 2.15-32.86 5.22-7.83 2.13-21.37 6.59-24.06 12.43l-.53 1.14v1.26c.19 11 13.07 19.57 29.33 19.57 6.5 0 14-2.24 21.69-6.49a28.57 28.57 0 0 0 14.81-21.31c.55-4.24-.1-7.39-1.86-9.37Z'
    }
  ],
  name: 'trip-computer',
  type: 'regular'
}
