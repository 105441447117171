import { CIcon } from '~/icons/types'
export const ciRadio: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M463 129q20 0 34.5 14.5T512 179v272q0 20-14.5 34.5T463 500H49q-20 0-34.5-14.5T0 451V179q0-21 14.5-35.5T49 129h299L94 48q-6 6-15 6t-15-6-6-15 6-15 15-6q17 0 21 17l313 100h50zm29 322V179q0-13-8.5-21.5T463 149H49q-8 0-14.5 4T24 164t-4 15v272q0 12 8.5 21t20.5 9h414q12 0 20.5-9t8.5-21zM50 190h405v97H152v-59q0-8-8-8t-8 8v59H50v-97zm35 145v106q0 6-4.5 10T70 455t-10.5-4-4.5-10V335q0-7 4.5-11t10.5-4 10.5 4 4.5 11zm53 0v106q0 6-4 10t-10.5 4-10.5-4-4-10V335q0-7 4-11t10.5-4 10.5 4 4 11zm54 0v106q0 3-1 5.5t-3 4.5-5 3-6 1q-6 0-10.5-4t-4.5-10V335q0-7 4.5-11t10.5-4 10.5 4 4.5 11zm53 0v106q0 6-4 10t-10 4-10.5-4-4.5-10V335q0-7 4.5-11t10.5-4 10 4 4 11zm54 0v106q0 6-4.5 10t-10.5 4-10.5-4-4.5-10V335q0-7 4.5-11t10.5-4 10.5 4 4.5 11zm53 0v106q0 6-4 10t-10 4-10.5-4-4.5-10V335q0-7 4.5-11t10.5-4 10 4 4 11zm103 16q0 13-9.5 22.5T423 383t-22-9.5-9-22.5 9-22 22-9 22.5 9 9.5 22zm-5 78q0 11-8 18.5t-19 7.5-18.5-7.5T397 429t7.5-19 18.5-8 19 8 8 19z',
      fill: 'currentColor'
    }
  ],
  name: 'radio',
  type: 'regular'
}
