import { CIcon } from '~/icons/types'
export const ciGarage: CIcon = {
  width: 1022,
  height: 1000,
  paths: [
    {
      d:
        'm993 385-66-50L574 66q-33-25-74-25t-74 25L7 385q-6 4-7 11t3 12q7 7 13.5 7t10.5-4l40-30v54q-6 3-10 7l-9 8q-11 6-19.5 17.5t-11 25.5 2.5 30q2 9-4 18Q3 560 .5 584.5T7 630q4 8 7 12 6 7 4 18l-1 2q-1 6-1 9-1 13 3 36l1 7q4 13 7 20 5 12 13 20 11 10 27 10v51H23q-11 0-17 7.5T1 840l18 118h867q20 0 33.5-14t13.5-33V381l40 30q4 4 10.5 4t13.5-7q4-5 3-12t-7-11zM53 705q0-3-1-8-4-15-3-23l1-6q6-26-9-46l-3-5q-6-13-4-29t10-29q14-22 8-46-3-7-1-15 1-6 5-11.5t9-7.5l15-13q2-2 8-4h11q9 2 16.5 6.5T126 480q5 9 2 17v9q-2 18 11 34 2 2 4 7v2q2 6 0 14-1 5-5 17l-3 6q-5 11-8 15-8 11-9.5 26t5 29.5 2 29T108 712q-7 6-20 14l-3 1q-4 2-6 2-10 5-17 0t-9-24zm66 220H48l-12-77h95zm114 0v-50h550v50H233zm550-217v50H233v-50h550zm-550-33v-50h550v50H233zm550-83H233v-50h550v50zM233 792h550v50H233v-50zm550-284H233v-50h550v50zm103 417h-69V425H200v500h-47l13-85q1-10-5-17.5t-17-7.5h-44v-58l3-1q42-22 53-60 4-13 3-27t-6-26q-4-8-2-18l3-5q8-13 15-28 9-23 7-43 0-4-1-7-1-12-11-23-5-6-3-15 5-21-5-39-8-14-21.5-23.5T103 429h-2l369-280q14-11 30.5-11t29.5 11l370 280v482q0 6-4 10t-10 4zm14-537L551 122q-22-17-50.5-17T449 122L100 388v-32l60-45q1 0 1-1l1-1L447 93q23-18 53-18t53 18l217 164q1 2 3 4l127 95v32z',
      fill: 'currentColor'
    }
  ],
  name: 'garage',
  type: 'light'
}
