import { CIcon } from '~/icons/types'
export const ciIdea: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M256 76q67 0 114 47t47 114q0 51-28.5 92T313 388v86q0 10-10 10h-9v19q0 9-10 9h-56q-10 0-10-9v-19h-9q-10 0-10-10v-86q-47-18-75.5-59T95 237q0-67 47-114t114-47zm28 389h10v-48h-76v48h10q9 0 9 9v19h38v-19q0-9 9-9zm16-93q44-14 71-51.5t27-83.5q0-59-41.5-100.5T256 95t-100.5 41.5T114 237q0 46 27 83.5t71 51.5q6 2 6 9v17h76v-17q0-7 6-9zM256 57q-9 0-9-10V9q0-9 9-9t9 9v38q0 10-9 10zm228 171q9 0 9 9.5t-9 9.5h-38q-10 0-10-9.5t10-9.5h38zm-418 0q10 0 10 9.5T66 247H28q-9 0-9-9.5t9-9.5h38zM410 69q7-6 13.5.5T424 83l-27 27q-3 2-7 2t-7-2q-6-7 0-14zM115 364q7-6 13.5.5t.5 13.5l-27 27q-3 2-7 2t-7-2q-6-7 0-14zm282 0 27 27q6 7 0 14-3 2-7 2t-7-2l-27-27q-6-7 .5-13.5t13.5-.5zM115 110 88 83q-6-7 .5-13.5T102 69l27 27q6 7 0 14-3 2-7 2t-7-2zm141 13q9 0 9 9.5t-9 9.5q-39 0-67 28t-28 67q0 10-9.5 10t-9.5-10q0-47 33.5-80.5T256 123z',
      fill: 'currentColor'
    }
  ],
  name: 'idea',
  type: 'light'
}
