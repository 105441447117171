




















import { defineComponent } from '@nuxtjs/composition-api'
import { ciCart } from '~/icons/source/solid/cart'

export default defineComponent({
  setup() {
    return {
      ciCart
    }
  }
})
