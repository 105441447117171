






















import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { capitalizeString } from '~/utils/string'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { Promotion } from '~/models/classified/promoted'
import { useNamespacedStore } from '~/compositions/store'
import { useI18n } from '~/compositions/i18n'

export default defineComponent({
  props: {
    promotion: {
      type: Object as PropType<Promotion>,
      required: true
    },
    classifiedIsOwn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { promotion, classifiedIsOwn } = toRefs(props)

    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const { state: classifiedSearchState } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )
    const { t } = useI18n()

    const userIsAdmin = computed(() => userGetters('isAdmin'))
    const currentRoute = computed(() => classifiedSearchState.currentRoute)

    const isPromoted = computed(
      () => promotion.value?.activePromotion?.isPromoted
    )

    const userCanSeeActions = computed(
      () => classifiedIsOwn.value || userIsAdmin.value
    )

    const currentTier = computed(() => {
      const showCurrentTier =
        isPromoted.value &&
        (userIsAdmin.value ||
          currentRoute.value.query.myclassifieds ||
          currentRoute.value.query.promotion ||
          currentRoute.value.query['promotion-tier'])

      return showCurrentTier ? promotion.value!.activePromotion!.tier : ''
    })

    const promoTooltipText = computed(() => {
      if (!isPromoted.value) {
        return ''
      }

      let txt = t('promoted classified')
      if (userCanSeeActions.value) {
        txt += ' ' + capitalizeString(promotion.value!.activePromotion!.tier)
      }
      return txt
    })

    return {
      isPromoted,
      faStar,
      userCanSeeActions,
      currentTier,
      promoTooltipText
    }
  }
})
