



















import { AlternativeSearch } from '~/models/search/types'
import { mapActions } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    alternativeSearches: {
      type: Array as PropType<AlternativeSearch[]>,
      default() {
        return []
      }
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      loadSeoUrlSearch: 'loadSeoUrlSearch'
    }),
    goToAlternativeSearch(seoUrl: string) {
      this.loadSeoUrlSearch({
        seoUrl
      })
    }
  }
})
