import { PromotedTier } from '~/models/classified/promoted'

export const allPromotedTiers = Object.freeze([
  PromotedTier.TURBO,
  PromotedTier.TOP,
  PromotedTier.SUPER
])

export const PROMOTION_PARAM_NAME = 'promotion'
export const PREVIOUS_PROMOTIONS_PARAM_NAME = 'has-previous-promotions'
export const PROMOTION_TIER_PARAM_NAME = 'promotion-tier'
