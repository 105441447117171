import { CIcon } from '~/icons/types'
export const ciCaravan: CIcon = {
  width: 512,
  height: 345.2,
  paths: [
    {
      d:
        'M366.1 274.6h63.4l-2-53h-61.4zm-243-15c17.8 0 32.3 14.5 32.3 32.3s-14.5 32.8-32.3 32.8-32.3-14.9-32.3-32.8 14.5-32.3 32.3-32.3z',
      fill: '#91D4F3'
    },
    {
      d:
        'M57.1 274.6h15.6c7.2-20.9 27.1-36 50.4-36s43.2 15.1 50.4 36H257v-53H22.5v18.3c0 19.1 15.5 34.7 34.6 34.7zM257 70.5c0-5.8 4.7-10.5 10.5-10.5h88.2c5.8 0 10.5 4.7 10.5 10.5v130.1h60.7l-3.4-90.4c-1.9-50-42.6-89.2-92.6-89.2H64.1c-23 0-41.7 18.7-41.7 41.7v137.9H257V70.5zm-46 89.8c0 5.8-4.7 10.5-10.5 10.5H79.3c-5.8 0-10.5-4.7-10.5-10.5V70.5c0-5.8 4.7-10.5 10.5-10.5h121.1c5.8 0 10.5 4.7 10.5 10.5v89.8h.1z',
      fill: '#91D4F3'
    },
    {
      d:
        'M57.1 295.6H70c1.9 27.7 25 49.6 53.2 49.6s51.2-21.9 53.2-49.6h284v21.9c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-21.9H500c5.8 0 10.5-4.7 10.5-10.5s-4.6-10.5-10.4-10.5h-49.6l-6.1-165.2c-1.1-29.5-13.5-57.1-34.7-77.6C388.5 11.3 360.5 0 330.8 0H64.1C29.5 0 1.5 28.1 1.5 62.7v177.2c0 30.7 25 55.7 55.6 55.7zm66 29.1c-17.8 0-32.3-15-32.3-32.8s14.5-32.3 32.3-32.3 32.3 14.5 32.3 32.3c.1 17.8-14.4 32.8-32.3 32.8zm222-50.1H278V81h67.2v193.6h-.1zm84.4 0h-63.4v-53h61.4l2 53zM22.5 62.7c0-23 18.7-41.7 41.7-41.7h266.7c50 0 90.7 39.2 92.6 89.2l3.4 90.4h-60.7V70.5c0-5.8-4.7-10.5-10.5-10.5h-88.2c-5.8 0-10.5 4.7-10.5 10.5v130.1H22.5V62.7zm0 158.9H257v53h-83.4c-7.2-20.9-27.1-36-50.4-36s-43.2 15.1-50.4 36H57.1c-19.1 0-34.7-15.6-34.7-34.7v-18.3h.1z',
      fill: '#1976D2'
    },
    {
      d:
        'M200.5 60H79.3c-5.8 0-10.5 4.7-10.5 10.5v89.8c0 5.8 4.7 10.5 10.5 10.5h121.1c5.8 0 10.5-4.7 10.5-10.5V70.5c.1-5.8-4.6-10.5-10.4-10.5zM190 149.8H89.8V81H190v68.8z',
      fill: '#1976D2'
    }
  ],
  name: 'caravan',
  type: 'duotone'
}
