import { CIcon } from '~/icons/types'
export const ciBathroom: CIcon = {
  name: 'bathroom',
  type: 'light',
  width: 463,
  height: 463,
  paths: [
    {
      d:
        'M439.5 0h-416C10.542 0 0 10.542 0 23.5v416C0 452.458 10.542 463 23.5 463h64c50.689 0 99.865-9.929 146.164-29.512 44.715-18.913 84.872-45.987 119.354-80.47s61.557-74.64 80.47-119.354C453.071 187.365 463 138.189 463 87.5v-64C463 10.542 452.458 0 439.5 0zm8.5 87.5c0 96.293-37.499 186.822-105.588 254.912C274.322 410.501 183.793 448 87.5 448h-64c-4.687 0-8.5-3.813-8.5-8.5v-416c0-4.687 3.813-8.5 8.5-8.5h416c4.687 0 8.5 3.813 8.5 8.5v64z'
    },
    {
      d:
        'M375.5 40h-288C61.308 40 40 61.308 40 87.5v288c0 26.191 21.309 47.5 47.5 47.5 60.751 0 120.304-16.448 172.22-47.566a7.498 7.498 0 0 0 1.447-11.736c-5.243-5.243-8.131-12.214-8.131-19.629s2.888-14.386 8.131-19.629l26.333-26.333 18.697 18.697c1.464 1.464 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197a7.5 7.5 0 0 0 0-10.606L298.106 287.5l26.333-26.333c10.824-10.823 28.435-10.823 39.259 0a7.5 7.5 0 0 0 11.736-1.447C406.552 207.803 423 148.25 423 87.5c0-26.192-21.308-47.5-47.5-47.5zm-8.634 204.614c-16.516-10.409-38.66-8.427-53.033 5.946L287.5 276.893l-22.697-22.697a7.5 7.5 0 0 0-10.606 0 7.5 7.5 0 0 0 0 10.606l22.697 22.697-26.333 26.333c-14.374 14.374-16.356 36.517-5.946 53.033C196.805 393.8 142.669 408 87.5 408 69.58 408 55 393.421 55 375.5v-288C55 69.579 69.579 55 87.5 55h288c17.92 0 32.5 14.58 32.5 32.5 0 55.169-14.2 109.305-41.134 157.114z'
    },
    {
      d:
        'M335.5 280a7.51 7.51 0 0 0-7.5 7.5 7.51 7.51 0 0 0 7.5 7.5c1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3a7.51 7.51 0 0 0-7.5-7.5z'
    },
    {
      d:
        'M287.5 328c-1.97 0-3.91.8-5.3 2.2a7.528 7.528 0 0 0-2.2 5.3 7.51 7.51 0 0 0 7.5 7.5c1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3 0-1.97-.8-3.91-2.2-5.3a7.528 7.528 0 0 0-5.3-2.2z'
    },
    {
      d:
        'M231.5 72c-1.97 0-3.91.8-5.3 2.2a7.528 7.528 0 0 0-2.2 5.3c0 1.97.8 3.91 2.2 5.3 1.39 1.4 3.33 2.2 5.3 2.2 1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3 0-1.97-.8-3.91-2.2-5.3a7.528 7.528 0 0 0-5.3-2.2z'
    },
    {
      d:
        'M79.5 224a7.51 7.51 0 0 0-7.5 7.5c0 1.97.8 3.91 2.2 5.3 1.39 1.4 3.33 2.2 5.3 2.2 1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3a7.51 7.51 0 0 0-7.5-7.5z'
    },
    {
      d:
        'M79.5 192c-1.97 0-3.91.8-5.3 2.2a7.528 7.528 0 0 0-2.2 5.3c0 1.97.8 3.91 2.2 5.3 1.39 1.4 3.33 2.2 5.3 2.2 1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3a7.51 7.51 0 0 0-7.5-7.5z'
    },
    {
      d:
        'M79.5 256a7.51 7.51 0 0 0-7.5 7.5 7.51 7.51 0 0 0 7.5 7.5 7.51 7.51 0 0 0 7.5-7.5 7.51 7.51 0 0 0-7.5-7.5z'
    },
    {
      d:
        'M199.5 72c-1.97 0-3.91.8-5.3 2.2a7.528 7.528 0 0 0-2.2 5.3c0 1.97.8 3.91 2.2 5.3 1.39 1.4 3.33 2.2 5.3 2.2 1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3 0-1.97-.8-3.91-2.2-5.3a7.528 7.528 0 0 0-5.3-2.2z'
    },
    {
      d:
        'M263.5 72a7.51 7.51 0 0 0-7.5 7.5c0 1.97.8 3.91 2.2 5.3 1.39 1.4 3.33 2.2 5.3 2.2 1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3 0-1.97-.8-3.91-2.2-5.3a7.528 7.528 0 0 0-5.3-2.2z'
    }
  ]
}
