import { CIcon } from '~/icons/types'
export const ciCaravan: CIcon = {
  name: 'caravan',
  type: 'regular',
  width: 512,
  height: 345.2,
  paths: [
    {
      d:
        'M500.1 274.6h-49.6l-6.1-165.2c-1.1-29.5-13.5-57.1-34.7-77.6C388.4 11.3 360.4 0 330.8 0H64.1C29.5 0 1.4 28.1 1.4 62.7v177.2c0 30.7 25 55.7 55.7 55.7h12.8c1.9 27.7 25 49.6 53.2 49.6s51.2-21.9 53.2-49.6h284v21.9c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-21.9H500c5.8 0 10.5-4.7 10.5-10.5s-4.6-10.5-10.4-10.5zM64.1 21h266.7c50 0 90.7 39.2 92.6 89.2l3.4 90.4h-60.7V70.5c0-5.8-4.7-10.5-10.5-10.5h-88.2c-5.8 0-10.5 4.7-10.5 10.5v130.1H22.4V62.7c0-23 18.7-41.7 41.7-41.7zM22.4 239.9v-18.3h234.5v53h-83.4c-7.2-20.9-27.1-36-50.4-36s-43.2 15.1-50.4 36H57.1c-19.1 0-34.7-15.6-34.7-34.7zm100.7 84.3c-17.8 0-32.3-14.5-32.3-32.3s14.5-32.3 32.3-32.3 32.3 14.5 32.3 32.3-14.5 32.3-32.3 32.3zm154.8-49.6V81h67.2v193.6h-67.2zm88.2 0v-53h61.4l2 53h-63.4z',
      fill: 'currentColor'
    },
    {
      d:
        'M79.3 170.8h121.1c5.8 0 10.5-4.7 10.5-10.5V70.5c0-5.8-4.7-10.5-10.5-10.5H79.3c-5.8 0-10.5 4.7-10.5 10.5v89.8c0 5.8 4.7 10.5 10.5 10.5zM89.8 81h100.1v68.8H89.8V81z',
      fill: 'currentColor'
    }
  ]
}
