import { CIcon } from '~/icons/types'
export const ciDisabled: CIcon = {
  name: 'disabled',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'm415.77 390.5-37.356-95.992c-5.566-14.313-15.21-26.461-27.887-35.13-12.675-8.667-27.496-13.25-42.851-13.25h-34.242A157.092 157.092 0 0 0 264 236.82v-73.972c0-22.676-11.363-42.743-28.684-54.832C252.461 97.926 264 79.289 264 58c0-31.98-26.02-58-58-58s-58 26.02-58 58c0 17.785 8.055 33.719 20.7 44.367a66.823 66.823 0 0 0-30.012 28.07c-12.02 21.676-18.993 45.918-20.407 70.622-25.703 6.66-49.375 19.734-68.82 38.12-21.125 19.981-36.313 45.29-43.914 73.188-1.453 5.332 1.687 10.828 7.02 12.278a9.889 9.889 0 0 0 2.632.355 10.01 10.01 0 0 0 9.645-7.375c12.418-45.582 48.34-82.062 93.16-95.71v29.597c-19.195 7.468-35.766 20.066-48.23 36.785C55.526 307.395 48 330.113 48 354c0 60.652 49.348 110 110 110s110-49.348 110-110c0-50.32-34.035-93.824-82-106.379v-28.773C249.45 231.887 296 288.105 296 354c0 76.094-61.906 138-138 138-61.27 0-115.828-41.086-132.68-99.918-1.523-5.309-7.058-8.379-12.367-6.86-5.308 1.524-8.383 7.06-6.86 12.368 9.259 32.324 29.145 61.437 55.993 81.976C89.824 500.786 122.988 512 158 512c47.328 0 89.86-20.922 118.84-54H448c5.523 0 10-4.477 10-10v-8.738c0-24.778-18.383-45.336-42.23-48.762zM206 20c20.953 0 38 17.047 38 38s-17.047 38-38 38-38-17.047-38-38 17.047-38 38-38zm42 334c0 49.625-40.375 90-90 90s-90-40.375-90-90c0-34.793 19.613-65.688 50-80.668V291c0 18.746 15.254 34 34 34s34-15.254 34-34v-22.566c36.54 11.898 62 46.187 62 85.566zm-72-169.668c-5.523 0-10 4.477-10 10V291c0 7.719-6.281 14-14 14s-14-6.281-14-14v-80.594a145.3 145.3 0 0 1 18.176-70.273A46.87 46.87 0 0 1 197.152 116C222.984 116 244 137.016 244 162.848v58.566a156.792 156.792 0 0 0-58-22.93v-4.148c0-5.523-4.477-10.004-10-10.004zM316 354c0-4.035-.16-8.035-.46-12h1.1l43.587 96h-68.465C307.105 413.652 316 384.848 316 354zm66.191 84-50.003-110.133a9.992 9.992 0 0 0-9.102-5.867h-10.352a157.613 157.613 0 0 0-23.433-55.871h18.375c23.226 0 43.68 13.984 52.101 35.633l39.641 101.863a10 10 0 0 0 9.32 6.375c15.711 0 28.575 12.445 29.235 28zm0 0'
    },
    {
      d:
        'M17.07 361.91a10.08 10.08 0 0 0 2.93-7.07c0-2.64-1.07-5.211-2.93-7.07-1.86-1.872-4.441-2.93-7.07-2.93s-5.21 1.058-7.07 2.93A10.059 10.059 0 0 0 0 354.84c0 2.629 1.07 5.21 2.93 7.07 1.86 1.86 4.441 2.93 7.07 2.93s5.21-1.07 7.07-2.93zm171.016 43.063a9.985 9.985 0 0 0 7.633 3.53 9.976 9.976 0 0 0 6.465-2.374c15.347-13.02 24.148-32.02 24.148-52.129 0-5.523-4.477-10-10-10s-10 4.477-10 10c0 14.227-6.227 27.664-17.09 36.879-4.21 3.57-4.726 9.883-1.156 14.094zm-29.953-2.641H158c-5.523 0-10 4.477-10 10s4.477 10 10 10h.25c5.52-.05 9.938-4.562 9.895-10.078-.043-5.496-4.524-9.922-10.012-9.922zm0 0'
    }
  ]
}
