import { CIcon } from '~/icons/types'
export const ciLeatherSeats: CIcon = {
  width: 445.2,
  height: 512,
  paths: [
    {
      d:
        'M222.6 60.21a12.31 12.31 0 0 0-12.31 12.3v7.92a12.31 12.31 0 0 0 24.61 0v-7.92a12.3 12.3 0 0 0-12.3-12.3Zm0 256.42a12.31 12.31 0 0 0-12.31 12.31v14.81a12.31 12.31 0 1 0 24.61 0v-14.81a12.31 12.31 0 0 0-12.3-12.31Zm0-65.83a12.31 12.31 0 0 0-12.31 12.31v14.81a12.31 12.31 0 1 0 24.61 0v-14.81a12.31 12.31 0 0 0-12.3-12.31Zm0-131.66a12.31 12.31 0 0 0-12.31 12.3v14.82a12.31 12.31 0 0 0 24.61 0v-14.82a12.3 12.3 0 0 0-12.3-12.3Zm0 65.86a12.31 12.31 0 0 0-12.31 12.31v14.81a12.31 12.31 0 1 0 24.61 0v-14.84A12.31 12.31 0 0 0 222.6 185Zm0 197.46a12.31 12.31 0 0 0-12.31 12.31v7.91a12.31 12.31 0 1 0 24.61 0v-7.91a12.31 12.31 0 0 0-12.3-12.31Z'
    },
    {
      d:
        'M432.7 360.18c-42.77 0-77.56-48.26-77.56-107.58S389.93 145 432.7 145a12.47 12.47 0 0 0 10.64-19l-47.59-77.6a12.49 12.49 0 0 0-19.47-2.3 47.6 47.6 0 0 1-67.24 0 47.2 47.2 0 0 1-13.93-33.62A12.5 12.5 0 0 0 282.63 0H162.56a12.5 12.5 0 0 0-12.48 12.48 47.24 47.24 0 0 1-13.93 33.62A47.19 47.19 0 0 1 102.54 60a47.23 47.23 0 0 1-33.63-13.9 12.6 12.6 0 0 0-10.29-3.57 12.47 12.47 0 0 0-9.18 5.88L1.85 126a12.48 12.48 0 0 0 10.64 19c42.77 0 77.56 48.26 77.56 107.58s-34.79 107.6-77.56 107.6a12.48 12.48 0 0 0-11.33 17.71l30 65a12.53 12.53 0 0 0 13.16 7.12 275.58 275.58 0 0 1 35.68-2.1 277 277 0 0 1 33.31 1.94c48.24 5.9 81.82 25 97.1 55.29a12.42 12.42 0 0 0 11 6.86h.13a12.47 12.47 0 0 0 11-6.64c39.66-75 167.33-60.43 168.62-60.27a12.52 12.52 0 0 0 12.67-6.81l30-60a12.4 12.4 0 0 0-.55-12.14 12.56 12.56 0 0 0-10.58-5.96Zm-37.36 59.3-3.35-.2c-34.59-2-120-.95-166.47 52.47l-3.85 4.43-3.85-4.43c-35.07-40.36-94.54-48.84-138.25-48.84-8.75 0-17.49.35-26 1l-3.55.29-18.93-41 5.58-1.72c18.79-5.75 35.9-18.38 49.48-36.48 18.62-24.83 28.85-57.64 28.85-92.39S104.77 185 86.15 160.22C73.22 143 57 130.65 39.31 124.56l-6-2.07 30-48.85L67.52 76a72.89 72.89 0 0 0 86.29-12.23 72.22 72.22 0 0 0 19.38-34.84l.91-4h97l.91 4a72.22 72.22 0 0 0 19.38 34.84A72.89 72.89 0 0 0 377.67 76l4.27-2.35 29.95 48.85-6 2.07C388.16 130.65 372 143 359 160.22c-18.61 24.82-28.87 57.63-28.87 92.38s10.3 67.57 28.87 92.4c13.42 17.89 30.29 30.44 48.8 36.31l5.68 1.8Z'
    }
  ],
  name: 'leather-seats',
  type: 'regular'
}
