import { CIcon } from '~/icons/types'
export const ciTrailerCar: CIcon = {
  name: 'trailer-car',
  type: 'regular',
  width: 512,
  height: 286.6,
  paths: [
    {
      d:
        'M491.8 185.2c-5.8 0-10.5 4.7-10.5 10.5V215h-62.4V10.5c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5V40H20.2c-5.8 0-10.5 4.7-10.5 10.5v175c0 5.8 4.7 10.5 10.5 10.5H132c9.1 29.3 36.5 50.5 68.7 50.5s59.5-21.3 68.7-50.5H492c5.8 0 10.5-4.7 10.5-10.5v-29.9c-.2-5.7-4.9-10.4-10.7-10.4zm-93.9-90.3h-39.4V61h39.4v33.9zm-106.2 0V61h45.9v33.9h-45.9zm-66.9 0V61h45.9v33.9h-45.9zm-66.9 0V61h45.9v33.9h-45.9zm-66.8 0V61H137v33.9H91.1zm-60.4 0V61h39.4v33.9H30.7zm169.9 170.7c-28.1 0-50.9-22.8-50.9-50.9s22.8-50.9 50.9-50.9 50.9 22.8 50.9 50.9-22.8 50.9-50.9 50.9zm0-122.8c-39.7 0-71.9 32.3-71.9 71.9v.4h-98v-99.2H398V215H272.5v-.4c0-39.6-32.3-71.8-71.9-71.8z'
    },
    {
      d:
        'M200.6 185.2c-16.3 0-29.5 13.2-29.5 29.5s13.2 29.5 29.5 29.5 29.5-13.2 29.5-29.5-13.2-29.5-29.5-29.5zm0 38c-4.7 0-8.5-3.8-8.5-8.5s3.8-8.5 8.5-8.5 8.5 3.8 8.5 8.5-3.8 8.5-8.5 8.5z'
    }
  ]
}
