


















































import { mapActions, mapGetters, mapState } from 'vuex'
import CollapsibleFacet from '~/components/car/classifieds/search/facets/CollapsibleFacet.vue'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import SellersResultsToggle from '~/components/car/classifieds/search/results/SellersResultsToggle.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import CRecentSearchButton from '~/components/shared/configurable/button/CRecentSearchButton.vue'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import PaymentsSummaryWidget from '~/components/car/classifieds/search/results/PaymentsSummaryWidget.vue'
import ClearAllButton from '~/components/car/classifieds/search/facets/ClearAllButton.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import MyClassifiedsLinks from '~/components/car/widgets/MyClassifiedsLinks.vue'
import CGoogleAd from '~/components/shared/configurable/ad/CGoogleAd.vue'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import SaveSearchButton from '~/components/car/classifieds/search/facets/search-saving/SaveSearchButton.vue'

export default defineComponent({
  components: {
    SaveSearchButton,
    CGoogleAd,
    MyClassifiedsLinks,
    PaymentsSummaryWidget,
    SeeDiscountsButton: () => import('./SeeDiscountsButton.vue'),
    Profile: () => import('./UserProfile/Profile.vue'),
    ClearAllButton,
    CollapsibleFacet,
    SellersResultsToggle,
    CRecentSearchButton
  },
  props: {
    filterPage: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      profile: state => state.userProfile,
      canBeSaved: state => state.canBeSaved,
      showDealsButton: state => state.config.settings.showDealsButton,
      facets: state => state.facets,
      mySearchLinks: state => state.mySearchLinks
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      adsKeyValues: 'getAdsKeyValues',
      shouldDisplayAds: 'shouldDisplayAds'
    }),
    ...mapGetters(USER_NS, {
      isPayingUser: 'isPayingUser'
    }),
    isDealerSite() {
      return this.$dep(DealerSiteService).routeIsOfDealerSite()
    },
    facetsDesktopAd() {
      return {
        path: '/297119706/gen_search_dt_sb',
        id: 'div-gpt-ad-1709034619322-0',
        sizes: [
          [300, 600],
          [160, 600]
        ],
        sizesMappings: [
          [
            [1360, 250],
            [
              [300, 600],
              [160, 600]
            ]
          ],
          [[992, 600], [[160, 600]]],
          [[0, 0], []]
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      clearAll: 'classifieds/search/clearAll'
    })
  }
})
