import { CIcon } from '~/icons/types'
export const ciTruck: CIcon = {
  width: 512,
  height: 329.4,
  paths: [
    {
      d:
        'M493.9 243.4c-7.2 0-13.1-6.8-13.1-15.1s5.9-15.1 13.1-15.1h15.9c-1.1-10.3-3.7-23.2-8.2-33.4-1.1-2.4-2.3-4.7-3.6-6.8l-8.2-14.2-22.1-38.4-12.1-21-8.3-14.5c-.1-.2-.2-.3-.3-.5-9.2-14.1-24.7-22.6-41.5-22.6h-94.7v219.5h22.9v-.3c0-37.7 30.7-68.4 68.4-68.4s68.4 30.7 68.4 68.4v.3h29.9c2.8 0 5.5-1.1 7.5-3.1s3.1-4.7 3-7.5l-.3-27.3h-16.7zm-30.1-63.6h-83.5c-21.1 0-25.5-11.9-25.5-21.9v-36.6c0-6.4 0-15.2 9-19.5 5.1-2.4 11.1-2.4 25.5-2.4h42.1l11.7 21 21.4 38.4 11.7 21h-12.4z'
    },
    {
      d:
        'M402.2 232.5c-26.7 0-48.4 21.7-48.4 48.4v.3c0 2.3.2 4.6.5 6.8 2.8 18.9 16.5 34.2 34.4 39.4.3.1.6.2.9.2.3.1.6.2.9.2 3.8.9 7.8 1.4 11.8 1.4 24.3-.1 44.3-18 47.8-41.3.3-2.2.5-4.5.5-6.8v-.3c.1-26.6-21.6-48.3-48.4-48.3zM278 0H11.4C5.6 0 .9 4.7.9 10.5v278.1c0 5.8 4.7 10.5 10.5 10.5h44.7c-.7-2.5-1.2-5-1.6-7.6v-.2l-.1-.5c-.5-3.3-.7-6.6-.7-9.8 0-37.7 30.7-68.4 68.4-68.4s68.4 30.7 68.4 68.4c0 3.2-.2 6.4-.7 9.6l-.1.4v.1c-.4 2.7-1 5.4-1.7 8h90c5.8 0 10.5-4.7 10.5-10.5V10.5C288.5 4.7 283.8 0 278 0z'
    },
    {
      d:
        'M122.1 232.5c-26.7 0-48.4 21.7-48.4 48.4 0 2.4.2 4.8.5 7.1.6 3.8 1.6 7.5 3 11 5.1 12.5 15.2 22.5 27.9 27.2.6.2 1.1.4 1.7.6.6.2 1.3.4 1.9.6.1 0 .2.1.3.1.4.1.9.2 1.3.3.3.1.6.1.9.2.2 0 .4.1.5.1.9.2 1.8.4 2.7.5.2 0 .4.1.5.1.3 0 .6.1.9.1.5.1.9.1 1.4.2h.3c1.4.1 2.9.2 4.4.2 20.3 0 37.7-12.5 44.9-30.3 1.4-3.5 2.4-7.2 3-11 .3-2.3.5-4.7.5-7.1.2-26.6-21.5-48.3-48.2-48.3z'
    }
  ],
  name: 'truck',
  type: 'solid'
}
