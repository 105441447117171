import { CIcon } from '~/icons/types'
export const ciRenovation: CIcon = {
  name: 'renovation',
  type: 'light',
  width: 495.175,
  height: 495,
  paths: [
    {
      d:
        'M160.441 327.18 332.656 329h.074a8.074 8.074 0 0 0 5.56-2.54 8.444 8.444 0 0 0 2.3-5.897V203h19.637a7.946 7.946 0 0 0 7.496-5.148 7.938 7.938 0 0 0-2.25-8.809L252.98 91.387a8 8 0 0 0-10.484 0l-112.785 97.675a7.922 7.922 0 0 0-2.254 8.797 7.933 7.933 0 0 0 7.492 5.141h17.637v115.957a8.133 8.133 0 0 0 7.855 8.223zm108.145-15.22-41-.378V265h41zm56 .52-40-.37v-55.243a7.996 7.996 0 0 0-8.129-7.867h-56.82a7.939 7.939 0 0 0-5.664 2.258 7.94 7.94 0 0 0-2.387 5.61v54.566l-43-.399V203h156zm-76.86-204.562L338.81 187H156.406zm0 0'
    },
    {
      d:
        'M440.195 149.566a8.009 8.009 0 0 0-6.691-3.613 8.009 8.009 0 0 0-6.691 3.613l-53.676 81.844a8.186 8.186 0 0 0-.352 8.29 8.109 8.109 0 0 0 7.043 4.3h43.461a187.151 187.151 0 0 1-63.184 113.8c-32.77 28.098-73.902 44.16-112.863 44.16-25.398 0-51.96-6.878-76.828-19.843a191.309 191.309 0 0 1-65.543-56.23 8.006 8.006 0 0 0-7.371-3.41 7.998 7.998 0 0 0-6.55 4.796 8 8 0 0 0 1.015 8.059 207.3 207.3 0 0 0 71.05 60.95c27.141 14.148 56.266 21.628 84.227 21.628 42.723 0 87.656-17.418 123.278-47.965A202.399 202.399 0 0 0 439.46 244h47.72a8.128 8.128 0 0 0 6.691-12.54zM394.641 228l38.863-59.258L472.367 228zm-272.254-49.703a8.091 8.091 0 0 0-7.04-4.297h-43.46A187.14 187.14 0 0 1 135.07 60.2c32.766-28.102 73.899-44.16 112.864-44.16 25.398 0 51.96 6.878 76.828 19.843a191.218 191.218 0 0 1 65.539 56.23 8.005 8.005 0 0 0 7.375 3.407 7.998 7.998 0 0 0 6.55-4.797 7.994 7.994 0 0 0-1.015-8.055 207.3 207.3 0 0 0-71.05-60.95C305.02 7.567 275.894.087 247.933.087c-42.723 0-87.657 17.422-123.278 47.965A202.4 202.4 0 0 0 55.715 174H7.996a8.124 8.124 0 0 0-6.691 12.535l53.675 81.871a8.004 8.004 0 0 0 13.383.016l53.676-81.89a8.085 8.085 0 0 0 .348-8.235zm-60.715 70.957L22.809 190h77.726zm0 0'
    }
  ]
}
