import { CIcon } from '~/icons/types'
export const ciTractor: CIcon = {
  name: 'tractor',
  type: 'regular',
  width: 512,
  height: 357.3,
  paths: [
    {
      d:
        'M117.3 128.3c-63.1 0-114.5 51.4-114.5 114.5s51.4 114.5 114.5 114.5 114.5-51.4 114.5-114.5-51.3-114.5-114.5-114.5zm0 208c-51.6 0-93.5-41.9-93.5-93.5s41.9-93.5 93.5-93.5 93.5 41.9 93.5 93.5-41.9 93.5-93.5 93.5z',
      fill: 'currentColor'
    },
    {
      d:
        'M475.3 196.9v-.2c0-43.8-35.6-79.4-79.4-79.4h-5.2V72.8c0-5.9 1.8-11.6 5.1-16.4l4.9-7.1c3.3-4.8 2.1-11.3-2.7-14.6-4.8-3.3-11.3-2.1-14.6 2.7l-4.9 7.1c-5.8 8.3-8.9 18.2-8.9 28.4v44.5h-68.9l-26-96.4h36.5c5.8 0 10.5-4.7 10.5-10.5S317 0 311.2 0H80.6c-5.8 0-10.5 4.7-10.5 10.5S74.8 21 80.6 21h16.7L80.8 96.2c-26.6 6.1-51.2 19-71.6 38-4.2 4-4.5 10.6-.5 14.8 4 4.2 10.6 4.5 14.8.5 25-23.4 57.6-36.3 91.8-36.3 74.2 0 134.6 60.4 134.6 134.6 0 8.6-.8 17.2-2.4 25.6-.6 3.1.2 6.2 2.2 8.7 2 2.4 5 3.8 8.1 3.8h73.1c8.6 40.7 44.9 71.4 88.1 71.4 49.7 0 90.1-40.4 90.1-90.1.1-28.4-13.2-53.8-33.8-70.3zm-360-104.7c-4.1 0-8.3.2-12.4.5l15.7-71.8H253l38.6 143.4h-45.2c-27.5-43.3-76-72.1-131.1-72.1zm142.5 93.1h47.6c3.3 0 6.3-1.5 8.3-4.1s2.7-6 1.8-9.1l-9.1-33.8h89.5c27.7 0 51 19.4 56.9 45.4-10.4-4.2-21.9-6.6-33.8-6.6-48.9 0-88.8 39.2-90.1 87.8h-59c.6-5.7.9-11.4.9-17.1.1-22.2-4.5-43.4-13-62.5zm161.2 151c-35.3 0-64.6-26.7-68.6-60.9 0-.7-.1-1.4-.2-2.1-.2-2-.3-4-.3-6.1 0-38.1 31-69.1 69.1-69.1s69.1 31 69.1 69.1c.1 38.1-30.9 69.1-69.1 69.1z',
      fill: 'currentColor'
    },
    {
      d:
        'M117.3 202.3c-22.3 0-40.5 18.2-40.5 40.5s18.2 40.5 40.5 40.5 40.5-18.2 40.5-40.5-18.1-40.5-40.5-40.5zm0 60c-10.8 0-19.5-8.7-19.5-19.5s8.7-19.5 19.5-19.5 19.5 8.7 19.5 19.5-8.7 19.5-19.5 19.5zM419 232.1c-19.4 0-35.1 15.7-35.1 35.1s15.7 35.1 35.1 35.1 35.1-15.7 35.1-35.1c.1-19.4-15.7-35.1-35.1-35.1zm0 49.2c-7.8 0-14.1-6.3-14.1-14.1s6.3-14.1 14.1-14.1 14.1 6.3 14.1 14.1c.1 7.8-6.3 14.1-14.1 14.1z',
      fill: 'currentColor'
    }
  ]
}
