














import { defineComponent } from '@nuxtjs/composition-api'
import { ciAngleRightLight } from '../../../../../../icons/source/solid/angle-right-light'

export default defineComponent({
  props: {
    suggestion: { type: Object, required: true },
    inGenericSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    return {
      ciAngleRightLight
    }
  }
})
