import { CIcon } from '~/icons/types'
export const ciRoof: CIcon = {
  name: 'roof',
  type: 'light',
  width: 362.787,
  height: 362.787,
  paths: [
    {
      d:
        'M260.945 144.979a7.499 7.499 0 0 0-6.477 3.719l-47.484 81.333a7.5 7.5 0 0 0 6.477 11.282h94.968a7.5 7.5 0 0 0 6.477-11.282l-47.483-81.333a7.502 7.502 0 0 0-6.478-3.719zm-34.421 81.333 34.421-58.958 34.42 58.958h-68.841z'
    },
    {
      d:
        'M355.287 254.69h-175.62l81.278-139.218 62.926 107.783a7.5 7.5 0 1 0 12.953-7.562L267.422 96.815c-.021-.036-.048-.067-.069-.103a7.668 7.668 0 0 0-.407-.603c-.044-.059-.086-.118-.131-.176a7.603 7.603 0 0 0-.584-.658c-.024-.024-.051-.045-.076-.069a7.484 7.484 0 0 0-1.364-1.041c-.055-.033-.108-.068-.164-.1a7.391 7.391 0 0 0-.809-.397l-.033-.012a7.49 7.49 0 0 0-.802-.273c-.073-.021-.146-.038-.22-.056a7.504 7.504 0 0 0-.677-.135c-.079-.012-.157-.026-.236-.036a7.505 7.505 0 0 0-.906-.06H101.842a7.499 7.499 0 0 0-6.477 3.719L1.023 258.408A7.5 7.5 0 0 0 7.5 269.69h86.538l.023.001.023-.001h35.889l.023.001.023-.001h225.27a7.5 7.5 0 1 0-.002-15zm-192.989 0h-19.263l69.43-119.47a7.5 7.5 0 0 0-2.716-10.253 7.498 7.498 0 0 0-10.253 2.716l-73.81 127.007H107.1l85.192-146.594h55.59L162.298 254.69zm-56.151-146.594h34.091L74.789 220.717a7.5 7.5 0 0 0 12.969 7.537l69.83-120.158h17.356L89.751 254.69H20.563l85.584-146.594z'
    }
  ]
}
