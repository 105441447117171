import { CIcon } from '~/icons/types'
export const ciSearchbarMagnifier: CIcon = {
  name: 'searchbar-magnifier',
  type: 'regular',
  width: 342.9,
  height: 292.3,
  paths: [
    {
      d:
        'M334.1 258c-19.9-14.4-39.8-28.8-59.6-42.5 11-20.6 17.8-44.6 18.5-68.5-1.4-61.7-39.1-115.8-96-137.8S72.2 5.7 33.9 53c-39.1 47.3-45.3 115.2-13.8 168s93.2 79.5 153.5 68.5c31.5-5.5 58.3-21.9 78.8-44.6 13 9.6 26.7 19.2 39.8 28.8 7.5 5.5 15.1 11 22.6 15.8 8.2 6.2 20.6 1.4 25.4-6.9 5.5-8.8 2.1-19.1-6.1-24.6zM184 248.4c-43.9 15.8-93.2 2.1-122.7-33.6-28.8-35-31.5-85.7-8.9-124.8C75 51.7 121.6 30.4 165.5 38.6c52.1 9.6 89.1 54.8 89.8 107.6 0 16.5-4.1 32.9-11 47.3-5.5 11-12.3 21.2-21.2 30.2-11 11-24.1 19.2-39.1 24.7z'
    }
  ]
}
