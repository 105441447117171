import { CIcon } from '~/icons/types'
export const ciParts2: CIcon = {
  width: 512,
  height: 395,
  paths: [
    {
      d:
        'M356 133.3c-18.8-45.3-54.1-80.7-99.5-99.5s-95.3-18.8-140.7 0-80.7 54.1-99.5 99.5c-18.8 45.3-18.8 95.3 0 140.7s54.1 80.7 99.5 99.5c22.7 9.4 46.5 14.1 70.3 14.1s47.6-4.7 70.3-14.1c45.4-18.8 80.7-54.1 99.5-99.5 18.9-45.4 18.9-95.3.1-140.7zm-19.4 132.6c-16.6 40.2-47.9 71.5-88.1 88.1s-84.4 16.6-124.6 0-71.5-47.9-88.1-88.1-16.6-84.4 0-124.6 47.9-71.5 88.1-88.1c20.1-8.3 41.2-12.5 62.3-12.5s42.2 4.2 62.3 12.5c40.2 16.6 71.5 47.9 88.1 88.1s16.6 84.5 0 124.6z'
    },
    {
      d:
        'M237.2 83.7c-.4-.2-.7-.4-1.1-.5-.4-.2-.8-.3-1.2-.4-30.6-12.3-65.8-13.2-98.6.4s-57.1 39.1-70 69.5c-.2.4-.4.7-.5 1.1-.2.4-.3.8-.4 1.2-12.3 30.6-13.2 65.8.4 98.6s39.1 57.1 69.5 70c.3.2.7.4 1.1.5.4.2.8.3 1.2.4 15.4 6.2 32 9.5 48.8 9.5 16.6 0 33.5-3.2 49.7-9.9 32.2-13.3 57.2-38.4 70.6-70.6 13.3-32.2 13.3-67.6 0-99.8-13.6-32.8-39.1-57.1-69.5-70zm.4 23.4c18.8 10 34.7 25.4 45.2 45.2l-77.2 32 32-77.2zm-51.5-12.9c11 0 21.8 1.7 32.1 4.8l-32 77.2-32-77.2c10.6-3.2 21.3-4.8 31.9-4.8zM134.9 107l32 77.2-77.1-32c9.9-18.7 25.3-34.7 45.1-45.2zm-53.2 64.7 77.1 32-77.2 32c-6.5-21.5-6.2-43.7.1-64zm53.2 128.4c-18.8-10-34.7-25.4-45.2-45.2l77.2-32-32 77.2zm51.5 12.9c-11 0-21.8-1.7-32.1-4.8l31.9-77.1 32 77.2c-10.5 3.2-21.2 4.7-31.8 4.7zm51.2-12.8-32-77.2 77.2 32c-10.2 19.3-25.9 35-45.2 45.2zm53.2-64.6-77.2-32 77.2-32c6.5 21 6.5 43.1 0 64zM500.6 12.3c-5.8-.6-10.9 3.6-11.5 9.4-2 18.9-14.6 34.1-31.3 40.4-2.8-.3-5.4.6-7.4 2.1-4.7 1-9.6 1.2-14.7.7-5.2-.5-10.2-1.9-14.8-4-1.3-1.1-2.8-1.9-4.6-2.3-16-9.3-26-27.4-23.9-47C393 5.8 388.8.7 383 .1s-10.9 3.6-11.5 9.4c-2.8 26.8 10.1 51.7 31.2 65.5l-27.6 264.1c-11.9 7.9-20.3 20.9-21.9 36.2-.6 5.8 3.6 10.9 9.4 11.5s10.9-3.6 11.5-9.4c.8-7.6 4.5-14.5 10.5-19.3s13.4-7 21-6.2 14.5 4.5 19.3 10.5 7 13.4 6.2 21c-.6 5.8 3.6 10.9 9.4 11.5.4 0 .7.1 1.1.1 5.3 0 9.9-4 10.4-9.4 1.4-13.2-2.5-26.1-10.8-36.5-.8-1-1.7-2-2.6-2.9l27.7-264.8c23.2-9.3 40.7-30.8 43.5-57.4.8-5.9-3.4-11.1-9.2-11.7zm-81.6 321c-3.5-1.2-7.2-2-11.1-2.4-3.6-.4-7.2-.3-10.7.1L423 84c3.4.9 6.9 1.5 10.5 1.9 2.4.3 4.9.4 7.3.4 1.3 0 2.7-.1 4-.1L419 333.3z'
    }
  ],
  name: 'parts2',
  type: 'regular'
}
