import { CIcon } from '~/icons/types'
export const ciHairdryer: CIcon = {
  name: 'hairdryer',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M40 209.434V256c0 5.52 4.477 10 10 10s10-4.48 10-10v-32.078a120.29 120.29 0 0 0 57 16.039V266h-7c-16.543 0-30 13.457-30 30s13.457 30 30 30h7.414c.54 6.75 1.477 14.293 3.098 19.16 7.476 22.43 32.824 46.738 37.933 51.48 1.914 2.981 2.114 6.602.5 9.829-1.73 3.465-5.074 5.531-8.945 5.531H90c-16.543 0-30-13.457-30-30v-46c0-5.523-4.477-10-10-10s-10 4.477-10 10v46c0 24.145 17.203 44.348 40 48.992V442c0 13.313 8.719 24.621 20.742 28.531C104.805 494.051 125.34 512 150 512h286c5.523 0 10-4.477 10-10s-4.477-10-10-10H150c-13.816 0-25.473-9.39-28.941-22.121C132.14 465.469 140 454.637 140 442v-10h10c24.707 0 38.824-28.238 24-48.004-.39-.516-.824-.992-1.305-1.426-7.406-6.675-27.812-27.547-33.21-43.734C137.878 334.008 137 321.484 137 316v-77.21c20.516-2.911 16.727-4.134 199.031-68.188l82.676 28.84C425.164 201.691 432 196.917 432 190V30c0-6.879-6.797-11.703-13.293-9.441l-83.195 29.02C144.395 3.46 145.282 0 120 0 54.621 0 0 52.996 0 120c0 34.32 14.523 66.645 40 89.434zM117 306h-7c-5.516 0-10-4.484-10-10s4.484-10 10-10h7zm3 136c0 5.516-4.484 10-10 10s-10-4.484-10-10v-10h20zM346 67.102l66-23.024v131.844l-66-23.024zM120 20c21.305 0 18.387 2.418 206 47.832v85.11C149.094 214.636 147.703 220 120 220c-23.203 0-45.8-8.113-63.629-22.848 0 0-.004 0-.004-.004C33.257 178.058 20 149.938 20 120 20 64.082 65.594 20 120 20zm0 0'
    },
    {
      d:
        'M120 190c38.652 0 70-31.484 70-70 0-38.504-31.328-70-70-70-38.652 0-70 31.484-70 70 0 38.504 31.328 70 70 70zm0-20c-15.973 0-30.688-7.664-39.977-20.012h79.954C150.687 162.336 135.973 170 120 170zm0-100c15.973 0 30.688 7.656 39.977 20H80.023c9.29-12.344 24.004-20 39.977-20zm-48.984 40h97.972a49.971 49.971 0 0 1 0 20H71.016a49.69 49.69 0 0 1 0-20zM502 100h-40c-5.523 0-10 4.477-10 10s4.477 10 10 10h40c5.523 0 10-4.477 10-10s-4.477-10-10-10zm0-40h-40c-5.523 0-10 4.477-10 10s4.477 10 10 10h40c5.523 0 10-4.477 10-10s-4.477-10-10-10zm0 80h-40c-5.523 0-10 4.477-10 10s4.477 10 10 10h40c5.523 0 10-4.477 10-10s-4.477-10-10-10zM60 296c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10zm0 0'
    }
  ]
}
