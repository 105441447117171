import { CIcon } from '~/icons/types'
export const ciAc: CIcon = {
  width: 512,
  height: 228.16,
  paths: [
    {
      d:
        'M140.27 41.69q-4.07-10.69-6.7-17.08a63.8 63.8 0 0 0-6.47-12A30 30 0 0 0 117 3.54Q110.77 0 101.44 0T86 3.54a29.42 29.42 0 0 0-10.08 9.25 75.28 75.28 0 0 0-7.22 14.38Q65.33 35.82 62.91 42L6.47 185.27q-3.46 8.56-5 13.39A31.3 31.3 0 0 0 0 208q0 7.83 6.47 14a21 21 0 0 0 14.9 6.17q9.93 0 14.3-5.8T46.2 200l10.54-28.29h89.7l10.56 27.7q2.11 5.11 5 11.82a47.18 47.18 0 0 0 5.5 9.93 18.46 18.46 0 0 0 6.32 5.12 20 20 0 0 0 9 1.88 21 21 0 0 0 15.42-6.4q6.41-6.39 6.4-14.07 0-7.38-6.77-23.63Zm-71.79 96.47 32.66-91 33.26 91.05ZM280.53 0q-8.73 0-11.74 5.12t-6.32 19l-42 173.53q-3 13.84-3 17.3 0 13.25 15.35 13.25 6.18 0 9.41-2.79a16.56 16.56 0 0 0 4.82-7.75q1.57-5 3.68-13.54l42.14-173.57q3.17-12.33 3.16-17 0-6.33-4-9.94T280.53 0Zm225.75 140.87a19 19 0 0 0-13.69-5.57q-9.34 0-13.55 5.49A47.65 47.65 0 0 0 472 155q-6.78 18.53-20.09 28.07t-32.59 9.56q-17.76 0-31-8.43t-20.5-25.5q-7.21-17.07-7.22-42.82 0-38.52 16.33-59.44t43.87-20.92q17.29 0 29.12 8.12t20.54 24.54q5.27 9.93 9.56 14t12.87 4.07A17.19 17.19 0 0 0 506 80.37a19.4 19.4 0 0 0 5.42-13.55q0-14-11.14-29.72T468.2 10.69Q447.29 0 420.5 0a109.59 109.59 0 0 0-41.39 7.83 96.63 96.63 0 0 0-33.86 22.8 101.56 101.56 0 0 0-22.2 36q-7.76 21.08-7.75 47.71a149.43 149.43 0 0 0 3.08 31 121 121 0 0 0 9.26 27.47 109.26 109.26 0 0 0 14.9 23.25A87.65 87.65 0 0 0 364 214.54a89.44 89.44 0 0 0 26 10.31 141.3 141.3 0 0 0 31.75 3.31q23 0 40.33-7.38t28.45-19.19q11.13-11.81 16.33-24.08t5.19-22.8a18.55 18.55 0 0 0-5.77-13.84Z'
    }
  ],
  name: 'ac',
  type: 'regular'
}
