import { CIcon } from '~/icons/types'
export const ciPicture: CIcon = {
  name: 'picture',
  type: 'solid',
  width: 56,
  height: 46,
  paths: [
    { d: 'm18.03 17.255-12 13.5V46h23.773l6.889-7.75z' },
    {
      d:
        'M4.03 30.375V5a1 1 0 0 1 1-1h45V0h-50v40h4zm34.747 8.539v.001L32.479 46H56.03v-3.995l-10-11.25z'
    },
    { d: 'M36.03 18a3 3 0 1 0 6 0 3 3 0 1 0-6 0' },
    {
      d:
        'M51.03 6h-45v21.745l11.253-12.659c.379-.427 1.115-.427 1.494 0L38.03 36.745l7.253-8.159c.379-.427 1.115-.427 1.494 0l9.253 10.409V6zm-12 17c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z'
    }
  ]
}
