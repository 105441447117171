import { CIcon } from '~/icons/types'
export const ciCategory: CIcon = {
  name: 'category',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M502 415.011h-81.746c-5.522 0-10-4.478-10-10s4.478-10 10-10H502c5.522 0 10 4.478 10 10s-4.478 10-10 10z'
    },
    {
      d:
        'M329.841 415.011h-81.747c-5.523 0-10-4.478-10-10s4.477-10 10-10h81.747c5.523 0 10 4.478 10 10s-4.478 10-10 10z'
    },
    {
      d:
        'M375.229 415.01c-4.197 0-7.98-2.707-9.406-6.636-1.401-3.862-.196-8.335 2.938-10.986 3.283-2.778 8.092-3.122 11.746-.864 3.507 2.168 5.322 6.399 4.523 10.436-.914 4.619-5.091 8.05-9.801 8.05z'
    },
    {
      d:
        'M502 362.765H248.094c-5.523 0-10-4.477-10-10 0-5.522 4.477-10 10-10H502c5.522 0 10 4.478 10 10s-4.478 10-10 10z'
    },
    {
      d:
        'M502 467.257H248.094c-5.523 0-10-4.478-10-10s4.477-10 10-10H502c5.522 0 10 4.478 10 10s-4.478 10-10 10z'
    },
    {
      d:
        'M199.997 44.743H10c-5.523 0-10 4.478-10 10v402.514c0 5.522 4.477 10 10 10h189.997c5.523 0 10-4.478 10-10V54.743c0-5.522-4.477-10-10-10zM20 64.743h169.997v278.119H20zm169.997 382.514H20v-84.395h169.997z'
    },
    {
      d:
        'M349.547 310.696H248.094c-5.523 0-10-4.477-10-10v-98.995c0-5.523 4.477-10 10-10h101.453c5.522 0 10 4.477 10 10v98.995c0 5.523-4.478 10-10 10zm-91.453-20h81.453v-78.995h-81.453z'
    },
    {
      d:
        'M502 310.696H400.547c-5.522 0-10-4.477-10-10v-98.995c0-5.523 4.478-10 10-10H502c5.522 0 10 4.477 10 10v98.995c0 5.523-4.478 10-10 10zm-91.453-20H492v-78.995h-81.453z'
    },
    {
      d:
        'M502 163.738H248.094c-5.523 0-10-4.478-10-10V54.743c0-5.522 4.477-10 10-10H502c5.522 0 10 4.478 10 10v98.995c0 5.523-4.478 10-10 10zm-243.905-20H492V64.743H258.095z'
    }
  ]
}
