import { CIcon } from '~/icons/types'
export const ciStudent: CIcon = {
  name: 'student',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M507.105 95.203 345.2 33.477a7.607 7.607 0 0 0-9.816 4.394 7.606 7.606 0 0 0 4.398 9.816l143.27 54.622-99.18 37.816-124.734-56.477a7.6 7.6 0 0 0-10.063 3.79 7.606 7.606 0 0 0 3.79 10.066l110.988 50.254L256 188.875 28.95 102.309 256 15.742l55.953 21.336a7.613 7.613 0 0 0 9.817-4.398 7.603 7.603 0 0 0-4.399-9.813L258.711.5a7.606 7.606 0 0 0-5.422 0L4.895 95.203A7.61 7.61 0 0 0 0 102.31v34.726a7.61 7.61 0 0 0 4.895 7.106l77.566 29.57v26.351a7.604 7.604 0 1 0 15.207 0v-20.55l155.621 59.332c.875.332 1.793.5 2.711.5s1.836-.168 2.71-.5l117.306-44.723v120.344c-36.828 19.331-78.126 29.69-120.016 29.69-57.172 0-113.25-19.273-158.332-54.347V230.48a7.604 7.604 0 1 0-15.207 0v63.012a7.599 7.599 0 0 0 2.848 5.934c48.246 38.652 108.867 59.937 170.691 59.937 41.695 0 82.836-9.695 120.016-27.855v30.777c-12.254 3.348-21.29 14.57-21.29 27.871 0 15.93 12.962 28.895 28.891 28.895 15.934 0 28.895-12.965 28.895-28.895 0-13.3-9.035-24.523-21.29-27.87v-38.81a271.025 271.025 0 0 0 35.473-24.05 7.603 7.603 0 0 0 2.852-5.934V173.707l77.558-29.57A7.603 7.603 0 0 0 512 137.03v-34.72a7.61 7.61 0 0 0-4.895-7.106zm-109.8 294.953c0 7.543-6.14 13.684-13.688 13.684-7.543 0-13.683-6.14-13.683-13.684 0-7.547 6.14-13.687 13.683-13.687 7.547 0 13.688 6.14 13.688 13.687zM15.207 113.348l233.188 88.906v18.445L15.207 131.793zm248.398 107.351v-18.445l112.41-42.86v18.45zm150.73 69.106a254.74 254.74 0 0 1-23.112 16.007V188.32l23.113-8.812zm-23.112-117.762v-18.445l105.57-40.25v18.445zm0 0',
      fill: 'currentColor'
    }
  ]
}
