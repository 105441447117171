export function extractOrientationValue(a: ArrayBuffer): number {
  const view = new DataView(a)

  const length = view.byteLength
  let offset = 2
  let orientation = -1
  while (offset < length) {
    if (view.getUint16(offset + 2, false) <= 8) {
      orientation = -1
    }
    const marker = view.getUint16(offset, false)
    offset += 2
    // eslint-disable-next-line
    if (marker === 0xffe1) {
      if (view.getUint32((offset += 2), false) !== 0x45786966) {
        orientation = -1
        break
      }

      const little = view.getUint16((offset += 6), false) === 0x4949
      offset += view.getUint32(offset + 4, little)
      const tags = view.getUint16(offset, little)
      offset += 2
      for (let i = 0; i < tags; i++) {
        if (view.getUint16(offset + i * 12, little) === 0x0112) {
          orientation = view.getUint16(offset + i * 12 + 8, little)
        }
      }
      if (orientation > 0) {
        break
      }
      // eslint-disable-next-line
    } else if ((marker & 0xff00) !== 0xff00) {
      break
    } else {
      offset += view.getUint16(offset, false)
    }
  }
  return orientation
}

export function base64ToFile(dataURI: string, file: File) {
  let byteString

  if (dataURI.split(',')[0].includes('base64')) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = decodeURI(dataURI.split(',')[1])
  }

  const mimestring = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  const content = []
  for (let i = 0; i < byteString.length; i++) {
    content[i] = byteString.charCodeAt(i)
  }

  return new File([new Uint8Array(content)], file.name, {
    type: mimestring
  })
}

export const blobUrlToFile = (
  blobUrl: string,
  title = 'image'
): Promise<File> =>
  new Promise(resolve => {
    fetch(blobUrl).then(async res => {
      const blob = await res.blob()
      const file = new File([blob], `${title}.png`, {
        type: 'image/png',
        lastModified: new Date().getTime()
      })
      resolve(file)
    })
  })

export function resizeImage({
  canvas,
  initWidth,
  initHeight,
  maxResizeWidth,
  maxResizeHeight,
  minWidth,
  minHeight,
  orientation,
  image,
  eventWidth,
  eventHeight,
  file,
  make
}: {
  canvas: HTMLCanvasElement
  initWidth: number
  initHeight: number
  maxResizeWidth: number
  maxResizeHeight: number
  minWidth: number
  minHeight: number
  orientation: number
  image: any
  eventWidth: any
  eventHeight: any
  file: File
  make: string
}) {
  function getImageAndThumb(canvas: HTMLCanvasElement) {
    return {
      file: base64ToFile(canvas.toDataURL('image/jpeg'), file),
      thumb: canvas.toDataURL('image/jpeg'),
      width: canvas.width,
      height: canvas.height
    }
  }
  let width = initWidth
  let height = initHeight
  canvas.width = width
  canvas.height = height
  canvas.style.opacity = '0'

  let resized = false

  if (width > maxResizeWidth) {
    height *= maxResizeWidth / width
    width = maxResizeWidth
    resized = true
  }
  if (height > maxResizeHeight) {
    width *= maxResizeHeight / height
    height = maxResizeHeight
    resized = true
  }

  // repeat for minimum resizes
  if (width < minWidth) {
    height *= minWidth / width
    width = minWidth
    resized = true
  }
  if (height < minHeight) {
    width *= minHeight / height
    height = minHeight
    resized = true
  }

  if (resized) {
    canvas.width = width
    canvas.height = height
  }
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return getImageAndThumb(canvas)
  }

  // transform context before drawing image
  if (orientation > 1) {
    switch (orientation) {
      case 2:
        // flip horizontal
        ctx.translate(width, 0)
        ctx.scale(-1, 1)
        ctx.drawImage(image, 0, 0, eventWidth, eventHeight, 0, 0, width, height)
        break // supported
      case 3:
        // rotate 180 degrees left
        ctx.translate(width, height)
        ctx.rotate(-Math.PI)
        ctx.drawImage(image, 0, 0, eventWidth, eventHeight, 0, 0, width, height)
        break // supported
      case 4:
        // vertical flip
        ctx.translate(0, height)
        ctx.scale(1, -1)
        ctx.drawImage(image, 0, 0, eventWidth, eventHeight, 0, 0, width, height)
        break // supported
      case 5:
        // 90 rotate right + flip horizontal
        ctx.translate(width, 0)
        ctx.scale(-1, 1)
        ctx.translate(width, 0)
        ctx.rotate((90 * Math.PI) / 180)

        ctx.drawImage(image, 0, 0, eventWidth, eventHeight, 0, 0, height, width)
        break // supported
      case 7:
        // horizontal flip + 90 rotate right
        ctx.translate(width, 0)
        ctx.scale(-1, 1)
        ctx.translate(0, height)
        ctx.rotate((-90 * Math.PI) / 180)

        ctx.drawImage(image, 0, 0, eventWidth, eventHeight, 0, 0, height, width)
        break
      case 8:
        ctx.translate(0, height)
        ctx.rotate((-90 * Math.PI) / 180)
        ctx.drawImage(image, 0, 0, eventWidth, eventHeight, 0, 0, height, width)
        break
    }
    // @Hack for ipad images
    if (orientation === 6 && make === 'Apple') {
      ctx.translate(0, height)
      ctx.rotate((-90 * Math.PI) / 180)
      ctx.drawImage(image, 0, 0, eventWidth, eventHeight, 0, 0, height, width)
    } else if (orientation === 6) {
      ctx.translate(width, 0)
      ctx.rotate((90 * Math.PI) / 180)
      ctx.drawImage(image, 0, 0, eventWidth, eventHeight, 0, 0, height, width)
    }
  }

  // if it has orientation it will be drawn in the orientation case
  if (orientation <= 1) {
    if (resized) {
      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height)
    } else {
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0, image.width, image.height)
    }
  }

  return getImageAndThumb(canvas)
}
