import { CIcon } from '~/icons/types'
export const ciChassis: CIcon = {
  name: 'chassis',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M394.667 149.333H416c17.646 0 32-14.354 32-32V32c0-17.646-14.354-32-32-32h-21.333c-17.646 0-32 14.354-32 32v22.257l-77.677 7.06c-5.078-10.975-16.122-18.65-28.99-18.65s-23.911 7.674-28.99 18.65l-77.677-7.06V32c0-17.646-14.354-32-32-32H96C78.354 0 64 14.354 64 32v85.333c0 17.646 14.354 32 32 32h21.333c17.646 0 32-14.354 32-32V95.077l77.677-7.06c1.858 4.016 4.534 7.534 7.799 10.444l7.773 115.427c-4.526 1.234-7.917 5.191-7.917 10.112v64c0 4.738 3.134 8.616 7.404 10.009l-7.245 94.184C228.238 398.059 224 406.505 224 416v7.712l-74.667-6.789v-22.257c0-17.646-14.354-32-32-32H96c-17.646 0-32 14.354-32 32V480c0 17.646 14.354 32 32 32h21.333c17.646 0 32-14.354 32-32v-22.256l74.667-6.79v7.712c0 17.646 14.354 32 32 32s32-14.354 32-32v-7.712l74.667 6.789V480c0 17.646 14.354 32 32 32H416c17.646 0 32-14.354 32-32v-85.333c0-17.646-14.354-32-32-32h-21.333c-17.646 0-32 14.354-32 32v22.257L288 423.712V416c0-9.497-4.24-17.945-10.831-23.811l-7.314-94.165c4.307-1.371 7.478-5.259 7.478-10.023v-64c0-4.891-3.35-8.835-7.835-10.096l7.695-115.445c3.263-2.91 5.938-6.427 7.796-10.441l77.677 7.06v22.256c.001 17.644 14.355 31.998 32.001 31.998z',
      fill: 'currentColor'
    }
  ]
}
