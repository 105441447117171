























































import BaseSearchHandler from './BaseSearchHandler'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { CCustomSelect, COptionWithCount },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedMakes: null,
      selectedModels: null
    }
  },
  computed: {
    showMakes() {
      return this.makesOptions.length !== 0
    },
    showModels() {
      return this.modelsOptions.length !== 0
    },
    makesOptions() {
      return (this.searchables.makes || []).map(mapBackendSearchableToOption)
    },
    modelsOptions() {
      return (this.searchables.models || []).map(mapBackendSearchableToOption)
    }
  },
  methods: {
    onBackendValues({ selected }) {
      const { makes, models } = selected
      this.selectedMakes = makes
      this.selectedModels = models
    },
    onClear() {
      this.selectedMakes = []
      this.selectedModels = []
      this.emitValues()
    },
    handleMakesChange(makes) {
      this.selectedMakes = makes
      this.emitValues()
    },
    handleModelsChange(models) {
      this.selectedModels = models
      this.emitValues()
    },
    emitValues() {
      this.emitParams([
        {
          name: 'manufacturer',
          value: this.selectedMakes
        },
        {
          name: 'product_model',
          value: this.selectedModels
        }
      ])
    }
  }
})
