import { CIcon } from '~/icons/types'

export const ciWantedParts: CIcon = {
  width: '512',
  height: '512',
  paths: [
    {
      d:
        'M510.492 201.136c-1.2-8.301-6.901-15.003-14.902-17.503-2.201-.7-4.501-1.1-6.802-1.1-5.901 0-11.402 2.4-15.603 6.701l-29.005 29.605-53.91-6.501-7.501-53.51 28.905-29.605c5.901-5.901 7.902-14.603 5.101-22.604-2.7-7.902-9.502-13.503-17.803-14.603-5.601-.7-11.302-1.1-16.903-1.1-40.408 0-77.914 18.603-102.919 51.009-11.502 14.903-19.704 32.606-23.704 51.31-3.801 17.603-3.901 36.106-.3 53.71L118.421 388.07c-13.503 13.703-20.904 32.006-20.704 51.51.2 19.503 7.901 37.707 21.804 51.209 13.602 13.303 31.606 20.704 50.809 20.704 19.704 0 38.207-7.702 51.91-21.704l136.725-141.626a130.3 130.3 0 0 0 23.304 2.1c34.806 0 68.612-14.202 92.817-38.907 14.402-14.602 24.904-32.106 31.105-51.909 5.802-18.504 7.302-38.707 4.301-58.311ZM188.833 447.981c-3.7 3.701-8.601 5.901-13.902 5.901-5.201 0-10.102-2-13.803-5.501-3.7-3.6-5.801-8.501-5.901-13.702-.1-5.301 2.001-10.302 5.501-14.003 3.501-3.7 8.502-5.801 13.603-5.801h.5c5.201 0 9.902 1.901 13.602 5.501 7.802 7.302 7.902 19.804.4 27.605Z',
      fill: '#000'
    },
    {
      d:
        'M106.119 260.648c7.702 1.4 15.603 2.2 23.405 2.2 7.801 0 15.703-.7 23.404-2.2 6.901-1.301 12.302-7.102 12.902-14.203l.7-7.101c4.101-1.501 8.202-3.201 12.103-5.101l5.301 4.401c2.8 2.4 6.501 3.7 10.202 3.7 3.3 0 6.401-1 9.101-2.9 12.903-9.102 24.005-20.304 33.006-33.506 4.001-5.801 3.601-13.703-.8-19.104l-4.501-5.501c1.901-4.101 3.701-8.201 5.101-12.502l6.901-.7c7.002-.7 12.803-6.101 14.103-13.103 2.801-15.803 2.801-31.705 0-47.308-1.3-7.002-7.001-12.403-14.103-13.103l-6.901-.7c-1.5-4.3-3.2-8.401-5.101-12.502l4.501-5.501c4.401-5.501 4.701-13.303.8-19.104-9.001-13.102-20.103-24.404-33.006-33.406-2.7-1.9-5.801-2.9-9.101-2.9-3.701 0-7.302 1.3-10.202 3.7l-5.301 4.401c-3.901-1.9-8.002-3.6-12.103-5.1l-.7-7.102c-.7-7.101-6.001-12.902-12.902-14.203C145.227.8 137.325 0 129.524 0c-7.802 0-15.603.7-23.405 2.2-6.9 1.3-12.302 7.102-12.902 14.203l-.7 7.101c-4.1 1.5-8.202 3.2-12.102 5.101l-5.301-4.4c-2.8-2.401-6.502-3.701-10.102-3.701-3.3 0-6.401 1-9.102 2.9A132.632 132.632 0 0 0 22.904 56.81c-4 5.801-3.6 13.703.8 19.204l4.501 5.5c-1.9 4.102-3.7 8.202-5.1 12.503l-6.902.7c-7.001.7-12.802 6.101-14.103 13.103a133.68 133.68 0 0 0 0 47.208c1.3 7.002 7.002 12.403 14.103 13.103l4.9.5 2.001.2c1.5 4.301 3.2 8.401 5.101 12.502l-4.5 5.501c-4.401 5.501-4.801 13.303-.8 19.104 8.9 13.102 20.003 24.404 33.005 33.506 2.7 1.9 5.801 2.9 9.102 2.9 3.7 0 7.301-1.3 10.102-3.7l5.3-4.401c3.901 1.9 8.002 3.6 12.103 5.101l.7 7.101c.7 7.101 6.001 12.902 12.902 14.203Zm23.505-96.418c-18.004-.1-32.706-14.903-32.706-33.006 0-10.202 5.101-20.104 13.502-26.405 5.601-4.201 12.202-6.401 19.104-6.401 7.001.1 13.702 2.4 19.303 6.501 8.502 6.101 13.503 16.003 13.503 26.405 0 18.003-14.703 32.806-32.706 32.906Z',
      fill: '#000'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'wanted-parts',
  type: 'solid'
}
