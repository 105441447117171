import { CIcon } from '~/icons/types'
export const ciToolsAndUtensils: CIcon = {
  name: 'tools-and-utensils',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    { d: 'M177.778 177.778h156.444v156.444H177.778z' },
    {
      d:
        'M512 177.778v-42.667h-49.778v-21.333c0-35.29-28.71-64-64-64h-21.333V0h-42.667v49.778h-56.889V0h-42.667v49.778h-56.889V0H135.11v49.778h-21.333c-35.29 0-64 28.71-64 64v21.333H0v42.667h49.778v56.889H0v42.667h49.778v56.889H0v42.667h49.778v21.333c0 35.29 28.71 64 64 64h21.333V512h42.667v-49.778h56.889V512h42.667v-49.778h56.889V512h42.667v-49.778h21.333c35.29 0 64-28.71 64-64v-21.333H512v-42.667h-49.778v-56.889H512v-42.667h-49.778v-56.889H512zM376.889 376.889H135.111V135.111h241.778v241.778z'
    }
  ]
}
