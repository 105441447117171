import { CIcon } from '~/icons/types'
export const ciArea: CIcon = {
  width: 1020,
  height: 1000,
  paths: [
    {
      d:
        'M917 100H250q-7 0-12 5t-5 12 5 11.5 12 4.5h667q20 0 35 15t15 35v734q0 21-14.5 35.5T917 967H100q-28 0-47.5-19.5T33 900t19.5-47.5T100 833t47.5 19.5T167 900q0 7 4.5 12t11.5 5 12-5 5-12V100q0-41-29.5-70.5T100 0 29.5 29.5 0 100v800q0 41 29.5 70.5T100 1000h817q35 0 59-24.5t24-58.5V183q0-35-24-59t-59-24zm-884 0q0-28 19.5-47.5T100 33t47.5 19.5T167 100v726q-29-26-67-26t-67 26V100zm650 767q-7 0-11.5 4.5T667 883t4.5 12 11.5 5h200q8 0 12.5-5t4.5-12V217q0-8-4.5-12.5T883 200H283q-7 0-11.5 5t-4.5 12v666q0 7 4.5 12t11.5 5h334q7 0 11.5-5t4.5-12q0-38 18-70.5t48.5-53.5 67.5-25v83q0 7 4.5 11.5T783 833t12-4.5 5-11.5V717q0-8-4.5-12.5T783 700q-47 0-87 22t-65.5 60.5T601 867H300V600h317q7 0 11.5-5t4.5-12v-66q0-7-4.5-12t-11.5-5-12 5-5 12v50H300V233h300v117q0 7 5 12t12 5 11.5-5 4.5-12V233h234v334h-84q-7 0-11.5 4.5T767 583t4.5 12 11.5 5h84v267H683z',
      fill: 'currentColor'
    }
  ],
  name: 'area',
  type: 'regular'
}
