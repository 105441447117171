import { CIcon } from '~/icons/types'
export const ciAshtray: CIcon = {
  name: 'ashtray',
  type: 'light',
  width: 64,
  height: 64,
  paths: [
    {
      d:
        'M38.853 8.992a1.005 1.005 0 0 0-1.244.674 1.002 1.002 0 0 0 .674 1.244C47.538 13.662 54.001 22.335 54.001 32c0 12.132-9.869 22.001-22.001 22.001a21.765 21.765 0 0 1-12.376-3.83l16.369-16.368a.997.997 0 0 0 0-1.414l-4.382-4.382a.999.999 0 0 0-1.414 0l-16.37 16.37A21.783 21.783 0 0 1 9.999 32c0-10.533 7.496-19.619 17.823-21.604.543-.104.897-.629.794-1.171a1.005 1.005 0 0 0-1.171-.794C16.177 10.598 7.999 20.51 7.999 32a23.76 23.76 0 0 0 4.394 13.812l-4.101 4.101a.999.999 0 0 0 0 1.414l4.381 4.382a.997.997 0 0 0 1.414 0l4.103-4.103a23.745 23.745 0 0 0 13.811 4.396c13.234 0 24.001-10.767 24.001-24.001-.001-10.544-7.053-20.006-17.149-23.009zm-7.949 21.136 2.968 2.968-2.968 2.967-2.967-2.968zM13.38 53.587l-2.967-2.968 16.11-16.11 2.967 2.968z'
    },
    {
      d:
        'M32 49.619c-1.767 0-3.51-.26-5.183-.773a1 1 0 1 0-.586 1.912c1.862.571 3.804.861 5.769.861 10.819 0 19.621-8.801 19.621-19.619 0-8.117-5.103-15.497-12.697-18.363a1 1 0 1 0-.707 1.871C45.038 18.082 49.621 24.71 49.621 32c0 9.715-7.905 17.619-17.621 17.619z'
    },
    {
      d:
        'M25.774 15.512a1 1 0 1 0-.707-1.871C17.478 16.508 12.38 23.886 12.38 32c0 1.966.29 3.906.862 5.769a1.001 1.001 0 0 0 1.912-.588A17.636 17.636 0 0 1 14.38 32c0-7.287 4.579-13.913 11.394-16.488z'
    },
    {
      d:
        'M31.4 19.653c1.144.857 1.79 1.396 1.79 2.484a1 1 0 1 0 2 0c0-2.142-1.437-3.219-2.59-4.084-1.144-.857-1.791-1.396-1.791-2.485s.647-1.627 1.79-2.484c1.154-.865 2.591-1.942 2.591-4.085a1 1 0 1 0-2 0c0 1.09-.647 1.628-1.791 2.485-1.153.865-2.59 1.942-2.59 4.084.001 2.143 1.437 3.22 2.591 4.085z'
    }
  ]
}
