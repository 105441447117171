import { Merge } from 'type-fest'
import { PropOptions } from 'vue'

export interface GoogleOrAdmanAd {
  google?: GoogleRowAd
  adman?: AdmanRowAd
}

export interface RowAdComponentProps {
  sticky?: boolean
  lazy?: boolean
  unit?: string
  containerSize?: [number, number]
}

export interface SharedRowAdProps {
  componentProps?: RowAdComponentProps
}

export type GoogleRowAd = Merge<SharedRowAdProps, GoogleAd>

export type AdmanRowAd = Merge<SharedRowAdProps, AdmanAd>

export type RowsAds = Record<number, GoogleOrAdmanAd>

export interface GoogleAd {
  path: string
  sizes: any[]
  id: string
  sizesMappings?: any[]
}

export interface AdmanAd {
  ws: string
  size: string
  passbackId: string
}
export interface AdsTargeting {
  name: string
  value: string[]
}

export const containerSizeProp: PropOptions<number[]> = {
  type: Array,
  required: false,
  default() {
    return [0, 0]
  }
}

export const containerSizeMappingProp: PropOptions<number[]> = {
  type: Array,
  required: false,
  default() {
    return []
  }
}

export const placeholderProp: PropOptions<boolean> = {
  type: Boolean,
  default: true
}

export interface BackendAdsConfig {
  disabled: boolean
}

export interface AdContainerSizeCssRules {
  'min-height'?: string
  'min-width'?: string
  height?: string
  width?: string
  left?: string
  top?: string
}
