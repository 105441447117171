import { useNamespacedStore } from '~/compositions/store'
import { APP_NS, AppState } from '~/store/modules/shared/app/state'
import { computed } from '@nuxtjs/composition-api'

export function useApp() {
  const { getters: appGetters } = useNamespacedStore<AppState>(APP_NS)

  const isPlot = computed(() => appGetters('isPlot'))
  const isCar = computed(() => appGetters('isCar'))

  return { isCar, isPlot }
}
