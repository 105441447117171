




















import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'

export default defineComponent({
  setup() {
    const {
      getters: searchGetters,
      dispatch: searchDispatch
    } = useNamespacedStore<SearchState>(CLASSIFIED_SEARCH_NS)

    const facetCount = computed(() => searchGetters('getFacetsCount'))

    function handleCloseButtonClick() {
      searchDispatch('toggleMobileFacets')
    }

    return { facetCount, handleCloseButtonClick, faTimes }
  }
})
