import { CIcon } from '~/icons/types'
export const ciIron: CIcon = {
  name: 'iron',
  type: 'light',
  width: 512.002,
  height: 512,
  paths: [
    {
      d:
        'M511.855 201.164c-4.683-42.121-18.992-83.82-32.726-115.996l16.418-6.344a18.779 18.779 0 0 0 10.488-9.996 18.815 18.815 0 0 0 .352-14.484l-16.043-41.532c-3.766-9.738-14.746-14.597-24.485-10.835l-25.562 9.875c-.39-.625-.781-1.254-1.184-1.895A21.15 21.15 0 0 0 418.262.203l-70.418 9.824C241.109 22.7 189.746 45.711 185.94 47.47a269.755 269.755 0 0 0-30.773 15.226c-1.414-10.77-10.645-19.113-21.793-19.113h-25.387c-12.125 0-21.984 9.863-21.984 21.988v54.446c-15.047 17.386-28.3 36.933-39.504 58.336-21.617 41.285-28.852 76.242-30.27 83.988L2.258 284.55a8.11 8.11 0 0 0-.438.81 18.966 18.966 0 0 0 1.164 18.32 18.97 18.97 0 0 0 16.09 8.84h57.16a7.49 7.49 0 1 0 0-14.98h-57.16c-2.023 0-3.082-1.325-3.441-1.895-.344-.536-1.024-1.915-.387-3.598l12.25-19.477h393.582v20.887a4.081 4.081 0 0 1-4.078 4.078H111.191a7.49 7.49 0 1 0 0 14.98H417c10.508 0 19.059-8.55 19.059-19.058v-56.875c0-4.887 3.976-8.863 8.863-8.863h43.242a23.84 23.84 0 0 0 17.742-7.93 23.98 23.98 0 0 0 5.95-18.625zM471.262 15.95a3.929 3.929 0 0 1 3.02.07 3.933 3.933 0 0 1 2.085 2.192l16.043 41.531a3.92 3.92 0 0 1-.07 3.024 3.958 3.958 0 0 1-2.192 2.086l-17.086 6.597a646.694 646.694 0 0 0-2.902-6.242c-7.765-16.457-13.273-26.367-21.844-40.394zM100.984 65.57a7.014 7.014 0 0 1 7.008-7.007h25.383a7.014 7.014 0 0 1 7.008 7.007v6.317c-14.067 9.39-27.227 20.125-39.399 32.136zM494.742 209.8a8.718 8.718 0 0 1-6.578 2.938h-43.242c-13.149 0-23.844 10.696-23.844 23.844v21.008H32.563c3.171-13.567 11.023-41.508 27.453-72.754C73.457 159.266 89.938 136.5 109 117.164c23.746-24.082 51.621-42.898 82.852-55.926.097-.043.199-.086.296-.133.5-.234 51.211-23.593 157.54-36.21.05-.004.101-.012.152-.02l70.492-9.836a6.164 6.164 0 0 1 6.07 2.844c13.703 21.969 19.957 31.996 30.215 53.722 15.375 32.57 34.856 81.774 40.352 131.215.285 2.578-.504 5.055-2.227 6.98zm0 0'
    },
    {
      d:
        'M400.328 145.637a7.488 7.488 0 0 0-9.133-5.367 7.49 7.49 0 0 0-5.367 9.132c1.516 5.825 2.656 11.403 3.395 16.578.367 2.575-.375 5.075-2.082 7.04-1.715 1.976-4.098 3.066-6.715 3.066h-41.531v-13.832c0-12.02-9.782-21.8-21.805-21.8h-75.61c-12.02 0-21.8 9.78-21.8 21.8v13.832h-54.137c-3.398 0-6.344-1.8-7.879-4.816-1.562-3.075-1.277-6.586.77-9.391 37.105-50.977 112.453-82.516 183.21-76.707 13.696 1.125 21.922 11.785 30.762 28.617a122.19 122.19 0 0 1 2.031 4.05c1.79 3.731 6.266 5.306 9.993 3.513a7.49 7.49 0 0 0 3.515-9.993 144.13 144.13 0 0 0-2.277-4.535c-6.34-12.078-18.145-34.558-42.797-36.582-35.273-2.898-73.719 3.113-108.262 16.93-37.214 14.887-67.746 37.672-88.289 65.89-5.347 7.344-6.117 16.926-2.007 25 4.082 8.024 12.218 13.004 21.23 13.004h214.883a23.858 23.858 0 0 0 23.629-27.191c-.817-5.738-2.07-11.875-3.727-18.238zm-76.418 30.449h-89.25v-13.832c0-3.758 3.059-6.82 6.82-6.82h75.61c3.762 0 6.82 3.062 6.82 6.82zm50.766 24.414c-13.196 0-23.93 10.734-23.93 23.93 0 13.195 10.734 23.93 23.93 23.93 13.195 0 23.93-10.735 23.93-23.93 0-13.196-10.735-23.93-23.93-23.93zm0 32.879c-4.934 0-8.95-4.016-8.95-8.95 0-4.933 4.016-8.949 8.95-8.949 4.933 0 8.949 4.016 8.949 8.95 0 4.933-4.016 8.949-8.95 8.949zm0 0'
    }
  ]
}
