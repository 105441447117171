import { CIcon } from '~/icons/types'
export const ciSemitruck: CIcon = {
  name: 'semitruck',
  type: 'regular',
  width: 512,
  height: 351.8,
  paths: [
    {
      d:
        'M509.1 223.2c0-.6-.1-1.1-.1-1.7L477.3 33.8c-.2-1.2-.6-2.3-1.2-3.4-2-3.5-8-14.3-45.1-23.4-18.4-4.5-37.6-7-46.3-7H255.4c-2.8 0-5.5 1.1-7.4 3.1-2 2-3.1 4.6-3.1 7.4l.4 242.8h-96c-8.8-7.1-19.9-11.6-31.9-12.3-13.9-.7-27.1 3.7-37.7 12.3H12.5C6.7 253.3 2 258 2 263.8v45.8c0 5.8 4.7 10.5 10.5 10.5h52.1c8.6 18.1 26.7 30.8 47.8 31.6h2.1c21.6 0 41-12.5 50.1-31.6h147.5c8.9 18.7 28 31.7 50.1 31.7s41.2-13 50.1-31.8h87.1c2.8 0 5.5-1.1 7.5-3.1s3.1-4.7 3-7.5l-.8-86.2zm-32.2-65.7H363.4c-3.7 0-5.6-.4-6.4-.7 0-.4-.1-.9-.1-1.7V78.9c3.7-.3 10.1-.3 16.7-.3h89.9l13.4 78.9zm11.3 69.3.1 14.2h-15.4c-2.3 0-4.9-2.9-4.9-7.1s2.6-7.1 4.9-7.1h15.3zM23 299.2v-24.8h40.8c-3.1 7.2-4.7 15-4.6 22.9 0 .6 0 1.3.1 1.9H23zm90.2 31.6c-18.2-.7-32.7-15.6-33-33.9-.1-9.4 3.4-18.3 10.1-24.9.4-.3.8-.7 1.1-1.1 6.4-5.8 14.5-9 23.1-9h1.8c8.3.4 15.9 3.9 21.6 9.2.1.1.2.2.4.3 5.9 5.7 9.9 13.5 10.5 22.2.3 4.4-.1 8.6-1.3 12.5-4.4 15.2-18.5 25.3-34.3 24.7zm131.7-31.6h-75.1c.1-2.3.1-4.6-.1-6.9v-.1c-.5-6.3-2-12.3-4.4-17.8h79.6v24.8zm117.3 31.7c-19 0-34.4-15.4-34.4-34.4s15.4-34.4 34.4-34.4 34.4 15.4 34.4 34.4-15.4 34.4-34.4 34.4zm55.4-31.8c0-.9.1-1.7.1-2.6 0-30.5-24.9-55.4-55.4-55.4s-55.4 24.9-55.4 55.4c0 .9 0 1.8.1 2.7h-40.5L266 21.1h118.8c6.2 0 23.7 2 41.3 6.3 20.1 4.9 28.5 10 31 12.4l3 17.8h-86.4c-16.1 0-22.8 0-28.3 2.6C336 64.7 336 73.7 336 81v74.1c0 10.7 4.8 23.4 27.5 23.4h117l4.6 27.3H473c-14.3 0-25.9 12.6-25.9 28.1S458.7 262 473 262h15.6l.3 37.1h-71.3z',
      fill: 'currentColor'
    }
  ]
}
