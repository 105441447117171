import { CIcon } from '~/icons/types'
export const ciXyma: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M309.7 427.8V277.7c0-5.8 4.7-10.5 10.5-10.5h79.2c5.8 0 10.5 4.7 10.5 10.5v149.5c15.8-3 27.9-17 27.9-33.7V239c-15.5-2.8-28.9-11.7-37.6-24.2C389.6 230 372 240 352.1 240s-37.5-10-48.1-25.2C293.4 230 275.8 240 255.9 240c-19.9 0-37.5-10-48.1-25.2-10.6 15.2-28.2 25.2-48.1 25.2-19.9 0-37.5-10-48.1-25.2-8.7 12.5-22.1 21.4-37.6 24.2v154.5c0 18.9 15.4 34.3 34.3 34.3h201.4zM102 380.7v-103c0-5.8 4.7-10.5 10.5-10.5h153.8c5.8 0 10.5 4.7 10.5 10.5v102.9c0 5.8-4.7 10.5-10.5 10.5H112.5c-5.8.1-10.5-4.6-10.5-10.4z',
      fill: '#91D4F3'
    },
    {
      d:
        'M101.3 181.3v-1.2c0-.2 0-.3.1-.5 0-.2.1-.4.1-.6v-.1L124 84.2H86.3c-11.9 0-22.6 8-25.8 19.5-.1.4-.3.9-.5 1.3l-33.9 78.3c1.1 19.8 17.5 35.5 37.5 35.5 20.8.1 37.7-16.8 37.7-37.5z',
      fill: '#91D4F3'
    },
    {
      d:
        'M256 218.9c20.7 0 37.6-16.9 37.6-37.6V84.2h-75.2v97.1c0 20.7 16.9 37.6 37.6 37.6z',
      fill: '#91D4F3'
    },
    {
      d:
        'M159.8 218.9c20.7 0 37.6-16.9 37.6-37.6V84.2h-51.8l-23.4 98.2c.7 20.2 17.3 36.5 37.6 36.5z',
      fill: '#91D4F3'
    },
    {
      d:
        'M410.8 182.3c.5 20.3 17.2 36.6 37.6 36.6 20 0 36.5-15.8 37.5-35.5L452 105c-.2-.4-.3-.9-.5-1.3-3.3-11.5-13.9-19.5-25.8-19.5H388l22.5 94.7c.3 1.1.4 2.3.3 3.4z',
      fill: '#91D4F3'
    },
    {
      d:
        'M352.2 218.9c20.4 0 37.1-16.4 37.6-36.8l-23.3-97.9h-51.9v97.1c0 20.7 16.8 37.6 37.6 37.6z',
      fill: '#91D4F3'
    },
    {
      d:
        'M403.5 448.8c30.5 0 55.3-24.8 55.3-55.3V238.9c27.3-5 48.1-28.9 48.1-57.6 0-1.4-.3-2.9-.9-4.2l-34.6-79.9c-6-20.1-24.8-34.1-45.8-34.1H86.3c-21 0-39.8 14-45.8 34.1L6 177.1c-.6 1.3-.9 2.7-.9 4.2 0 28.7 20.8 52.7 48.1 57.6v154.5c0 30.5 24.8 55.3 55.3 55.3h295zm-14.5-21h-58.2V288.2H389v139.6zm36.7-343.6c11.9 0 22.6 8 25.8 19.5.1.4.3.9.5 1.3l33.9 78.3c-1.1 19.8-17.5 35.5-37.5 35.5-20.4 0-37-16.3-37.6-36.6.1-1.1 0-2.3-.2-3.4L388 84.2h37.7zm-111.1 0h51.9l23.3 97.9c-.4 20.3-17.1 36.8-37.6 36.8-20.7 0-37.6-16.9-37.6-37.6V84.2zm-96.2 0h75.2v97.1c0 20.7-16.9 37.6-37.6 37.6s-37.6-16.9-37.6-37.6V84.2zm-21 0v97.1c0 20.7-16.9 37.6-37.6 37.6-20.4 0-37-16.3-37.6-36.5l23.4-98.2h51.8zM63.7 218.9c-20 0-36.5-15.8-37.5-35.5L60 105c.2-.4.3-.9.5-1.3 3.3-11.5 13.9-19.5 25.8-19.5H124l-22.5 94.7v.1c0 .2-.1.4-.1.6 0 .2-.1.3-.1.5v1.2c0 20.7-16.9 37.6-37.6 37.6zm10.5 174.6V239c15.5-2.8 28.9-11.7 37.6-24.2C122.4 230 140 240 159.9 240c19.9 0 37.5-10 48.1-25.2 10.6 15.2 28.2 25.2 48.1 25.2 19.9 0 37.5-10 48.1-25.2 10.6 15.2 28.2 25.2 48.1 25.2s37.5-10 48.1-25.2c8.7 12.5 22.1 21.4 37.6 24.2v154.5c0 16.7-12 30.7-27.9 33.7V277.7c0-5.8-4.7-10.5-10.5-10.5h-79.2c-5.8 0-10.5 4.7-10.5 10.5v150.1H108.5c-18.9 0-34.3-15.4-34.3-34.3z',
      fill: '#1976D2'
    },
    {
      d:
        'M266.3 391.2c5.8 0 10.5-4.7 10.5-10.5v-103c0-5.8-4.7-10.5-10.5-10.5H112.5c-5.8 0-10.5 4.7-10.5 10.5v102.9c0 5.8 4.7 10.5 10.5 10.5h153.8zM123 288.2h132.8v81.9H123v-81.9z',
      fill: '#1976D2'
    }
  ],
  name: 'xyma',
  type: 'duotone'
}
