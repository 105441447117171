import { CIcon } from '~/icons/types'
export const ciCaravan: CIcon = {
  width: 512,
  height: 345.2,
  paths: [
    {
      d:
        'M500.1 274.6h-49.6l-2-53h-89.4v53h-85.2v-53H1.4v18.3c0 30.7 25 55.7 55.7 55.7h11.5c.2-7.1 1.6-14 3.9-20.6 6.6-18.3 20.7-33 38.7-40.3 8.1-3.3 16.7-5 25.5-5s17.4 1.7 25.5 5c12.5 5 23.1 13.6 30.7 24.7 7.4 10.8 11.5 23.2 12 36.3h255.3v21.9c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-21.9h18.7c5.8 0 10.5-4.7 10.5-10.5s-4.5-10.6-10.3-10.6z'
    },
    {
      d:
        'M154.5 254.1c-5.5-2.2-11.4-3.4-17.6-3.4s-12.2 1.2-17.6 3.4c-12.4 5-22.3 15.2-26.8 27.9-1.5 4.3-2.5 8.8-2.7 13.5 0 .8-.1 1.6-.1 2.4 0 9.1 2.6 17.7 7.1 24.9 7.2 11.6 19.2 19.8 33.3 21.9 2.2.3 4.5.5 6.9.5 12.5 0 24-4.9 32.4-13 3.8-3.6 7-7.8 9.4-12.4 3-5.6 4.8-12 5.3-18.6.1-1.1.1-2.1.1-3.2 0-.8 0-1.6-.1-2.4-1-18.8-13-34.7-29.6-41.5zm102.4-53.5h17V85.8c0-5.8 4.7-10.5 10.5-10.5h64.2c5.8 0 10.5 4.7 10.5 10.5v114.8h88.7l-3.4-91.2c-1.1-29.5-13.5-57.1-34.7-77.6C388.4 11.3 360.4 0 330.8 0H64.2C29.5 0 1.4 28.1 1.4 62.7v137.9h255.5zM77.2 83.8c0-4.7 3.8-8.6 8.6-8.6h108.3c4.7 0 8.6 3.8 8.6 8.6v65.9c0 6.5-5.3 11.8-11.8 11.8H85.7c-4.7 0-8.6-3.8-8.6-8.6l.1-69.1z'
    }
  ],
  name: 'caravan',
  type: 'solid'
}
