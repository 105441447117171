import { CIcon } from '~/icons/types'
export const ciGasStation: CIcon = {
  name: 'gas-station',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M405.499 422H45.5c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h359.999c8.284 0 15-6.716 15-15v-60c0-8.284-6.715-15-15-15zm70.185-326.691c-6.552-5.072-15.972-3.874-21.044 2.675l-41 52.942a15 15 0 0 0-3.141 9.185v169.144h-20V45c0-24.813-20.186-45-45-45h-240c-24.813 0-45 20.187-45 45v347h330v-32.746h35c8.284 0 15-6.716 15-15V209.49h9.62c8.284 0 15-6.716 15-15v-61.042l13.239-17.095c5.073-6.55 3.876-15.972-2.674-21.044zM174.593 260.078H135.5c-8.284 0-15-6.716-15-15s6.716-15 15-15h39.093c8.284 0 15 6.716 15 15s-6.716 15-15 15zM330.5 183.092c0 8.284-6.716 15-15 15h-180c-8.284 0-15-6.716-15-15V75c0-8.284 6.716-15 15-15h180c8.284 0 15 6.716 15 15v108.092z',
      fill: 'currentColor'
    }
  ]
}
