import { CIcon } from '~/icons/types'
export const ciBus: CIcon = {
  name: 'bus',
  type: 'regular',
  width: 512,
  height: 296.2,
  paths: [
    {
      d:
        'M510.3 242.5c-.4-40.2-4-80.6-10.6-120.1L483.9 29c-.2-1.2-.6-2.3-1.2-3.3C471.5 5.4 414.2 0 406.9 0H33.6C16 0 1.6 14.4 1.6 32l.3 201.4c.2 16.5 13.7 30 30.2 30H68c7.4 18.6 25.1 31.9 46.2 32.8h2.1c21.3 0 40.4-13.1 48.2-32.8h176.8c7.7 19.2 26.4 32.8 48.3 32.8s40.6-13.6 48.3-32.7h61.9c2.8 0 5.5-1.1 7.5-3.1s3.1-4.7 3-7.5v-10.4zm-25.6-75.7L441.8 153c-22.4-7.2-57.1-11.7-85.9-13.3v-74h112.9l10.2 60c2.2 13.7 4.1 27.4 5.7 41.1zm-324.9-27.7V65.8H242v73.3h-82.2zm-92.8 0V65.8h71.8v73.3H67zm196-73.3h71.9v73.3H263V65.8zM145.9 253c-4.1 13.6-16.8 22.7-30.9 22.1-16.4-.7-29.5-14.1-29.7-30.6-.1-8.6 3.2-16.7 9.4-22.7 5.9-5.7 13.5-8.7 21.6-8.7h1.6c15.2.8 28 13.4 29.2 28.6.3 3.9-.1 7.7-1.2 11.3zm243.8 22.1c-17.1 0-31-13.9-31-31s13.9-31 31-31 31 13.9 31 31-13.9 31-31 31zm52-32.7c-.9-27.9-23.9-50.3-52-50.3s-51 22.4-52 50.2H168.2c0-.7-.1-1.5-.1-2.2-2-26-23.1-46.6-49-48-14.6-.7-28.4 4.5-38.9 14.6-9.7 9.4-15.3 22.2-15.8 35.6H32.1c-5 0-9.2-4.1-9.2-9.1l-.3-201.3c0-6.1 4.9-11 11-11h373.3c4.9 0 18.1 1.5 32 4.8 15.5 3.7 22.4 7.5 24.7 9.4l1.6 9.7H56.5c-5.8 0-10.5 4.7-10.5 10.5v94.3c0 5.8 4.7 10.5 10.5 10.5h277.6c29.8 0 75.8 4.9 101.3 13l51.5 16.5c1.4 17.6 2.2 35.2 2.4 52.8h-47.6z',
      fill: 'currentColor'
    }
  ]
}
