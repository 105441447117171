import { CIcon } from '~/icons/types'
export const ciBluetooth: CIcon = {
  width: 263.21,
  height: 514,
  paths: [
    {
      d:
        'm258.77 342.39-41.23-32.45-65.74-51.74-1.53-1.2 27.64-21.75 65.74-51.74 15.12-11.9c2.72-2.14 4.58-7.36 4.43-10.7a16.86 16.86 0 0 0-4.43-10.69l-12.48-14.55-29.82-34.74-36.22-42.22-31.2-36.36c-5.07-5.91-9.92-12.14-15.27-17.79a2.75 2.75 0 0 1-.22-.26C129.65-.25 122.21-1 117 1.17A15.17 15.17 0 0 0 107.74 15v212.28l-10.59-8.44L66 194l-26.88-21.41q-6.54-5.24-13.1-10.45l-.19-.15a11.37 11.37 0 0 0-4.83-3.13 11.42 11.42 0 0 0-5.82-1.3A15.42 15.42 0 0 0 4.44 162C1.8 164.87-.17 168.66 0 172.68c.17 3.75 1.36 8.25 4.43 10.7l10.71 8.53 25.7 20.49L72 237.2l25.8 20.57-22 17.55-31.12 24.8-26.9 21.45c-4.37 3.48-8.82 6.88-13.11 10.45l-.19.15A11.37 11.37 0 0 0 1.31 337 11.42 11.42 0 0 0 0 342.86a15.42 15.42 0 0 0 4.43 10.7c2.88 2.64 6.67 4.61 10.7 4.43l4-.54a15.18 15.18 0 0 0 6.67-3.89L36.53 345l25.71-20.5 31.12-24.8 14.38-11.46v191.1c0 6.28-.09 12.56 0 18.83v.83a15.16 15.16 0 0 0 9.3 13.82c5.22 2.21 12.6 1.44 16.52-3.12L146 495.14l29.81-34.74 36.23-42.21 31.2-36.36c5.09-5.93 10.23-11.82 15.27-17.8.07-.08.14-.17.22-.25 2.53-2.95 4.61-6.63 4.43-10.7-.16-3.72-1.32-8.27-4.39-10.69ZM154.47 75l36.22 42.21 31.2 36.36q2.27 2.64 4.5 5.3l-30.24 23.81L138 228.43V55.78Zm40.61 316.7-36.22 42.21Q148.42 446 138 458.21V285.55l18.53 14.59 65.74 51.74 4.16 3.28c-.51.59-1 1.18-1.52 1.78Z'
    }
  ],
  name: 'bluetooth',
  type: 'regular'
}
