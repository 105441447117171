import { CIcon } from '~/icons/types'
export const ciBreakAlt: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M256 0q106 0 181 75t75 181-75 181-181 75-181-75T0 256 75 75 256 0zm0 495q99 0 169-70t70-169-70-169-169-70T87 87 17 256t70 169 169 70zm0-367q53 0 90.5 37.5T384 256t-37.5 90.5T256 384t-90.5-37.5T128 256t37.5-90.5T256 128zm0 239q46 0 78.5-32.5T367 256t-32.5-78.5T256 145t-78.5 32.5T145 256t32.5 78.5T256 367zm0-137q-9 0-17-4.5T226.5 213t-4.5-17q0-14 10-24t24-10 24 10 10 24-10 24-24 10zm0-51q-5 0-8.5 2.5t-6 6.5-2.5 8q0 7 5 12t12 5 12-5 5-12-5-12-12-5zm0 103q14 0 24 10t10 24-10 24-24 10-24-10-10-24 10-24 24-10zm0 51q7 0 12-5t5-12-5-12-12-5-12 5-5 12 5 12 12 5zm-60-111q14 0 24 10t10 24q0 9-4.5 17T213 285.5t-17 4.5q-14 0-24-10t-10-24 10-24 24-10zm0 51q7 0 12-5t5-12-5-12-12-5-12 5-5 12 5 12 12 5zm86-17q0-9 4.5-17t12.5-12.5 17-4.5q14 0 24 10t10 24-10 24-24 10-24-10-10-24zm51 0q0-5-2.5-8.5t-6.5-6-8-2.5q-7 0-12 5t-5 12q0 5 2 8.5t6 6 9 2.5q7 0 12-5t5-12zm-86 0q0-9 9-9t9 9-9 9-9-9zM70 170q9 3 4 11-3 9-11 4-9-3-4-11 4-7 11-4zm32 31q-8-3-4-11 4-7 11-4 9 4 4 11-3 9-11 4zm308 109q8 3 4 11-4 9-11 4-9-3-4-11 2-7 11-4zm39 16q9 3 4 11-3 9-11 4-9-3-4-11 3-7 11-4zM331 74q-9-3-4-11 3-9 11-4 7 4 4 11-3 9-11 4zm-16 39q-9-3-4-11 3-8 11-4 7 4 4 11-4 9-11 4zM198 398q7 4 4 11-3 9-11 4-9-4-4-11 3-9 11-4zm-16 40q7 3 4 11-3 9-11 4-9-3-4-11 3-9 11-4zm-1-363q-8 3-11-4-3-8 4-11 8-5 11 4 3 8-4 11zm5 35q-3-8 4-11 8-5 11 4 4 8-4 11-8 4-11-4zm129 289q8-5 11 4 3 8-4 11-8 3-11-4-4-8 4-11zm17 39q6-4 11 4 3 8-4 11-8 3-11-4-4-8 4-11zm116-253q-8 3-11-4-3-8 4-11 8-3 11 4 5 8-4 11zm-39 16q-8 4-11-4-4-8 4-11t11 4q5 8-4 11zM102 311q8-4 11 4 5 8-4 11-8 3-11-4-3-8 4-11zm-39 17q8-4 11 4 4 6-4 11-8 3-11-4-3-8 4-11z',
      fill: 'currentColor'
    }
  ],
  name: 'break-1',
  type: 'light'
}
