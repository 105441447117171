import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { Location } from '~/models/location/closest'
import GoogleMapsService from '~/services/GoogleMapsService'
import { invalidBodyError } from '~/services/errors'

@containerScoped()
export default class ClosestLocationService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(GoogleMapsService) private mapsService: GoogleMapsService
  ) {}

  async getClosestLocations(lat: number, lon: number): Promise<Location[]> {
    const response = await this.http.get('/api/locations/closest/', {
      params: { lat, lon, es: true }
    })
    const body = response.data
    if (!Array.isArray(body.data)) {
      throw invalidBodyError(body)
    }

    return body.data.map(this.formatLocation)
  }

  async getClosestLocationsToCurrent(): Promise<Location[]> {
    if (!this.mapsService) {
      throw new Error(
        'Geocoder is undefined. Please make sure that you are running this on the client side.'
      )
    }
    const { lat, lon } = await this.mapsService.getCurrentGpsGeolocation()
    return this.getClosestLocations(lat, lon)
  }

  private formatLocation(l: any): Location {
    return {
      id: l.id.toString(),
      leaf: l.is_leaf,
      name: l.name,
      path: l.path
    }
  }
}
