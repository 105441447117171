import { CIcon } from '~/icons/types'
export const ciAirConditionerAlt: CIcon = {
  width: 528,
  height: 512,
  paths: [
    {
      d:
        'M456.5 0Q478 0 493 15t15 36-15 36-36.5 15T420 87t-15-36 15-36 36.5-15zm.5 85q9 0 17-4.5T486.5 68t4.5-17q0-14-10-24t-24-10-24.5 10T422 51t10.5 24T457 85zm-29 30q25 7 37.5 30.5T469 192l-59 172q-4 9-13 14.5t-19 4.5l-129-18q-8-1-9 6l-48 117q-5 18-25 23-2 1-8 1-15 0-26.5-12t-7.5-30l57-164q2-9 10-14.5t18-3.5l86 10q10 1 13-8l47-136q8-23 28.5-34t43.5-5zm25 70q6-15-2-34-10-16-27-21-8-3-16-1.5t-15 5-12.5 9.5-8.5 14l-47 137q-3 9-11.5 14.5T294 313l-86-10-1 1q-7 0-9 6l-57 165q-2 9 3 13 9 9 19 6t13-12l48-118q3-9 11-14t18-4l128 18q10 2 13-7zM98 128q-8 0-8-9V51q0-8 8-8h128V9q0-7 5-8 5-3 10 2l77 76q5 6 0 12l-77 77q-3 3-6 3t-4-1q-5-1-5-8v-34H98zm9-68v51h128q8 0 8 8v23l57-57-57-56v22q0 9-8 9H107zm10 205q8 14 8 25 0 21-20 40-4 3-7 3t-6-3q-6-6 0-12 15-11 15-28 0-7-5-17-1-1-2-2.5t-1-2.5v-1q-1-1-1-2-12 0-24 1 7 13 7 24 0 21-19 40-3 3-6.5 3t-5.5-3q-6-6 0-12 14-11 14-28 0-7-5-17 0-1-1-2.5t-2-2.5q-16 2-33 5h-2q-1 0-5-2-3-3-3-6l-9-85q-1-8 7-9 40-9 96-9t95 9q7 1 7 9l-8 85q0 3-4 6-2 2-7 2-14-3-29-5 6 12 6 22 0 21-19 40-4 3-7 3-4 0-6-3-6-6 0-12 14-14 14-28 0-7-5-17 0-1-1-2.5t-1-2.5v-1q-1 0-1-1-12-1-24-1zm-95-79 7 68q10-1 20-3-2-6-2-12 0-11 8-25 1-3 1-4 3-8 11-4 8 3 5 11-1 0-1.5 2t-1.5 3q-5 10-5 17 0 5 2 10 13-1 25-1-1-5-1-9 0-11 7-25 2-3 2-4 3-8 11-4 8 3 4 11l-1 2-1 3q-5 10-5 17 0 4 1 9h26q-2-4-2-9 0-11 8-25 2-3 2-4 3-8 11-4 7 3 4 11l-1 2-1 3q-6 10-6 17 0 5 3 11l32 4 7-68q-36-7-84-7t-85 7z',
      fill: 'currentColor'
    }
  ],
  name: 'air-conditioner-alt',
  type: 'light'
}
