import { CIcon } from '~/icons/types'

export const ciEngine: CIcon = {
  width: 512,
  height: 381.1,
  paths: [
    {
      d:
        'm379.8 222.3-124.4-93.1c-9.1-8.8-20.9-13.6-33.6-13.6-12.9 0-25 5-34.1 14.1-18.7 18.6-18.9 48.9-.6 67.8L280 322.1c.3.4.6.8 1 1.1 13.3 13.4 31.1 20.7 50 20.8h.1c18.9 0 36.6-7.3 50-20.7 13.4-13.3 20.7-31.1 20.8-50 0-18.9-7.3-36.7-20.7-50-.6-.4-1-.7-1.4-1zM366 308.4c-9.4 9.4-21.8 14.5-35.1 14.5-13 0-25.3-5-34.6-14.1l-92.8-124.5c-.3-.4-.6-.8-1-1.1-10.6-10.6-10.6-27.9 0-38.6 10.6-10.6 27.9-10.6 38.6 0 .4.4.7.7 1.1 1l124.4 93c9.1 9.3 14 21.6 14 34.6 0 13.4-5.2 25.8-14.6 35.2z'
    },
    {
      d:
        'M305.1 247.4c-13.7 13.7-13.8 36.1 0 49.8 6.9 6.9 15.9 10.3 24.9 10.3s18-3.4 24.9-10.3c13.7-13.7 13.8-36.1 0-49.8-13.7-13.7-36-13.7-49.8 0zm34.9 35c-5.5 5.5-14.5 5.5-20 0s-5.5-14.5 0-20 14.5-5.5 20 0c2.7 2.7 4.1 6.2 4.1 10 .1 3.7-1.4 7.3-4.1 10z'
    },
    {
      d:
        'M483.8 124.6h-20.7c-5.8 0-10.5 4.7-10.5 10.5v22.3h-13.8v-23.2c0-5.4-4.1-9.9-9.4-10.5l-33.5-3.5c-5.9-.6-11-4.7-12.8-10.4l-11-34.3c-1.4-4.4-5.5-7.3-10-7.3h-58.5V56.1H356c5.8 0 10.5-4.7 10.5-10.5V27.9C366.5 12.5 354 0 338.6 0h-126c-15.4 0-27.9 12.5-27.9 27.9v17.7c0 5.8 4.7 10.5 10.5 10.5h52.5v12.3h-53.8c-7.9 0-15.2 4.4-18.9 11.4L167.5 94c-5.1 9.7-14.6 16.4-25.4 18l-51.9 7.7c-5.2.8-9 5.2-9 10.4v52.1H64.8V132c0-15.6-12.7-28.3-28.3-28.3-8.1 0-15.9 3.5-21.3 9.6s-7.8 14.3-6.8 22.4l6.2 46.5h-4.3c-5.8 0-10.5 4.7-10.5 10.5v34.9c0 5.8 4.7 10.5 10.5 10.5h4.3l-6.2 46.5c-1.1 8.1 1.4 16.2 6.8 22.4 5.4 6.1 13.1 9.6 21.3 9.6 15.6 0 28.3-12.7 28.3-28.3v-50.2h16.4V270c0 2.2.7 4.3 1.9 6.1l20.2 28.6c2 2.8 5.2 4.5 8.6 4.5h22.3c5.2 0 10.2 1.9 14.1 5.4l60.3 54.1c8.9 8 20.4 12.4 32.3 12.4h159.9c21 0 38-17.1 38-38v-69.5h13.8v22.3c0 5.8 4.7 10.5 10.5 10.5h20.7c15.4 0 27.9-12.5 27.9-27.9v-126c0-15.4-12.5-27.9-27.9-27.9zM31 127.2c.8-.9 2.6-2.5 5.4-2.5 4 0 7.2 3.2 7.2 7.2v50.2h-7.9l-6.4-49.2c-.4-2.8.9-4.8 1.7-5.7zm12.7 161.2c0 4-3.2 7.2-7.2 7.2-2.8 0-4.6-1.5-5.4-2.5-.8-.9-2.1-2.9-1.7-5.7l6.5-49.3h7.8v50.3zm37.5-71.3H20.8v-13.9h60.4v13.9zm50.7 71.1h-14.6l-15.1-21.5V139.2l29.7-4.4v153.4zM205.8 35v-7.1c0-3.8 3.1-6.8 6.8-6.8h126c3.8 0 6.8 3.1 6.8 6.8V35H205.8zm76.7 21.1v12.3h-13.7V56.1h13.7zm118.4 303.8h-160c-6.8 0-13.2-2.5-18.3-7l-60.3-54.1c-2.8-2.5-6-4.7-9.3-6.3V131c14.1-4.3 26.1-14 33.1-27.2l7.5-14.2c0-.1.1-.1.2-.1h160.5l8.6 26.9c4.4 13.7 16.4 23.4 30.7 24.9l24.1 2.5V343h.1c0 9.3-7.6 16.9-16.9 16.9zm89.7-81.4c0 3.8-3.1 6.8-6.8 6.8h-10.1V263c0-5.8-4.7-10.5-10.5-10.5h-24.3v-73.9h24.3c5.8 0 10.5-4.7 10.5-10.5v-22.3h10.1c3.8 0 6.8 3.1 6.8 6.8v125.9z'
    }
  ],
  ellipses: [{ cx: '221.8', cy: '164', rx: '13.8', ry: '13.7' }],
  circles: [],
  name: 'engine',
  type: 'regular'
}
