import { CIcon } from '~/icons/types'
export const ciWatersport: CIcon = {
  name: 'watersport',
  type: 'regular',
  width: 512,
  height: 477.9,
  paths: [
    {
      d:
        'M485.6 368.5c-1.7-3.6-5.4-6-9.5-6H225.7c54.6-48.6 123.8-75.3 195.5-75.3h.5c3.5 0 6.8-1.8 8.7-4.7 1.9-2.9 2.3-6.6.9-9.9l-91-216.5c-1.3-3.2-4.2-5.5-7.6-6.2l-62.9-12.6c3.5-7.4 7.2-14.7 11-21.9 2.7-5.1.8-11.5-4.3-14.2-5.1-2.7-11.5-.8-14.2 4.3-6 11.3-11.7 22.8-16.9 34.5-.2.4-.4.8-.6 1.3-25.9 58.2-41.6 121-46 183.7-3.1 44.5-1.5 89.8 5 134.8.1.9.3 1.8.7 2.7H35.9c-4.7 0-8.8 3.1-10.1 7.6s.6 9.3 4.6 11.8c42.1 26.2 89.3 40 136.4 40h139.7l44.3 43.7 8.5 9c2 2.1 4.8 3.3 7.6 3.3 1.5 0 3.1-.3 4.5-1 4.1-1.9 6.5-6.3 5.9-10.8-1.9-14.6-1.9-29.4 0-44.2h24.2c31 0 61.1-15.4 82.8-42.3 2.5-3.2 3-7.5 1.3-11.1zM323.2 69.4l82.9 197.2c-66.9 3.3-131 28.2-183.8 71.2-3.1-27.4-4.3-54.8-3.7-82h90.1c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-89.3c.2-2.8.3-5.6.5-8.4.8-11.7 2-23.4 3.7-35.1h107.2c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5H226.9c2.7-15.1 6.2-30.2 10.3-45.1h54c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-47.8c5.1-16 11-31.8 17.6-47.2l62.2 12.4zm31.7 370.8-18.6-18.3h19.8c-.6 6.1-1 12.2-1.2 18.3zm46.6-39.3H166.8c-29.6 0-59.2-5.9-87.3-17.4h371.1c-14.7 11.3-31.6 17.4-49.1 17.4z',
      fill: 'currentColor'
    }
  ]
}
