import { CIcon } from '~/icons/types'
export const ciAbs: CIcon = {
  width: 512,
  height: 398.68,
  paths: [
    {
      d:
        'M510.22 171.65a228.39 228.39 0 0 0-6.4-32 228.65 228.65 0 0 0-11-30.1 218.23 218.23 0 0 0-32.05-51.18q-2.28-2.76-4.66-5.43a12.05 12.05 0 0 0-20.57 8.52 13.26 13.26 0 0 0 3.52 8.54q4.24 4.8 8.19 9.84a209.67 209.67 0 0 1 25.43 43.5 208.1 208.1 0 0 1 13.67 50.27 212 212 0 0 1 0 51.83 208.26 208.26 0 0 1-13.65 50.16 209.13 209.13 0 0 1-25.53 43.66c-2.38 3-4.82 6-7.36 8.89a12.78 12.78 0 0 0-3.53 8.52 12.27 12.27 0 0 0 3.53 8.52 12.14 12.14 0 0 0 8.52 3.53c3.08-.14 6.43-1.13 8.53-3.53a220.57 220.57 0 0 0 33.52-50.44 217.45 217.45 0 0 0 18.62-59 221.05 221.05 0 0 0 1.22-64.1ZM64.71 319.11a209.19 209.19 0 0 1-25.5-43.65 208.27 208.27 0 0 1-13.62-50.23 211.44 211.44 0 0 1 0-51.58 208.19 208.19 0 0 1 13.65-50.27 208.77 208.77 0 0 1 25.41-43.52q4-5.07 8.24-9.91a12.73 12.73 0 0 0 3.53-8.52 12.05 12.05 0 0 0-12-12c-3.08.14-6.4 1.14-8.52 3.53a220 220 0 0 0-34 50.71A217.2 217.2 0 0 0 3 163a224.16 224.16 0 0 0-1 65.83 219.1 219.1 0 0 0 6.29 30.85 228.16 228.16 0 0 0 11.24 30.48 219.17 219.17 0 0 0 32.61 51.54q2.32 2.76 4.73 5.44a12.06 12.06 0 1 0 17.05-17.05q-4.8-5.32-9.21-10.98Z'
    },
    {
      d:
        'M455.32 199.35a199.65 199.65 0 0 0-9.56-61.12 197.24 197.24 0 0 0-26.67-53.42 206.64 206.64 0 0 0-43-44.49 199 199 0 0 0-273.28 31.51A206.3 206.3 0 0 0 71 125.08a199.34 199.34 0 0 0 101.89 255.45 205 205 0 0 0 61.17 16.89 199.3 199.3 0 0 0 200.12-108.7 196.36 196.36 0 0 0 18.55-57.61 211.26 211.26 0 0 0 2.59-31.76Zm-25.58 23.47a187.59 187.59 0 0 1-12.16 44.61 188.93 188.93 0 0 1-22.78 39 190.63 190.63 0 0 1-31.74 31.74 189.42 189.42 0 0 1-39 22.79 188 188 0 0 1-44.61 12.15 191.08 191.08 0 0 1-46.95 0A187.83 187.83 0 0 1 187.9 361a189.49 189.49 0 0 1-39-22.79 190.63 190.63 0 0 1-31.74-31.74 188.93 188.93 0 0 1-22.78-39 188.54 188.54 0 0 1-12.16-44.61 191.08 191.08 0 0 1 0-46.95 188.46 188.46 0 0 1 12.16-44.6 188.67 188.67 0 0 1 22.78-39 190.25 190.25 0 0 1 31.74-31.74 189 189 0 0 1 39-22.79 188.46 188.46 0 0 1 44.6-12.16 192.07 192.07 0 0 1 46.95 0 188.54 188.54 0 0 1 44.61 12.16 189 189 0 0 1 39 22.79 190.25 190.25 0 0 1 31.74 31.7 188.67 188.67 0 0 1 22.78 39 187.59 187.59 0 0 1 12.16 44.61 186.83 186.83 0 0 1 0 46.94Z'
    },
    {
      d:
        'M167.94 139.41q-2.24-7.64-6.11-11.35a12.55 12.55 0 0 0-9-3.72 11.83 11.83 0 0 0-7.84 3 18 18 0 0 0-5.21 7.42q-1.69 4.46-5.32 17.23l-27.31 94.27q-3 10.17-3 15.2a14.74 14.74 0 0 0 3 8.83c2 2.73 4.41 4.1 7.3 4.1q4.71 0 6.8-3.66t5.21-14.81l5-18.15h43l5.26 17.75q3.33 11.46 5.42 15.16t6.87 3.71q4.48 0 7.49-4.25a15.56 15.56 0 0 0 3-9.17q0-4.74-3.11-15.5ZM137.06 215l15.65-59.67L168.82 215Zm148.28-19.87q15.92-10 15.92-31.88a41.61 41.61 0 0 0-4.74-20.2q-4.74-8.72-11.51-12.47t-19.5-3.77h-29.46c-5.93 0-10 .5-12.12 1.49s-3.68 2.95-4.6 5.89-1.33 8.69-1.33 17.26v95.89q0 12 1 16.28a9.33 9.33 0 0 0 4.27 6.28q3.3 2 12.85 2h27.5q15.06 0 23.45-3.8t13.68-14.1a50 50 0 0 0 5.36-23.44q0-27.32-20.77-35.43Zm-46-45.56H257q14.47 0 18.82 4.69t4.34 12.88q0 8.3-4.09 13.47t-16.83 5.18h-20Zm40.31 94q-4.71 5.52-16.94 5.51h-23.4v-40.53H262q12.66 0 17.52 5.37t4.84 14.62q-.04 9.56-4.74 15.07Zm111.21-49.76q-7.74-5.75-23.74-10.95T347.18 173a16 16 0 0 1-3.94-10.76 15.62 15.62 0 0 1 5.35-12.4 19.36 19.36 0 0 1 13.18-4.66q13.46 0 19 15a49.2 49.2 0 0 0 4.56 10.14 6.68 6.68 0 0 0 5.93 3q4.27 0 7-4.35a17 17 0 0 0 2.75-9.3q0-11.88-9.55-23.65t-28.37-11.78q-18.45 0-29.17 11t-10.71 29.54q0 12.57 4.63 21a37.82 37.82 0 0 0 10.86 12.86q6.22 4.45 21.65 9.33t19.76 10.32a20.35 20.35 0 0 1 4.33 13.08q0 9.81-6.26 15.56a21.9 21.9 0 0 1-15.38 5.74q-15.27 0-21.21-18.7-2.74-8.81-4.7-12.07a6.45 6.45 0 0 0-5.93-3.27 8.27 8.27 0 0 0-7.06 3.91 15.17 15.17 0 0 0-2.72 8.86q0 14.94 10.54 28.89t31.8 14q19.47 0 30.73-12.87t11.25-33.15a55.82 55.82 0 0 0-3.5-19.42 32.14 32.14 0 0 0-11.14-15.04Z'
    }
  ],
  name: 'abs',
  type: 'regular'
}
