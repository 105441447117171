import { CIcon } from '~/icons/types'
export const ciWashingMachine: CIcon = {
  name: 'washing-machine',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M384 0H42.668C19.113.027.028 19.113 0 42.668v409.598c0 14.14 11.46 25.601 25.602 25.601v25.598a8.533 8.533 0 0 0 8.53 8.535h358.403a8.533 8.533 0 0 0 8.531-8.535v-25.598c14.137 0 25.602-11.46 25.602-25.601V42.668C426.637 19.113 407.555.028 384 0zM42.668 17.066H384c14.137 0 25.602 11.461 25.602 25.602v59.734H17.066V42.668c0-14.14 11.461-25.602 25.602-25.602zM384 494.934H42.668v-17.067H384zm17.066-34.133H25.602a8.534 8.534 0 0 1-8.536-8.535V119.465h392.536v332.8a8.534 8.534 0 0 1-8.536 8.536zm0 0'
    },
    {
      d:
        'M42.668 85.332h102.398a8.533 8.533 0 0 0 8.536-8.531V42.668a8.534 8.534 0 0 0-8.536-8.535H42.668a8.534 8.534 0 0 0-8.535 8.535v34.133c0 4.71 3.82 8.531 8.535 8.531zm8.531-34.133h85.336v17.067H51.2zm145.067 34.133c14.14 0 25.601-11.46 25.601-25.598 0-14.14-11.46-25.601-25.601-25.601-14.137 0-25.598 11.46-25.598 25.601 0 14.137 11.46 25.598 25.598 25.598zm0-34.133a8.534 8.534 0 1 1 .002 17.068 8.534 8.534 0 0 1-.002-17.068zm68.265 34.133c14.14 0 25.602-11.46 25.602-25.598 0-14.14-11.461-25.601-25.602-25.601-14.136 0-25.597 11.46-25.597 25.601 0 14.137 11.46 25.598 25.597 25.598zm0-34.133a8.534 8.534 0 1 1 .003 17.068 8.534 8.534 0 0 1-.003-17.068zm51.203 17.067h17.067c4.71 0 8.531-3.82 8.531-8.532a8.533 8.533 0 0 0-8.531-8.535h-17.067a8.534 8.534 0 1 0 0 17.067zm51.2 0H384a8.533 8.533 0 1 0 0-17.067h-17.066a8.534 8.534 0 1 0 0 17.067zm-153.602 85.336c-75.402 0-136.531 61.125-136.531 136.53s61.129 136.536 136.531 136.536c75.406 0 136.535-61.129 136.535-136.535-.09-75.367-61.164-136.442-136.535-136.531zm0 256c-65.977 0-119.465-53.489-119.465-119.47 0-65.98 53.488-119.464 119.465-119.464 65.98 0 119.469 53.484 119.469 119.465-.07 65.949-53.516 119.398-119.469 119.469zm0 0'
    },
    {
      d:
        'M213.332 179.2c-61.203-.06-110.871 49.507-110.934 110.71a110.89 110.89 0 0 0 8.34 42.285c.051.172 0 .348.067.52.07.172.23.332.316.531 23.766 56.418 88.77 82.887 145.188 59.121a110.859 110.859 0 0 0 64.058-73.543c.047-.14.082-.285.11-.43 15.847-58.937-19.086-119.562-78.028-135.406a110.514 110.514 0 0 0-29.117-3.789zm0 17.066c51.719-.121 93.746 41.707 93.867 93.422a93.626 93.626 0 0 1-2.277 20.753 131.727 131.727 0 0 0-13.082 5.122 77.43 77.43 0 0 1-35.84 8.703 57.637 57.637 0 0 1-30.32-7.68 74.545 74.545 0 0 0-37.946-9.387 224.164 224.164 0 0 0-63.625 11.946c-16.086-49.22 10.774-102.157 59.993-118.239a93.698 93.698 0 0 1 29.23-4.64zm0 187.734c-34.398-.023-66.035-18.852-82.465-49.074a203.32 203.32 0 0 1 56.867-10.66 57.651 57.651 0 0 1 30.317 7.68A74.53 74.53 0 0 0 256 341.331a92.057 92.057 0 0 0 41.328-9.547c-15.816 31.938-48.355 52.168-83.996 52.215zm0 0'
    }
  ]
}
