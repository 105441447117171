import { CIcon } from '~/icons/types'
export const ciParts: CIcon = {
  width: 512,
  height: 395,
  paths: [
    {
      d:
        'M154.3 308.2c10.3 3.1 21.1 4.8 32.1 4.8 10.6 0 21.3-1.5 31.8-4.7l-32-77.2-31.9 77.1zM89.7 254.9c10.5 19.8 26.4 35.2 45.2 45.2l32-77.2-77.2 32zM81.7 171.7c-6.3 20.3-6.6 42.5-.1 64l77.2-32-77.1-32zM218.2 99c-10.3-3.1-21.1-4.8-32.1-4.8-10.6 0-21.3 1.6-31.9 4.8l32 77.2 32-77.2zM282.8 152.3c-10.5-19.8-26.4-35.2-45.2-45.2l-32 77.2 77.2-32zM134.9 107c-19.8 10.5-35.2 26.5-45.1 45.2l77.1 32-32-77.2z'
    },
    {
      d:
        'M356 133.3c-18.8-45.3-54.1-80.7-99.5-99.5-45.4-18.8-95.3-18.8-140.7 0-45.4 18.8-80.7 54.1-99.5 99.5-18.8 45.3-18.8 95.3 0 140.7 18.8 45.4 54.1 80.7 99.5 99.5 22.7 9.4 46.5 14.1 70.3 14.1 23.8 0 47.6-4.7 70.3-14.1 45.4-18.8 80.7-54.1 99.5-99.5 18.9-45.4 18.9-95.3.1-140.7zm-49.3 120.2c-13.4 32.2-38.4 57.3-70.6 70.6-16.2 6.7-33.1 9.9-49.7 9.9-16.8 0-33.4-3.3-48.8-9.5-.4-.1-.8-.2-1.2-.4-.4-.1-.8-.3-1.1-.5-30.4-12.9-55.9-37.2-69.5-70-13.6-32.8-12.7-68-.4-98.6.1-.4.2-.8.4-1.2.1-.4.3-.7.5-1.1 12.9-30.4 37.2-55.9 70-69.5 32.8-13.6 68-12.7 98.6-.4.4.1.8.2 1.2.4.4.1.7.3 1.1.5 30.4 12.9 55.9 37.2 69.5 70 13.3 32.2 13.3 67.6 0 99.8z'
    },
    {
      d:
        'M237.6 300.2c19.3-10.2 35-25.9 45.2-45.2l-77.2-32 32 77.2zM213.6 203.6l77.2 32c6.5-20.9 6.5-43 0-64l-77.2 32zM500.6 12.3c-5.8-.6-10.9 3.6-11.5 9.4-2 18.9-14.6 34.1-31.3 40.4-2.8-.3-5.4.6-7.4 2.1-4.7 1-9.6 1.2-14.7.7-5.2-.5-10.2-1.9-14.8-4-1.3-1.1-2.8-1.9-4.6-2.3-16-9.3-26-27.4-23.9-47C393 5.8 388.8.7 383 .1c-5.8-.6-10.9 3.6-11.5 9.4-2.8 26.8 10.1 51.7 31.2 65.5l-27.6 264.1c-11.9 7.9-20.3 20.9-21.9 36.2-.6 5.8 3.6 10.9 9.4 11.5 5.8.6 10.9-3.6 11.5-9.4.8-7.6 4.5-14.5 10.5-19.3s13.4-7 21-6.2c7.6.8 14.5 4.5 19.3 10.5s7 13.4 6.2 21c-.6 5.8 3.6 10.9 9.4 11.5.4 0 .7.1 1.1.1 5.3 0 9.9-4 10.4-9.4 1.4-13.2-2.5-26.1-10.8-36.5-.8-1-1.7-2-2.6-2.9l27.7-264.8c23.2-9.3 40.7-30.8 43.5-57.4.8-5.9-3.4-11.1-9.2-11.7z'
    }
  ],
  name: 'parts',
  type: 'solid'
}
