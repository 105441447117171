import { CIcon } from '~/icons/types'
export const ciVan: CIcon = {
  width: 512,
  height: 277.4,
  paths: [
    {
      d:
        'M509.7 174.2v-.3c0-20.2-4.5-37.7-13.1-50.6l-1.5-2.1c-5.1-6.9-12-13.1-19.4-19.5-.6-.5-1.1-1-1.7-1.5-6.6-5.9-13.2-11.9-17.6-18-5.9-8.2-11.2-15.9-15.8-22.8-5.5-8.3-10-15.4-13.4-21-5.2-8.5-8-13.6-8.1-13.6-.1-.2-.3-.5-.4-.7C409.1 9 392.6 0 374.6 0H36.7C17.5 0 1.9 15.6 1.9 34.8v166.5c0 19.2 15.6 34.8 34.8 34.8h10.9c-.2-2.4-.4-4.8-.4-7.1 0-38.3 31.1-69.4 69.4-69.4S186 190.7 186 229c0 2.4-.1 4.7-.4 7.1h128.6c-.2-2.4-.4-4.7-.4-7.1 0-38.3 31.1-69.4 69.4-69.4s69.4 31.1 69.4 69.4c0 2.3-.1 4.7-.4 7h24.3c9 0 17.4-3.5 23.8-10 6.3-6.4 9.7-14.9 9.5-23.8l-.1-28zm-179-64.8-.3-35.3V51h78.2c6.2 9.6 21.1 30 30.8 43.4 1.4 2 11.5 13.1 13.1 15H330.7z'
    },
    {
      d:
        'M383.4 180.5c-26.7 0-48.4 21.7-48.4 48.4 0 2.4.2 4.8.5 7.1 3.4 23.4 23.6 41.4 47.9 41.4 24.4 0 44.5-18 47.9-41.4.3-2.3.5-4.6.5-7 0-26.8-21.7-48.5-48.4-48.5zm-266.7 0c-26.7 0-48.4 21.7-48.4 48.4 0 2.4.2 4.8.5 7.1 3.4 23.4 23.6 41.3 47.9 41.3s44.5-17.9 47.9-41.3c.3-2.3.5-4.7.5-7.1 0-26.7-21.7-48.4-48.4-48.4z'
    }
  ],
  name: 'van',
  type: 'solid'
}
