


















import { mapState } from 'vuex'
import { ciPlot } from '~/icons/source/brand/plot'
import { capitalizeSubStrings } from '~/utils/string'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    labled: { type: String, default: null }
  },
  computed: {
    ...mapState({
      showInPlotUrl: state => state.classifieds.search.showInPlotUrl
    }),
    icons() {
      return {
        plot: ciPlot
      }
    },
    plotTitle() {
      return capitalizeSubStrings(this.$t('view in plot.gr'), ['plot.gr'])
    }
  },
  methods: {
    recordPlotButtonEvent() {
      this.$analytics.recordEvent({
        namespace: 'n_classifieds_search_plot_map_button',
        action: 'click'
      })
    }
  }
})
