import { CIcon } from '~/icons/types'
export const ciBus: CIcon = {
  width: 512,
  height: 296.2,
  paths: [
    {
      d:
        'M499.7 122.4 483.9 29c-.2-1.2-.6-2.3-1.2-3.3C471.5 5.4 414.2 0 406.9 0H33.6C16 0 1.6 14.4 1.6 32l.3 201.4c.2 16.5 13.7 30 30.2 30h27c-.5-3.1-.8-6.3-.9-9.5-.2-17.9 6.7-34.5 19.5-46.9 12-11.6 27.8-18 44.6-18h3.4c15.3.8 29.9 7.3 41.2 18.3 11.2 11 18 25.4 19.2 40.7.4 5.2.2 10.3-.6 15.3h135.1c-.6-3.4-.8-6.9-.8-10.3 0-35.3 28.7-64.1 64.1-64.1 35.3 0 64.1 28.7 64.1 64.1 0 3.5-.3 6.9-.9 10.3H500c2.8 0 5.5-1.1 7.5-3.1s3.1-4.7 3-7.5v-10.4c-.6-40-4.2-80.4-10.8-119.9zm-360.9 16.7H57V55.8h81.8v83.3zm103.2 0h-82.2V55.8H242v83.3zm92.9 0H263V55.8h71.9v83.3zM441.8 153c-22.4-7.2-57.1-11.7-85.9-13.3v-84l112.7.1 10.4 69.9c2.2 13.7 4.1 27.4 5.7 41.1L441.8 153z'
    },
    {
      d:
        'M383.7 210.1c-23.8 0-43.1 19.3-43.1 43.1 0 3.6.4 7 1.3 10.3 4.6 18.8 21.6 32.7 41.8 32.7 20.2 0 37.2-13.9 41.8-32.7.8-3.3 1.3-6.8 1.3-10.3 0-23.8-19.3-43.1-43.1-43.1zm-259.1 0h-2.2c-11.2 0-21.8 4.2-30 12.1-8.6 8.3-13.2 19.6-13.1 31.5 0 3.4.5 6.6 1.2 9.7 4.5 18.2 20.6 31.9 40 32.8h1.8c18.8 0 35.6-12.4 41.1-30.7l.6-2.1c1.1-4.4 1.5-9 1.1-13.6-1.6-21.1-19.4-38.6-40.5-39.7z'
    }
  ],
  name: 'bus',
  type: 'solid'
}
