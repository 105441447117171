import { CIcon } from '~/icons/types'
export const ciPenthouse: CIcon = {
  name: 'penthouse',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M291.377 141.948h-70.751a7.5 7.5 0 0 0-7.5 7.5V367.09a7.5 7.5 0 0 0 7.5 7.5h70.751a7.5 7.5 0 0 0 7.5-7.5V149.448a7.5 7.5 0 0 0-7.5-7.5zm-63.252 15h55.751v28.528h-55.751v-28.528zm0 43.528h55.751v28.529h-55.751v-28.529zm0 87.057h55.751v28.529h-55.751v-28.529zm55.752 72.057h-55.751v-28.528h55.751v28.528zm0-87.057h-55.751v-28.528h55.751v28.528zM175.299 141.948h-70.752a7.5 7.5 0 0 0-7.5 7.5v304.699a7.5 7.5 0 0 0 7.5 7.5h70.752a7.5 7.5 0 0 0 7.5-7.5V149.448a7.5 7.5 0 0 0-7.5-7.5zm-7.5 304.699h-55.752v-28.529h55.752v28.529zm0-43.529h-55.752V374.59h55.752v28.528zm0-43.528h-55.752v-28.528h55.752v28.528zm0-43.528h-55.752v-28.529h55.752v28.529zm0-43.529h-55.752v-28.528h55.752v28.528zm0-43.528h-55.752v-28.529h55.752v28.529zm0-43.529h-55.752v-28.528h55.752v28.528zm239.655-43.528h-70.752a7.5 7.5 0 0 0-7.5 7.5v304.699a7.5 7.5 0 0 0 7.5 7.5h70.752a7.5 7.5 0 0 0 7.5-7.5V149.448c0-4.142-3.356-7.5-7.5-7.5zm-63.251 102.057h55.752v28.528h-55.752v-28.528zm55.751 202.642h-55.752v-28.529h55.752v28.529zm.001-43.529h-55.752V374.59h55.752v28.528zm0-43.528h-55.752v-28.528h55.752v28.528zm0-43.528h-55.752v-28.529h55.752v28.529zm0-87.057h-55.752v-28.529h55.752v28.529zm0-43.529h-55.752v-28.528h55.752v28.528z'
    },
    {
      d:
        'M462.486 65.585h-13.128V34.503h13.128c9.662 0 9.684-15 0-15H263.501V7.5a7.5 7.5 0 0 0-7.5-7.5H70.142a7.5 7.5 0 0 0-7.5 7.5v58.085H49.514a7.5 7.5 0 0 0-7.5 7.5v37.095a7.5 7.5 0 0 0 7.5 7.5h13.127V504.5a7.5 7.5 0 0 0 7.5 7.5h371.718a7.5 7.5 0 0 0 7.5-7.5V117.681h13.128a7.5 7.5 0 0 0 7.5-7.5V73.085a7.501 7.501 0 0 0-7.501-7.5zm-50.298-31.082h22.172v31.082h-22.172V34.503zm-37.172 0h22.172v31.082h-22.172V34.503zm-37.172 0h22.172v31.082h-22.172V34.503zm-37.172 0h22.172v31.082h-22.172V34.503zm-37.171 0h22.171v31.082h-22.171V34.503zM77.642 15h170.859v50.585H182.8V34.259a7.5 7.5 0 0 0-7.5-7.5h-70.752a7.5 7.5 0 0 0-7.5 7.5v31.327H77.642V15zm90.157 26.759v23.826h-55.752V41.759h55.752zM298.092 497H213.91v-84.506h84.183V497zm156.896-394.319H189.437c-9.652 0-9.685 15 0 15H434.36V497H313.092v-92.006a7.5 7.5 0 0 0-7.5-7.5h-99.183a7.5 7.5 0 0 0-7.5 7.5V497H77.642V117.681h81.795c9.64 0 9.688-15 0-15H57.014V80.585h397.974v22.096z'
    }
  ]
}
