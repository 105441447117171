import { CIcon } from '~/icons/types'
export const ciEnergyClass: CIcon = {
  name: 'energy-class',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M292.3 0H0v152h292.3l75-76zM256 97.3 234.7 76 256 54.7 277.3 76zM382.3 182H0v150h382.3l75-75zM346 278.3 324.7 257l21.3-21.3 21.3 21.3zm94.3 83.7H0v150h440.3l71.7-75zM404 458.3 382.7 437l21.3-21.3 21.3 21.3zm0 0',
      fill: 'currentColor'
    }
  ]
}
