import { CIcon } from '~/icons/types'
export const ciBicycle: CIcon = {
  width: 512,
  height: 319.6,
  paths: [
    {
      d:
        'M409.2 119.6c-9.8 0-19.3 1.4-28.3 4.1l-12.5-35-.3-.9-6.3-17.8c-.2-.5-.4-.9-.6-1.3l-20.1-56.5c0-.1-.1-.1-.1-.2-.1-.2-.2-.4-.2-.6-.1-.1-.1-.3-.2-.4-.1-.2-.2-.3-.3-.5-.1-.2-.2-.3-.3-.5-.1-.1-.2-.3-.3-.4-.1-.2-.2-.3-.3-.5l-.3-.3-.4-.4-.3-.3c-.1-.1-.3-.2-.4-.4-.1-.1-.3-.2-.4-.3-.1-.1-.2-.2-.4-.3-.2-.1-.3-.2-.5-.3-.1-.1-.2-.1-.3-.2l-.6-.3c-.1-.1-.2-.1-.3-.2-.2-.1-.4-.2-.6-.2-.1-.1-.3-.1-.4-.1-.2-.1-.4-.1-.5-.2-.2 0-.3-.1-.5-.1s-.3-.1-.5-.1-.4-.1-.6-.1h-.2L283.9.1c-5.8-.6-10.9 3.5-11.6 9.3-.6 5.8 3.5 10.9 9.3 11.6l41.7 4.5 13.6 38.2-137.5 6.4-6.1-14 15 .1c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5l-54.1-.2c-5.8 0-10.5 4.7-10.5 10.5S148.4 56 154.2 56l16.2.1v.1l7.3 16.8c-1 .8-1.9 1.8-2.6 2.9L143 128.3c-12.4-5.5-26.1-8.6-40.5-8.6-55.1 0-99.9 44.8-99.9 99.9s44.8 99.9 99.9 99.9c51.5 0 94.1-39.2 99.3-89.4H226c5.3 8.4 14.6 14 25.2 14 16.4 0 29.7-13.3 29.7-29.7 0-3.6-.6-7-1.8-10.1l75.2-91.8 6.9 19.4c-31.1 17-52.2 49.9-52.2 87.8 0 55.1 44.8 99.9 99.9 99.9s99.9-44.8 99.9-99.9-44.5-100.1-99.6-100.1zM188 95.6l42.5 97.8c-4.2 4.2-7.1 9.6-8.2 15.7h-20.1c-3-28.8-18.4-54.1-40.7-70.3L188 95.6zM181 209h-62.6l32.1-52.3c16.3 12.4 27.6 31 30.5 52.3zm-78.2 89.4c-43.5 0-78.9-35.4-78.9-78.9s35.4-78.9 78.9-78.9c10.4 0 20.3 2 29.4 5.7L90.7 214c-2 3.2-2.1 7.3-.2 10.6s5.4 5.4 9.2 5.4H181c-5.2 38.6-38.3 68.4-78.2 68.4zm162.7-110.3c-4.2-2.2-8.9-3.5-14-3.5-.6 0-1.3 0-1.9.1l-40.9-94 135.9-6.3 1.8 4.9-80.9 98.8zm143.7 110.3c-43.5 0-78.9-35.4-78.9-78.9 0-28.7 15.4-53.8 38.4-67.7l25.4 71.2c1.5 4.3 5.6 7 9.9 7 1.2 0 2.4-.2 3.5-.6 5.5-1.9 8.3-8 6.4-13.4L388 143.5c6.8-1.9 13.9-2.9 21.2-2.9 43.5 0 78.9 35.4 78.9 78.9s-35.4 78.9-78.9 78.9z'
    }
  ],
  name: 'bicycle',
  type: 'solid'
}
