import { CIcon } from '~/icons/types'
export const ciQuality: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M468 456q2 5-1.5 8.5T458 467l-66-19-33 60q-2 4-7 4h-1q-5 0-7-5l-73-178h-15q-71 0-120-52L66 446l56-16q6-2 9 4l28 51 57-137q3-8 10.5-4.5T231 354l-63 153q-2 5-7 5h-1q-5 0-7-4l-33-60-66 19q-5 1-8.5-2.5T44 456l80-193q-33-44-33-98 0-68 48.5-116.5T256 0t116.5 48.5T421 165q0 59-38 104.5T287 326l66 159 28-51q3-6 9-4l56 16-61-148q-3-7 4.5-10t10.5 4zM108 164.5q0 61.5 43.5 105T256 313t104.5-43.5 43.5-105-43.5-105T256 16 151.5 59.5t-43.5 105zM350.5 134q1.5 5-2.5 8l-39 40 9 55q0 5-4 8-2 1-4 1h-4l-50-25-50 25q-4 2-8-1t-4-8l9-55-39-40q-4-3-2.5-8t6.5-6l55-9 26-49q2-5 7-5t7 5l26 49 55 9q5 1 6.5 6zM295 173l31-31-44-7q-4-1-6-5l-20-39-20 39q-2 4-6 5l-44 7 31 31q3 3 2 7l-6 44 39-20q2-1 4-1t4 1l39 20-6-44q-1-4 2-7zm85 0q8 1 7 9-7 49-44.5 82T256 297q-55 0-93.5-39T124 164.5t38.5-93T256 33q49 0 86.5 32.5T387 147q1 8-7 9t-9-7q-4-28-20.5-51T309 62t-53-13q-31 0-58 15.5t-42.5 42T140 165q0 47 34 81t82 34q43 0 76-28.5t39-71.5q1-8 9-7z',
      fill: 'currentColor'
    }
  ],
  name: 'quality',
  type: 'light'
}
