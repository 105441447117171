import { CIcon } from '~/icons/types'
export const ciWater: CIcon = {
  name: 'water',
  type: 'solid',
  width: 512.005,
  height: 512.005,
  paths: [
    {
      d:
        'M263.148 2.766c-4.042-3.688-10.25-3.688-14.292 0-7.563 6.823-184.854 168.875-184.854 317.24 0 105.865 86.125 192 192 192s192-86.135 192-192c.001-148.365-177.291-310.417-184.854-317.24zm-7.145 445.239c-70.583 0-128-57.417-128-128a10.66 10.66 0 0 1 10.667-10.667 10.66 10.66 0 0 1 10.667 10.667c0 58.813 47.854 106.667 106.667 106.667a10.66 10.66 0 0 1 10.667 10.667 10.663 10.663 0 0 1-10.668 10.666z',
      fill: 'currentColor'
    }
  ]
}
