import { CIcon } from '~/icons/types'
export const ciCoupeSport: CIcon = {
  name: 'coupe-sport',
  type: 'regular',
  width: 512,
  height: 212.9,
  paths: [
    {
      d:
        'M459.8 75.7c-25-9.9-58.6-15.7-99.8-17.4-4.8-3.5-9.3-7-13.7-10.4C313.4 22.7 285 .9 212.1.9c-81 0-108.7 19-143.8 43-1.5 1.1-3.1 2.1-4.7 3.2-.5.4-1.1.7-1.6 1-.1.1-.2.1-.3.2h-.1c-10.4 5.8-28.2 2.5-40.8-3.3-5.3-2.3-11.5 0-13.9 5.3-2.4 5.2-.1 11.5 5.1 13.9 2.1 1 4.4 1.9 6.7 2.7-6.1 6.2-10.2 14.9-11 24.7-.3 3.7-.3 7.5-.4 11.6 0 2-.1 4-.1 6.1-5 5.9-6.5 14.7-6.5 20.1V152c0 20.2 11.8 33.8 29.3 33.8h33.6c.6 0 1.2-.1 1.7-.2 9.6 16.3 27.3 27.2 47.5 27.2 20.1 0 37.8-10.9 47.4-27h188.1c9.6 16.2 27.2 27 47.4 27 20.5 0 38.4-11.2 47.9-27.9 1.3.5 2.6.8 4.1.8 2.9 0 18.3-.1 32.6-2.6 7.7-1.3 31-5.5 31-23v-23.3c0-18.5-9-44.3-51.5-61.1zM325.5 58.5H200.2L194 22.3c5.7-.2 11.7-.4 18.1-.4 60.2 0 84.9 15 113.4 36.6zM172.9 23.8l5.9 34.7h-22.2c-9.8 0-19.4-3.7-27.2-10.4L118 38.2c14.6-6.8 31.5-11.8 54.9-14.4zm-60 168.1c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.1 34.1-34.1S147 139 147 157.8s-15.3 34.1-34.1 34.1zm282.8 0c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.1 34.1-34.1 34.1 15.3 34.1 34.1-15.3 34.1-34.1 34.1zm94.6-33.5c-1.7 1-6.4 3.2-18.2 4.8-8.7 1.2-17.3 1.5-21.7 1.6.3-2.3.5-4.6.5-7 0-30.4-24.7-55.1-55.1-55.1-30.4 0-55.1 24.7-55.1 55.1 0 2.4.2 4.7.5 7H167.5c.3-2.3.5-4.6.5-7 0-30.4-24.7-55.1-55.1-55.1-30.4 0-55.1 24.7-55.1 55.1 0 2.4.2 4.7.5 7H30.1c-6.9 0-8.3-7-8.3-12.8v-22.7c0-2.6.8-5.2 1.3-6.2 2.9-1.8 4.8-4.9 4.9-8.4.2-4 .3-7.9.3-11.3 0-3.7.1-7.2.3-10.2.5-6.8 4.3-12.5 9.1-14l17.7-5.3c5.9-1.8 11.6-4.3 16.8-7.5.6-.3 1.2-.7 1.7-1.1.2-.1.3-.2.5-.3l.9-.6c1.6-1.1 3.2-2.2 4.7-3.2 6.3-4.3 12.3-8.4 18.4-12.2l17.2 14.9c11.6 10 26.1 15.5 40.9 15.5h199.8c.6 0 1.3-.1 1.9-.2 41.5 1.7 132 10.7 132 57.6v21.6z'
    }
  ]
}
