















import { defineComponent } from '@nuxtjs/composition-api'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default defineComponent({
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  setup() {
    return { faChevronRight }
  }
})
