import { CIcon } from '~/icons/types'
export const ciForklift: CIcon = {
  width: 512,
  height: 381.9,
  paths: [
    {
      d:
        'M497.2 336.6h-86.4V21.1c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v168.4h-26.5V180c0-5.8-4.7-10.5-10.5-10.5H320l-11-95.7v-.1C303.7 31.7 267.7 0 225.3 0h-60c-24.6 0-44.7 20.1-44.7 44.7v124.8H56.4c-28.7 0-52.1 23.4-52.1 52.1v105.1c0 5.8 4.7 10.5 10.5 10.5h32.9c0-36.2 29.5-65.6 65.6-65.6 36.2 0 65.6 29.4 65.6 65.6h21.8c0-36.2 29.5-65.7 65.6-65.7 36.2 0 65.7 29.5 65.7 65.7h20.7c5.8 0 10.5-4.7 10.5-10.5v-12.5h26.5V347c0 5.8 4.7 10.5 10.5 10.5h96.9c5.8 0 10.5-4.7 10.5-10.5.1-5.7-4.6-10.4-10.4-10.4zm-206-145.8-30.6-32 9.3-7c4.6-3.5 5.6-10.1 2.1-14.7-3.5-4.6-10.1-5.6-14.7-2.1l-38.7 29.1c-4.6 3.5-5.6 10.1-2.1 14.7 2.1 2.7 5.2 4.2 8.4 4.2 2.2 0 4.4-.7 6.3-2.1l12.5-9.4 35.8 37.4-7.4 11.4h-95.3l-35.2-44.1V44.8c-.1-13.1 10.5-23.8 23.6-23.8h60c31.8 0 58.8 23.8 62.9 55.3l11.7 101.3-8.6 13.2zm98.6 102.5h-26.5v-82.8h26.5v82.8z'
    },
    {
      d:
        'M113.4 292.6c-24.7 0-44.6 20-44.6 44.6 0 24.7 20 44.6 44.7 44.6s44.6-20 44.6-44.6c-.1-24.6-20-44.6-44.7-44.6zm153.1 0c-24.7 0-44.6 20-44.6 44.7 0 24.7 20 44.6 44.6 44.6 24.7 0 44.6-20 44.6-44.6 0-24.7-19.9-44.7-44.6-44.7z'
    }
  ],
  name: 'forklift',
  type: 'solid'
}
