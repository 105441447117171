import { CIcon } from '~/icons/types'
export const ciCombiCaravan: CIcon = {
  name: 'combi-caravan',
  type: 'regular',
  width: 512,
  height: 197.1,
  paths: [
    {
      d:
        'M474.9 65c-19.2-7.9-45.1-8.9-75.2-10.2-9.1-.4-18.4-.8-28.1-1.3-4.4-3.3-8.6-6.5-12.7-9.7-30.6-23.5-57-43.8-124-43.8-67.4 0-142.6 1.6-182 3.2-14.8.6-28 9.8-33.8 23.5-2.9 6.8-5 13.5-6.4 19.9-4 18.4-4 39.4-4 48.9-4.2 5.2-5.8 12.6-5.8 18.5v21c0 19.1 11.2 31.9 27.7 31.9H78c8.1 17.8 26 30.3 46.8 30.3s38.7-12.4 46.8-30.3h173.8c8.1 17.8 26 30.3 46.8 30.3 20.8 0 38.8-12.5 46.8-30.3h4.5c2.8 0 5.4.1 8.1.1 26.4 0 44.5-2 52.7-10.1 3.1-3 4.8-7 4.8-11.1v-24.7c0-28.3-11.2-46.6-34.2-56.1zM337.2 53.8l-113.1 1.8-3-34.5h13.7c53.7-.1 77.5 13.9 102.4 32.7zM109.3 22.5l3 34.7h-2.4c-5.9 0-12.5-.1-19.4-.2-7.2 0-13.3-5-14.7-12.1l-4.3-21.3c11.1-.4 23.9-.7 37.8-1.1zm24 34.4-3-34.8c22.1-.4 46-.8 69.8-.9l3 34.7-69.8 1zm-8.4 119.2c-16.7 0-30.3-13.6-30.3-30.3s13.6-30.3 30.3-30.3 30.3 13.6 30.3 30.3-13.6 30.3-30.3 30.3zm267.4 0c-16.7 0-30.3-13.6-30.3-30.3s13.6-30.3 30.3-30.3 30.3 13.6 30.3 30.3-13.6 30.3-30.3 30.3zm95.8-33.5c-3.9 1.5-14.7 3.7-44.2 3.3h-.2v-.1c0-28.3-23-51.3-51.3-51.3-28.3 0-51.3 23-51.3 51.3v.1H176.2v-.1c0-28.3-23-51.3-51.3-51.3s-51.3 23-51.3 51.3v.1H30.7c-4.5 0-6.7-3.7-6.7-10.9v-20.8c0-2.1.6-4.2 1-5.1 2.8-1.8 4.5-4.9 4.7-8.2.1-1 0-2.4 0-4.6 0-8.5-.1-28.4 3.5-45 1.1-5.2 2.9-10.7 5.3-16.3 2.1-5.1 6.5-8.8 11.7-10.1l5 24.3c3.4 16.8 17.8 28.7 35 29 7 .1 13.7.2 19.8.2h.2l257.5-4c10.9.7 21.2 1.1 31.2 1.5 28.1 1.2 52.3 2.1 68.1 8.6 11 4.5 21.1 12.1 21.1 36.7v21.4z'
    }
  ]
}
