import { CIcon } from '~/icons/types'
export const ciFilterCategoriesV2: CIcon = {
  width: 14,
  height: 12,
  paths: [
    {
      d:
        'M13.127 8.03h-1.6a2.831 2.831 0 0 0-2.682-1.949 2.829 2.829 0 0 0-2.683 1.95H.872a.873.873 0 0 0 0 1.744h5.29a2.829 2.829 0 0 0 2.683 1.949 2.831 2.831 0 0 0 2.682-1.95h1.6a.872.872 0 0 0 0-1.744Zm-4.282 2.252a1.382 1.382 0 0 1-1.38-1.38 1.382 1.382 0 0 1 2.356-.975c.259.259.404.61.405.976a1.382 1.382 0 0 1-1.38 1.38Z',
      fill: 'currentColor'
    },
    {
      d:
        'M.872 3.692H2.5a2.833 2.833 0 0 0 2.684 1.95 2.832 2.832 0 0 0 2.683-1.95h5.26a.873.873 0 0 0 0-1.743h-5.26A2.832 2.832 0 0 0 5.185 0a2.832 2.832 0 0 0-2.681 1.95H.872a.873.873 0 0 0 0 1.742ZM5.183 1.44a1.382 1.382 0 0 1 .976 2.357 1.382 1.382 0 0 1-1.952 0 1.382 1.382 0 0 1 0-1.952c.26-.259.61-.404.976-.405Z',
      fill: 'currentColor'
    }
  ],
  name: 'filter-categories-v2',
  type: 'regular'
}
