













import { Option } from '~/models/shared/types'
import MakeOptionWithLogoAndCount from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeOptionWithLogoAndCount.vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    MakeOptionWithLogoAndCount
  },
  props: {
    focused: {
      type: Boolean,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    },
    option: {
      type: Object as PropType<Option>,
      required: true
    },
    withMakeLogo: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Boolean,
      default: true
    }
  }
})
