import { CIcon } from '~/icons/types'
export const ciRoofRails: CIcon = {
  width: 512,
  height: 367.63,
  paths: [
    {
      d:
        'm225.22 130.36 42.86-37.92 33.43-29.57 17.17 18.73 2.85 3.11a15.14 15.14 0 0 0 21.38 0c2.65-2.89 4.61-6.67 4.43-10.69a16.32 16.32 0 0 0-4.43-10.7l-18.77-20.47 6.36-5.63 12.37-10.94.52-.46c2.79-2.47 4.43-7 4.43-10.69a15.38 15.38 0 0 0-4.43-10.69C340.51 1.79 336.72-.17 332.7 0A16.59 16.59 0 0 0 322 4.44L313.48 12l-23.13 20.44-34.18 30.24-41.81 37L168.81 140l-46.21 40.84-42.86 37.91L43.34 251l-26 23-12.4 10.92-.51.45c-2.8 2.48-4.43 7.06-4.43 10.7a15.42 15.42 0 0 0 4.43 10.69c2.88 2.64 6.66 4.61 10.69 4.43a16.66 16.66 0 0 0 10.69-4.43l2.41-2.14v32.3a15.63 15.63 0 0 0 4.43 10.69A15.36 15.36 0 0 0 43.34 352c3.91-.17 8-1.46 10.69-4.42a15.71 15.71 0 0 0 4.43-10.69v-59.02l33.19-29.36 41.81-37L179 171.23Zm263.1-71.92 6.36-5.63 12.38-10.94.51-.46c2.8-2.47 4.43-7.06 4.43-10.69A15.38 15.38 0 0 0 507.57 20c-2.88-2.65-6.66-4.61-10.69-4.43A16.56 16.56 0 0 0 486.19 20l-8.53 7.54L454.53 48l-34.18 30.27-41.81 37L333 155.55l-46.21 40.88-42.86 37.91-36.39 32.2-26 23-12.37 10.95-.52.45c-2.79 2.48-4.43 7.06-4.43 10.69a15.38 15.38 0 0 0 4.43 10.69c2.88 2.65 6.67 4.61 10.69 4.43a16.54 16.54 0 0 0 10.66-4.41l2.42-2.13v32.29a15.59 15.59 0 0 0 4.42 10.69 15.35 15.35 0 0 0 10.69 4.43c3.91-.17 8-1.46 10.7-4.43a15.72 15.72 0 0 0 4.42-10.69v-59l33.19-29.36 41.81-37 45.55-40.3 46.21-40.88L432.26 108l33.43-29.58 17.18 18.74 2.84 3.1a15.11 15.11 0 0 0 21.38 0c2.65-2.88 4.61-6.66 4.43-10.69a16.29 16.29 0 0 0-4.43-10.69Z'
    }
  ],
  name: 'roof-rails',
  type: 'regular'
}
