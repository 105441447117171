












































































import {
  defineComponent,
  onMounted,
  ref,
  toRefs,
  onBeforeUnmount,
  computed,
  nextTick,
  watch
} from '@nuxtjs/composition-api'
import CImg from '~/components/shared/configurable/image/CImg.vue'

import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import Player from 'video.js/dist/types/player'
import CProgressBar from '~/components/shared/configurable/progress-bar/CProgressBar.vue'
import UserAgentService from '~/services/UserAgentService'
import { useDep } from '~/compositions/dependency-container'

export default defineComponent({
  components: { CImg, CProgressBar },
  props: {
    src: {
      type: String,
      required: true
    },
    controls: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    preload: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    fluid: {
      type: Boolean,
      default: false
    },
    thumbnail: {
      type: String,
      default: ''
    },
    loadVideoAfterClick: {
      type: Boolean,
      default: false
    },
    withoutProgressBar: {
      type: Boolean,
      default: false
    },
    withoutThumb: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    stopVideoOutsideOfViewport: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const {
      src,
      controls,
      loop,
      muted,
      preload,
      autoplay,
      thumbnail,
      fluid,
      loadVideoAfterClick,
      fullscreen,
      withoutProgressBar
    } = toRefs(props)
    const isPlaying = ref(false)
    const isLoading = ref(false)
    const isVideoReady = ref(false)
    const videoRatio = ref(0)
    const player = ref<Player | null>(null)
    const videoPlayerRef = ref<HTMLElement | null>(null)
    const isTap = ref(false)
    const isClick = ref(false)
    const progress = ref(0)
    const internalLoadVideoAfterClick = ref(loadVideoAfterClick.value)
    const isMuted = ref(muted.value)
    const userAgentService = useDep(UserAgentService)
    const isIos = userAgentService.isIos()

    onMounted(() => {
      emit('mounted')

      if (thumbnail.value && !internalLoadVideoAfterClick.value) {
        isLoading.value = true
      }
      if (!internalLoadVideoAfterClick.value) {
        createPlayer()
      }
    })

    const isProgressBarVisible = computed(
      () =>
        !controls.value &&
        !withoutProgressBar.value &&
        isVideoReady.value &&
        ((isIos && progress.value > 0.1) || !isIos)
    )
    watch(src, () => {
      isVideoReady.value = false
      isPlaying.value = false
      isLoading.value = true
      player.value.src(getSrc())
    })

    onBeforeUnmount(() => {
      if (player.value) {
        player.value.dispose()
      }
    })
    function createPlayer() {
      return new Promise(resolve => {
        player.value = videojs(videoPlayerRef.value as HTMLElement, {
          loop: loop.value,
          aspectRatio: '9:16',
          muted: muted.value,
          autoplay: autoplay.value,
          fluid: fluid.value,
          controls: controls.value,
          fullscreen: fullscreen.value,
          enableDocumentPictureInPicture: false,
          preload: preload.value,
          sources: [
            {
              src: getSrc()
            }
          ]
        })
        player.value.ready(() => {
          player.value.on('loadeddata', data => {
            emit('load', data)
          })
          player.value.on('loadedmetadata', metadata => {
            isVideoReady.value = true
            isLoading.value = false
            emit('loaded-metadata', metadata)
            resolve(player)
          })
        })

        player.value.on('touchstart', () => {
          isTap.value = true
        })
        player.value.on('touchmove', () => {
          isTap.value = false
        })
        player.value.on('touchend', () => {
          if (isTap.value) {
            playToggle()
          }
        })
        player.value.on('ended', () => {
          isPlaying.value = false
        })

        player.value.on('mousedown', () => {
          player.value.on('mousemove', () => {
            isClick.value = false
          })
          isClick.value = true
        })

        player.value.on('mouseup', () => {
          player.value.off('mousemove')
          if (isClick.value) {
            playToggle()
          }
        })

        player.value.on('timeupdate', () => {
          progress.value =
            (100 * player.value.currentTime()) / player.value.duration()
          emit('progress-change', progress.value)
        })
      })
    }

    async function playToggle() {
      if (!isVideoReady.value) {
        isLoading.value = true
        internalLoadVideoAfterClick.value = false
        await nextTick()
        await createPlayer()
      }
      if (isPlaying.value) {
        stopPlayer()
        isPlaying.value = false
        isLoading.value = false
        return
      }
      try {
        await playVideo()
        emit('play')
        isVideoReady.value = true
        isPlaying.value = true
      } finally {
        isLoading.value = false
      }
    }

    function setCurrentTime(progress: number) {
      if (player.value) {
        player.value.currentTime(player.value.duration() * (progress / 100))
      }
    }

    function stopPlayer(resetTime: boolean = false) {
      if (player.value && isPlaying.value && !player.value.isDisposed()) {
        player.value.pause()
        isPlaying.value = false
        if (resetTime) {
          setCurrentTime(0)
        }
      }
    }

    async function playVideo() {
      if (player.value) {
        await player.value.play()
        isPlaying.value = true
      }
    }

    function volumeToggle() {
      if (player.value) {
        player.value.volume(isMuted.value ? 1 : 0)
        isMuted.value = !isMuted.value

        emit('volume-change', isMuted.value)
      }
    }

    function getVideoDimensions() {
      return {
        height: videoPlayerRef.value.videoHeight,
        width: videoPlayerRef.value.videoWidth
      }
    }

    function handleVisibleChange(isVisible: boolean) {
      if (!isVisible) {
        stopPlayer()
      }
    }

    function getSrc() {
      return `${src.value}?clientBandwidthHint=1000#t=0.1`
    }

    return {
      videoPlayerRef,
      faPlayCircle,
      playToggle,
      playVideo,
      isPlaying,
      isLoading,
      videoRatio,
      isVideoReady,
      progress,
      isMuted,
      setCurrentTime,
      internalLoadVideoAfterClick,
      volumeToggle,
      stopPlayer,
      getVideoDimensions,
      isIos,
      isProgressBarVisible,
      handleVisibleChange,
      getSrc
    }
  }
})
