import { CIcon } from '~/icons/types'
export const ciTractor: CIcon = {
  width: 512,
  height: 344.3,
  paths: [
    {
      d:
        'M425.4 175.8c21.2 0 40.7 7.2 56.3 19.3-.9-43-36.1-77.7-79.4-77.7h-5.2V72.8c0-5.9 1.8-11.6 5.1-16.4l4.9-7.1c3.3-4.8 2.1-11.3-2.7-14.6-4.8-3.3-11.3-2.1-14.6 2.7l-4.9 7.1c-5.8 8.3-8.9 18.2-8.9 28.4v44.5h-68.9l-26-96.4h36.5c5.8 0 10.5-4.7 10.5-10.5S323.4 0 317.6 0H87c-5.8 0-10.5 4.7-10.5 10.5S81.2 21 87 21h16.7L87.2 96.2c-26.6 6.1-51.2 19-71.6 38-4.2 4-4.5 10.6-.5 14.8s10.6 4.5 14.8.5c25-23.4 57.6-36.3 91.8-36.3 74.2 0 130.6 59.4 130.6 133.6 0 8.6-.8 17.2-2.4 25.6-.6 3.1.2 6.2 2.2 8.7 2 2.4 5 3.8 8.1 3.8l75.7 1c-1.3-6-1.9-12.3-1.9-18.7 0-50.5 40.9-91.4 91.4-91.4zm-172.6-11.5c-27.5-43.3-76-72.1-131.1-72.1-4.1 0-8.3.2-12.4.5L125 20.9h134.4L298 164.3h-45.2z'
    },
    {
      d:
        'M425.4 195.4c-41.1 0-74.3 33.2-74.3 74.3s33.2 74.3 74.3 74.3c41.1 0 74.3-33.2 74.3-74.3.2-41-33.2-74.3-74.3-74.3zm0 102c-15.3 0-27.7-12.4-27.7-27.7s12.4-27.7 27.7-27.7c15.3 0 27.7 12.4 27.7 27.7.2 15.3-12.4 27.7-27.7 27.7zM121.2 133.3c-58.1 0-105.5 47.4-105.5 105.5s47.4 105.5 105.5 105.5S226.7 297 226.7 238.8c0-58.1-47.3-105.5-105.5-105.5zm0 148.5c-23.6 0-42.9-19.3-42.9-42.9 0-23.6 19.3-42.9 42.9-42.9 23.7 0 42.9 19.3 42.9 42.9 0 23.6-19.3 42.9-42.9 42.9z'
    }
  ],
  name: 'tractor',
  type: 'solid'
}
