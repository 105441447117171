





































import { faClock } from '@fortawesome/free-regular-svg-icons/faClock'
import { mapGetters, mapState } from 'vuex'
import { SearchbarSuggestion } from '~/models/search-bar'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { resolveCategory } from '~/utils/category'
import { categoryInAllTexts } from '~/constants/category'
import { ciSearchbarMagnifier } from '~/icons/source/regular/searchbar-magnifier'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
import SearchbarPathText from '~/components/shared/classified/search/searchbar/SearchbarPathText.vue'
import { ciTimes } from '~/icons/source/regular/times'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { SearchbarPathText },
  props: {
    suggestion: {
      type: Object as PropType<SearchbarSuggestion>,
      required: true
    },
    currentQ: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: true
    },
    inSameCategory: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      inDealsSearch: 'inDealsSearch'
    }),
    ...mapState({
      rootCategory: state =>
        state.classifieds.search?.config?.rootParams?.category
    }),
    ...mapDeps({
      scrollService: ScrollService
    }),
    icons() {
      return {
        history: faClock,
        search: ciSearchbarMagnifier,
        delete: ciTimes
      }
    },
    inAllDisplayText() {
      if (this.inDealsSearch) {
        return this.$t('in deals')
      }
      if (this.rootCategory) {
        return this.$t(resolveCategory(categoryInAllTexts, this.rootCategory))
      }
      return ''
    },
    suggestionText() {
      if (this.suggestion.raw) {
        return (
          this.suggestion.data.query + ' ' + this.inAllDisplayText.toLowerCase()
        )
      }

      return this.suggestion.displayPhrase
    },
    typedQ() {
      const qTrimmed = this.currentQ.trim()
      const indexOfTyped = this.indexOfStringInSuggestion(qTrimmed)
      if (indexOfTyped < 0) {
        // if typed is not on suggestion text, return only the suggestion phrase (handled in afterTyped)
        return ''
      }

      if (qTrimmed === this.suggestionText.toLowerCase()) {
        // in order to get correct case in results
        return this.suggestionText
      }
      return this.suggestionText.substring(
        indexOfTyped,
        indexOfTyped + qTrimmed.length
      )
    },
    afterTyped() {
      if (this.typedQ === '') {
        return this.suggestion.displayPhrase
      }
      const indexOfTyped = this.indexOfStringInSuggestion(this.typedQ)
      if (indexOfTyped < 0) {
        return ''
      }
      return this.suggestionText.substring(indexOfTyped + this.typedQ.length)
    },
    beforeTyped() {
      const indexOfTyped = this.indexOfStringInSuggestion(this.typedQ)
      if (indexOfTyped <= 0) {
        return ''
      }
      return this.suggestionText.substring(0, indexOfTyped)
    },
    isFromHistory() {
      return this.suggestion.fromHistory
    }
  },
  watch: {
    active(val, oldVal) {
      if (oldVal === false && val === true) {
        if (!this.suggestion.raw) {
          this.$emit(
            'suggestion-active',
            this.beforeTyped + this.typedQ + this.afterTyped
          )
        }

        this.scrollService.scrollIfOverflownVertical(
          this.$el,
          this.$parent.$refs.suggestions
        )
      }
    }
  },
  methods: {
    indexOfStringInSuggestion(str) {
      const title = this.suggestionText.toLowerCase()
      const typed = str.toLowerCase()
      return title.indexOf(typed)
    },
    remove(e) {
      e.stopPropagation()
      e.preventDefault()
      this.$emit('remove')
    },
    suggestionClicked(e) {
      e.preventDefault()
      e.stopPropagation()

      this.$emit('clicked')
    }
  }
})
