import { CIcon } from '~/icons/types'

export const ciHeart: CIcon = {
  width: '512',
  height: '512',
  paths: [
    {
      d:
        'M256 481.1c-3.6 0-7.3-1.3-10.2-3.8-22.4-19.6-43.5-37.6-63.9-54.9-52.8-44.9-98.5-83.7-130.1-121.2C16.5 259.1 0 219.9 0 177.7c0-38.9 13.4-75.3 37.7-102.6C63.1 46.7 98.1 31 136.2 31c42.2 0 79 18.9 106.3 54.7 5.3 6.9 9.8 13.9 13.4 20.4 3.7-6.5 8.1-13.4 13.4-20.4C296.6 49.9 333.4 31 375.6 31c38.2 0 73.2 15.7 98.5 44.1 24.3 27.3 37.7 63.7 37.7 102.6 0 42.2-16.5 81.5-51.8 123.4-31.6 37.5-77.2 76.3-130.1 121.2-20.4 17.3-41.5 35.3-63.9 54.9-2.7 2.6-6.4 3.9-10 3.9z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'heart',
  type: 'solid'
}
