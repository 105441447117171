































import vClickOutside from 'v-click-outside'
import { resolveCategory } from '~/utils/category'
import { mapGetters } from 'vuex'
import { categoriesIconsMappings } from '~/constants/category/icon'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import SearchbarCategoryDropdownItems from '~/components/shared/classified/search/searchbar/SearchbarCategoryDropdownItems.vue'
import { ciSearchbarMagnifier } from '~/icons/source/regular/searchbar-magnifier'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { ciDeals } from '~/icons/source/brand/deals'
import { ciBlackFriday } from '~/icons/source/brand/black-friday'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { SearchbarCategoryDropdownItems },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    loadedCatArray: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      fetching: false,
      showDropdown: false
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      inDealsSearch: 'inDealsSearch',
      inBlackFridaySearch: 'inBlackFridaySearch'
    }),
    loadedCat() {
      if (this.loadedCatArray.length) {
        return parseInt(this.loadedCatArray[0])
      }
      return null
    },
    selectedIcon() {
      if (this.inBlackFridaySearch) {
        return ciBlackFriday
      } else if (this.inDealsSearch) {
        return ciDeals
      }
      return (
        resolveCategory(categoriesIconsMappings, this.loadedCatArray) ||
        ciSearchbarMagnifier
      )
    },
    icons() {
      return {
        arrowDown: faCaretDown
      }
    }
  },
  methods: {
    selectedClicked() {
      this.showDropdown = !this.showDropdown
      this.$emit('clicked')
    },
    clickedOutside() {
      if (!this.isMobile) {
        this.showDropdown = false
      }
    },
    catSelected(cat) {
      // dead function, reuse it if you need router navigation on cat change again

      this.$emit('cat-changed', cat)
      this.showDropdown = false
    }
  }
})
