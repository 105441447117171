









import { defineComponent } from '@nuxtjs/composition-api'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { ciCreditCard } from '~/icons/source/regular/credit-card'

export default defineComponent({
  components: { CBadge },
  props: {
    isPaid: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    return { ciCreditCard }
  }
})
