import { CIcon } from '~/icons/types'
export const ciSlidingDoor: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M155.3 250.4c-24.9-.3-47.6-.3-69.3 0-4.8.1-8.8 1.6-11.4 4.3-2.7 2.8-4.1 7-4 11.9.2 9.1 6.3 15.1 15.5 15.3 8 .1 16.1.1 24 .1h21.1c7.9 0 16.1.1 24.1-.1 9.7-.1 16.1-6.5 16.1-15.7 0-9.8-6-15.7-16.1-15.8z',
      fill: 'currentColor'
    },
    {
      d:
        'm492.6 294.6-81-76.2-.8-.8-.9-.7c-5.6-4.4-12.7-6.9-19.9-6.9-1.6 0-3.1.1-4.6.3V178c0-45.7 0-72.9-.1-119.4C385.2 23.7 364.1.1 332.7 0c-31.2-.1-23-.1-53.6-.1H154.8c-30.7 0-62.4 0-93.6.1C29.8.2 8.7 23.8 8.6 58.7c-.1 46.5-.1 73.7-.1 119.4v274.8c0 35.9 20.8 59.1 52.9 59.1h271c32.1 0 52.9-23.2 52.9-59.1v-26.2c1.5.2 3 .3 4.6.3 7.2 0 14.3-2.4 19.9-6.9l.9-.7.8-.8 81-76.2c6.9-6 10.9-14.7 10.9-23.9.1-9.2-3.9-17.9-10.8-23.9zM387.4 400.4c-2.1-1-3.4-3-3.4-5.2v-38.5c-55.5.4-88.9 6-133.2 84.8-1.1 1.9-3.1 3-5.3 3-.5 0-1-.1-1.5-.2-2.7-.6-4.5-3-4.5-5.6 0-40.3 16.8-80.7 46-110.9 27.1-28 61.6-44.7 98.4-47.9v-38c0-2.2 1.3-4.3 3.4-5.2.8-.4 1.7-.6 2.6-.6 1.4 0 2.7.4 3.8 1.3l81.5 76.7c1.4 1.1 2.2 2.7 2.2 4.5 0 1.7-.8 3.4-2.2 4.5l-81.5 76.7c-1.1.9-2.5 1.3-3.8 1.3-.8-.1-1.7-.3-2.5-.7zM36.1 159.6c0-33.2 0-67.5.3-101.3.1-17.6 9-26.6 26.4-26.6 30.7-.1 61.4-.1 90.5-.1h127.4c29.1 0 19.8 0 50.5.1 17.4 0 26.3 9 26.4 26.6.3 33.8.3 68.1.3 101.3v10.5c0 1.4-.2 2.6-.5 3.9-.1.6-.2 1.1-.3 1.8l-.5 3.5H37.4l-.5-3.5c-.1-.7-.2-1.2-.3-1.8-.2-1.3-.5-2.5-.5-3.9v-10.5zm293.1 320.7H64.7c-20.9 0-28.7-8.5-28.7-31.4V212.7l3.8-.2c1.6-.1 2.9-.2 4.2-.3 2.6-.2 4.8-.4 7-.4h291.9c2.3 0 4.5.2 7 .4 1.3.1 2.7.2 4.2.3l3.8.2v44.8c-13.1 3-25.9 7.5-38.2 13.5-19.5 9.5-37.3 22.5-52.9 38.5-33.9 35-53.4 82.1-53.4 129 0 14.7 10 27.4 24.4 30.9 2.5.6 5 .9 7.6.9 11.6 0 22.3-6.2 28-16.3 31.6-56.2 53.9-68 84.5-70.7v65.4c0 23.1-7.8 31.6-28.7 31.6z',
      fill: 'currentColor'
    }
  ],
  name: 'sliding-door',
  type: 'regular'
}
