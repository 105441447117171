import { CIcon } from '~/icons/types'
export const ciDraws: CIcon = {
  name: 'draws',
  type: 'regular',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M502.737 0H9.265A9.264 9.264 0 0 0 .001 9.264v139.175a9.264 9.264 0 0 0 18.528 0v-47.865l50.242 50.242-50.242 50.242v-22.355a9.264 9.264 0 0 0-18.528 0v324.034a9.264 9.264 0 0 0 9.264 9.264h493.471a9.264 9.264 0 0 0 9.264-9.264V9.264C512.001 4.148 507.853 0 502.737 0zm-24.136 18.528L430.128 67 381.65 18.528h96.951zm-139.656 0L290.474 67l-48.483-48.472h96.954zm-33.903 89.249c3.618 3.618 9.484 3.618 13.1 0a9.263 9.263 0 0 0 0-13.101l-14.57-14.57 56.723-56.723 127.44 127.433-56.727 56.727-91.869-91.869c-3.618-3.618-9.484-3.618-13.1 0a9.263 9.263 0 0 0 0 13.101l22.042 22.042-56.725 56.727L163.919 80.106l56.723-56.723 84.4 84.394zm-26.787 112.867L150.817 348.082l-56.725-56.725 127.437-127.438 56.726 56.725zM199.293 18.528 150.821 67l-48.48-48.472h96.952zm-180.764 0h41.11l-41.11 41.11v-41.11zm5.74 61.573 56.719-56.719 127.439 127.434-56.727 56.726L24.269 80.101zm-5.74 147.151c.021-.02.043-.037.063-.057l63.28-63.279 56.723 56.728L18.529 340.716V227.252zm0 154.4 50.245 50.245-50.245 50.234V381.652zm14.863 111.82 48.485-48.474 48.474 48.474H33.392zm-9.123-132.285 56.723-56.729 127.435 127.435-56.727 56.726L24.269 361.187zm148.783 132.285 48.477-48.477 48.477 48.477h-96.954zm-9.134-132.29 56.727-56.725 127.437 127.437-56.725 56.727-127.439-127.439zm148.788 132.29 48.477-48.477 48.477 48.477h-96.954zm-9.133-132.29 18.82-18.819a9.263 9.263 0 0 0 0-13.101c-3.618-3.618-9.484-3.618-13.101 0l-18.82 18.82-56.727-56.727 127.437-127.437 56.727 56.727-90.089 90.089a9.263 9.263 0 0 0 0 13.101c3.618 3.618 9.484 3.618 13.101 0l19.378-19.378 127.435 127.439-56.724 56.724-127.437-127.438zm189.898 132.29H452.36l41.111-41.111v41.111zm0-82.046-50.241-50.24 50.241-50.253v100.493zm-.074-126.622-63.267 63.282-56.73-56.729 120.071-120.072v113.452h.001c-.024.022-.05.043-.075.067zm.076-154.461-50.242-50.242 50.242-50.242v100.484z',
      fill: 'currentColor'
    }
  ]
}
