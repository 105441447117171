import { MakeLogoType } from '~/models/make'
import {
  BIKES_MAKES_IDS_WITH_LOGO,
  CAR_MAKES_IDS_WITH_LOGO,
  FALLBACK_MAKE_LOGO_IMG_SOURCE,
  MAKE_LOGOS_BASE_STATIC_PATH
} from '~/constants/makemodels/logo'

export function useMakeLogo() {
  function getMakeLogo(makeId: number, logoType: MakeLogoType): string {
    switch (logoType) {
      case MakeLogoType.CARS: {
        if (CAR_MAKES_IDS_WITH_LOGO.includes(makeId)) {
          return `${MAKE_LOGOS_BASE_STATIC_PATH}/cars/make-${makeId}.png`
        }
        return FALLBACK_MAKE_LOGO_IMG_SOURCE
      }
      case MakeLogoType.BIKES: {
        if (BIKES_MAKES_IDS_WITH_LOGO.includes(makeId)) {
          return `${MAKE_LOGOS_BASE_STATIC_PATH}/bikes/make-${makeId}.png`
        }
        return FALLBACK_MAKE_LOGO_IMG_SOURCE
      }
    }
  }

  return { getMakeLogo }
}
