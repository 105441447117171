import { CIcon } from '~/icons/types'

export const ciHeart: CIcon = {
  width: '20',
  height: '20',
  paths: [
    {
      d:
        'M17.906 2.512C15.648.613 12.16.898 10 3.098 7.84.898 4.352.609 2.094 2.512c-2.938 2.472-2.508 6.504-.414 8.64l6.851 6.98a2.048 2.048 0 0 0 2.938.005l6.851-6.98c2.09-2.137 2.528-6.169-.414-8.645Zm-.922 7.324-6.851 6.98c-.094.094-.172.094-.266 0l-6.851-6.98C1.59 8.383 1.3 5.633 3.3 3.949c1.52-1.277 3.863-1.086 5.332.41L10 5.754l1.367-1.395c1.477-1.504 3.82-1.687 5.332-.414 1.996 1.684 1.7 4.45.285 5.89Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'heart',
  type: 'regular'
}
