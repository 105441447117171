import { CIcon } from '~/icons/types'
export const ciTftScreen: CIcon = {
  width: 512,
  height: 386.13,
  paths: [
    {
      d:
        'M403.83 361.74H108.17a12.56 12.56 0 0 0-8.61 3.57 12.33 12.33 0 0 0-3.56 8.62c.29 6.6 5.35 12.19 12.18 12.19h295.65a12.56 12.56 0 0 0 8.61-3.57 12.35 12.35 0 0 0 3.57-8.62c-.29-6.6-5.35-12.19-12.18-12.19Zm26.23-279.35-.3-.39a11.29 11.29 0 0 0-4.95-2.89l-18.29-7.56-29.17-12.08-6.73-2.78c-2.49-1-6.42-.16-8.64 1.13a11.21 11.21 0 0 0-4 15.34l1.75 2.27a11.18 11.18 0 0 0 5 2.88L383 85.88l8.87 3.67-7.62 3.63-22.08 10.51-32.74 15.59-39.95 19-43.6 20.75-44.3 21.1-41 19.53-34.88 16.59-16.07 7.64 10.06-24.3q1.38-3.36 2.78-6.72c1-2.5.17-6.42-1.13-8.64a11.21 11.21 0 0 0-15.34-4l-2.27 1.77a11.18 11.18 0 0 0-2.88 4.95q-3.78 9.15-7.57 18.29l-12.07 29.13-2.78 6.72c-1 2.5-.17 6.43 1.13 8.64a11.79 11.79 0 0 0 2.38 2.82l.3.39a11.18 11.18 0 0 0 5 2.88l18.29 7.57 29.17 12.07 6.73 2.78c2.49 1 6.42.17 8.64-1.13a11.21 11.21 0 0 0 4-15.34c-.59-.75-1.17-1.51-1.75-2.27a11.18 11.18 0 0 0-4.95-2.88l-18.29-7.57-10.32-4.27 9.67-4.59 32.74-15.59 39.94-19 43.6-20.75L289 163.73l41-19.53 34.8-16.57 25-11.91c3.95-1.88 7.9-3.73 11.83-5.63l.52-.25c.3-.14.59-.3.88-.46q-5.38 13-10.75 26-1.38 3.36-2.78 6.72c-1 2.5-.17 6.43 1.13 8.64a11.2 11.2 0 0 0 15.34 4l2.27-1.76a11.2 11.2 0 0 0 2.88-4.94l7.57-18.3q6-14.58 12.07-29.17 1.4-3.36 2.78-6.72c1-2.5.17-6.42-1.13-8.64a11.56 11.56 0 0 0-2.35-2.82Z'
    },
    {
      d:
        'M511.9 12.75v-.53A12.36 12.36 0 0 0 499.71 0H32.07c-6.31 0-12.64-.07-19 0h-.83A12.37 12.37 0 0 0 .1 12.22v272.46c0 12.49-.22 25 0 37.5v.53a12.36 12.36 0 0 0 12.19 12.19h467.64c6.31 0 12.64.07 18.95 0h.83c6.38 0 12.49-5.61 12.19-12.19V50.25c0-12.49.22-25.01 0-37.5Zm-24.38 30.46v241.47c0 8.61-.09 17.23-.09 25.84H24.48V50.25c0-8.6.15-17.22.14-25.84h462.9Z'
    }
  ],
  name: 'tft-screen',
  type: 'regular'
}
