

































































































import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import LeasingWithVatCheckbox from '~/components/car/classifieds/leasing/LeasingWithVatCheckbox.vue'
import {
  DURATION_SEARCH_PARAM,
  LEASE_DOWNPAYMENT_FROM_SEARCH_PARAM,
  LEASE_DOWNPAYMENT_TO_SEARCH_PARAM,
  LEASE_FROM_SEARCH_PARAM,
  LEASE_TO_SEARCH_PARAM,
  WITH_VAT_SEARCH_PARAM,
  YEARLY_MILEAGE_SEARCH_PARAM
} from '~/constants/leasing'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { LeasingWithVatCheckbox },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedLeaseFrom: null,
      selectedLeaseTo: null,
      selectedDownpaymentFrom: null,
      selectedDownpaymentTo: null,
      selectedWithVat: null,
      selectedDuration: null,
      selectedMileage: null
    }
  },
  computed: {
    icons: () => ({
      questionMark: faQuestionCircle
    }),
    leaseOptions() {
      return this.searchables.lease.map(mapBackendSearchableToOption)
    },
    downpaymentOptions() {
      return this.searchables.downpayment.map(mapBackendSearchableToOption)
    },
    durationOptions() {
      return this.searchables.duration.map(mapBackendSearchableToOption)
    },
    mileageOptions() {
      return this.searchables.mileage.map(mapBackendSearchableToOption)
    }
  },
  methods: {
    onBackendValues({ selected }) {
      this.selectedLeaseFrom = selected['lease-from']
      this.selectedLeaseTo = selected['lease-to']
      this.selectedDownpaymentFrom = selected['lease-downpayment-from']
      this.selectedDownpaymentTo = selected['lease-downpayment-to']
      this.selectedWithVat = selected['with-vat']
      this.selectedDuration = selected.duration
      this.selectedMileage = selected['yearly-mileage']
    },
    handleLeaseFromChange(leaseFrom) {
      this.selectedLeaseFrom = leaseFrom
      this.emitLeaseDownpaymentAndVat()
    },
    handleLeaseToChange(leaseTo) {
      this.selectedLeaseTo = leaseTo
      this.emitLeaseDownpaymentAndVat()
    },
    handleDownpaymentFromChange(downpaymentFrom) {
      this.selectedDownpaymentFrom = downpaymentFrom
      this.emitLeaseDownpaymentAndVat()
    },
    handleDownpaymentToChange(downpaymentTo) {
      this.selectedDownpaymentTo = downpaymentTo
      this.emitLeaseDownpaymentAndVat()
    },
    handleDurationChange(duration) {
      this.emitParams([{ name: DURATION_SEARCH_PARAM, value: duration }])
    },
    handleMileageChange(mileage) {
      this.emitParams([{ name: YEARLY_MILEAGE_SEARCH_PARAM, value: mileage }])
    },
    handleWithVatChange(withVat) {
      this.selectedWithVat = withVat

      // We dont need to perform a search request in case these are not filled in yet. The vat param does nothing
      // without lease or downpayment
      if (
        this.selectedLeaseFrom === null &&
        this.selectedLeaseTo === null &&
        this.selectedDownpaymentFrom === null &&
        this.selectedDownpaymentTo === null
      ) {
        return
      }
      this.emitLeaseDownpaymentAndVat()
    },
    emitLeaseDownpaymentAndVat() {
      this.emitParams([
        { name: LEASE_FROM_SEARCH_PARAM, value: this.selectedLeaseFrom },
        { name: LEASE_TO_SEARCH_PARAM, value: this.selectedLeaseTo },
        {
          name: LEASE_DOWNPAYMENT_FROM_SEARCH_PARAM,
          value: this.selectedDownpaymentFrom
        },
        {
          name: LEASE_DOWNPAYMENT_TO_SEARCH_PARAM,
          value: this.selectedDownpaymentTo
        },
        { name: WITH_VAT_SEARCH_PARAM, value: this.selectedWithVat }
      ])
    }
  }
})
