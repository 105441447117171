






















































import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { SET_PARAM } from '~/store/modules/shared/classifieds/search/mutation-types'
import SearchFacetsDeselectService from '~/services/search/SearchFacetsDeselectService'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  computed: {
    facetsDeselectService() {
      return this.$requestContainer.resolve(SearchFacetsDeselectService)
    },
    icons: () => ({
      close: faTimes
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      selectedHandlers: 'getSelectedFacets',
      getFacetByName: 'getFacetByName',
      notMyClassifiedsUrl: 'getNotMyClassifiedsUrl'
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      loadingFacets: state => state.loading.facets,
      params: state => state.params
    })
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      deselectFacet: 'deselectFacet',
      search: 'search'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setParam: SET_PARAM
    }),
    handleSelectedHandlerClick(handler, paramValue, paramUrlArg, paramName) {
      this.removeUrlArgPairs(handler)
      const facet = this.getFacetByName(handler.name)
      if (facet) {
        this.facetsDeselectService.deselectFacet({
          handler,
          paramValue,
          paramUrlArg,
          paramName // unused for now but available if needed later
        })
      } else {
        this.search()
      }
    },
    removeUrlArgPairs(handler) {
      this.$emit('remove-pairs', handler)
      const { humanParams } = handler.values
      humanParams.forEach(humanParam => {
        const { selectedUrlArgValuePairs } = humanParam
        if (selectedUrlArgValuePairs) {
          selectedUrlArgValuePairs.forEach(valuePair => {
            const { value, urlarg } = valuePair
            Object.entries(this.params).forEach(([paramName, paramValue]) => {
              if (paramName === urlarg && paramValue.includes(value)) {
                this.setParam({ name: paramName, value: null })
              }
            })
          })
        }
      })
    }
  }
})
