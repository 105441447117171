import { CIcon } from '~/icons/types'

export const ciCart: CIcon = {
  width: '23',
  height: '22',
  paths: [
    {
      d:
        'm20.58 12.947 1.896-8.937c.137-.645-.321-1.26-.939-1.26H5.785L5.417.825C5.326.345 4.932 0 4.474 0H1.462C.931 0 .5.462.5 1.031v.688c0 .57.43 1.031.962 1.031h1.703l2.817 14.757c-.674.415-1.128 1.194-1.128 2.087C4.854 20.923 5.86 22 7.1 22s2.246-1.077 2.246-2.406c0-.674-.259-1.282-.675-1.719h8.408a2.486 2.486 0 0 0-.675 1.719c0 1.329 1.006 2.406 2.246 2.406s2.246-1.077 2.246-2.406c0-.953-.517-1.776-1.267-2.166l.222-1.043c.137-.646-.321-1.26-.939-1.26H8.147l-.262-1.375H19.64c.45 0 .84-.333.939-.803Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'cart',
  type: 'solid'
}
