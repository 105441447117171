import { CIcon } from '~/icons/types'
export const ciContainer: CIcon = {
  width: 512,
  height: 395.5,
  paths: [
    {
      d:
        'M489.9 182.2h-47.5l-159.6-58.1c6.1-6.6 9.9-15.4 9.9-25.1 0-16.7-11.2-30.9-26.5-35.4V54h17c8.5 0 15.4-6.9 15.4-15.4V15.4c.1-8.5-6.8-15.4-15.3-15.4h-54.9C219.9 0 213 6.9 213 15.4v23.1c0 8.5 6.9 15.4 15.4 15.4h17v18.6c0 5.8 4.7 10.5 10.5 10.5 8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5c0 9.7 3.8 18.5 9.9 25.1L69.3 182.2H21.7c-11.6 0-21 9.4-21 21v171.3c0 11.6 9.4 21 21 21h468.1c11.6 0 21-9.4 21-21V203.1c0-11.5-9.4-20.9-20.9-20.9zM60.6 347.7V229.8c0-5.8 4.7-10.5 10.5-10.5s10.5 4.7 10.5 10.5v117.9c0 5.8-4.7 10.5-10.5 10.5s-10.5-4.7-10.5-10.5zm61.6 0V229.8c0-5.8 4.7-10.5 10.5-10.5s10.5 4.7 10.5 10.5v117.9c0 5.8-4.7 10.5-10.5 10.5s-10.5-4.7-10.5-10.5zm61.5 0V229.8c0-5.8 4.7-10.5 10.5-10.5s10.5 4.7 10.5 10.5v117.9c0 5.8-4.7 10.5-10.5 10.5s-10.5-4.7-10.5-10.5zm61.6 0V229.8c0-5.8 4.7-10.5 10.5-10.5s10.5 4.7 10.5 10.5v117.9c0 5.8-4.7 10.5-10.5 10.5s-10.5-4.7-10.5-10.5zm61.6 0V229.8c0-5.8 4.7-10.5 10.5-10.5 5.7 0 10.4 4.7 10.5 10.5v117.9c0 5.8-4.7 10.5-10.5 10.5s-10.5-4.7-10.5-10.5zm61.5 0V229.8c0-5.8 4.7-10.5 10.5-10.5s10.5 4.7 10.5 10.5v117.9c0 5.8-4.7 10.5-10.5 10.5s-10.5-4.7-10.5-10.5zm82.6 0c0 5.8-4.7 10.5-10.5 10.5s-10.5-4.7-10.5-10.5V229.8c0-5.8 4.7-10.5 10.5-10.5s10.4 4.7 10.5 10.5v117.9zM255.8 136.5l125.3 45.6H130.5l125.3-45.6z'
    }
  ],
  name: 'container',
  type: 'solid'
}
