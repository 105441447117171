import { CIcon } from '~/icons/types'
export const ciMeasuringTape: CIcon = {
  name: 'measuring-tape',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M497 286H330V181c0-74.443-74.019-135-165-135S0 106.557 0 181v165c0 39.047 23.791 73.458 60 95.387V361c0-8.291 6.709-15 15-15s15 6.709 15 15v94.828c9.553 3.354 19.537 6.037 30 7.744V391c0-8.291 6.709-15 15-15s15 6.709 15 15v75h30V361c0-8.291 6.709-15 15-15s15 6.709 15 15v105h30v-75c0-8.291 6.709-15 15-15s15 6.709 15 15v75h30V361c0-8.291 6.709-15 15-15s15 6.709 15 15v105h30v-75c0-8.291 6.709-15 15-15s15 6.709 15 15v75h30V361c0-8.291 6.709-15 15-15s15 6.709 15 15v105h47c8.291 0 15-6.709 15-15V301c0-8.291-6.709-15-15-15zm-332-30c-56.909 0-105-34.351-105-75s48.091-75 105-75 105 34.351 105 75-48.091 75-105 75z'
    },
    {
      d:
        'M165 136c-42.231 0-75 24.185-75 45s32.769 45 75 45 75-24.185 75-45-32.769-45-75-45zm30 60h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15z'
    }
  ]
}
