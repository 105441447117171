var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.telephone)?_c('span',[(_vm.telephone.base64)?_c('span',{staticClass:"nowrap"},[(_vm.showLabel)?_c('span',[_vm._v(_vm._s(_vm.$t('tel'))+":")]):_vm._e(),_vm._v(" "),_c('CImg',{class:{ inverted: _vm.invert },attrs:{"lazy":"","width":_vm.width,"height":_vm.height,"src":_vm.telephone.base64,"alt":_vm.$t('telephone')}})],1):(_vm.telephone.telephone)?_c('span',{staticClass:"phone-wrapper",style:({ fontSize: _vm.fontSize })},[_c(_vm.telephoneNumberTag,{tag:"component",staticClass:"phone-digits",class:[_vm.telephoneClasses, { 'has-apps': _vm.shouldShowMessagingApps }],attrs:{"href":_vm.telephoneNumberHref,"target":_vm.isAdmin ? '_blank' : undefined},on:{"click":_vm.handlePhoneClick}},[(_vm.showLabel)?[_vm._v(_vm._s(_vm.$t('tel'))+":")]:_vm._e(),_vm._v(" "),(_vm.telephone.code)?[_vm._v("\n        "+_vm._s(_vm.getTelephoneCode(_vm.telephone.code, _vm.$i18n.locale))+"\n      ")]:_vm._e(),_vm._v(" "),(_vm.disableGoogleIndexing)?_vm._l((_vm.telephone.telephone),function(digit,index){return _c('span',{key:index},[_vm._v(_vm._s(digit))])}):[_vm._v("\n        "+_vm._s(_vm.telephone.telephone)+"\n      ")]],2),(_vm.shouldShowMessagingApps)?_c('span',{staticClass:"messaging-apps"},[(_vm.telephone.social.viber)?_c('a',{staticClass:"messaging-app-badge viber",attrs:{"title":_vm.$t('open {number} on {app}.', {
            number:
              _vm.getTelephoneCode(_vm.telephone.code, _vm.$i18n.locale) +
              _vm.telephone.telephone,
            app: 'Viber'
          }),"href":_vm.phoneService.getViberChatUrl(_vm.telephone.telephone, _vm.telephone.code)},on:{"click":_vm.handleViberClick}},[_c('fa',{attrs:{"icon":_vm.icons.faViber}})],1):_vm._e(),_vm._v(" "),(_vm.telephone.social.whatsapp)?_c('a',{staticClass:"messaging-app-badge whatsapp",attrs:{"title":_vm.$t('open {number} on {app}.', {
            number:
              _vm.getTelephoneCode(_vm.telephone.code, _vm.$i18n.locale) +
              _vm.telephone.telephone,
            app: 'WhatsApp'
          }),"href":_vm.phoneService.getWhatsappChatUrl(_vm.telephone.telephone, _vm.telephone.code)},on:{"click":_vm.handleWhatsAppClick}},[_c('fa',{attrs:{"icon":_vm.icons.faWhatsapp}})],1):_vm._e()]):_vm._e()],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }