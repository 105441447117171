import { LocaleCode } from '~/models/locale/types'

export function defineComponentTranslations(
  translations: Record<string, Partial<Record<LocaleCode, string>>>
) {
  const messages = Object.entries(translations).reduce(
    (
      acc: Record<LocaleCode, Record<string, string>>,
      [translationKey, translations]
    ) => {
      translations.el && (acc.el[translationKey] = translations.el)
      translations.en && (acc.en[translationKey] = translations.en)
      translations.de && (acc.de[translationKey] = translations.de)
      translations.bg && (acc.bg[translationKey] = translations.bg)

      return acc
    },
    { en: {}, el: {}, de: {}, bg: {} }
  )

  return { messages }
}
