import { UserCredits } from '~/models/user/types'

export enum PromotedTier {
  TURBO = 'turbo',
  SUPER = 'super',
  TOP = 'top'
}

export interface PromotionTier {
  days: number
  neededCredits?: number
  cost: number
  canBeBought: boolean
  tier: string
  buyCreditsLink?: string
  daysInfo?: string
}

export interface ActivePromotion {
  days: number
  tierDays?: number
  neededCredits?: number
  cost: number
  canBeBought?: boolean
  tier: string
  buyCreditsLink?: string
  expires?: string
  isPromoted?: boolean
  stats?: PromoteStats
  started?: string
  ended?: string
}

export interface Promotion {
  activePromotion?: ActivePromotion
  available: boolean
  notAvailableForUser?: boolean
  notAvailableForCategory?: boolean
  previousPromotions?: ActivePromotion[]
  previousPromotionsCount?: number
}

export interface PromoteInfoResult {
  availableTiers: PromotionTier[]
  promotion?: Promotion
  availablePromotions?: number
  status: number
  message?: string
  preferredPayment: PromoPaymentType
}

export interface PromoteStatsTotals {
  impressions: number
  clicks: number
}

export interface PromotePostResult {
  availableCredits: UserCredits
  promotion: Promotion
  status: number
  message?: string
  session?: string
  url?: string
}

export interface PromoteStats {
  plot: {
    xAxis: any
    yAxles: any
  }
  total: PromoteStatsTotals
}

export interface PromoUserStatus {
  userEnabled: boolean
}

export interface PromoClassifiedStatus {
  classifiedEnabled: boolean
}
export type PromoPaymentType = 'stripe' | 'credits'
