



















import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  extends: BaseSearchHandler,
  data() {
    return {
      selectedValues: null
    }
  },
  computed: {
    extrasOptions() {
      return this.searchables.map(mapBackendSearchableToOption)
    }
  },
  methods: {
    onBackendValues({ selected }) {
      this.selectedValues = selected
    },
    handleExtrasChange(value) {
      this.emitParams([{ name: this.defaultUrlArgName, value }])
    }
  }
})
