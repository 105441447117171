import { CIcon } from '~/icons/types'
export const ciJeepSuv: CIcon = {
  name: 'jeep-suv',
  type: 'regular',
  width: 512,
  height: 269.8,
  paths: [
    {
      d:
        'M505.5 101c-.7-2.2-2.1-4.1-4-5.4C499 93.9 476.6 78.7 460 78c-12.2-.5-73-2.9-88.4-3.5l-70.1-58.4C289.9 6.5 275.3 1 260.2.6c-97.9-3-196.4 6.9-197.4 7L38 10.2c-5.8.6-9.9 5.8-9.3 11.6.6 5.4 5.1 9.4 10.4 9.4.4 0 .7 0 1.1-.1l2.7-.3-30.7 46.8c-1.1 1.6-1.7 3.5-1.7 5.4l-1.3 40.2c-1.4 4.6-5.7 19.1-7.2 33.1-1.7 16.2 3.9 41.3 6.4 51.4 1.2 4.7 5.4 8 10.2 8H47c5.1 30.7 31.9 54.2 64 54.2s58.9-23.5 64-54.2h154c5.1 30.7 31.9 54.2 64 54.2s58.9-23.5 64-54.2h38.7c5.2 0 9.6-3.8 10.4-9 .2-3 10.1-72.4-.6-105.7zM259.6 21.5c10.4.3 20.5 4.1 28.4 10.7l50.4 42-115.4-.4-7.2-52.8c14.4 0 29.1.1 43.8.5zm-142.7 3L86.8 71.3c-.4.6-.7 1.2-1 1.9L40.3 73l29.6-45c7.9-.7 24.6-2.1 47-3.5zm25.8-1.5c15.9-.8 33.5-1.4 52-1.8l7.1 52.4-91.3-.4L142.7 23zm-31.8 225.8C86.7 248.8 67 229.1 67 205c0-24.2 19.7-43.9 43.9-43.9s43.9 19.7 43.9 43.9c0 24.1-19.7 43.8-43.9 43.8zm281.9 0c-24.2 0-43.9-19.7-43.9-43.9s19.7-43.9 43.9-43.9 43.9 19.7 43.9 43.9-19.7 43.9-43.9 43.9zm93.4-54.1h-29.4c-4.9-30.9-31.8-54.6-64.1-54.6s-59.1 23.7-64.1 54.6H175c-4.9-30.9-31.8-54.6-64.1-54.6s-59.1 23.7-64.1 54.6h-20c-2.7-12.6-5-27.5-4.1-36.1 1.5-14.4 6.7-30.1 6.7-30.3.3-1 .5-1.9.5-3l1-31.4 336.2 1.4c4 .2 78.2 3 91.8 3.6 6.7.3 18.9 6.5 27.3 11.8 5.6 21.8 2.6 61.9 0 84z'
    }
  ]
}
