import { CIcon } from '~/icons/types'
export const ciKey: CIcon = {
  name: 'key',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M281.293 242.637c18.7 0 36.277-7.282 49.5-20.5 16.781-16.782 23.227-40.035 19.371-61.801l26.125-26.121a10.009 10.009 0 0 0 2.93-7.074V103l8.285-8.285h24.14a10 10 0 0 0 7.07-2.93l11.075-11.074a10 10 0 0 0 2.93-7.07V31.215c0-2.653-1.055-5.2-2.93-7.074L408.574 2.93a9.996 9.996 0 0 0-14.14 0l-24.055 24.054c-3.906 3.907-3.906 10.239 0 14.145 3.906 3.902 10.234 3.902 14.14 0l16.985-16.984 11.215 11.21V69.5l-5.215 5.215h-24.145a10 10 0 0 0-7.07 2.93l-14.144 14.14a10.006 10.006 0 0 0-2.93 7.07V123l-16.352 16.352a69.727 69.727 0 0 0-12.07-16.211 70.272 70.272 0 0 0-16.188-12.098l13.348-13.348c3.902-3.902 3.902-10.234 0-14.14-3.906-3.907-10.238-3.907-14.144 0l-20.18 20.18a71.015 71.015 0 0 0-12.336-1.098c-18.7 0-36.277 7.281-49.496 20.504-27.297 27.293-27.297 71.703 0 98.996 13.223 13.218 30.8 20.5 49.496 20.5zM245.938 137.28c9.445-9.445 22.003-14.644 35.355-14.644 4.34 0 8.59.558 12.676 1.62l.043.013c8.488 2.214 16.273 6.644 22.636 13.011 6.372 6.371 10.801 14.16 13.016 22.649.004.007.004.02.008.027a50.195 50.195 0 0 1 1.621 12.68c0 13.355-5.2 25.914-14.645 35.355-9.441 9.446-22 14.645-35.355 14.645-13.352 0-25.91-5.2-35.355-14.645-9.442-9.445-14.645-22-14.645-35.355s5.203-25.91 14.645-35.356zm0 0'
    },
    {
      d:
        'M259.754 193.508a9.968 9.968 0 0 0 7.398 3.273c2.399 0 4.809-.86 6.727-2.601 4.086-3.715 4.387-10.043.668-14.13-3.715-4.085-10.04-4.386-14.125-.671s-4.387 10.039-.672 14.125zm250.805 124.187c-4.32-13.43-19.164-25.293-41.551-19.61-.059.017-.113.028-.172.044l-91.527 24.96c-1.625-6.542-5.368-12.316-10.778-16.527-6.347-4.94-14.21-7.113-22.136-6.117-5.352.672-19.778 2.766-35.055 4.985-14.504 2.101-29.504 4.28-34.656 4.93-11.825 1.484-25.723-4.118-40.446-10.048-23.34-9.398-51.558-20.757-82.234-7.152v-3.156c0-5.524-4.477-10-10-10H62c-5.52 0-10 4.476-10 10H10c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10h42v120H10c-5.523 0-10 4.48-10 10 0 5.523 4.477 10.004 10 10.004h42c0 5.52 4.48 10 10 10h80.004c5.523 0 10-4.48 10-10v-10.442l13.344.086h.062c5.492 0 9.965-4.437 10-9.937.035-5.524-4.414-10.031-9.937-10.063l-13.47-.086v-103.75c26.185-16.492 49.817-6.996 74.763 3.051 16.441 6.621 33.433 13.469 50.41 11.336 5.344-.672 19.765-2.766 35.035-4.98 14.512-2.106 29.516-4.286 34.672-4.934 2.625-.328 5.234.402 7.36 2.055a10.212 10.212 0 0 1 3.89 6.836c.219 1.77.8 6.476-11.73 12.289-26.532 12.304-54.801 22.84-77.563 28.894-5.34 1.422-8.512 6.899-7.094 12.239 1.422 5.335 6.899 8.511 12.238 7.09 23.832-6.344 53.297-17.305 80.832-30.079 9.575-4.441 15.461-9.61 18.938-14.82l100.254-27.34c10.547-2.648 15.957 1.547 17.508 6.363 1.527 4.746-.313 10.856-7.86 13.832-7.847 2.993-26.582 11.239-50.289 21.672-31.41 13.82-70.5 31.028-101.668 43.672-37.047 15.024-48.344 17.254-86.258 17.156h-.03c-5.513 0-9.985 4.457-10 9.97-.02 5.523 4.444 10.01 9.968 10.03.82 0 1.629.004 2.426.004 38.61 0 52.863-2.992 91.41-18.629 31.441-12.753 70.68-30.023 102.21-43.898 22.571-9.934 42.063-18.512 49.4-21.305l.093-.035c18.684-7.324 24.023-24.957 19.64-38.59zM72.004 440.004v-140h60v140.004h-60zm0 0'
    },
    {
      d:
        'M102.004 399.996c-5.524 0-10 4.477-10 10v.008c0 5.523 4.476 9.996 10 9.996 5.523 0 10-4.48 10-10.004 0-5.523-4.477-10-10-10zm247.16-327.652a9.968 9.968 0 0 0 7.07-2.93l.004-.004c3.907-3.906 3.903-10.234-.004-14.14-3.902-3.907-10.238-3.903-14.144.003-3.903 3.903-3.903 10.235 0 14.141a9.976 9.976 0 0 0 7.074 2.93zm-143.75 347.558h-.008c-5.523 0-9.996 4.477-9.996 10 0 5.524 4.48 10 10.004 10 5.524 0 10-4.476 10-10 0-5.523-4.476-10-10-10zm0 0'
    }
  ]
}
