import { CIcon } from '~/icons/types'
export const ciCalendar: CIcon = {
  name: 'calendar',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M403.03 35.46v57.96h-30V35.46H138.97v57.96h-30V35.46H0v98.5h512v-98.5zM0 163.96V504.5h512V163.96H0zm115.95 288.38H74.67v-30h41.28v30zm0-68.39H74.67v-30h41.28v30zm0-68.39H74.67v-30h41.28v30zm79.01 136.78h-41.29v-30h41.29v30zm0-68.39h-41.29v-30h41.29v30zm0-68.39h-41.29v-30h41.29v30zm0-68.39h-41.29v-30h41.29v30zm80.79 205.17h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zm80.79 205.17h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zm80.79 205.17h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zm0-68.39h-41.28v-30h41.28v30zM373.03 7.5h30v27.96h-30zm-264.06 0h30v27.96h-30z',
      fill: 'currentColor'
    }
  ]
}
