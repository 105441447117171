







































import { defineComponent } from '@nuxtjs/composition-api'
import SearchbarFilterSuggestion from '~/components/shared/classified/search/searchbar/suggestions/SearchbarFilterSuggestion.vue'
import SearchbarAutocompleteSuggestion from '~/components/shared/classified/search/searchbar/suggestions/SearchbarAutocompleteSuggestion.vue'
import SearchbarLegacySuggestion from '~/components/shared/classified/search/searchbar/suggestions/SearchbarLegacySuggestion.vue'
import SearchbarSuggestedClassified from '~/components/shared/classified/search/searchbar/suggestions/SearchbarSuggestedClassified.vue'
import SearhcbarMoreLink from '~/components/shared/classified/search/searchbar/suggestions/SearhcbarMoreLink.vue'
import { SuggestionType } from '~/models/search-bar'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'

export default defineComponent({
  components: {
    SearhcbarMoreLink,
    SearchbarSuggestedClassified,
    SearchbarLegacySuggestion,
    SearchbarAutocompleteSuggestion,
    SearchbarFilterSuggestion
  },
  props: {
    suggestion: { type: Object, required: true },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: true
    },
    inGenericSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    currentQ: {
      type: String,
      required: true
    }
  },
  watch: {
    active(val, oldVal) {
      if (oldVal === false && val === true) {
        if (this.isAutocomplete && !this.suggestion.raw) {
          const autocompleteComponent = this.$refs.autocomplete
          if (autocompleteComponent)
            this.$emit(
              'suggestion-active',
              autocompleteComponent.beforeTyped +
                autocompleteComponent.typedQ +
                autocompleteComponent.afterTyped
            )
        } else {
          this.$emit('suggestion-active')
        }

        this.scrollService.scrollIfOverflownVertical(
          this.$el,
          this.$parent.$refs.suggestions
        )
      }
    }
  },
  computed: {
    ...mapDeps({
      scrollService: ScrollService
    }),
    isAutocomplete(): boolean {
      return this.suggestion.type === SuggestionType.AUTOCOMPLETE
    },
    isFilter(): boolean {
      return this.suggestion.type === SuggestionType.FILTER
    },
    isLegacy(): boolean {
      return this.suggestion.type === SuggestionType.LEGACY
    },
    isClassified(): boolean {
      return this.suggestion.type === SuggestionType.CLASSIFIED
    },
    isMore(): boolean {
      return this.suggestion.type === SuggestionType.MORE
    },
    suggestionUrl() {
      if (this.isClassified) {
        return this.suggestion.seo_url || `/${this.suggestion.id}`
      }
      return (
        this.suggestion.url ||
        this.suggestion.seoUrl ||
        this.suggestion.data?.url ||
        ''
      )
    }
  },
  methods: {
    suggestionClicked(e: Event) {
      e.preventDefault()
      e.stopPropagation()

      this.$emit('clicked')
    },
    triggerClassified() {
      const clsfdComponent = this.$refs.classified
      if (!this.isClassified || !clsfdComponent) {
        return
      }
      clsfdComponent.triggerThumbClick(null)
    }
  }
})
