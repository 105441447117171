import { CIcon } from '~/icons/types'
export const ciCabrio: CIcon = {
  name: 'cabrio',
  type: 'regular',
  width: 512,
  height: 218.1,
  paths: [
    {
      d:
        'M509.9 135.7c-4.4-20.8-24.1-39.8-55.5-53.4-32.2-14-74-21.4-121.1-21.4-5.5-4-10.7-8.1-15.9-12.1-21.3-16.5-43.2-33.5-79.4-48-5.4-2.2-11.5.5-13.6 5.8-2.2 5.4.5 11.5 5.8 13.6 30.5 12.2 49.7 26.1 68.9 40.8H200l-5.8-11.3c-2.8-5.5-8.7-8.8-14.8-8.2-6.2.6-11.3 4.8-13.1 10.7l-2.6 8.8h-19c-6.2 0-12.1-1.5-17.8-4.3-.6-.3-1.3-.6-1.9-1-13-5.9-28-7.6-42.3-4.6l-44.5 9.3C22.6 63.7 10.8 76.3 8.9 91.8c-.1.6-.1 1.2-.2 1.8-.3 3.6-.3 7.4-.4 11.3 0 1.9-.1 3.8-.1 5.8-4.6 5.4-6.3 13.4-6.3 19.7v22.2c0 19.9 11.6 33.2 28.9 33.2h21.6c8.4 19 27.4 32.2 49.4 32.2s41-13.3 49.4-32.2h177.1c8.4 19 27.4 32.2 49.4 32.2 22.1 0 41.1-13.3 49.4-32.3.4 0 .8.1 1.2.1.3 0 26.5-.1 34.4-.7 20.3-1.6 47.2-5.8 47.2-24.6v-22.7c.2-.7.1-1.4 0-2.1zm-408.1 61.4c-18.2 0-33-14.8-33-33s14.8-33 33-33 33 14.8 33 33-14.8 33-33 33zm276 0c-18.2 0-33-14.8-33-33s14.8-33 33-33 33 14.8 33 33-14.8 33-33 33zm111.3-38.6c-2.2 1.3-9.2 4.2-27.8 5.7-5.2.4-21.6.6-29.5.6v-.7c0-29.8-24.2-54-54-54s-54 24.2-54 54v.8h-168v-.8c0-29.8-24.2-54-54-54s-54 24.2-54 54v.8h-17c-5.2 0-7.9-4.1-7.9-12.2v-22.2c0-2.5.7-4.9 1.2-5.9 2.9-1.8 4.7-4.9 4.9-8.3.2-3.9.3-7.7.3-11 0-3.6.1-7 .3-10 0-.3.1-.6.1-.9.8-6.6 5.9-11.9 12.7-13.3L87 71.7c9.9-2.1 20.2-1 28.8 3.1.5.2.9.5 1.4.7 8.5 4.3 17.9 6.6 27.3 6.6h185.3c.8 0 1.5-.1 2.2-.2 56.4-.1 93 10.6 113.9 19.7 23.7 10.3 39.7 24.3 43.1 37.5v19.4z'
    }
  ]
}
