import { CIcon } from '~/icons/types'
export const ciAccounting: CIcon = {
  name: 'accounting',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M0 30h90v91H0zm90 379.545V151H0v258.545l45 91zM482 436H211V76h271V0H120v512h362z'
    },
    {
      d:
        'M331 256h60v60h-60zm0 90h60v60h-60zm-90-240h271v120H241zm0 150h60v60h-60zm180 0h91v150h-91zm-180 90h60v60h-60z'
    }
  ]
}
