import { CIcon } from '~/icons/types'
export const ciAngleRightLight: CIcon = {
  name: 'angle-right-light',
  type: 'solid',
  width: 192,
  height: 512,
  paths: [
    {
      d:
        'm166.9 264.5-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z',
      fill: 'currentColor'
    }
  ]
}
