









































import { mapBackendSearchableToOption } from '~/services/search/formatters'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { Option } from '~/models/shared/types'
import { mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CCount from '~/components/shared/configurable/CCount.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import { ciExternalLink } from '~/icons/source/regular/external-link'

export default defineComponent({
  components: {
    COptionWithCount,
    CCount
  },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedValues: []
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, ['inVehiclesSearch']),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      isMapSearch: state => state.flags.isMapSearch
    }),
    icons: () => ({
      externalLink: ciExternalLink
    }),
    options(): Option[] {
      return (this.searchables || []).map(mapBackendSearchableToOption)
    },
    optionsFiltered(): Option[] {
      return this.options.filter(o => !this.isVehicleRentalOption(o))
    },
    hasRentalOption(): boolean {
      return Boolean(
        this.options.filter(o => this.isVehicleRentalOption(o)).length
      )
    },
    rentalsSearchUrl() {
      // TODO: make dynamic, from backend maybe
      return '/rentals'
    }
  },
  methods: {
    isVehicleRentalOption(option: Option): boolean {
      return option.value === 'rent'
    },
    onClear() {
      this.selectedValues = null
      this.emitParams([{ name: this.defaultUrlArgName, value: null }])
    },
    onBackendValues({ selected }) {
      this.selectedValues = selected
    },
    handleValueChange(value) {
      this.emitParams([{ name: this.defaultUrlArgName, value }])
    }
  }
})
