import { CIcon } from '~/icons/types'
export const ciExchange: CIcon = {
  name: 'exchange',
  type: 'light',
  width: 512.422,
  height: 512.422,
  paths: [
    {
      d:
        'M41.053 223.464c2.667 1.067 5.76 1.067 8.427-.213l83.307-37.867c5.333-2.56 7.573-8.96 5.013-14.293-2.453-5.12-8.533-7.467-13.76-5.12l-58.347 26.56C93.533 109.224 171.08 53.544 260.36 53.544c93.547 0 175.36 62.507 198.933 152 1.493 5.653 7.36 9.067 13.013 7.573 5.653-1.493 9.067-7.36 7.573-13.013-26.027-98.773-116.267-167.893-219.52-167.893-98.453 0-184.107 61.44-215.04 153.387l-24.533-61.333c-1.813-5.547-7.893-8.64-13.44-6.827-5.547 1.813-8.64 7.893-6.827 13.44.107.427.32.853.533 1.28l34.027 85.333c1.067 2.667 3.2 4.907 5.974 5.973zm470.72 157.44c-.107-.213-.213-.427-.213-.64l-34.027-85.333c-1.067-2.667-3.2-4.907-5.973-5.973-2.667-1.067-5.76-.96-8.427.213l-83.307 37.867c-5.44 2.24-8 8.533-5.76 13.973 2.24 5.44 8.533 8 13.973 5.76l.64-.32 58.347-26.56c-28.053 83.307-105.707 138.987-194.88 138.987-93.547 0-175.36-62.507-198.933-152-1.493-5.653-7.36-9.067-13.013-7.573-5.653 1.493-9.067 7.36-7.573 13.013 25.92 98.88 116.267 167.893 219.52 167.893 98.453 0 184-61.44 215.04-153.387l24.533 61.333c2.027 5.547 8.107 8.427 13.653 6.4 5.546-2.026 8.426-8.106 6.4-13.653z',
      fill: 'currentColor'
    }
  ]
}
