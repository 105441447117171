import { CIcon } from '~/icons/types'
export const ciGokart: CIcon = {
  width: 512,
  height: 230.1,
  paths: [
    {
      d:
        'M261.2 95c2.7 0 5.4-1 7.4-3.1l13-13 41.5 49.8c5.8-4.3 12.2-7.7 19-10l-1.6-2-44-52.7 14.3-14.3c4.1-4.1 4.1-10.7 0-14.8s-10.7-4.1-14.8 0l-42.2 42.2c-4.1 4.1-4.1 10.7 0 14.8 2.1 2.1 4.7 3.1 7.4 3.1zm196.6 42.6-2.2-4.5C442 103.7 418.8 80.3 390.1 67c0 0-.1-.1-.2-.1-1-.4-1.9-.9-3-1.3l-45.5-19.8c-5.3-2.3-11.5.1-13.8 5.4s.1 11.5 5.4 13.8l45.7 19.9c.1 0 .2.1.3.1.8.3 1.6.7 2.4 1 22.7 10.5 41.4 28.8 53.1 51.5l-21-.5c11.2 12.1 18.1 28.3 18.1 46 0 9.9-2.2 19.4-6 27.9h64.2c5.8 0 10.5-4.7 10.5-10.5v-20.3c0-23.4-19.1-42.5-42.5-42.5zM73.9 118.2V63.5C56.1 67.8 43.2 81 43.2 96.6v19.3c3.4-.5 6.9-.8 10.4-.8 7 .1 13.9 1.1 20.3 3.1zm133 7.5c-29.9-26.7-55.7-58.2-77-94L114.2 5.2c-2.4-4.1-7.2-6-11.8-4.8-4.2 1-7.1 4.6-7.6 8.8v119.7h115.8c-1.1-.9-2.4-2.1-3.7-3.2zm97 24.2h-191c5.5 9.9 8.7 21.2 8.7 33.2 0 4.8-.5 9.5-1.5 14l8.9 10.1c2 2.3 4.9 3.6 7.9 3.6h164.8c-4-8.7-6-18.2-6-27.9 0-11.6 3-23 8.7-33h-.5z'
    },
    {
      d:
        'M363.7 136.1c-15.8 0-29.6 7.7-38.1 19.6-.1.3-.4.5-.5.8-5.2 7.6-8.3 16.7-8.3 26.6s3.1 19 8.3 26.6c.1.3.4.5.5.8 8.5 11.9 22.4 19.6 38.1 19.6 25.9 0 46.9-21.1 46.9-46.9s-21-47.1-46.9-47.1zm-352 46.5c0 26.1 21.3 47.5 47.5 47.5 16 0 30.1-7.8 38.6-19.8.1-.3.4-.5.5-.8 5.3-7.7 8.4-16.9 8.4-26.9s-3.1-19.2-8.4-26.9c-.1-.3-.4-.5-.5-.8-8.6-12-22.6-19.8-38.6-19.8-26.1-.2-47.5 21.3-47.5 47.5z'
    }
  ],
  name: 'gokart',
  type: 'solid'
}
