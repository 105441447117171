





import { defineComponent } from '@nuxtjs/composition-api'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'

export default defineComponent({
  components: { CBadge }
})
