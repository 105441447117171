import { CIcon } from '~/icons/types'
export const ciSteeringLights: CIcon = {
  width: 512,
  height: 368.9,
  paths: [
    {
      d:
        'M283.31 49.68C278 39.72 270.87 30.21 262.07 23A77.21 77.21 0 0 0 229 6.85a100.14 100.14 0 0 0-20.13-2.11c-6.33 0-12.4 5.58-12.11 12.11s5.36 12.09 12.15 12.15a92.86 92.86 0 0 1 10 .57 70.5 70.5 0 0 1 14.68 4A64.47 64.47 0 0 1 244.78 40a71.29 71.29 0 0 1 10.35 10.42 101.43 101.43 0 0 1 11.35 19.89c6.23 15.32 9.74 31.64 12 48a470.78 470.78 0 0 1 3.53 51.45c.36 18 .17 36-.91 54-.56 9.41-1.41 18.8-2.63 28.15-2.31 16.31-5.82 32.61-12 47.91a101.09 101.09 0 0 1-11.34 19.88 71.29 71.29 0 0 1-10.35 10.42 64.47 64.47 0 0 1-11.18 6.54 69.92 69.92 0 0 1-14.6 3.92 100.52 100.52 0 0 1-23.64-.29c-15-2.22-29.67-6.8-43.71-12.57a278.5 278.5 0 0 1-47.86-26.41c-4.11-2.82-8.14-5.77-12.1-8.8a249.19 249.19 0 0 1-33.5-31.41c-2.56-2.91-5-5.89-7.4-8.93a158.3 158.3 0 0 1-19.08-32.06 110.08 110.08 0 0 1-6.87-24.6 93 93 0 0 1 0-20.9A111 111 0 0 1 31.71 150a145.85 145.85 0 0 1 8.11-15.84c3.22-5.46 1.42-13.52-4.34-16.57s-13.13-1.45-16.57 4.41C9 138.84 2.15 157.34.37 176.91a104.73 104.73 0 0 0 4.13 38.63C11.88 240.7 27 263 44.61 282.15c18.43 20 40.39 37 63.78 50.74a290 290 0 0 0 36.52 18.05 203.76 203.76 0 0 0 33.59 10.78c23.8 5.26 49.39 5.77 71.36-6.17s35-34.11 42.84-57a214.43 214.43 0 0 0 8.81-37.77 434 434 0 0 0 4.26-44.78c1.33-29.23 1.11-58.81-1.88-87.93-2.76-26.85-7.65-54.36-20.58-78.39Z'
    },
    {
      d:
        'M59.88 101.68a12.71 12.71 0 0 0 8.56-3.55 262.94 262.94 0 0 1 23.68-20.82A285.22 285.22 0 0 1 137.49 49c.87-.44 1.74-.86 2.62-1.29q-3.24 13-6.47 26.06l-1.77 7.15a11.7 11.7 0 0 0 1.3 8.6 11.55 11.55 0 0 0 7 5.17c6 1.21 12.23-2.11 13.76-8.3l4.86-19.57 7.74-31.19 1.77-7.15a11.23 11.23 0 0 0-3.71-11.39c-.19-.17-.38-.33-.58-.48l-.14-.1c-.24-.17-.47-.34-.72-.49a9.55 9.55 0 0 0-3.15-1.34l-19.57-4.86-31.19-7.73-7.15-1.78a11.75 11.75 0 0 0-8.6 1.3 11.55 11.55 0 0 0-5.17 7c-1.21 6 2.11 12.23 8.3 13.77l19.57 4.85 7.29 1.77a299.42 299.42 0 0 0-53.77 35.4A258.341 258.341 0 0 0 51.32 81a12.45 12.45 0 0 0-3.55 8.56 12.32 12.32 0 0 0 3.55 8.56 12.17 12.17 0 0 0 8.56 3.56Zm303.9-32.91L377 73.12l31.64 10.44 38.22 12.64L480 107.12c5.36 1.77 10.7 3.64 16.09 5.32l.23.07a12.11 12.11 0 0 0 6.44-23.35l-13.17-4.35-31.69-10.44-38.27-12.64-33.09-10.92c-5.36-1.77-10.7-3.64-16.09-5.32l-.23-.07a12.11 12.11 0 0 0-6.44 23.35Zm139.41 105.47L490 169.89l-31.63-10.44-38.27-12.64-33.1-10.92c-5.36-1.77-10.7-3.64-16.09-5.32l-.22-.07a12.11 12.11 0 1 0-6.44 23.35l13.17 4.35 31.63 10.44 38.28 12.64 33.09 10.92c5.36 1.77 10.7 3.64 16.09 5.32l.23.07a12.11 12.11 0 1 0 6.43-23.35Zm0 85.57L490 255.46 458.39 245l-38.27-12.63L387 221.45c-5.36-1.77-10.7-3.64-16.09-5.31l-.22-.08a12.11 12.11 0 1 0-6.44 23.35l13.17 4.35 31.63 10.44 38.28 12.64 33.09 10.93c5.36 1.77 10.7 3.64 16.09 5.31l.23.08a12.11 12.11 0 1 0 6.43-23.35Zm0 85.3L490 340.76l-31.63-10.45-38.27-12.63-33.1-10.93c-5.36-1.77-10.7-3.64-16.09-5.31l-.22-.08a12.11 12.11 0 1 0-6.44 23.35l13.17 4.35 31.63 10.44 38.28 12.64 33.09 10.93c5.36 1.77 10.7 3.64 16.09 5.31l.23.07a12.1 12.1 0 1 0 6.43-23.34Z'
    }
  ],
  name: 'steering-lights',
  type: 'regular'
}
