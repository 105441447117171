import { CategoryId } from '~/models/category/types'
export const categoryInAllTexts: {
  [categoryId: number]: string
} = Object.freeze({
  [CategoryId.XYMA]: 'in all xyma',
  [CategoryId.PLOT]: 'in plot.gr',
  [CategoryId.PARTS]: 'in all parts',
  [CategoryId.JOBS]: 'in all jobs',
  [CategoryId.CARS]: 'in all cars',
  [CategoryId.BIKES]: 'in all bikes',
  [CategoryId.BOATS]: 'in all boats',
  [CategoryId.BICYCLES]: 'in all bicycles',
  [CategoryId.TRUCKS]: 'in all trucks',
  [CategoryId.TRAILERS]: 'in all trailers',
  [CategoryId.TAXIS]: 'in all taxis',
  [CategoryId.CARAVANS]: 'in all caravans',
  [CategoryId.MOTORHOMES]: 'in all motorhomes',
  [CategoryId.RADIOCONTROLS]: 'in all radiocontrols',
  [CategoryId.GOKARTS]: 'in all gokarts',
  [CategoryId.WATERSPORTS]: 'in all watersports',
  [CategoryId.SNOWSPORTS]: 'in all snowsports',
  [CategoryId.AIRSPORTS]: 'in all airsports',
  [CategoryId.VANS]: 'in all vans',
  [CategoryId.BUSES]: 'in all buses',
  [CategoryId.BUILDERS]: 'in all builders',
  [CategoryId.SEMITRUCKS]: 'in all semitrucks',
  [CategoryId.SEMITRAILERS]: 'in all semitrailers',
  [CategoryId.TRACTORS]: 'in all tractors',
  [CategoryId.FORKLIFTS]: 'in all forklifts'
})
