import { CIcon } from '~/icons/types'
export const ciPowerWindows: CIcon = {
  width: 426.17,
  height: 512,
  paths: [
    {
      d:
        'M426.1 260.39v-62.36c0-45.67.05-92.91-.06-139.36C426 23.75 404.85.21 373.48.09 342.27 0 310.53 0 279.84 0h-57.56C197.71 0 176.75 9.59 160 28.47c-36.67 41.18-73.76 83.23-109.62 123.9q-17.62 20-35.27 40c-10 11.3-15 24.91-15 40.47v64.54C0 348.84 0 402.08.17 454.43c.15 34.35 21.19 57.45 52.36 57.48 104.39.12 212.3.12 320.72 0 32.12 0 52.88-23.24 52.9-59.13V260.39ZM77.86 166.27c17.37-19.74 34.2-38.86 51-58q8.34-9.44 16.71-18.83c10.46-11.77 21.28-23.93 31.75-36.07C189.76 39 205.11 31.74 223 31.72c44.25-.06 96.55-.1 148.9 0 17.4 0 26.3 9 26.43 26.59.26 33.76.27 68.09.28 101.28v30.47a20.09 20.09 0 0 1-.47 3.9c-.1.56-.21 1.15-.31 1.81l-.52 3.46H48.83ZM398.68 448.9c0 22.86-7.78 31.37-28.67 31.37H56.85c-21.27 0-28.81-8.12-28.82-31V262.36c0-21.92 7.65-30.46 27.23-30.47 111.64-.06 223.44 0 328.38 0 2.26 0 4.46.17 7 .37l4.23.3 3.81.24v71.45q.01 72.31 0 144.65Z'
    },
    {
      d:
        'M338.72 280.35c-21.76-.29-44.44-.3-69.35 0-10 .1-16.05 6-16.08 15.81 0 9.27 6.45 15.6 16.11 15.73 8 .12 16.2.09 24.11.06h21.2c7.87 0 16 .06 24-.08 9.18-.16 15.25-6.14 15.46-15.25.11-5-1.28-9.08-4-11.92s-6.65-4.29-11.45-4.35Z'
    }
  ],
  name: 'power-windows',
  type: 'regular'
}
