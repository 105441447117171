import { CIcon } from '~/icons/types'
export const ciBreak: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M282.5 185q40.5 0 69 28.5t28.5 69-28.5 69T282 380q-26 0-48.5-13T198 331.5 185 282q0-40 28.5-68.5t69-28.5zm0 177q33.5 0 56.5-23t23-56.5-23-56.5-56.5-23-56.5 23-23 56.5 23 56.5 56.5 23zm0-124q18.5 0 31.5 13t13 31.5-13 31.5-31.5 13-31.5-13-13-31.5 13-31.5 31.5-13zm-.5 71q12 0 19.5-7.5t7.5-19-7.5-19-19-7.5-19 7.5T256 282q0 8 3.5 14t9.5 9.5 13 3.5zm.5 97q8.5 0 8.5 9t-8.5 9-8.5-9 8.5-9zm0 44q8.5 0 8.5 9t-8.5 9-8.5-9 8.5-9zm0-291q-8.5 0-8.5-9t8.5-9 8.5 9-8.5 9zM340 60q75 20 123.5 82T512 282q0 95-67.5 162.5T282 512q-78 0-140-48.5T60 340l-4 3q-2 3-5.5 3t-6.5-3Q0 299 0 237.5T44 132l88-88Q176 0 237 0q19 0 37 4 3 0 6 2 17 5 32 14 1 0 3 2 15 9 28 22 3 3 3 6.5t-3 5.5zM35 304q7 11 15 21l7-7 2-2L316 59l9-9q-7-6-15-11l-42 42q-2 3-5.5 3t-6.5-3q-6-6 0-12l38-39q-8-4-17-7l-70 71q-3 2-6.5 2t-6.5-2q-6-7 0-13l62-62q-9-1-19-1-54 0-93 38l-88 88q-38 39-38 93v10l58-58q6-6 12 0t0 12l-60 60q-2 3-6 3h-2q3 12 7 23l29-29q7-6 13 0t0 12zm247 190q88 0 150-62t62-150q0-75-47.5-133.5T325 75L75 325q15 74 73.5 121.5T282 494zm177-220q9 0 9 8.5t-9 8.5-9-8.5 9-8.5zm-44 0q9 0 9 8.5t-9 8.5-9-8.5 9-8.5zm-232 96q6-6 12 0t0 12-12 0 0-12zm-31 31q5-7 12 0 6 6 0 12t-12 0 0-12zm261-237q-6 6-12 0t0-12q2-3 6-3t6 3q6 6 0 12zm-43 19q6-6 12 0t0 12-12 0 0-12zm31 218q6-6 12 0 2 1 2.5 3.5t0 5-2.5 3.5q-6 6-12 0t0-12zm-31-31q6-6 12 0t0 12-12 0 0-12z',
      fill: 'currentColor'
    }
  ],
  name: 'break',
  type: 'light'
}
