






































import CClassifiedThumbnail from '~/components/shared/configurable/classified/image/CClassifiedThumbnail.vue'
import { mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
import CPrice from '~/components/shared/configurable/CPrice.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { CPrice, CClassifiedThumbnail },
  props: {
    classified: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapDeps({
      scrollService: ScrollService
    }),
    classifiedURL() {
      return this.classified.seo_url || `/${this.classified.id}`
    },
    mainPrice() {
      if (this.classified.price) {
        return this.classified.price
      }
      if (this.classified.payment) {
        if (this.classified.payment.label) {
          return (
            this.classified.payment.value + ' ' + this.classified.payment.label
          )
        }
        return this.classified.payment.value
      }

      if (this.classified.salary) {
        if (this.classified.salary.label) {
          return (
            this.classified.salary.value + ' ' + this.classified.salary.label
          )
        }
        return this.classified.salary.value
      }

      return null
    }
  },
  watch: {
    active(val, oldVal) {
      if (oldVal === false && val === true) {
        this.$emit('suggestion-active')
        this.scrollService.scrollIfOverflownVertical(
          this.$el,
          this.$parent.$parent.$refs.suggestions
        )
      }
    }
  },
  methods: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      rowsExtraQueryParams: state => state.config.settings.rowsExtraQueryParams
    }),
    hrefClick(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$emit('classified-clicked')
      return false
    },
    triggerThumbClick(e) {
      this.$refs.thumb.imgClicked(e)
    },
    classifiedClicked(evt, refData = {}) {
      // remove this hack when https://github.com/vuejs/vue-router/issues/703 is resolved

      // this.$analytics.recordEvent({
      //   namespace: this.inRecents
      //     ? 'n_index_feed_recent_classified_click'
      //     : 'n_index_feed_classified_click',
      //   action: 'click',
      //   label: this.classified.title + ' (' + this.classified.id + ')'
      // })

      if (evt) {
        evt.preventDefault()
        if (evt.ctrlKey) {
          window.open(this.classifiedURL)
          return
        }
      }

      const refs = {
        categoryIds: refData.categoryIds || this.classified.category_ids,
        id: refData.id || this.classified.id,
        isParked: refData.isParked || null,
        thumb: refData.thumb || this.classified.thumb,
        title: refData.title || this.classified.title,
        price: refData.price || this.classified.price,
        salary: refData.salary || this.classified.salary,
        payment: refData.payment || this.classified.payment,
        thumbIndex: refData.thumbIndex || 0,
        offertype: refData.offertype || this.classified.offertype,
        hasPhotos: refData.hasPhotos || this.classified.has_photos,
        thumbsLength:
          refData.thumbsLength || (this.classified.has_photos ? 1 : 0),
        externalLink:
          refData.externalLink || this.classified.external_link || null
      }

      this.$store.commit('classifieds/view/SET_REFS', refs)

      this.$emit('close-suggestions')

      return this.$router.push({
        path: this.classifiedURL,
        query: this.rowsExtraQueryParams
      })
    }
  }
})
