










import { mapState } from 'vuex'
import CAdTarget from '~/components/shared/configurable/ad/target/CAdTarget.vue'
import CAdContainer from '~/components/shared/configurable/ad/container/CAdContainer.vue'
import { GoogleAd, placeholderProp } from '~/models/ads'
import { KeyValue } from '~/models/common/types'
import AdViewService from '~/services/ads/AdViewService'
import { USER_NS } from '~/store/modules/shared/user/state'
import GoogleAdsService from '~/services/ads/GoogleAdsService'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { CAdContainer, CAdTarget },
  props: {
    ad: {
      type: Object as PropType<GoogleAd>,
      required: true
    },
    keyValues: {
      type: Array as PropType<KeyValue[]>,
      required: false,
      default() {
        return []
      }
    },
    placeholder: placeholderProp,
    lazy: {
      type: Boolean,
      default: true
    },
    sticky: {
      type: Boolean,
      default: false
    },
    divCollapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slot: null
    }
  },
  computed: {
    ...mapState(USER_NS, {
      userType: state => state.type
    }),
    adViewService(): AdViewService {
      return this.$dep(AdViewService)
    },
    googleAdsService(): GoogleAdsService {
      return this.$dep(GoogleAdsService)
    },
    containerSize() {
      const { ad, adViewService } = this
      return adViewService.getMaxSizeDimensions(ad.sizes)
    },
    internalKeyValues(): KeyValue[] {
      return [
        ...this.keyValues,
        ...[{ key: 'user-type', value: this.userType }]
      ]
    },
    sizeMapping() {
      const { sizesMappings } = this.ad
      return sizesMappings || []
    }
  },
  deactivated() {
    if (this.slot) {
      this.destroyAd()
    }
  },
  beforeDestroy() {
    if (this.slot) {
      this.destroyAd()
    }
  },
  activated() {
    this.createAd()
  },
  mounted() {
    this.$emit('mounted')
    this.createAd()
  },
  methods: {
    destroyAd() {
      this.googleAdsService.destroySlots([this.slot])
      this.slot = null
    },
    createAd() {
      const { googleAdsService } = this
      const { id, path, sizes, sizesMappings } = this.ad

      if (this.divCollapse) {
        googleAdsService.setCollapseEmptyDivs()
      }
      googleAdsService.enqueueCommand(() => {
        this.slot = googleAdsService.defineSlot(path, sizes, id)
        if (this.slot) {
          if (sizesMappings) {
            googleAdsService.defineSlotSizeMapping(this.slot, sizesMappings)
          }
          googleAdsService.setTargeting(this.slot, this.internalKeyValues)
          googleAdsService.prepareAndDisplaySlot(this.slot, id)
        }
      })
    },
    refresh() {
      this.googleAdsService.refreshSlots([this.slot])
    }
  }
})
