import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { GeocodingSearchResult } from '~/models/location/geocoding'

@containerScoped()
export default class GeocodingService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  search(query: string): Promise<GeocodingSearchResult> {
    return this.requestBuilder
      .request('get', '/api/geocoding/search/')
      .params({ q: query })
      .previousRequestCancelable()
      .send()
  }
}
