import { CIcon } from '~/icons/types'
export const ciVan: CIcon = {
  name: 'van',
  type: 'regular',
  width: 512,
  height: 277.4,
  paths: [
    {
      d:
        'M509.7 174.2v-.3c0-20.2-4.5-37.7-13.1-50.6-5.2-7.7-12.8-14.5-20.9-21.6-7.1-6.4-14.5-12.9-19.3-19.5-23.8-33-37-57.2-37.2-57.4-.1-.2-.3-.5-.4-.7C409.1 9 392.6 0 374.6 0H36.7C17.5 0 1.9 15.6 1.9 34.8v166.5c0 19.2 15.6 34.8 34.8 34.8h16.4c5.5 23 25.8 40.3 50.4 41.3h2.1c23.9 0 45-15.6 51.8-38.6.3-.9.5-1.8.7-2.7h180.3c.6 0 1.2-.1 1.8-.2 5.7 23.8 27.1 41.5 52.6 41.5s46.8-17.7 52.6-41.4h31.3c9 0 17.4-3.5 23.8-10 6.3-6.4 9.7-14.9 9.5-23.8l-.3-28zm-70.3-79.8c1.4 2 2.9 3.8 4.5 5.7l-113.2.1-.5-41.3h85c6.2 9.7 14.3 21.8 24.2 35.5zM137.3 232.7c-4.3 14.5-17.9 24.2-33 23.6-17.6-.7-31.5-15-31.7-32.6-.1-9.2 3.4-17.9 10.1-24.3 6.2-6 14.3-9.3 23-9.3h1.8c16.2.8 29.9 14.1 31.1 30.4.3 4.2-.1 8.3-1.3 12.2zm255.6 23.6c-18.3 0-33.1-14.8-33.1-33.1s14.8-33.1 33.1-33.1 33.1 14.8 33.1 33.1c0 18.2-14.9 33.1-33.1 33.1zm92.7-45.1c-2.3 2.4-5.5 3.7-8.8 3.7h-30.4c-4-25.9-26.5-45.8-53.5-45.8-27.1 0-49.5 20-53.5 45.9H159.1c-3.8-25.2-24.9-44.6-50.6-45.8-15.2-.8-29.5 4.6-40.4 15.1-8.5 8.2-14.1 19.1-15.9 30.7H36.7c-7.6 0-13.8-6.2-13.8-13.8V34.7c0-7.6 6.2-13.8 13.8-13.8h337.9c10.5 0 20.6 5.4 26.4 14.2.3.5.8 1.5 1.6 2.8h-84.2c-.2 0-.3.1-.5.1s-.4 0-.5.1c-.2 0-.3.1-.5.1s-.3.1-.5.1c-.2.1-.3.1-.5.2s-.3.1-.5.2-.3.2-.5.2c-.1.1-.3.1-.4.2-.2.1-.3.2-.5.3-.1.1-.3.2-.4.3s-.3.2-.4.3-.3.2-.4.3-.3.2-.4.4c-.1.1-.2.2-.4.3-.1.1-.2.3-.3.4-.1.1-.2.2-.3.4-.1.1-.2.3-.3.4-.1.1-.2.3-.3.4-.1.1-.2.3-.3.4-.1.1-.2.3-.3.4-.1.1-.1.3-.2.5s-.2.3-.2.5c-.1.2-.1.3-.2.5s-.1.3-.2.5c0 .2-.1.3-.1.5s-.1.3-.1.5-.1.3-.1.5-.1.4-.1.5v1.2l.7 62.3v1.1c0 .2 0 .3.1.5 0 .2.1.4.1.5 0 .2.1.3.1.5s.1.3.1.5c.1.2.1.3.2.5s.1.3.2.5.1.3.2.5.1.3.2.5.2.3.3.4c.1.1.2.3.3.4.1.1.2.3.3.4.1.1.2.3.3.4.1.1.2.2.3.4.1.1.2.3.3.4.1.1.2.2.4.3.1.1.2.2.4.3.1.1.3.2.4.3s.3.2.4.3.3.2.4.2c.2.1.3.2.5.3.1.1.3.1.4.2.2.1.3.2.5.2.2.1.3.1.5.2s.3.1.5.2.4.1.6.2c.1 0 .3.1.4.1.3.1.5.1.8.1h.3c.4 0 .7.1 1.1.1l143.7-.1c.6 0 1.2-.1 1.7-.2 5.5 5 10.5 9.7 13.4 14.1 6.2 9.3 9.6 23 9.5 38.9v.5l.4 28c.2 3.1-1.1 6.2-3.4 8.6z',
      fill: 'currentColor'
    }
  ]
}
