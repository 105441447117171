import { CIcon } from '~/icons/types'
export const ciAirbag1: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M382.5 412q23.5 20 23.5 50v9q0 17-12.5 29T364 512H167q-17 0-29-12t-12-29v-7q-12-13-12-31 0-9 4-17L64 191 36 91q-3-12 3-23t19-15q14-3 26.5 0T107 65.5 121 89l11 41q5 16-1 31.5T112 186q25 11 45 30 34 33 45 78l28 117 99-15q30-4 53.5 16zM64 75q-8 2-6 10l25 89 8-3q11-3 17-13t2-22L99 95q-6-21-28-21-3 0-7 1zm78 157q-23-21-52-31l46 193q11-7 25-7 31 0 43 28h4l-28-115q-9-40-38-68zm-5 201q0 10 7 17t17 7 17-7 7-17-7-17-17-7-17 7-7 17zm246 38v-9q0-20-15.5-33T332 419l-124 19q-2 18-15.5 30T161 480q-5 0-10-1 5 10 16 10h197q8 0 13.5-5t5.5-13zm90-287q13 51-14.5 95.5T381 337q-15 3-31 3-35 0-65-18-45-27-57-78l-22-88q-12-50 15-95t78-57q33-9 65.5.5t55.5 34T452 96zm-34 83.5q22-36.5 12-78.5l-21-88q-9-35-37.5-56.5T329 23q-13 0-25 3-41 10-63.5 46.5T228 151l22 88q10 41 47 63.5t78.5 12 63.5-47z',
      fill: 'currentColor'
    }
  ],
  name: 'airbag-1',
  type: 'regular'
}
