import { CIcon } from '~/icons/types'
export const ciTrailer: CIcon = {
  width: 512,
  height: 230.8,
  paths: [
    {
      d:
        'M496 161.4h-49.6V10.5c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5V67H26.5V46.2c0-5.8-4.7-10.5-10.5-10.5S5.5 40.4 5.5 46.2v125.7c0 5.8 4.7 10.5 10.5 10.5h31c2.5-32.8 30-58.7 63.4-58.7 16.6 0 32.3 6.4 44.3 18 6.1 5.9 10.8 12.9 14.1 20.4 9.8-22.6 32.3-38.4 58.4-38.4 16.6 0 32.3 6.4 44.3 18 11.2 10.9 17.9 25.3 19.1 40.7h195v15.1c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-25.6c-.1-5.8-4.8-10.5-10.6-10.5z'
    },
    {
      d:
        'M153.6 182.4c-2.5-21.7-20.9-38.6-43.2-38.6-22.3 0-40.8 16.9-43.2 38.6-.2 1.6-.3 3.2-.3 4.9 0 24 19.5 43.5 43.5 43.5 23.5 0 42.7-18.8 43.5-42.1-.1-.5-.1-.9-.1-1.4 0-.5.1-.9.1-1.4-.1-1.2-.2-2.3-.3-3.5zm116.7 0c-2.5-21.7-20.9-38.6-43.2-38.6-22.3 0-40.8 16.9-43.2 38.6-.2 1.6-.3 3.2-.3 4.9 0 24 19.5 43.5 43.5 43.5 23.5 0 42.7-18.8 43.5-42.1-.1-.5-.1-.9-.1-1.4 0-.5.1-.9.1-1.4-.1-1.2-.2-2.3-.3-3.5z'
    }
  ],
  name: 'trailer',
  type: 'solid'
}
