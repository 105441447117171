import { CIcon } from '~/icons/types'
export const ciDiamond: CIcon = {
  name: 'diamond',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'm506.135 221.963-87.197-98.667a10 10 0 0 0-7.493-3.378H100.556a10 10 0 0 0-7.493 3.378L5.865 221.963a10.001 10.001 0 0 0 .954 14.185c.021.023.037.049.058.073l241.642 272.415a9.999 9.999 0 0 0 14.962 0l241.642-272.415c.021-.023.038-.049.058-.073a10.001 10.001 0 0 0 .954-14.185zm-112.422-82.046-44.441 71.644-71.22-71.644h115.661zm-65.659 78.668H183.946L256 146.102l72.054 72.483zm-94.106-78.667-71.22 71.644-44.441-71.644h115.661zm-134.944 6.856 44.545 71.811H35.541l63.463-71.811zm-63.295 91.811h118.178l81.636 225.261L35.709 238.585zm241.139 224.843 57.038-154.372c1.914-5.181-.734-10.932-5.914-12.846-5.183-1.916-10.933.733-12.846 5.914l-59.035 159.777-80.931-223.316h301.131L276.848 463.428zm91.603-244.843 44.545-71.811 63.463 71.811H368.451zM256 0c-5.522 0-10 4.477-10 10v51.513c0 5.523 4.478 10 10 10s10-4.477 10-10V10c0-5.523-4.478-10-10-10zm91.062 39.524c-3.905-3.905-10.235-3.905-14.143 0l-36.426 36.425c-3.905 3.905-3.905 10.237 0 14.142a9.972 9.972 0 0 0 7.072 2.929 9.972 9.972 0 0 0 7.071-2.929l36.426-36.425c3.905-3.905 3.905-10.237 0-14.142zM213.558 75.949l-36.425-36.425c-3.904-3.905-10.234-3.905-14.143 0-3.905 3.905-3.905 10.237 0 14.142l36.426 36.425c1.953 1.953 4.512 2.929 7.071 2.929s5.118-.977 7.071-2.929c3.905-3.905 3.905-10.237 0-14.142z'
    },
    { d: 'M327.43 268.36a10 10 0 1 0 20 0 10 10 0 1 0-20 0' }
  ]
}
