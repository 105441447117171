

























import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import {
  SET_PAGE,
  SET_PARAM,
  SET_SHOW_SELLERS
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { capitalizeSentences } from '~/utils/string'
import { CategoryId } from '~/models/category/types'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    alwaysSellers: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      totalSellers: state => state.sellersInfo?.total,
      totalClassifieds: state => state.sellersClassifiedsInfo?.total,
      disableShowSellers: state => state.config.settings.disableShowSellers,
      privateSellersParam: state => state.params.st,
      shopParam: state => state.params.shop,
      sellerTypeParam: state => state.params.st,
      loadingResults: state => state.loading.results
    }),
    ...mapState({
      rootCategory: state => state.classifieds.search.config.rootParams.category
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      showSellers: 'showSellers'
    }),
    show() {
      if (this.showSellers) {
        return true
      }
      let isRentals = false
      if (Array.isArray(this.rootCategory)) {
        isRentals = this.rootCategory.includes(CategoryId.RENTALS)
      } else if (this.rootCategory) {
        isRentals = this.rootCategory === CategoryId.RENTALS
      }
      return (
        !this.disableShowSellers &&
        (this.totalSellers > 1 ||
          (Boolean(this.privateSellersParam) && !isRentals))
      )
    },
    icons() {
      return {
        store: faShoppingCart
      }
    },
    sellersLabel() {
      // show the label if the private sellers param is not active and the total amount of sellers is above 0
      let sellersCount = null
      const shopParamIsActive = this.shopParam && this.shopParam.length > 0
      if (this.totalSellers > 0 && !shopParamIsActive && !this.loadingResults) {
        sellersCount = this.totalSellers
      }
      let translation = this.$tc(
        'view 1 seller | view {count} sellers',
        sellersCount
      )
      let isPlot = false
      if (Array.isArray(this.rootCategory)) {
        isPlot = this.rootCategory.includes(CategoryId.PLOT)
      } else if (this.rootCategory) {
        isPlot = this.rootCategory === CategoryId.PLOT
      }
      if (isPlot) {
        translation = this.$tc('view 1 agent | {count} agents', sellersCount)
      }

      return capitalizeSentences(translation)
    },
    showClassifiedsLabel() {
      const classifiedsCount =
        this.totalClassifieds > 0 ? this.totalClassifieds : null
      return capitalizeSentences(
        this.$tc(
          'view 1 classified | view {count} classifieds',
          classifiedsCount
        )
      )
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      search: 'search',
      toggleMobileFacets: 'toggleMobileFacets'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setShowSellers: SET_SHOW_SELLERS,
      setPage: SET_PAGE,
      setParam: SET_PARAM
    }),
    async toggleShowSellers(showSellersState: boolean) {
      if (showSellersState) {
        if (this.sellerTypeParam === 'private') {
          this.setParam({ name: 'st', value: null })
        }
        this.setParam({ name: 'shop', value: null })
      }
      this.recordEvent(showSellersState)
      this.setShowSellers(showSellersState)
      this.setPage(1)
      await this.search()
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    recordEvent(showSellersState: boolean) {
      if (showSellersState) {
        this.$analytics.recordEvent({
          namespace: 'n_classifieds_search_show_sellers',
          action: 'click'
        })
        this.toggleMobileFacets()
      } else {
        this.$analytics.recordEvent({
          namespace: 'n_classifieds_search_show_classifieds',
          action: 'click'
        })
      }
    }
  }
})
