import { CIcon } from '~/icons/types'
export const ciTaxi: CIcon = {
  name: 'taxi',
  type: 'regular',
  width: 512,
  height: 244.9,
  paths: [
    {
      d:
        'M481.5 102.5c-17.5-11-42.7-16.7-81.4-18.3-5.9-4.4-11.4-8.7-16.8-13-25.2-19.9-49.2-38.9-95.1-45.4L277.6 5.6c-1.8-3.4-5.2-5.5-9-5.6s-7.3 1.8-9.3 5.1l-11.1 18.4c-87.6.2-127.1 5.5-148.7 15.2-.4.2-.9.3-1.3.5-.1.1-.3.2-.4.2-11.9 5.7-18.2 12.9-24.5 21.4C69 66.6 65 71.9 57 77.4l-25.2 7.5c-13.1 4-22.8 17-24 32.5-.3 3.5-.3 7.2-.4 11.1 0 2.1-.1 4.3-.1 6.6-4.7 5.5-6.5 13.6-6.5 20.1V178c0 20.2 11.8 33.8 29.3 33.8h32.3c8.5 19.5 27.9 33.1 50.5 33.1s42-13.6 50.5-33.1h181.8c8.5 19.5 27.9 33.1 50.5 33.1s42.1-13.7 50.6-33.2c.5.1 1 .1 1.4.1 2.9 0 18.3-.1 32.6-2.6 7.7-1.3 31-5.5 31-23v-23.3c-.1-28.1-9.8-47.8-29.8-60.4zM240.6 84.9l-6.5-40.3c5.8-.1 11.8-.1 18.1-.1 60.6 0 85.3 17.4 114 40l-125.6.4zm-27.8-40 6.5 40.1-85.6.3-16-30.9c.8-.2 1.6-.4 2.4-.7 18.9-4.9 48.8-7.8 92.7-8.8zm-99.9 178.9c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.1 34.1-34.1 34.1 15.3 34.1 34.1-15.3 34.1-34.1 34.1zm282.8 0c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.1 34.1-34.1 34.1 15.3 34.1 34.1-15.3 34.1-34.1 34.1zm94.5-39.5c-1.7 1-6.5 3.2-18.2 4.8-8.5 1.2-16.8 1.5-21.3 1.6v-1c0-30.4-24.7-55.1-55.1-55.1s-55.1 24.7-55.1 55.1v1H167.9v-1c0-30.4-24.7-55.1-55.1-55.1s-55.1 24.7-55.1 55.1v1H30.1c-6.9 0-8.3-7-8.3-12.8v-22.8c0-2.6.8-5.2 1.3-6.2 2.9-1.8 4.8-4.9 5-8.3.3-4.3.3-8.3.4-11.9 0-3.5.1-6.8.3-9.7.5-6.8 4.3-12.5 9.1-14l26.7-8c1-.3 1.9-.7 2.8-1.3C79.3 87.9 85.2 79.9 90 73.5c2.8-3.8 5.3-7.1 8.8-10.1l19.2 37.2c1.8 3.5 5.4 5.7 9.3 5.7l269-.9c.7 0 1.4-.1 2.1-.2 34.8 1.4 57.7 6.2 71.9 15.1 13.8 8.6 19.9 21.7 19.9 42.5v21.5z',
      fill: 'currentColor'
    },
    {
      d:
        'M312.8 134.6H193.2c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h119.5c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.4-10.5z',
      fill: 'currentColor'
    }
  ]
}
