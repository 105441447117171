import { CIcon } from '~/icons/types'
export const ciAgricultural: CIcon = {
  width: 512,
  height: 278.8,
  paths: [
    {
      d:
        'M25.5 110.2c-.2.1-.6.3 0 0zm-.3 85.6s-.1-.3-.3-.6c.1.3.3.6.3.6zm462.7 4.4-.1.2c.1-.1.1-.1.1-.2zm0-.5c.1-1.1.1-2.3 0 0zm-231.8-60.3c0-.1.4-.3.4-.3-.1.1-.2.1-.4.3l.2-.2-.2.1c0-.1 0-.1.1-.2 0-.1 0-.2.1-.2v.1c0-.1 0-.2.1-.3 0 .1-.1.1-.1.2 0-.1 0-.1.1-.2-.1.1-.1.3-.2.4 0-.7 0-.1-.1.3-.1-.1-.1-.1-.1-.2v.1l-.3-.3c.1.1.2.3.3.4-1-.5-.3-.1 0 0h-.2v.1c-.1.1-.2.1-.3.2.1 0 .3-.1.3-.2-.2.4-.2.7-.1.7 0 0 .1 0 .1-.1s.1-.3.1-.4l.1-.1v.4c0-.1 0-.3.1-.4 0 .1.1.1.1.1 0-.1-.1-.1-.1-.2.1.1.2.3.3.4v-.1c0 .2-.1-.1-.3-.4.1.1.4.2.5.3-.2-.2-.4-.2-.5-.3z',
      fill: 'currentColor'
    },
    {
      d:
        'M507.8 176.9c-18.7-76.7-29.8-86.2-109-85.3C376 62.4 348.4 3.4 307.6 2.2c-12.9-.5-42.7-1.6-55.9-2-23.6-.8-55.3-.4-61.4 27.6-2.5 16.9-2.9 42.7-4 59.8-32.3-.5-95.3-1.7-127.2-2.3-17.8.8-44.8-3.7-53 11.1-8.4 15.1-4.8 69.5-6 94.7-1.3 27 29.7 40.7 53.4 41.1 15.2 61.8 104 62 119.2.1h170.9c15.3 61.8 104 61.8 119.2 0 35.9 1.2 59.1-18.8 45-55.4zM273.1 25.2c11.3 0 31.7-.4 41.7 3 23.8 12.2 38.4 42.5 54.3 62.9-21.9-.4-73.6-1.4-96-1.8V25.2zm-62.7 62.7s1.1-31.5 2.6-46.8c2-19.6 21.3-17 36-16.8v64.5l-38.6-.9zM25.3 110.3c.1 0 .2-.1.2-.1l-.1.1c-.3.2-.3.1-.1 0zm-.4 84.9c.2.3.3.6.3.6s-.2-.3-.3-.6zm125.2 27.4c-8.1 48.7-79.1 39-73.9-10.1 8-48.6 78.9-38.9 73.9 10.1zm290 0c-8.1 48.7-79 39.1-73.9-10.1 8.2-48.7 78.9-39 73.9 10.1zm47.5-21.7c-.2.4-.2 1-.7 1.3v-.1c-3.6 4-9.6 4.5-14.6 5.5 4.3-.8.7-.1-.3 0-2.7.3-5.5.4-8.2.5-10.7-68.7-110.7-68.7-121.2.1H173.7c-11.1-68.8-110.1-69-121.3-.1-5.2-.2-10.2-1.5-15.1-3.3l.5.3c-4.3-2.1-9-4.3-11.8-8.4l.8 1.3c-1.1-1.6-2.4-3.5-2.7-5.3-.1-8.6 0-17.2 0-25.9.5-10.1-1.2-45.6 1.2-55-.5.4.5-1.4.6-1.7v.1c.3-.5 1-.5 1.4-.9l-.8.5c135.6 1.1 280.6 4 416.2 7.6-4.1-.4-.7-.1.3.1 8.7 1.4 17.8 4.6 23.3 11.9l-.5-.7c.2.3 1.8 2.4 1.2 1.7 9.1 15 10.9 33.4 16.6 49.8 1.6 6.8 5.4 13.6 4 20.7zm.2-.5.1-.2c0 .1 0 .1-.1.2zm.1-.7c.1-2.3.1-1.1 0 0z',
      fill: 'currentColor'
    }
  ],
  name: 'agricultural',
  type: 'regular'
}
