import { CIcon } from '~/icons/types'
export const ciDamper: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M44.5 418.5Q55 408 69 408t24 10.5 10 24.5-10 24q-12 10-25 10t-23.5-10T34 443t10.5-24.5zM80 455q12-12 0-24-5-5-12-5t-12 5q-12 12 0 24t24 0zM509 39q6 6 0 12l-17 18 5 6q3 2 3 5.5t-3 6.5l-23 24q-3 2-6.5 2t-5.5-2l-13-13-92 92 42 43q6 6 0 11l-24 24q0 1-1 2-11 11-28 11-19 0-30-13h-1l-36 36h1q7 7 18 7t18-7q6-6 12 0t0 12q-12 13-30.5 13T268 316l-37 36v1q8 8 18.5 8t17.5-8q6-6 12 0t0 12q-11 13-29.5 13T219 365v-1l-13 13 24 24q6 6 0 12l-23 24q-2 3-6 3-3 0-6-3l-24-24-35 35q-1 25-19 44-21 20-48.5 20T20 492Q0 471 0 443.5T20 395q19-18 44-20l34-34-24-24q-6-6 0-12l24-24 .5-.5.5-.5q13-12 30-12 18 0 29 12l37-37q-7-7-18-6.5t-17 7.5q-6 6-12 0t0-12q12-13 29-13 18 0 30 12l36-36q-7-6-18-6t-18 7q-6 6-12 0t0-12q13-13 30-13 18 0 30 12l12-12-24-23q-6-6 0-12l24-24q6-6 12 0l42 42 92-92-12-12q-2-3-2-6t2-6l24-24q6-6 12 0l7 7 18-18q6-6 12 0zM328 233l-48-49-12 12 48 49zM104 479q15-15 15-35 0-23-15-37-14-15-36-15t-36 15q-15 15-15 36.5T32 479q15 16 36.5 16t35.5-16zm31-53 24-24-48-49-25 24q17 5 31 18 10 10 15 24 2 4 3 7zm66-7 11-12-23-24-61-61-24-24-12 12zm-84-131 17 17 12-13q-13-12-29-4zm78 78 13-13-49-48-12 12zm25-25 36-36-48-49-37 37zm48-48 36-36-48-49-36 36zm60-37q13 14 29 5l-17-17-13 12h1zm41-6 12-12-41-42-24-24-43-43-12 12zm-23-72 91-91-12-12-91 91zm122-85 12-12-23-23-19-19-7-7-12 12zm-13-61 24 24 12-12-24-24z',
      fill: 'currentColor'
    }
  ],
  name: 'damper',
  type: 'light'
}
