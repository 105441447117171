import { CIcon } from '~/icons/types'
export const ciTruckTrailer: CIcon = {
  width: 512,
  height: 302.8,
  paths: [
    {
      d:
        'M499.3 0H12.5C6.7 0 2 4.7 2 10.5v238.9c0 5.8 4.7 10.5 10.5 10.5l18.3-.1c2.3-30.8 28-55.2 59.4-55.2 31.1 0 56.7 24 59.3 54.4l9-.1c2.7-30.4 28.2-54.4 59.3-54.4 29.8 0 54.6 22 58.9 50.6l37-15.5h155.4v30h-2.6c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h26.2c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-2.6v-30h9.2c5.8 0 10.5-4.7 10.5-10.5V10.5c0-5.8-4.6-10.5-10.5-10.5z'
    },
    {
      d:
        'M218 225.6c-19.4 0-35.6 14.5-38.2 33.2-.2 1.8-.4 3.5-.4 5.4 0 21.2 17.4 38.6 38.6 38.6 21.2 0 38.6-17.4 38.6-38.6 0-2-.2-3.9-.4-5.8-2.9-18.5-19-32.8-38.2-32.8zm-127.8 0c-19.7 0-36 14.9-38.3 34-.2 1.5-.3 3-.3 4.6 0 21.2 17.4 38.6 38.6 38.6 21.2 0 38.6-17.4 38.6-38.6 0-1.7-.1-3.4-.3-5-2.5-18.9-18.8-33.6-38.3-33.6z'
    }
  ],
  name: 'truck-trailer',
  type: 'solid'
}
