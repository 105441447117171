import { CIcon } from '~/icons/types'
export const ciDoubleBed: CIcon = {
  name: 'double-bed',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M472.447 225.482H39.553C17.744 225.482 0 243.226 0 265.036v105.673h512V265.036c0-21.81-17.744-39.554-39.553-39.554zM271 132.578h126.862v62.904H271z'
    },
    { d: 'M84.139 102.578h343.723v92.904h37.468V65.108H46.67v130.374h37.469z' },
    {
      d:
        'M114.139 132.578H241v62.904H114.139zM16.008 400.709h479.983v46.183H16.008z'
    }
  ]
}
