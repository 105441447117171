import { CIcon } from '~/icons/types'
export const ciXyma: CIcon = {
  width: 512,
  height: 378.6,
  paths: [
    {
      d:
        'M148.8 151.2c23.1 0 41.9-18.8 41.9-41.9V1.1H133l-26.1 109.4c.7 22.5 19.2 40.7 41.9 40.7zM83.6 109.3v-1.4c0-.2.1-.3.1-.6 0-.2.1-.4.1-.7v-.1L108.9 1.1h-42c-13.3 0-25.1 8.9-28.7 21.7-.2.4-.3 1-.6 1.4L0 111.6c1.1 21.9 19.5 39.5 41.8 39.5 23 .1 41.8-18.8 41.8-41.8zM363.1 151.2c22.8 0 41.4-18.4 41.9-41l-26-109h-57.8v108.1c0 23 18.8 41.9 41.9 41.9zM255.9 151.2c23.1 0 41.9-18.8 41.9-41.9V1.1H214v108.1c.1 23.1 18.9 42 41.9 42zM474.2 24.3c-.2-.4-.4-1-.6-1.4-3.5-12.8-15.4-21.8-28.6-21.8h-42l25.2 105.4c.2 1.2.3 2.6.2 3.8.7 22.6 19.2 40.8 41.9 40.8 22.3 0 40.5-17.5 41.8-39.5l-37.9-87.3z'
    },
    {
      d:
        'M416.8 146.6c-11.8 16.9-31.4 28.1-53.6 28.1-22.2 0-41.8-11.1-53.6-28.1-11.8 16.9-31.4 28.1-53.6 28.1-22.2 0-41.8-11.1-53.6-28.1-11.8 16.9-31.4 28.1-53.6 28.1-22.2 0-41.8-11.1-53.6-28.1-9.7 13.9-24.6 23.8-41.9 27v166.8c0 21.1 17.2 38.2 38.2 38.2h221V231.8c0-6.5 5.2-11.7 11.7-11.7l72.3-.4c6.5 0 11.7 5.2 11.7 11.7l.1 146.5h19.2c17.7-3.3 31.1-18.9 31.1-37.5V173.6c-17.2-3.2-32.1-13.1-41.8-27zm-292.1 73.1h114.1c9.3 0 16.9 7.6 16.9 16.9v60.7c0 9.3-7.6 16.9-16.9 16.9H124.7c-9.3 0-16.9-7.6-16.9-16.9v-60.7c0-9.3 7.6-16.9 16.9-16.9z'
    }
  ],
  name: 'xyma',
  type: 'solid'
}
