import { containerScoped } from '~/decorators/dependency-container'
import {
  PhoneNumberPart,
  PopupPhone,
  PopupPhoneCallService,
  PhoneCallServiceName,
  PhoneCode,
  PopupPhoneType
} from '~/models/phone/types'
import { Telephone } from '~/models/shared/types'

@containerScoped()
export default class PhoneService {
  numberIsGreekLandlinePhone(
    number: PhoneNumberPart,
    code: PhoneCode = '30'
  ): boolean {
    return (
      ['2', '3', '4'].some(d => number.startsWith(d)) &&
      code.toString() === '30'
    )
  }

  // TODO: Create model for phone arg.
  phoneIsUsedInVoipMessagingApps(phone: any): boolean {
    if (
      !phone ||
      !phone.social ||
      this.numberIsGreekLandlinePhone(phone.telephone, phone.code)
    ) {
      return false
    }

    const { whatsapp, viber } = phone.social
    return viber || whatsapp
  }

  getViberChatUrl(
    phoneNumber: PhoneNumberPart,
    phoneCode: PhoneCode = '30',
    message?: string
  ): string {
    const messageQueryParam = message ? `&text=${message}` : ''
    return `viber://chat/?number=%2B${phoneCode}${phoneNumber}${messageQueryParam}`
  }

  getWhatsappChatUrl(
    phoneNumber: PhoneNumberPart,
    phoneCode: PhoneCode = '30',
    message?: string
  ): string {
    const messageQueryParam = message ? `&text=${message}` : ''
    return `whatsapp://send?phone=%2B${phoneCode}${phoneNumber}${messageQueryParam}`
  }

  // TODO: Create model for phone arg.
  createPopupPhone(
    phone: any,
    name: string,
    label: string,
    type: PopupPhoneType
  ): PopupPhone {
    const services = new Map<PhoneCallServiceName, PopupPhoneCallService>()

    services.set('tel', {
      name: 'tel',
      url: this.getPhoneUrlByNumberAndCode(phone.telephone, phone.code)
    })

    const social = phone && phone.social
    if (
      social &&
      !this.numberIsGreekLandlinePhone(phone.telephone, phone.code)
    ) {
      social.viber &&
        services.set('viber', {
          name: 'viber',
          url: this.getViberChatUrl(phone.telephone, phone.code)
        })

      social.whatsapp &&
        services.set('whatsapp', {
          name: 'whatsapp',
          url: this.getWhatsappChatUrl(phone.telephone, phone.code)
        })
    }

    return {
      name,
      number: phone,
      services,
      label,
      type
    }
  }

  private getPhoneUrlByNumberAndCode(
    phoneNumber: PhoneNumberPart,
    phoneCode: PhoneCode = '30'
  ): string {
    return `tel:+${phoneCode}${phoneNumber}`
  }

  getPhoneUrl(phone: Telephone) {
    return `tel:+${phone.code}${phone.telephone}`
  }

  removeDecimalSeparatorFromPhone(telephone: string) {
    const symbols = ',.'
    return [...symbols].reduce(
      (tel, symbol) => tel.replace(symbol, ''),
      telephone
    )
  }
}
