import { CIcon } from '~/icons/types'
export const ciCrane: CIcon = {
  name: 'crane',
  type: 'regular',
  width: 480.003,
  height: 480,
  paths: [
    {
      d:
        'M136 272h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm-64 32h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm-128 32h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm-128 32h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm-128 32h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm-128 32h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm32 0h16v16h-16zm0 0'
    },
    {
      d:
        'M472 24h-56V8a8 8 0 0 0-8-8h-80a8 8 0 0 0-8 8v16H8a8 8 0 0 0-8 8v32c0 4.422 3.582 8 8 8h24v152c0 4.422 3.582 8 8 8a8 8 0 1 1-8 8H16c.023 5.855 2.2 11.5 6.121 15.852l-20.52 27.351.102.078A7.835 7.835 0 0 0 0 288v32c0 2.125.844 4.156 2.344 5.656l16 16A8.001 8.001 0 0 0 24 344h32a8.001 8.001 0 0 0 5.656-2.344l16-16A7.994 7.994 0 0 0 80 320v-32a7.835 7.835 0 0 0-1.703-4.719l.101-.078-20.52-27.351a23.849 23.849 0 0 0 5.372-21.891A23.85 23.85 0 0 0 48 217.37V72h272v8c0 4.422 3.582 8 8 8h16v376h-32V144h-16v32h-48v-16h-16v16h-48v-8h-16v8h-48v-24h104v-16H120v-32h-16v360H91.312l-37.656-37.652a7.997 7.997 0 0 0-11.312 0L4.687 464H0v16h480v-16h-6.238a23.778 23.778 0 0 0 5.27-22.79 23.78 23.78 0 0 0-16.794-16.28 23.997 23.997 0 0 0-7.675-28.098 23.993 23.993 0 0 0-29.125 0 23.997 23.997 0 0 0-7.676 28.098 23.78 23.78 0 0 0-16.793 16.28 23.778 23.778 0 0 0 5.27 22.79H392V88h16c4.418 0 8-3.578 8-8v-8h56c4.418 0 8-3.578 8-8V32a8 8 0 0 0-8-8zM44 264l12 16H24l12-16zm20 52.691L52.687 328H27.314L16 316.691V296h48zM437.602 40 416 54.402V40zM336 16h32v32h-32zM120 41.602 141.602 56H98.398zm-48 12.8L50.398 40h43.204zM146.398 40h43.204L168 54.402zM216 41.602 237.602 56h-43.204zM242.398 40h43.204L264 54.402zM16 40h5.602l24 16H16zm274.398 16 24-16H320v16zM376 437.602 361.602 416 376 394.402zm0-192L361.602 224 376 202.402zm-16 4.8L374.398 272 360 293.602zm0-52.8v-43.2L374.398 176zm0 148.8L374.398 368 360 389.602zm16-4.847L361.602 320 376 298.402zM361.602 128 376 106.402v43.2zM360 101.602V88h9.055zM296 192v80h-48v-80zm-64 0v68.8l-18.398-18.183a8.02 8.02 0 0 0-5.602-2.312h-24V192zm-64 0v13.336h-48V192zM48 443.313 68.688 464H27.312zM120 464V221.336h48v27c0 4.422 3.582 8 8 8h28.71l29.688 29.355A8 8 0 0 0 240 288h56v176zm240-21.598L374.398 464H360zM456 456a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-16-48a8 8 0 1 1 0 16 8 8 0 0 1 0-16zm-16 32a8 8 0 1 1 0 16 8 8 0 0 1 0-16zM400 72h-64v-8h40c4.418 0 8-3.578 8-8V16h16zm42.398-16L464 41.602V56zm0 0'
    }
  ]
}
