export const InHouseSizes = {
  in_house_hp_dt_sb: {
    sizes: [300, 250]
  },
  in_house_cat_dt_btm: {
    sizes: [
      [728, 90],
      [970, 250]
    ]
  },
  in_house_hp_mb_top: {
    sizes: [320, 100]
  },
  in_house_search_dt_top_list: {
    sizes: [
      [970, 250],
      [728, 90]
    ],
    sizesMappings: [
      [[1344, 768], [[970, 250]]],
      [[768, 576], [[728, 90]]],
      [[0, 0], []]
    ],
    lazy: false
  },
  in_house_search_dt_top2_list: {
    sizes: [
      [970, 250],
      [728, 90]
    ],
    sizesMappings: [
      [[1344, 768], [[970, 250]]],
      [[768, 576], [[728, 90]]],
      [[0, 0], []]
    ]
  },
  in_house_search_dt_btm1_gal: {
    sizes: [300, 250]
  },
  in_house_search_dt_btm2_gal: {
    sizes: [300, 250]
  },
  in_house_search_dt_top_gal: {
    sizes: [300, 250]
  },
  in_house_search_mb_top_gal: {
    sizes: [300, 250]
  },
  in_house_search_mb_mid_gal: {
    sizes: [300, 250]
  },
  in_house_search_mb_btm_gal: {
    sizes: [300, 250]
  },
  in_house_cls_dt_top: {
    sizes: [300, 250]
  },
  in_house_cls_mb_top1: {
    sizes: [320, 100]
  }
  // in_house_cls_mb_top: {
  //   sizes: [300, 250]
  // }
}

export const IN_HOUSE_PUBLISH_INFO = 'inhpinfo'

export const MOBILE_AD_UNITS = [
  'in_house_hp_mb_top',
  'in_house_search_mb_top_gal',
  'in_house_search_mb_mid_gal',
  'in_house_search_mb_btm_gal',
  'in_house_cls_mb_top1'
]
export const DESKTOP_AD_UNITS = [
  'in_house_hp_dt_sb',
  'in_house_cat_dt_btm',
  'in_house_search_dt_top_list',
  'in_house_search_dt_top2_list',
  'in_house_search_dt_btm1_gal',
  'in_house_search_dt_btm2_gal',
  'in_house_search_dt_top_gal',
  'in_house_cls_dt_top'
]
