import { CIcon } from '~/icons/types'
export const ciJobs: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M375 318.7c-2.9 2.1-5.8 4-8.9 5.9l63.3 93.8c2.2 3 5.4 5 9.1 5.6 3.7.6 7.4-.3 10.5-2.5 6.3-4.5 7.7-13.2 3.3-19.5l-68.9-89.8c-2.7 2.2-5.5 4.4-8.4 6.5z',
      fill: '#91D4F3'
    },
    {
      d:
        'M73 432.8h182.8c8.8 0 15.9-7.1 15.9-15.9V343c-36.4-6-68.4-25.7-90-55.7-14.9-20.6-23.6-44.4-25.6-69.1h-30.6c-37.7 0-68.4 30.7-68.4 68.4v130.3c0 8.7 7.2 15.9 15.9 15.9z',
      fill: '#91D4F3'
    },
    {
      d:
        'M156 196.6c.3-4.1.8-8.3 1.5-12.4 5.6-34.2 23.3-64.5 50.2-85.9-10.8-11.6-26.2-19-43.3-19-32.5 0-59 26.5-59 59 .1 29.6 22.1 54.2 50.6 58.3z',
      fill: '#91D4F3'
    },
    {
      d:
        'M73 453.8h182.8c20.3 0 36.9-16.5 36.9-36.9v-72.1h1.7c18.2 0 36.2-3.6 52.9-10.6l64.8 96.1.2.3c5.5 7.6 13.6 12.6 22.8 14.1 1.9.3 3.8.5 5.7.5 7.3 0 14.4-2.3 20.4-6.6 15.7-11.3 19.3-33.3 8-49l-70.8-92.2c41.3-47.4 46.2-118.7 8-171.9-41.8-58-120.5-74.1-181.2-39.2-14.7-17.2-36.5-28.1-60.8-28.1-44.1 0-80 35.9-80 80 0 23.9 10.6 45.4 27.2 60-42.6 6.7-75.5 43.8-75.5 88.4v130.3c0 20.3 16.6 36.9 36.9 36.9zm198.7-132.2c-29.5-5.7-55.3-22.2-72.9-46.6-12.2-17-19.6-36.5-21.6-56.8h26.1c37.7 0 68.4 30.7 68.4 68.4v35zm180.6 80.3c4.4 6.3 3 15-3.3 19.5-3 2.2-6.8 3.1-10.5 2.5-3.7-.6-6.9-2.6-9.1-5.6l-63.3-93.8c3-1.8 6-3.8 8.9-5.9 2.9-2.1 5.7-4.3 8.4-6.5l68.9 89.8zm-215.6-298c17.9-10.1 37.7-15 57.3-15 36.5 0 72.5 16.9 95.4 48.8 37.8 52.5 25.9 126.1-26.7 163.9-20.7 14.9-45 22.5-70 22.2v-37.2c0-44.6-32.8-81.6-75.6-88.3 16.7-14.7 27.2-36.1 27.2-60 .1-12.4-2.7-24-7.6-34.4zM219.1 116c2.8 6.9 4.3 14.3 4.3 22.2 0 28.2-19.8 51.8-46.3 57.6.3-2.8.6-5.5 1-8.3 4.7-28.3 19.1-53.4 41-71.5zm-113.6 22.2c0-32.5 26.5-59 59-59 17.1 0 32.5 7.3 43.3 19-26.9 21.5-44.7 51.7-50.2 85.9-.7 4.2-1.2 8.3-1.5 12.4-28.6-4-50.6-28.6-50.6-58.3zM57.1 286.6c0-37.7 30.7-68.4 68.4-68.4h30.6c2.1 24.7 10.8 48.4 25.6 69.1 21.6 30 53.5 49.7 90 55.7v73.9c0 8.8-7.1 15.9-15.9 15.9H73c-8.8 0-15.9-7.1-15.9-15.9V286.6z',
      fill: '#1976D2'
    }
  ],
  name: 'jobs',
  type: 'duotone'
}
