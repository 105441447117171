import { CIcon } from '~/icons/types'
export const ciMotorhome: CIcon = {
  name: 'motorhome',
  type: 'regular',
  width: 512,
  height: 320.6,
  paths: [
    {
      d:
        'M55.9 167.3H147c5.8 0 10.5-4.7 10.5-10.5V86.5c0-5.8-4.7-10.5-10.5-10.5H55.9c-5.8 0-10.5 4.7-10.5 10.5v70.3c0 5.8 4.8 10.5 10.5 10.5zM66.4 97h70.1v49.3H66.4V97z',
      fill: 'currentColor'
    },
    {
      d:
        'M508.8 223.6c-.1-11.1-3.3-33.5-11.4-46.3l-45.9-79.8c-.1-.2-.2-.3-.3-.5-1.8-2.8-4-5.4-6.3-7.7L467.8 59c8.5-11.2 9.9-26 3.6-38.6C465.2 7.8 452.5 0 438.5 0H13.2C7.4 0 2.7 4.7 2.7 10.5v272.1c0 5.8 4.7 10.5 10.5 10.5h53.4c7.1 15.7 22.6 26.8 40.9 27.5h1.9c18.6 0 35.2-10.9 42.7-27.5h163.6c5.7 0 10.4-4.6 10.5-10.3l.1-5.8H345c1.7 24.3 22.1 43.6 46.9 43.6s45.2-19.3 46.9-43.7h60.1c2.8 0 5.5-1.1 7.5-3.1s3.1-4.7 3-7.5l-.6-42.7zm-43.4-59.8H390c-1.4 0-2.4-.1-3.1-.2v-32.5c2.9-.1 7-.1 11.3-.1h48.4l18.8 32.8zM438.5 21c6.1 0 11.4 3.3 14.1 8.7 2.7 5.5 2.1 11.7-1.6 16.5l-24.2 32c-4.5-1.4-9.2-2.2-14-2.2h-97.1c-2.8 0-5.5 1.1-7.4 3.1-2 2-3.1 4.7-3.1 7.4l.2 102.5h-25.7V86.5c0-5.8-4.7-10.5-10.5-10.5H191c-5.8 0-10.5 4.7-10.5 10.5v102.6H23.7V21h414.8zM201.4 97h57.2v175.1h-57.2V97zM23.7 210.1h156.8v62h-24.2c0-.7-.1-1.4-.1-2.2-1.8-23.5-20.9-42.1-44.4-43.2-13.2-.6-25.6 4.1-35.1 13.2-8.7 8.5-13.9 20.1-14.3 32.2H23.7v-62zm110.5 71c-3.4 11.3-14 18.9-25.9 18.4-13.5-.5-24.7-12-24.9-25.6-.1-7.2 2.7-14 7.9-19 4.9-4.8 11.4-7.4 18.2-7.4h1.3c12.9.6 23.5 10.9 24.5 23.9v.1c.1 3.2-.2 6.4-1.1 9.6zm145.4-9v-62h25.8l.1 54.2-.2 7.8h-25.7zm112.2 27.5c-14.3 0-26-11.7-26-26s11.7-26 26-26 26 11.7 26 26-11.6 26-26 26zm43.6-43.7c-7-17.2-23.9-29.3-43.5-29.3-19.7 0-36.6 12.2-43.6 29.4h-21.8l-.4-158.9h86.6c8.3 0 16 4.1 20.7 11.1l1 1.8h-36.3c-13.2 0-18.7 0-23.6 2.3-8.7 4.1-8.7 12.8-8.7 18.5V164c0 7.8 3.1 20.8 24.1 20.8h87.5l1.8 3.1c.1.1.2.3.3.4 4.7 7.4 8.1 24.6 8.2 35.5l.5 32.1h-52.8z',
      fill: 'currentColor'
    }
  ]
}
