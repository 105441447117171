









import { mapGetters, mapState } from 'vuex'
import Search from '~/components/car/classifieds/search/Search'
import DealerSiteLayoutService from '~/services/dealers/site/DealerSiteLayoutService'
import SearchService from '~/services/search/SearchService'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    Search
  },
  computed: {
    ...mapGetters(DEALER_SITE_NS, [
      'activePage',
      'getTemplateOption',
      'componentShouldRenderPage',
      'shouldAnimatePageTransition'
    ]),
    ...mapState(DEALER_SITE_NS, {
      siteTitle: 'title',
      loading: 'loading'
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, ['searchId', 'page'])
  },
  head() {
    const [searchService, dealerSiteLayoutService] = this.$deps(
      SearchService,
      DealerSiteLayoutService
    )
    const head = searchService.createSearchHead(this.$route)
    dealerSiteLayoutService.setPageTitle(head, head.title)
    return head
  }
})
