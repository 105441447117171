import { CIcon } from '~/icons/types'
export const ciCredit: CIcon = {
  width: 938,
  height: 913,
  paths: [
    {
      d:
        'M727.6 193.6c36.5 76.7 53.7 157.5 51.5 242.3 2.2 84.9-15.2 165.4-52 241.8s-88.9 140.3-156 192c61.2-5.2 117.1-27.9 167.6-68.1 50.6-40.2 90.5-92.1 120.1-155.5 29.5-65.6 44.3-136.1 44.3-211.3s-14.7-145.3-44.3-210.2c-29.5-63.4-69.5-115.3-120.1-155.5S632.2 6.3 571 1.1C638.8 52.8 691.1 117 727.6 193.6z',
      fill: 'currentColor'
    },
    {
      d:
        'M657.3 215.8c-31.8-65.6-74.1-117.6-127.2-156C475.5 19.9 416.3 0 352.4 0s-123 19.9-177.6 59.8c-53.1 38.4-95.5 90.4-127.2 156C15.8 283.6 0 356.6 0 434.9c0 78.9 15.8 152.4 47.6 220.2 31.8 65.6 74.1 117.6 127.2 156 54.6 39.8 113.8 59.8 177.6 59.8s123-19.9 177.6-59.8c53.1-38.4 95.5-90.4 127.2-156 31.8-67.8 47.6-141.1 47.6-219.6S689 283.6 657.3 215.8zM247.9 519c11.8 22.9 28.2 41.3 49.2 55.3s43.7 20.7 68.1 19.9c16.9 0 33.5-3.1 49.8-9.4 16.3-6.3 29.5-16.4 39.8-30.4l35.4 100.7c-19.1 14.1-39.6 24.7-61.4 32.1s-43.7 11.1-65.8 11.1c-40.6 0-77.8-11.1-111.8-33.2-36.2-24.3-63.8-55.7-83-94.1-21.4-42.8-32.1-88-32.1-135.5S146.8 343 168.2 301c19.1-39.1 46.8-70.5 83-94.1 34-22.9 71.6-34.3 112.9-34.3 22.9 0 45.1 3.7 66.9 11.1 21.8 7.4 41.8 17.7 60.3 31l-35.4 100.7c-10.3-13.3-23.6-23-39.8-29.3-16.3-6.3-33.2-9.4-50.9-9.4-25.8-.8-49.2 6.3-70.3 21s-37.1 34-48.1 57.5C234.9 381 229 408.3 229 437.1s6.4 56.1 18.9 81.9z',
      fill: 'currentColor'
    }
  ],
  name: 'credit',
  type: 'solid'
}
