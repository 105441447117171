import { CIcon } from '~/icons/types'
export const ciInsurance: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M512 410q0 1-1 2v1l-1 1-1 2-93 93q-3 3-6 3H128q-9 0-9-9V9q0-9 9-9h375q9 0 9 9v401zM137 17v478h264v-85q0-9 9-9h85V17H137zm281 466 33-32 32-33h-65v65zM282 316q0 8-9 8H171v-17h94v-34h-94v-17h94V68h-94V51h102q9 0 9 9v59h59q5 0 6 3l69 77q2 2 2 6v111h-17V213h-68v-17h58l-53-59h-56v179zm59-26q21 0 36.5 15t15.5 36-15.5 36.5T341 393t-36-15.5-15-36.5 15-36 36-15zm0 85q14 0 24-10t10-24q0-9-4.5-17t-12-12.5T341 307q-14 0-24 10t-10 24 10 24 24 10zm-8-119v-17h25v17h-25zm-9 85q0-7 5-12t12-5 12 5 5 12-5 12-12 5-12-5-5-12zm60-273V51h77v17h-77zm-26 34V85h103v17H358zM171 384v-17h51v17h-51zm0 43v-17h119v17H171zm0 42v-17h119v17H171zm17-350v-17h17v17h-17zm34 0v-17h17v17h-17zm-34 35v-17h17v17h-17zm34 0v-17h17v17h-17zm-34 34v-17h17v17h-17zm34 0v-17h17v17h-17zm-34 34v-17h17v17h-17zm34 0v-17h17v17h-17zM76 6l25 74q1 2 1 5v418q0 9-8 9H43q-9 0-9-9v-34H9q-9 0-9-8V205h17v247h17V84q0-2 2-4L61 6q1-6 7-6t8 6zM51 94v307h34V94H51zm17-58L55 77h27zm17 459v-77H51v77h34z',
      fill: 'currentColor'
    }
  ],
  name: 'insurance',
  type: 'light'
}
