import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { GoogleAd, RowsAds } from '~/models/ads'
import { computed, useStore } from '@nuxtjs/composition-api'
import { Ref } from '@vue/composition-api'
import { APP_NS } from '~/store/modules/shared/app/state'
import {
  carDesktopGalleryRowsAds,
  carDesktopListRowsAds,
  carMobileRowsAds
} from '~/constants/ads/search/rows/car'
import {
  plotDesktopGalleryRowsAds,
  plotDesktopListRowsAds,
  plotMobileRowsAds
} from '~/constants/ads/search/rows/plot'
import {
  carMapSearchDesktopBottomFullAd,
  carSearchBottomGalleryAd1,
  carSearchBottomGalleryAd2,
  carSearchDesktopBottomFullAd,
  carSearchMobileBottomAd,
  plotMapSearchDesktopBottomFullAd,
  plotSearchBottomGalleryAd1,
  plotSearchBottomGalleryAd2,
  plotSearchDesktopBottomFullAd,
  plotSearchMobileBottomAd
} from '~/constants/ads/search/search'

export function useSearchRowsAds(): {
  rowsAds: Ref<RowsAds>
  sellersAds: Ref<RowsAds>
} {
  const store = useStore()

  const isPc = computed(
    () =>
      store.getters[`${USER_AGENT_NS}/isPc`] ||
      store.getters[`${USER_AGENT_NS}/isTablet`]
  )
  const appIsCar = computed(() => store.getters[`${APP_NS}/isCar`])
  const viewTypeIsList = computed(
    () => store.getters[`${CLASSIFIED_SEARCH_NS}/viewTypeIsList`]
  )
  const mobileRowsAds = computed(() => {
    if (appIsCar.value) {
      return carMobileRowsAds
    } else return plotMobileRowsAds
  })
  const desktopGalleryRowsAds = computed(() => {
    if (appIsCar.value) {
      return carDesktopGalleryRowsAds
    } else return plotDesktopGalleryRowsAds
  })
  const desktopListRowsAds = computed(() => {
    if (appIsCar.value) {
      return carDesktopListRowsAds
    } else return plotDesktopListRowsAds
  })

  const rowsAds = computed(() => {
    if (isPc.value) {
      if (viewTypeIsList.value) {
        return desktopListRowsAds.value
      } else {
        return desktopGalleryRowsAds.value
      }
    } else {
      return mobileRowsAds.value
    }
  })
  const sellersAds = computed(() => {
    if (isPc.value) {
      return desktopListRowsAds.value
    } else {
      return mobileRowsAds.value
    }
  })
  return { rowsAds, sellersAds }
}

export function useSearchBottomAds(): {
  fullAd: Ref<GoogleAd>
  leftAd: Ref<GoogleAd>
  rightAd: Ref<GoogleAd>
} {
  const store = useStore()
  const isPc = computed(() => store.getters[`${USER_AGENT_NS}/isPc`])
  const appIsCar = computed(() => store.getters[`${APP_NS}/isCar`])
  const desktopFullAd = computed(() => {
    if (appIsCar.value) {
      return carSearchDesktopBottomFullAd
    } else return plotSearchDesktopBottomFullAd
  })
  const mobileFullAd = computed(() => {
    if (appIsCar.value) {
      return carSearchMobileBottomAd
    } else return plotSearchMobileBottomAd
  })

  const leftAd = computed(() => {
    if (appIsCar.value) {
      return carSearchBottomGalleryAd1
    } else return plotSearchBottomGalleryAd1
  })

  const rightAd = computed(() => {
    if (appIsCar.value) {
      return carSearchBottomGalleryAd2
    } else return plotSearchBottomGalleryAd2
  })

  const fullAd = computed(() => {
    if (isPc.value) {
      return desktopFullAd.value
    } else {
      return mobileFullAd.value
    }
  })

  return { fullAd, leftAd, rightAd }
}

export function useMapSearchBottomAds(): {
  fullAd: Ref<GoogleAd>
} {
  const store = useStore()
  const appIsCar = computed(() => store.getters[`${APP_NS}/isCar`])
  const fullAd = computed(() => {
    if (appIsCar.value) {
      return carMapSearchDesktopBottomFullAd
    } else return plotMapSearchDesktopBottomFullAd
  })

  return { fullAd }
}
