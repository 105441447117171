import { CIcon } from '~/icons/types'
export const ciSteeringWheel: CIcon = {
  width: 526,
  height: 512,
  paths: [
    {
      d:
        'M256 0q103 0 176.5 75T506 256q0 70-33.5 129t-91 93T256 512q-103 0-176.5-75T6 256 79.5 75 256 0zm60 452q39-12 69.5-39t49.5-66.5 22-84.5q0-5-4-9t-9-4l-33 2q-6 0-9-4l-70 28q-7 32-35 49l-9 80q5 2 6 8l6 31q3 10 13 10 2 0 3-1zm8-187 73-29-10-39-80 11h-1q-2 0-4-1-19-19-46-19-26 0-45 18-2 2-5 2l-80-11-10 38 71 30q3 0 4 4 6 31 32 46 2 2 3 4l10 83h40l10-83q0-3 3-4 26-15 32-46 1-3 3-4zm-222-14-34-2q-6 0-9.5 3.5T55 261q4 69 42.5 120.5T195 452q1 1 4 1 10 0 12-10l6-31q1-6 7-9l-10-79q-27-18-35-50l-67-27q-4 4-10 4zm289-74 29-15q11-7 5-18-27-43-72-68.5T256 50t-96.5 25.5T87 144q-3 5-1.5 10.5T92 162l30 15q5 2 6 8l76 11q23-20 52-20t52 20l77-11q1-6 6-8zM256 38h12V13q-6-1-12-1t-12 1v25h12zm0 462q98 0 168-71.5T494 256q0-96-63.5-166T274 13v26q50 4 92 30t69 69q1 3 2 5 12 22 20 44 1 2-2 4h-1q-2 0-3-2-5-16-13-32v1q-3 10-13 15l-28 14v3q1 0 0 2l13 46 33-2h1q10 0 18 8t7 19q-3 47-22.5 88t-52 70-75.5 43q-4 1-7 1-16 4-31 6-3 0-3-2.5t2-3.5q10-1 20-3-9-6-12-16l-6-32h-50l-1 1h-2l-6 31q-1 5-4.5 9.5t-8.5 7-11 2.5q-2 0-7-1-64-21-104.5-76T43 262q-1-5 1-10 0-14 2-26 0-4 3-3 4 0 3 4-1 7-2 16 7-7 17-7h2l33 2 1-1 13-50-29-15q-10-5-13-15t3-19q27-43 69-69t92-30V13q-93 7-156.5 77T18 256q0 66 32 122.5t87 89T256 500zm24-243.5q0 9.5-7 16.5t-17 7-17-7-7-16.5 7-16.5 17-7 17 7 7 16.5zM256 276q19 0 19-19 0-9-5.5-14.5T256 237t-13.5 6-5.5 14 5.5 13.5T256 276zm-16 9q8 5 3 13-3 4-8 4-1 0-5-1v-1q-8-4-3-12 5-9 12-3h1zm-5-56q-5 0-8-5-5-7 3-12v-1q8-4 13 3t-3 13q-3 2-5 2zm37-2q-8-4-3.5-12t12.5-4l1 1q7 4 3 12-2 5-8 5-1 0-5-2zm26 19q4 0 6.5 2.5t2.5 6.5q0 10-9 10t-9-9v-1q0-4 2.5-6.5t6.5-2.5zm-84 0q2 0 4.5 1.5t3.5 3.5 1 4v1q0 10-9 10-4 0-6.5-3t-2.5-6v-2q0-3 2.5-6t6.5-3zm58 39q8-5 13 3 5 7-3 12-2 2-5 2-5 0-8-5-5-7 3-12zM54 201q-3-1-2-4l1-1q1-3 4-2t2 3l-1 2q-2 2-3 2h-1zm-3 18q-3-1-3-4 1-5 1-7 1-3 3-3 3 1 3 4-1 5-1 7 0 3-3 3zm184 246q4 0 3 3 0 3-3 3h-4q-4 0-2-4 0-4 3-3zm26 1q4 0 4 3t-4 3h-14q-3 0-3-3t3-3h14zm200-265q1 1 1.5 3.5t.5 3.5q1 3-2 4h-1q-2 0-3-2 0-1-.5-2.5t-.5-3-1-2.5q-1-3 3-4 2-1 3 3z',
      fill: 'currentColor'
    }
  ],
  name: 'steering-wheel',
  type: 'light'
}
