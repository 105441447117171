











import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { Corner, FloatingCornerElementName } from '~/models/layout'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REMOVE_CORNER_ELEMENT } from '~/store/modules/shared/page/mutation-types'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  data: () => ({
    icons: {
      chevronUp: faChevronUp
    },
    showBtt: false
  }),
  computed: {
    ...mapGetters(PAGE_NS, {
      renderButton: 'backToTopButtonShown'
    }),
    ...mapDeps({
      scrollService: ScrollService
    })
  },
  mounted() {
    this.addCornerElement({
      name: FloatingCornerElementName.BACK_TO_TOP_BUTTON,
      corner: Corner.BOTTOM_RIGHT
    })

    window.addEventListener('scroll', this.onScroll, { passive: true })
  },
  beforeDestroy() {
    this.removeCornerElement({
      name: FloatingCornerElementName.BACK_TO_TOP_BUTTON,
      corner: Corner.BOTTOM_RIGHT
    })

    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapActions(PAGE_NS, ['addCornerElement']),
    ...mapMutations(PAGE_NS, { removeCornerElement: REMOVE_CORNER_ELEMENT }),
    onScroll() {
      this.showBtt =
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
    },
    backToTop() {
      this.$analytics.recordEvent({
        namespace: 'n_back_to_top',
        action: 'click'
      })
      this.scrollService.scrollToTop({ duration: 500, easing: 'ease-out' })
    }
  }
})
