import { CIcon } from '~/icons/types'
export const ciAntiqueBalcony: CIcon = {
  name: 'antique-balcony',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M472 280H40c-13.234 0-24 10.767-24 24a8 8 0 0 0 16 0c0-4.411 3.589-8 8-8h6.556L27.983 407.443a72.072 72.072 0 0 0 9.282 48.884L48 474.216V503c0 4.963 4.038 9 9 9h398c4.962 0 9-4.037 9-9v-28.784l10.735-17.889a72.072 72.072 0 0 0 9.282-48.884L465.444 296H472c4.411 0 8 3.589 8 8a8 8 0 0 0 16 0c0-13.233-10.766-24-24-24zm-50.985 168.095L411.471 464h-23.654l5.652-14.13a72.249 72.249 0 0 0 4.71-34.694L384.938 296h24.285l19.012 114.074a56.063 56.063 0 0 1-7.22 38.021zM346.603 464l5.335-18.69a71.74 71.74 0 0 0 2.541-25.456L344.659 296h24.18l13.438 120.943a56.173 56.173 0 0 1-3.664 26.983L370.584 464h-23.981zm-42.14 0 1.646-29.252c.096-1.711.131-3.449.104-5.168L304.126 296h24.483l9.92 125.119a55.797 55.797 0 0 1-1.976 19.798L329.964 464h-25.501zM264 464V296h24.124l2.091 133.83a56.122 56.122 0 0 1-.081 4.021L288.438 464H264zm-40.485 0-1.872-29.944a56.464 56.464 0 0 1-.1-4.467L223.863 296H248v168h-24.485zm-122.986 0-9.544-15.905a56.06 56.06 0 0 1-7.22-38.021L102.777 296h24.285L113.82 415.176a72.249 72.249 0 0 0 4.71 34.694l5.652 14.13h-23.653zm40.887 0-8.03-20.073a56.185 56.185 0 0 1-3.664-26.983L143.161 296h24.175l-9.884 123.77a71.761 71.761 0 0 0 2.575 25.627L165.376 464h-23.96zm40.608 0-6.62-23.025a55.789 55.789 0 0 1-2.003-19.932L183.387 296h24.473l-2.314 133.311c-.034 1.902.01 3.834.129 5.742l1.81 28.947h-25.461zM43.765 410.074 62.777 296h23.78L67.983 407.443a72.072 72.072 0 0 0 9.282 48.884L81.869 464h-21.34l-9.544-15.905a56.063 56.063 0 0 1-7.22-38.021zM448 496H64v-16h31.992l.015.001.015-.001h39.954l.026.002.042-.002h39.928l.024.002.028-.002h159.952l.028.002.024-.002h39.928l.042.002.026-.002h39.954l.015.001.015-.001H448v16zm13.015-47.905L451.471 464h-21.34l4.604-7.673a72.072 72.072 0 0 0 9.282-48.884L425.444 296h23.779l19.012 114.074a56.063 56.063 0 0 1-7.22 38.021z'
    },
    {
      d:
        'M104 264a8 8 0 0 0 8-8V16h288v240a8 8 0 0 0 16 0V16c0-8.822-7.178-16-16-16H112c-8.822 0-16 7.178-16 16v240a8 8 0 0 0 8 8z'
    },
    {
      d:
        'M376 264a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8H136a8 8 0 0 0-8 8v216a8 8 0 0 0 16 0V136h79.999a8 8 0 0 0 0-16H144V48h104v208a8 8 0 0 0 16 0V136h104v120a8 8 0 0 0 8 8zM264 120V48h104v72H264z'
    }
  ]
}
