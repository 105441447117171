













import CCount from '~/components/shared/configurable/CCount.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { CCount },
  props: {
    text: {
      type: String,
      default: null
    },
    count: {
      type: [String, Number],
      default: -1
    }
  }
})
