import { CIcon } from '~/icons/types'
export const ciTradesMatch: CIcon = {
  width: 811.7,
  height: 709.5,
  paths: [
    {
      d:
        'M798.5 505.5 674.9 381.9c-17.6-17.6-46.1-17.6-63.6 0-17.6 17.6-17.6 46.1 0 63.6l48.6 48.6H45c-24.9 0-45 20.1-45 45s20.1 45 45 45h614.8l-48.6 48.6c-17.6 17.6-17.6 46.1 0 63.6 17.6 17.6 46.1 17.6 63.6 0l123.6-123.6c9.2-9.2 13.6-21.5 13.1-33.6.6-12.1-3.8-24.3-13-33.6z'
    },
    {
      d:
        'm13.2 204 123.6 123.6c17.6 17.6 46.1 17.6 63.6 0 17.6-17.6 17.6-46.1 0-63.6l-48.6-48.6h614.8c24.9 0 45-20.1 45-45s-20.1-45-45-45H151.9l48.6-48.6c17.6-17.6 17.6-46.1 0-63.6-17.6-17.6-46.1-17.6-63.6 0L13.2 136.8C3.9 146.1-.4 158.3 0 170.4c-.4 12.1 3.9 24.3 13.2 33.6z'
    }
  ],
  name: 'trades-match',
  type: 'regular'
}
