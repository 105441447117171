import { CIcon } from '~/icons/types'
export const ciWallet: CIcon = {
  name: 'wallet',
  type: 'solid',
  width: 469.341,
  height: 469.341,
  paths: [
    {
      d:
        'M437.337 384.007H362.67c-47.052 0-85.333-38.281-85.333-85.333s38.281-85.333 85.333-85.333h74.667a10.66 10.66 0 0 0 10.667-10.667v-32c0-22.368-17.35-40.559-39.271-42.323l-61.26-107c-5.677-9.896-14.844-16.969-25.813-19.906-10.917-2.917-22.333-1.385-32.104 4.302L79.553 128.007H42.67c-23.531 0-42.667 19.135-42.667 42.667v256c0 23.531 19.135 42.667 42.667 42.667h362.667c23.531 0 42.667-19.135 42.667-42.667v-32a10.66 10.66 0 0 0-10.667-10.667zM360.702 87.411l23.242 40.596h-92.971l69.729-40.596zm-238.749 40.596L300.295 24.184c4.823-2.823 10.458-3.573 15.844-2.135 5.448 1.458 9.99 4.979 12.813 9.906l.022.039-164.91 96.013h-42.111z'
    },
    {
      d:
        'M437.337 234.674H362.67c-35.292 0-64 28.708-64 64s28.708 64 64 64h74.667c17.646 0 32-14.354 32-32v-64c0-17.646-14.354-32-32-32zm-74.667 85.333c-11.76 0-21.333-9.573-21.333-21.333 0-11.76 9.573-21.333 21.333-21.333 11.76 0 21.333 9.573 21.333 21.333.001 11.76-9.572 21.333-21.333 21.333z'
    }
  ]
}
