var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-rounded-lg tw-border tw-border-solid tw-border-[4px] tw-overflow-hidden",class:[
    _vm.primaryBg,
    _vm.borderColor,
    { 'tw-p-6': _vm.sizeString === '300_250', 'tw-flex-shrink-0': _vm.noShrink }
  ],style:(_vm.appliedStyle)},[_c('div',{staticClass:"tw-flex tw-items-stretch tw-h-full",class:{
      'tw-flex-col tw-rounded-md tw-h-full tw-gap-2': _vm.sizeString === '300_250'
    }},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-relative tw-shrink-0",class:[_vm.imageContainerClasses],style:({
        backgroundImage:
          _vm.sizeString === '300_250'
            ? ("url(" + (_vm.item.square_image_url) + ")")
            : undefined,
        width: _vm.imgWidth
      })},[(_vm.sizeString === '300_250')?_c('span',{staticClass:"tw-absolute tw-w-full tw-h-full blur-backdrop tw-bg-white/10 tw-pointer-events-none tw-z-[0]"}):_vm._e(),_vm._v(" "),(_vm.item.square_image_url)?_c('CImg',{staticClass:"tw-h-full tw-object-cover tw-z-[1]",class:_vm.sizeString === '300_250'
            ? 'tw-rounded-full tw-w-auto on-top'
            : 'tw-w-full',attrs:{"src":_vm.item.square_image_url,"draggable":"false"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col  tw-flex-1",class:[
        _vm.gapClass,
        {
          'tw-text-center tw-p-2 tw-self-center': _vm.sizeString !== '300_250',
          '!tw-p-4': _vm.sizeString === '970_250'
        }
      ]},[_c('span',{staticClass:"tw-my-auto",class:[
          _vm.textColorClass,
          _vm.textSizeClass,
          { 'tw-font-light': _vm.sizeString === '970_250' }
        ]},[_vm._v(_vm._s(_vm.item.subtitle))]),_vm._v(" "),_c('span',{staticClass:"tw-cursor-pointer tw-px-4 tw-font-semibold tw-rounded-full tw-text-grey-800 tw-bg-grey-50 tw-text-center",class:[
          _vm.btnTextSizeClass,
          _vm.sizeString === '300_250' ? 'tw-ml-auto' : 'tw-self-center',
          ['728_90', '320_100'].includes(_vm.sizeString) ? 'tw-py-1' : 'tw-py-2'
        ]},[_vm._v(_vm._s(_vm.item.title))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }