import { CIcon } from '~/icons/types'
export const ciDeals: CIcon = {
  width: 512,
  height: 368.6,
  paths: [
    {
      d:
        'M490.6 164.8 132 3.6C126.6 1.2 120.8 0 115.2 0c-7.8 0-15.4 2.3-21.7 6.8L16.9 51.4C6.2 59.1.5 71.6 1.9 84.7l16.7 88.8c1.3 13.3 10.2 25.1 23.1 30.9l358.6 161.1c4.6 2.1 9.5 3.1 14.2 3.1 11.9 0 22.9-6.3 27.8-17.2l65.6-146c6.8-15.1-1-33.3-17.3-40.6zm-1.9 32-65.6 145.9c-1.6 3.6-5.6 4.9-8.7 4.9-1.9 0-3.7-.4-5.5-1.2L50.3 185.3c-6.1-2.7-10.2-8-10.8-13.8l-.1-.9-.2-.9-16.5-87.9c-.3-5 1.8-9.7 5.8-12.9L104 25l.9-.5.8-.6c2.6-1.9 5.8-2.9 9.4-2.9 2.8 0 5.6.6 8.2 1.8L482 184c3.2 1.4 5.6 3.9 6.7 6.8.6 1.5 1 3.7 0 6z'
    },
    {
      d:
        'M78.9 81.1c-10.8 0-19.5 9.6-19.5 20.4S68.1 121 78.9 121s19.5-8.7 19.5-19.5-8.7-20.4-19.5-20.4zm98.8 56.9c7-15.5 9.4-30.4 7.2-43.6-2.2-12.6-8.1-20.9-17.2-25l-18.2-8.1c-3.8-1.8-9.3 2.3-12.4 9l-42.2 94c-3.1 6.8-2.4 13.5 1.4 15.3l18.2 8.1c9.2 4.1 19.4 2.8 30.2-3.9 11.2-7 20.9-18.8 27.8-34.4l5.2-11.4zm-16.2-7.4-5.3 11.6c-3.3 7.4-7.4 12.3-12.8 15.7-5.9 3.7-11.2 4.2-16.4 2l-8.7-3.9 28.2-62.7 8.7 3.9c5.2 2.4 8.2 7 9.3 13.8 1.1 6.3.2 12.6-3 19.6zm58.3 47.7c3.8 1.8 9.3-2.3 12.4-9l1.6-3.6c3.1-6.8 2.4-13.6-1.4-15.3l-25.9-11.6 7-15.5 30.5 13.8c3.8 1.8 9.3-2.3 12.4-9l1.5-3.4c3.1-6.8 2.4-13.6-1.4-15.3l-39.9-17.9c-3.8-1.8-9.3 2.3-12.4 9l-42.3 93.9c-3.1 6.8-2.4 13.6 1.4 15.4l39.9 17.9c3.8 1.8 9.3-2.3 12.4-9l1.6-3.6c3.1-6.8 2.4-13.6-1.4-15.3l-30.5-13.7 8.7-19.4 25.8 11.6zm84.2-47.5-3-1.4c-2.6-1.2-6.4.3-9.5 4.2l-66.6 82.9c-2.7 3.2-4.5 7.7-4.8 11.8-.3 4.2 1.1 7.2 3.4 8.2l2.7 1.2c2.7 1.3 6.5-.5 9.4-4.2l14.9-18.4 23.2 10.4-3.9 23.3c-.9 4.9.3 8.7 3.1 9.9l2.7 1.2c3.8 1.8 9.3-2.3 12.4-9 .8-2 1.4-4.1 1.7-5.9l17.5-104.4c.8-4.8-.5-8.6-3.2-9.8zm-25 74.1-16.6-7.5 22.5-28.2-5.9 35.7zm65.3 52.7-26.7-12.1 35.1-78.3c3.1-6.8 2.4-13.5-1.4-15.3l-2.5-1.1c-3.8-1.8-9.3 2.3-12.4 9l-42.2 94c-3.1 6.8-2.4 13.6 1.4 15.3l36.1 16.3c3.7 1.7 9.3-2.3 12.4-9l1.6-3.6c3.1-6.7 2.4-13.5-1.4-15.2zm79.5-74.9c-7.6-3.3-15.1-3.7-22.8-.6-11.5 4.5-18.3 15.1-21.8 23-5 11.3-6.2 22.1-3.3 30.3 2.1 6.5 6.5 12.8 13.4 19.6 7.1 7.4 9.2 12 9.8 13.9.6 2.3.3 3.3-.3 4.7-.3.6-.8 2-4 3.2-3.8 1.5-8.1 1.2-12.5-.8-4.7-2.1-8.1-5.4-10.1-10-1.4-3.3-3.3-5.3-5.3-6.3-4.2-1.9-8.9 1.9-11.9 6.5-1.4 2.3-4.4 8-3.5 14.7.3 2 .8 3.8 1.7 5.9 3.3 8 8.9 13.8 16.6 17.2 7.6 3.4 15.3 3.7 23 .6 11.7-4.5 18.7-15.4 22.4-23.6 5.2-11.6 6.4-22.5 3.4-31.3-2.2-6.9-6.7-13.3-13.4-20-7.2-7-9.1-11.4-9.6-13.4-.6-2.3-.2-3.3.2-4 .4-.8 2-2 3.5-2.5 3.5-1.5 7.6-1.3 12.1.8 4.2 1.9 6.9 5.1 7.8 9.2 1.2 4.3 2.9 7 5.2 8 3.4 1.5 7.8-.8 11.3-5.7 1.5-2.4 4.3-7.2 4.3-13.7.1-2.2-.3-4.3-.9-6.7-2.2-9.2-7.4-15.4-15.3-19z'
    }
  ],
  name: 'deals',
  type: 'regular'
}
