import { CIcon } from '~/icons/types'

export const ciPlay: CIcon = {
  width: 49,
  height: 49,
  paths: [
    {
      d:
        'M33.94 24.793a1.16 1.16 0 0 0-.133-.314 1.553 1.553 0 0 0-.51-.503l-1.298-.864-3.169-2.101-3.843-2.549-3.31-2.194c-.534-.355-1.064-.72-1.605-1.065l-.022-.014c-.4-.266-.973-.237-1.375 0a1.373 1.373 0 0 0-.671 1.179v15.445c0 .71-.009 1.419 0 2.13v.031a1.38 1.38 0 0 0 .67 1.18c.403.237.976.265 1.376 0l1.325-.878 3.169-2.101 3.842-2.548 3.31-2.195c.534-.355 1.073-.706 1.605-1.065l.023-.014.029-.022c.19-.113.35-.274.46-.468l.024-.052a1.268 1.268 0 0 0 .143-.437 1.178 1.178 0 0 0-.031-.56l-.01-.02Zm-6.49 2.176-3.843 2.549-2.887 1.913V18.907c.81.54 1.626 1.08 2.446 1.62l3.843 2.549 3.157 2.094-2.717 1.8Z',
      fill: '#ffffff'
    }
  ],
  ellipses: [],
  name: 'play',
  type: 'regular'
}
