



















import CAdPlaceholder from '~/components/shared/configurable/ad/placeholder/CAdPlaceholder.vue'
import {
  containerSizeProp,
  AdContainerSizeCssRules,
  placeholderProp,
  containerSizeMappingProp
} from '~/models/ads'
import AdViewService from '~/services/ads/AdViewService'
import CAdInlineStyle from '~/components/shared/configurable/ad/container/CAdInlineStyle.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    CAdInlineStyle,
    CAdPlaceholder
  },
  props: {
    containerSize: containerSizeProp,
    placeholder: placeholderProp,
    containerSizeMapping: containerSizeMappingProp,
    unit: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    noSizeMappingStyleRules(): AdContainerSizeCssRules {
      if (this.containerSizeMapping.length === 0) {
        const rules = this.$dep(AdViewService).getAdContainerSizeCssRules(
          this.containerSize
        )

        if (process.client && rules['min-width']) {
          const containerWidth = parseInt(rules['min-width'].split('px')[0])
          const parentWidth =
            this.$el?.parentElement?.clientWidth || window.outerWidth
          if (containerWidth > parentWidth) {
            rules['min-width'] = parentWidth + 'px'
          }
        }
        return rules
      }
      return null
    },
    containerResponsiveProps(): any {
      if (this.containerSizeMapping.length) {
        const attrs = this.$dep(AdViewService).getAdContainerResponsiveProps(
          this.containerSizeMapping
        )
        return attrs
      }
      return null
    }
  }
})
