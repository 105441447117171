import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { LocationSuggestion } from '~/models/location/autocomplete/types'
import { invalidBodyError } from '../../errors'

@containerScoped()
export default class LocationSuggestionService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async getLocations(query: string): Promise<Map<string, LocationSuggestion>> {
    const response = await this.http.get('/api/autocomplete/locations/', {
      params: { q: query }
    })
    const body = response.data
    if (!body || !Array.isArray(body.suggestions)) {
      throw invalidBodyError(body)
    }

    return new Map(
      body.suggestions.map((l: any) => [
        l.id.toString(),
        this.formatLocationSuggestion(l)
      ])
    )
  }

  private formatLocationSuggestion(l: any): LocationSuggestion {
    return {
      id: l.id.toString(),
      name: l.name,
      area: l.subvalue,
      weight: l.weight && l.weight[0]
    }
  }
}
