import { singleton } from '~/decorators/dependency-container'
import { AdContainerSizeCssRules } from '~/models/ads'

@singleton()
/**
 * Performs presentational computations related to ads.
 */
export default class AdViewService {
  getAdContainerSizeCssRules(
    containerSize?: [number, number]
  ): AdContainerSizeCssRules {
    if (!containerSize) {
      return {}
    }

    const [containerWidth, containerHeight] = containerSize
    const rules: AdContainerSizeCssRules = {}
    if (this.validateSizeDimension(containerWidth)) {
      rules['min-width'] = containerWidth + 'px'
    }
    if (this.validateSizeDimension(containerHeight)) {
      rules['min-height'] = containerHeight + 'px'
    }
    return rules
  }

  getAdPlaceholderCssRules(
    containerSize?: [number, number]
  ): AdContainerSizeCssRules {
    if (!containerSize) {
      return {}
    }

    const [containerWidth, containerHeight] = containerSize
    const rules: AdContainerSizeCssRules = {}
    if (this.validateSizeDimension(containerWidth)) {
      rules.width = containerWidth + 'px'
    }
    if (this.validateSizeDimension(containerHeight)) {
      rules.height = containerHeight + 'px'
    }
    return rules
  }

  getMinSizeDimensions(
    sizeTupleOrTuples: [number, number] | [number, number][]
  ): [number, number] {
    if (
      Array.isArray(sizeTupleOrTuples) &&
      Number.isInteger(sizeTupleOrTuples[0]) &&
      Number.isInteger(sizeTupleOrTuples[1])
    ) {
      return sizeTupleOrTuples as [number, number]
    }
    const sizeTuples = sizeTupleOrTuples as [number, number][]
    let minW: number = sizeTuples[0][0] // width
    let minH: number = sizeTuples[0][1] // height
    for (const [w, h] of sizeTuples) {
      if (minW > w) {
        minW = w
      }
      if (minH > h) {
        minH = h
      }
    }
    return [minW, minH]
  }

  getMaxSizeDimensions(
    sizeTupleOrTuples: [number, number] | [number, number][]
  ): [number, number] {
    if (
      Array.isArray(sizeTupleOrTuples) &&
      Number.isInteger(sizeTupleOrTuples[0]) &&
      Number.isInteger(sizeTupleOrTuples[1])
    ) {
      return sizeTupleOrTuples as [number, number]
    }
    const sizeTuples = sizeTupleOrTuples as [number, number][]
    let maxW: number = sizeTuples[0][0] // width
    let maxH: number = sizeTuples[0][1] // height
    for (const [w, h] of sizeTuples) {
      if (maxW < w) {
        maxW = w
      }
      if (maxH < h) {
        maxH = h
      }
    }
    return [maxW, maxH]
  }

  getAdContainerResponsiveProps(sizeMappings: any[]): any {
    if (
      sizeMappings.length === 0 ||
      !Array.isArray(sizeMappings[0]) ||
      !Array.isArray(sizeMappings[0][0])
    ) {
      return ''
    }

    const queries: any[] = []
    for (const [dimensions, sizes] of sizeMappings) {
      const [dimW, dimH] = dimensions

      const maxSizes =
        sizes.length === 0 ? [0, 0] : this.getMaxSizeDimensions(sizes)

      queries.push({
        query: `@media screen and (min-width: ${dimW}px) and (min-height: ${dimH}px)`,
        className: `min-w-${dimW}-h-${dimH}`,
        queryValue:
          sizes.length === 0
            ? 'display: none'
            : `display: block; min-width: ${maxSizes[0]}px; min-height: ${maxSizes[1]}px;`,
        placeholderQueryValue: `width: ${maxSizes[0]}px; height: ${maxSizes[1]}px;`
      })
    }

    queries.reverse() // to be in correct css order

    const classes = queries.map(q => q.className)

    return {
      classes,
      queries
    }
  }

  private validateSizeDimension(size: number) {
    return Number.isFinite(size) && size >= 0
  }
}
