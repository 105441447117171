import { CIcon } from '~/icons/types'
export const ciPowerButton: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M370 90q52 30 82 82.5T482 286q0 93-66 159.5T256 512 96 445.5 30 286q0-61 30-113.5T142 90q8-5 13 4 5 8-3 13-48 28-75.5 76T49 286q0 85 60.5 145.5T256 492t146.5-60.5T463 286q0-37-12.5-71T415 153t-55-46q-8-5-3-13.5t13-3.5zM256 236q-10 0-10-10V10q0-10 10-10t10 10v216q0 10-10 10zm0 59q-10 0-10-9v-15q0-10 10-10t10 10v15q0 9-10 9z',
      fill: 'currentColor'
    }
  ],
  name: 'power-button',
  type: 'light'
}
