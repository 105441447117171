import { CIcon } from '~/icons/types'
export const ciExcavator: CIcon = {
  width: 512,
  height: 434.1,
  paths: [
    {
      d:
        'M41.2 185.1c-5.8 0-10.5 4.7-10.5 10.5v113.1c0 5.8 4.7 10.5 10.5 10.5h146V185.1h-146zM465.9 9c-.5-3.6-2.8-6.5-5.9-8l-55.8 104.4L460.7 329l44.6-40.4L465.9 9z'
    },
    {
      d:
        'M372.8 0c-2.9 0-5.6 1.2-7.6 3.3L234 141.7h-15.2c-5.8 0-10.5 4.7-10.5 10.5v167h139.8c5.8 0 10.5-4.7 10.5-10.5v-65.9c0-1.4-.3-2.7-.8-4l-24-59.1 61.7-102.6L436.7 0h-63.9zm-35.2 244.8v1.8h-56.9c-8.3 0-15-6.7-15-15v-68.9h38.5l7.9 19.4.6 1.5 24.9 61.2zM422.4 413h-15.9c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h91.8c5.8 0 10.5-4.7 10.5-10.5V313.8l-54.5 49.4-31.9 49.8zM280 340.2H99.3c11.7 12.2 18.8 28.7 18.8 46.9s-7.2 34.7-18.8 46.9H280c-11.7-12.2-18.8-28.7-18.8-46.9s7.1-34.7 18.8-46.9zm96 49.3c-1.3 24.8-21.7 44.5-46.8 44.5h-2.6c.8 0 1.7.1 2.5.1 25.1 0 45.7-19.8 46.9-44.6zm0-4.8c-1.3-24.8-21.8-44.6-46.9-44.6-.9 0-1.7 0-2.5.1h2.6c25.1 0 45.6 19.7 46.8 44.5z'
    },
    {
      d:
        'M376 389.5c0-.8.1-1.6.1-2.4s0-1.6-.1-2.4c-1.3-24.8-21.7-44.5-46.8-44.5h-2.6c-24.8 1.3-44.4 21.8-44.4 46.9s19.7 45.6 44.4 46.9h2.6c25.1 0 45.6-19.7 46.8-44.5z'
    },
    {
      d:
        'M376 384.7c0 .8.1 1.6.1 2.4 0-.8 0-1.6-.1-2.4zm.1 2.4c0 .8 0 1.6-.1 2.4.1-.8.1-1.6.1-2.4zM3.3 389.5c1.3 24.8 21.8 44.6 46.9 44.6.9 0 1.7 0 2.5-.1h-2.6C25 434 4.5 414.3 3.3 389.5zm46.8-49.3h2.6c-.8 0-1.7-.1-2.6 0-25.1-.1-45.6 19.7-46.8 44.5 1.2-24.8 21.7-44.5 46.8-44.5z'
    },
    {
      d:
        'M97.1 387.1c0-25.1-19.7-45.6-44.4-46.9h-2.6c-25.1 0-45.6 19.7-46.8 44.5 0 .8-.1 1.6-.1 2.4s0 1.6.1 2.4C4.5 414.3 25 434 50.1 434h2.6c24.8-1.3 44.4-21.8 44.4-46.9z'
    },
    {
      d:
        'M3.3 389.5c0-.8-.1-1.6-.1-2.4 0 .8 0 1.6.1 2.4zm-.1-2.4c0-.8 0-1.6.1-2.4-.1.8-.1 1.6-.1 2.4z'
    }
  ],
  name: 'excavator',
  type: 'solid'
}
