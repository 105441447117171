import { CIcon } from '~/icons/types'
export const ciVanMinibus: CIcon = {
  name: 'van-mini-bus',
  type: 'regular',
  width: 512,
  height: 277.3,
  paths: [
    {
      d:
        'M509.7 174.4c-.1-8.7-1.2-25.1-7.4-37.7-4.8-9.7-14.2-17.6-23.3-25.3-6.6-5.6-13.4-11.3-17.6-17.1-23.7-32.8-41.8-68.8-42-69.2-.2-.3-.4-.7-.6-1C409.1 9 392.5 0 374.6 0h-321C36.1 0 21.5 12.5 18.7 29.7L3.2 127.2c-.8 5.1-1.2 10.3-1.2 15.5v58.6c0 19.2 15.6 34.8 34.8 34.8h16.4c5.6 23 25.8 40.3 50.4 41.2h2.1c23.9 0 45-15.6 51.9-38.6.3-.9.5-1.8.7-2.6h180.3c.6 0 1.2-.1 1.8-.2 5.7 23.7 27.1 41.4 52.6 41.4 25.4 0 46.8-17.6 52.5-41.3h31.4c9 0 17.4-3.5 23.7-9.9 6.3-6.4 9.7-14.8 9.6-23.8l-.5-27.9zM77.5 105.6 84 58.9h84.6v46.7H77.5zm112.1-46.7h82.2l14.3 46.7h-96.5V58.9zm121.6 46.8h-3.1L293.8 59h120.1c7.6 13.2 17.9 30.2 29.8 46.7H311.2zm-173.8 127c-4.3 14.5-17.9 24.2-33.1 23.6-17.3-.7-31.5-15.3-31.8-32.6-.1-9.3 3.5-17.9 10.1-24.3 6.2-6 14.3-9.3 23-9.3h1.7c16.6.8 30 13.9 31.3 30.4.4 4.3-.1 8.3-1.2 12.2zm255.5 23.6c-18.2 0-33.1-14.8-33.1-33.1s14.8-33.1 33.1-33.1S426 205 426 223.3s-14.9 33-33.1 33zm92.6-44.9c-2.3 2.4-5.4 3.6-8.7 3.6h-30.4c-4-26-26.4-45.9-53.5-45.9s-49.5 20-53.5 46H159.1c-3.8-25.1-24.9-44.5-50.7-45.8-15.1-.8-29.5 4.6-40.4 15.1-8.6 8.2-14.1 19.1-15.9 30.8H36.7c-7.6 0-13.8-6.2-13.8-13.8v-58.6c0-4.1.3-8.2 1-12.2l15.6-97.5c1.1-7 7.1-12.1 14.1-12.1h321c10.6 0 20.4 5.2 26.3 14.1.3.6.8 1.6 1.5 2.9H74.9c-5.2 0-9.7 3.9-10.4 9.1l-9.4 67.7c-.4 3 .5 6 2.5 8.3s4.9 3.6 7.9 3.6h399.2c.3.3.6.5 1 .8 7.4 6.3 15.1 12.7 18 18.6 3.2 6.4 5.1 17.1 5.2 28.6l.4 28c-.2 3.3-1.4 6.4-3.8 8.7z'
    }
  ]
}
