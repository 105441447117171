















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

export default defineComponent({
  props: {
    typedText: {
      tyup: String,
      required: true
    },
    categoryPath: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  computed: {
    caretIcon() {
      return faCaretRight
    }
  },
  methods: {
    isLast(index: number) {
      return index === this.categoryPath.length - 1
    }
  }
})
