export function resolveCategory(categoryDicts: any, categoryArray: any) {
  if (categoryDicts.constructor !== Object) {
    return categoryDicts
  }
  for (let i = categoryArray.length - 1; i >= 0; i--) {
    if (categoryDicts[categoryArray[i] + ''] !== undefined) {
      return categoryDicts[categoryArray[i] + '']
    }
  }
  return null
}
