









































import CTransitionExpand from '~/components/shared/configurable/expand/CTransitionExpand.vue'
import { mapActions, mapGetters } from 'vuex'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { faBolt, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { electricFuelTypeFacetsNames } from '~/constants/search/electric'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { CTransitionExpand },
  props: {
    facetName: {
      type: String,
      required: true
    },
    facetIsSelected: {
      type: Boolean,
      default: false
    },
    facetIsOpen: {
      type: Boolean,
      default: false
    },
    facetHumanName: {
      type: String,
      required: true
    },
    facetType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: this.facetIsOpen
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getFacetByName: 'getFacetByName'
    }),
    icons: () => ({
      electric: faBolt,
      caretDown: faCaretDown
    }),
    facetIsElectric() {
      return electricFuelTypeFacetsNames.includes(this.facetName)
    },
    isSelected() {
      switch (this.facetName) {
        case 'location2': {
          const postcodeFacet = this.getFacetByName('postcode')
          return this.facetIsSelected || postcodeFacet?.isSelected
        }
        case 'price': {
          const financeOptionsFacet = this.getFacetByName('finance_options')
          const visibilityFacet = this.getFacetByName('visibility')
          const withOnlineShoppingFacet = this.getFacetByName('online_shopping')
          const withPriceFacet = this.getFacetByName('with_price')
          const discountFacet = this.getFacetByName('discount')
          const wholesalePriceFacet = this.getFacetByName('wholesale_price')
          const cheapOnlyFacet = this.getFacetByName('cheap_only')
          const financeFacet = this.getFacetByName('finance')
          return (
            this.facetIsSelected ||
            financeOptionsFacet?.isSelected ||
            withOnlineShoppingFacet?.isSelected ||
            withPriceFacet?.isSelected ||
            discountFacet?.isSelected ||
            wholesalePriceFacet?.isSelected ||
            cheapOnlyFacet?.isSelected ||
            visibilityFacet?.isSelected ||
            financeFacet?.isSelected
          )
        }
        case 'engine_power': {
          if (this.facetType === 'ElectricEnginePowerSearchHandler') {
            const enginePowerFacet = this.getFacetByName('engine_power')
            const engineSizeFacet = this.getFacetByName('engine_size')
            return (
              this.facetIsSelected ||
              enginePowerFacet?.isSelected ||
              engineSizeFacet?.isSelected
            )
          }
          return this.facetIsSelected
        }
        default: {
          return this.facetIsSelected
        }
      }
    },
    label() {
      switch (this.facetType) {
        case 'ElectricEnginePowerSearchHandler': {
          return this.$t('power').toString()
        }
        default: {
          return this.facetHumanName
        }
      }
    }
  },
  methods: {
    ...mapActions(STORAGE_NS, {
      storageSet: 'set'
    }),
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      clearFacet: 'clearFacet'
    }),
    handleClear() {
      this.clearFacet({ name: this.facetName })
    },
    handleCollapseStateChange() {
      if (this.isOpen) {
        this.handleClose()
      } else {
        this.handleOpen()
      }
    },
    saveUserOpenPreference() {
      this.storageSet({
        search: {
          facets: {
            [this.facetName]: {
              isOpen: this.isOpen
            }
          }
        }
      })
    },
    handleOpen() {
      if (!this.isOpen) {
        this.$emit('collapse-change', true)
        this.isOpen = true
        this.saveUserOpenPreference()
      }
    },
    handleClose() {
      this.$emit('collapse-change', false)
      this.isOpen = false
      this.saveUserOpenPreference()
    }
  }
})
