




























































































































import CCount from '~/components/shared/configurable/CCount.vue'
import CCustomOptionGroupsSelect from '~/components/shared/configurable/form/select/custom/CCustomOptionGroupsSelect.vue'
import { IndexedOption, IndexedSearchable } from '~/models/shared/types'
import {
  mapBackendSearchableToOption,
  mapSearchableToOption
} from '~/services/search/formatters'
import { mapGetters } from 'vuex'
import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'

import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import {
  MAKE_SEARCH_PARAM,
  MODEL_SEARCH_PARAM,
  SERIES_SEARCH_PARAM,
  VARIANT_SEARCH_PARAM
} from '~/constants/makemodels'
import { debounce } from '~/utils/function'
import { DEBOUNCE_DEFAULT } from '~/constants/debounce'
import MakeOptionWithLogoAndCount from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeOptionWithLogoAndCount.vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { MakeLogoType } from '~/models/make'
import { defineComponent } from '@nuxtjs/composition-api'

interface Data {
  selectedMakesValues: any[]
  selectedModelsValues: any[]
  variantQ: string
}
export default defineComponent({
  components: {
    CCustomOptionGroupsSelect,
    CCount,
    MakeOptionWithLogoAndCount,
    COptionWithCount
  },
  extends: BaseSearchHandler,
  props: {
    showOnlyMake: {
      type: Boolean,
      default: false
    },
    showInputSearch: {
      type: Boolean,
      default: false
    },
    isQuickSearchMobile: {
      type: Boolean,
      default: false
    }
  },
  data(): Data {
    return {
      selectedMakesValues: [],
      selectedModelsValues: [],
      variantQ: ''
    }
  },
  computed: {
    icons: () => ({
      seriesModel: faLevelUpAlt
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getSetting: 'getSetting',
      showMakesLogos: 'showMakesLogos',
      currentCategory: 'getCurrentCategory',
      inBikesSearch: 'inBikesSearch'
    }),
    makeLogoType() {
      if (this.inBikesSearch) {
        return MakeLogoType.BIKES
      }
      return MakeLogoType.CARS
    },
    modelsSelectPlaceholder() {
      const { selectedMakes, modelOptionGroups } = this
      if (selectedMakes.length === 0) {
        return this.$t('model')
      } else if (modelOptionGroups.length === 0) {
        return this.$t('no models found')
      }
      return this.$t('model')
    },
    selectedMakes() {
      return this.makeSearchables.filter(make => make.selected)
    },
    makeOptionGroups(): IndexedOption[] {
      const allOptions = this.makeSearchables.map(s => {
        const option: IndexedOption = mapSearchableToOption(s)
        option.key = s.key
        return option
      })

      const topOptions = allOptions.filter(option => option.isTop)
      const restOfOptions = allOptions.filter(option => !option.isTop)

      return [
        {
          label: this.$t('popular makes'),
          options: topOptions
        },
        {
          label: this.$t('all makes'),
          options: restOfOptions
        }
      ]
    },

    makeSearchables(): IndexedSearchable[] {
      const { searchables } = this
      if (!searchables) {
        return []
      }

      const omitEmptyMakes = this.getSetting('omitEmptyMakes')
      if (omitEmptyMakes) {
        return this.searchables.filter(s => s.count > 0)
      }

      return this.searchables
    },
    modelOptionGroups() {
      return this.selectedMakes
        .filter(make => make.children)
        .map(make => {
          return {
            label: make.name,
            options: make.children.flatMap(model => {
              const flatSeriesAndModels = [
                mapBackendSearchableToOption(model),
                model.k
                  ? model.k.map(model => {
                      return {
                        ...mapBackendSearchableToOption(model),
                        belongsToASeries: true
                      }
                    })
                  : []
              ]
              return [].concat(...flatSeriesAndModels)
            })
          }
        })
    }
  },
  methods: {
    getMakeSearchableById(id: number) {
      return this.makeSearchables.find(s => s.value === id)
    },
    onBackendValues({ selected }) {
      const { makes, models, variant } = selected
      this.selectedMakesValues = makes
      this.selectedModelsValues = models
      if (!this.variantQ || !variant) {
        this.variantQ = variant
      }
    },
    onClearAll() {
      const { makesSelect, modelsSelect } = this.$refs
      modelsSelect && modelsSelect.clearSearchInput()
      makesSelect && makesSelect.clearSearchInput()
    },
    onClear() {
      this.emitParams([
        { name: MAKE_SEARCH_PARAM, value: null },
        { name: MODEL_SEARCH_PARAM, value: null },
        { name: VARIANT_SEARCH_PARAM, value: null },
        { name: SERIES_SEARCH_PARAM, value: null }
      ])
    },
    handleMakesChange(makes) {
      if (makes.length === 0) {
        // there are no makes, so clear all makes and models
        return this.emitParams([
          { name: MAKE_SEARCH_PARAM, value: null },
          { name: MODEL_SEARCH_PARAM, value: null }
        ])
      }
      if (this.selectedModelsValues.length > 0) {
        // some model is already selected so we need to handle the make child models deselection
        const modelsToKeep = []
        for (const make of makes) {
          const makeSearchable = this.getMakeSearchableById(make)
          if (makeSearchable.children) {
            for (const model of this.selectedModelsValues) {
              if (makeSearchable.children.some(c => c.v === model)) {
                modelsToKeep.push(model)
              }
            }
          }
        }
        return this.emitParams([
          { name: MAKE_SEARCH_PARAM, value: makes },
          {
            name: MODEL_SEARCH_PARAM,
            value: modelsToKeep
          }
        ])
      }
      // only makes are selected, no side-effects
      return this.emitParams([{ name: MAKE_SEARCH_PARAM, value: makes }])
    },
    handleModelChange(models) {
      this.emitParams([{ name: MODEL_SEARCH_PARAM, value: models }])
    },
    onVariantInput: debounce(function(text: string) {
      this.emitParams([
        {
          name: VARIANT_SEARCH_PARAM,
          value: text
        }
      ])
    }, DEBOUNCE_DEFAULT)
  }
})
