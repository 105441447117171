import { CIcon } from '~/icons/types'
export const ciFlash: CIcon = {
  name: 'flash',
  type: 'solid',
  width: 512,
  height: 512.001,
  paths: [
    {
      d:
        'M315 211H190.855L298.023 22.441a15.015 15.015 0 0 0-.043-14.972A15.022 15.022 0 0 0 285 0H105a14.987 14.987 0 0 0-14.223 10.254l-90 271a14.986 14.986 0 0 0 2.051 13.52A15.016 15.016 0 0 0 15 301h127.254L61.219 491.098a14.977 14.977 0 0 0 5.742 18.558c6.11 3.906 14.316 2.88 19.246-2.691l240-271a14.99 14.99 0 0 0 2.477-16.102A15.003 15.003 0 0 0 315 211zm0 0',
      fill: 'currentColor'
    }
  ]
}
