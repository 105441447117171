var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-select-none"},[(_vm.isQuickSearch && !_vm.showSelectedTags && _vm.selectedOptionSet.length > 0)?_c('button',{staticClass:"tw-absolute -tw-top-[2.6rem] tw-right-0 tw-p-4 tw-pr-0 tw-z-50 tw-text-red-500",on:{"click":function($event){return _vm.$emit('deselect-all')}}},[_vm._v("\n    "+_vm._s(_vm.$t('clear'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-form-input tw-border tw-border-solid tw-py-0 tw-flex tw-items-center tw-relative tw-w-full tw-text-left header",class:_vm.computedClasses,attrs:{"role":"button","title":_vm.headerTitle || _vm.placeholder,"aria-disabled":_vm.disabled},on:{"click":_vm.handleClick}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-full"},[_c('div',{staticClass:"header-text-container tw-w-full tw-min-w-0"},[_c('div',{staticClass:"tw-flex tw-items-center tw-mr-[0.3125rem] tw-w-full tw-h-full header-text"},[(_vm.$slots.headerPreText)?_vm._t("headerPreText"):(_vm.headerPreText)?_c('div',{staticClass:"pre-text"},[_vm._v("\n            "+_vm._s(_vm.headerPreText)+":\n          ")]):_vm._e(),_vm._v(" "),(_vm.showSelectedTags && _vm.selectedOptionSet.length > 0)?_c('div',{staticClass:"main-text tw-text-grey-900"},[(_vm.multiSelect)?_c('div',[_vm._v("\n              "+_vm._s(_vm.selectedOptions.length > 1
                  ? _vm.$tc(
                      '1 selected | {count} selected',
                      _vm.selectedOptions.length
                    )
                  : _vm.firstOption)+"\n            ")]):(_vm.canDeselectSingle)?_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_vm._v("\n              "+_vm._s(_vm.selectedOptionSet[0].text)+"\n              "),_c('CButton',{staticClass:"!tw-bg-transparent !tw-border-0 hover:tw-text-grey-900 tw-text-grey-600",attrs:{"title":_vm.$t('clear all'),"size":"sm","variant":"light","outline":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('deselect-all')}}},[_c('fa',{attrs:{"icon":_vm.faTimes}})],1)],1):_c('div',[(_vm.prefix)?_c('span',{staticClass:"prefix"},[_vm._v(_vm._s(_vm.prefix))]):_vm._e(),_vm._v(" "),(_vm.withMakeLogo)?_c('MakeOptionWithLogoAndCount',{staticClass:"main-text-selected",attrs:{"id":_vm.selectedOptionSet[0].value || _vm.selectedOptionSet[0].value,"name":_vm.firstOption,"show-count":false}}):_c('div',{staticClass:"main-text-selected tw-text-ellipsis tw-overflow-hidden tw-max-w-full",class:_vm.mainTextClasses},[_vm._v("\n                "+_vm._s(_vm.firstOption)+"\n              ")]),_vm._v(" "),(_vm.suffix)?_c('span',{staticClass:"suffix"},[_vm._v(_vm._s(_vm.prefix))]):_vm._e()],1)]):_c('div',{staticClass:"main-text placeholder"},[(!_vm.showSelectedTags && _vm.selectedOptionSet.length)?_c('span',{staticClass:"tw-select-none tw-bg-grey-300 tw-py-[0.125rem] tw-px-2 tw-mr-[0.1875rem] tw-rounded-sm selected-options-number",attrs:{"title":_vm.separatedSelectedOptions}},[_vm._v("\n              "+_vm._s(_vm.placeholderWhenShowNoTags)+"\n            ")]):_c('span',[_vm._v("\n              "+_vm._s(_vm.placeholder)+"\n            ")]),_vm._v(" "),(_vm.suffix)?_c('span',{staticClass:"suffix"},[_vm._v(_vm._s(_vm.suffix))]):_vm._e()])],2)]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-ml-1"},[_c('CIcon',{staticClass:"caret-icon tw-transition-transform",class:[
            _vm.disabled ? 'tw-text-grey-600' : 'tw-text-grey-700',
            { 'tw-rotate-180': _vm.internalDropdownOpen }
          ],attrs:{"icon":_vm.ciChevronDown,"size":_vm.size === 'sm' ? 16 : 20}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }