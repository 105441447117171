import { CIcon } from '~/icons/types'
export const ciDoubleArrowAlt: CIcon = {
  name: 'double-arrow-alt',
  type: 'solid',
  width: 512.016,
  height: 512.016,
  paths: [
    {
      d:
        'M352.019 384.008h-48v-256h48c6.464 0 12.32-3.904 14.784-9.888s1.088-12.864-3.456-17.44l-96-96c-6.24-6.24-16.384-6.24-22.624 0l-96 96c-4.608 4.608-5.984 11.456-3.488 17.44 2.464 5.984 8.32 9.888 14.784 9.888h48v256h-48c-6.464 0-12.32 3.904-14.784 9.888a15.964 15.964 0 0 0 3.456 17.44l96 96c6.24 6.24 16.384 6.24 22.624 0l96-96a15.964 15.964 0 0 0 3.456-17.44c-2.496-5.984-8.288-9.888-14.752-9.888z',
      fill: 'currentColor'
    }
  ]
}
