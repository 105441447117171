import { CIcon } from '~/icons/types'
export const ciConsole: CIcon = {
  name: 'console',
  type: 'light',
  width: 321.145,
  height: 321.145,
  paths: [
    {
      d:
        'M320.973 200.981c-.8-18.4-4-38.8-8.8-58-4.8-18.4-10.8-35.6-18-48.8-28-49.2-58.4-41.6-94.8-32-11.6 2.8-24 6-36.8 7.2h-4c-12.8-1.2-25.2-4.4-36.8-7.2-36.4-9.2-66.8-17.2-94.8 32.4-7.2 13.2-13.6 30.4-18 48.8-4.8 19.2-8 39.6-8.8 58-.8 20.4 1.2 35.2 5.6 45.6 4.4 9.6 10.8 15.6 19.2 18 7.6 2 16.4 1.2 25.6-2.8 15.6-6.4 33.6-20 51.2-36.4 12.4-12 35.6-18 58.8-18s46.4 6 58.8 18c17.6 16.4 35.6 30 51.2 36.4 9.2 3.6 18 4.8 25.6 2.8 8-2.4 14.8-8 19.2-18.4 4.4-10 6.4-24.8 5.6-45.6zm-19.2 40c-2.4 5.6-5.6 8.8-9.6 10-4.4 1.2-10 .4-16.4-2-14-5.6-30.4-18-46.4-33.2-15.2-15.2-42-22.8-68.8-22.8s-53.6 7.6-69.2 22c-16.4 15.2-32.8 28-46.4 33.2-6.4 2.4-12 3.6-16.4 2-4-1.2-7.2-4.4-9.6-10-3.2-7.6-4.8-20-4-38.4.8-17.2 3.6-36.8 8.4-55.2 4.4-17.2 10-33.2 16.8-45.2 22-39.6 47.6-33.2 78-25.2 12.4 3.2 25.2 6.4 39.2 7.6h6c14.4-1.2 27.2-4.4 39.6-7.6 30.4-7.6 56-14.4 78 25.2 6.8 12 12.4 27.6 16.8 45.2 4.4 18.4 7.6 37.6 8.4 55.2.8 18.4-.8 30.8-4.4 39.2z'
    },
    {
      d:
        'M123.773 122.981c-4-3.6-8.8-6.4-14.4-6.8-.4-5.2-2.8-10.4-6.4-14l-.4-.4c-4.4-4.4-10-6.8-16.4-6.8-6.4 0-12.4 2.8-16.4 6.8-3.6 3.6-6.4 8.8-6.8 14.4-5.6.4-10.4 2.8-14.4 6.4l-.4.4c-4.4 4.4-6.8 10-6.8 16.4 0 6.4 2.8 12.4 6.8 16.4 4 4 8.8 6.4 14.8 6.8.4 5.6 2.8 10.8 6.8 14.4 4.4 4.4 10 6.8 16.4 6.8 6.4 0 12.4-2.8 16.4-6.8 3.6-4 6.4-8.8 6.8-14.4 5.6-.4 10.8-2.8 14.4-6.8 4.4-4.4 6.8-10 6.8-16.4 0-6.4-2.8-12.4-6.8-16.4zm-10 22.4c-1.6 1.6-3.6 2.4-6 2.4h-5.6c-4 0-7.6 3.2-7.6 7.6v5.2c0 2.4-.8 4.4-2.4 6-1.6 1.6-3.6 2.4-6 2.4s-4.4-.8-6-2.4c-1.6-1.6-2.4-3.6-2.4-6v-5.6c0-4-3.2-7.6-7.6-7.6h-5.6c-2.4 0-4.4-.8-6-2.4-1.2-1.2-2.4-3.2-2.4-5.6 0-2.4.8-4.4 2.4-6l.4-.4c1.6-1.2 3.6-2 5.6-2h5.6c4 0 7.6-3.2 7.6-7.6v-5.6c0-2.4.8-4.4 2.4-6 1.6-1.6 3.6-2.4 6-2.4s4.4.8 6 2.4l.4.4c1.2 1.6 2 3.6 2 5.6v5.6c0 4 3.2 7.6 7.6 7.6h5.6c2.4 0 4.4.8 6 2.4 1.6 1.6 2.4 3.6 2.4 6s-.8 4.4-2.4 6z'
    },
    { d: 'M216.173 110.981a14 14 0 1 0 28 0 14 14 0 1 0-28 0' },
    { d: 'M216.173 167.781a14 14 0 1 0 28 0 14 14 0 1 0-28 0' },
    { d: 'M187.773 139.381a14 14 0 1 0 28 0 14 14 0 1 0-28 0' },
    { d: 'M244.573 139.381a14 14 0 1 0 28 0 14 14 0 1 0-28 0' }
  ]
}
