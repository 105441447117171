import { CIcon } from '~/icons/types'
export const ciThermometer: CIcon = {
  name: 'thermometer',
  type: 'solid',
  width: 496,
  height: 496,
  paths: [
    {
      d:
        'M467.051 56H405.59C400.276 23.943 372.118-.499 338.473.008 301.716.561 272.5 31.191 272.5 67.953v249.434c0 4.105-1.562 8.069-4.412 11.023-18 18.657-27.949 43.541-27.578 70.021.763 54.399 45.99 98.317 100.389 97.56 86.714-1.207 129.494-105.846 70.059-167.534a15.95 15.95 0 0 1-4.458-11.069V288h20.551c8.618 0 16.03-6.626 16.431-15.234.428-9.189-6.888-16.766-15.982-16.766h-21v-68h40.551c8.618 0 16.03-6.626 16.431-15.234.428-9.189-6.888-16.766-15.982-16.766h-41V88h61c9.094 0 16.41-7.577 15.982-16.766C483.081 62.626 475.669 56 467.051 56zM336.83 433.906c-33.614-2.372-46.969-45.612-19.684-66.371 3.99-3.036 6.354-7.741 6.354-12.754V163.449c0-8.618 6.626-16.031 15.235-16.431 9.188-.428 16.765 6.889 16.765 15.982v191.578c0 5.026 2.246 9.853 6.256 12.883 29.353 22.179 11.698 69.029-24.926 66.445zM56.5 88c-24.262 0-44-19.738-44-44s19.738-44 44-44 44 19.738 44 44-19.738 44-44 44zm0-56c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12zM168 222c-37.771 0-68.5-30.729-68.5-68.5v-47C99.5 68.729 130.228 38 168 38c20.517 0 39.78 9.083 52.852 24.921 5.625 6.815 4.66 16.899-2.155 22.524-6.814 5.625-16.899 4.66-22.524-2.155C174.471 56.995 131.5 72.342 131.5 106.5v47c0 34.184 42.995 49.486 64.673 23.208 5.625-6.816 15.709-7.783 22.524-2.159 6.816 5.624 7.783 15.708 2.159 22.524C207.787 212.914 188.521 222 168 222z',
      fill: 'currentColor'
    }
  ]
}
