import { CIcon } from '~/icons/types'
export const ciTwoStoreBuilding: CIcon = {
  name: 'two-store-building',
  type: 'light',
  width: 415.5,
  height: 415.5,
  paths: [
    {
      d:
        'M192.25 311.107c2.1 0 4.17-.86 5.66-2.35a8.045 8.045 0 0 0 2.34-5.65c0-2.11-.85-4.17-2.34-5.66a8.068 8.068 0 0 0-5.66-2.34c-2.1 0-4.17.85-5.66 2.34a8.05 8.05 0 0 0-2.34 5.66c0 2.1.85 4.16 2.34 5.65a8.078 8.078 0 0 0 5.66 2.35z'
    },
    {
      d:
        'M359.917 231.792h-79.458a8 8 0 0 0-8 8v79.875a8 8 0 0 0 8 8h79.458a8 8 0 0 0 8-8v-79.875a8 8 0 0 0-8-8zm-71.459 16h23.729v63.875h-23.729v-63.875zm63.459 63.875h-23.729v-63.875h23.729v63.875z'
    },
    {
      d:
        'M247.417 103.375h-79.333a8 8 0 0 0-8 8v79.875a8 8 0 0 0 8 8h79.333a8 8 0 0 0 8-8v-79.875a8 8 0 0 0-8-8zm-71.334 16h23.667v63.875h-23.667v-63.875zm63.334 63.875H215.75v-63.875h23.667v63.875z'
    },
    {
      d:
        'M359.917 103.375h-79.458a8 8 0 0 0-8 8v79.875a8 8 0 0 0 8 8h79.458a8 8 0 0 0 8-8v-79.875a8 8 0 0 0-8-8zm-71.459 16h23.729v63.875h-23.729v-63.875zm63.459 63.875h-23.729v-63.875h23.729v63.875z'
    },
    {
      d:
        'M135.917 231.792H56.458a8 8 0 0 0-8 8v79.875a8 8 0 0 0 8 8h79.458a8 8 0 0 0 8-8v-79.875a7.998 7.998 0 0 0-7.999-8zm-71.459 16h23.729v63.875H64.458v-63.875zm63.459 63.875h-23.729v-63.875h23.729v63.875z'
    },
    {
      d:
        'M135.917 103.375H56.458a8 8 0 0 0-8 8v79.875a8 8 0 0 0 8 8h79.458a8 8 0 0 0 8-8v-79.875a7.999 7.999 0 0 0-7.999-8zm-71.459 16h23.729v63.875H64.458v-63.875zm63.459 63.875h-23.729v-63.875h23.729v63.875z'
    },
    {
      d:
        'M407.5 359.917h-7.75V55.514c3.945-.493 7-3.852 7-7.931a8 8 0 0 0-8-8H16.083a8 8 0 0 0-8 8c0 4.306 3.404 7.807 7.667 7.983v304.35H8a8 8 0 0 0 0 16h399.5a8 8 0 0 0 0-15.999zM31.75 55.583h352v304.333H255.417V239.792a8 8 0 0 0-8-8h-79.333a8 8 0 0 0-8 8v120.125H31.75V55.583zm207.667 304.334h-63.333V247.792h63.333v112.125z'
    },
    { d: 'M56.458 87.583h301.917a8 8 0 0 0 0-16H56.458a8 8 0 0 0 0 16z' }
  ]
}
