import { CIcon } from '~/icons/types'
export const ciTabs: CIcon = {
  name: 'tabs',
  type: 'solid',
  width: 451.111,
  height: 451.111,
  paths: [
    {
      d:
        'M451.111 0H96.667v96.667H0v354.445h354.445v-96.667h96.667V0zM290 386.667H64.444V161.111H290zM386.667 290h-32.222V96.667H161.111V64.444h225.556z',
      fill: 'currentColor'
    }
  ]
}
