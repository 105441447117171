















import { Option } from '~/models/shared/types'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    multiSelect: {
      type: Boolean,
      required: true
    },
    internalValue: {
      type: [Array, String, Number],
      default: null
    },
    internalOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: undefined
    },
    tabindex: {
      type: [String, Number],
      default: undefined,
      required: false
    }
  },
  computed: {
    nativeOptions(): Option[] {
      const options = [...this.internalOptions]
      if (this.placeholder && this.placeholder !== '') {
        options.unshift({
          value: null,
          text: this.placeholder,
          disabled: true,
          hidden: true,
          selected: false
        })
      }
      return options
    },
    nativeValue() {
      if (this.multiSelect) {
        return this.internalValue
      } else if (this.internalValue?.length === 0) {
        return null
      } else if (Array.isArray(this.internalValue)) {
        return this.internalValue[0]
      }

      return this.internalValue
    }
  },
  methods: {
    selectionChanged(newSelection) {
      this.$emit('change', newSelection)
    }
  }
})
