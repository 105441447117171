







import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  methods: {
    emitVisibilityChange(isVisible: boolean) {
      this.$emit('visibility-change', isVisible)
    }
  }
})
