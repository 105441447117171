import { CIcon } from '~/icons/types'
export const ciCaravanHouse: CIcon = {
  width: 512,
  height: 333.2,
  paths: [
    {
      d:
        'M425.1 86.6h25.1c-3.4-10.4-11.8-18.7-22.6-21.8l-180-63-.7-.2c-7.5-2.1-15.3-2.1-22.8 0l-.2.1-199.4 63C13.6 67.8 5.2 76.2 1.8 86.6h423.3zm-398.4 0h398.4H26.7zm152.1 246.6c23.6 0 43.1-18 45.4-41h-90.8c2.4 23 21.8 41 45.4 41zm111.4 0c23.6 0 43.1-18 45.4-41h-90.8c2.3 23 21.8 41 45.4 41z'
    },
    {
      d:
        'M501.5 257.5h-11.1v-6.8c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v6.8h-17.5V107.6H0v137.9c0 18.2 14.8 33 33 33h436.3v25.4c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-25.4h11.1c5.8 0 10.5-4.7 10.5-10.5.1-5.8-4.6-10.5-10.4-10.5zM157 203.2c0 9.2-7.5 16.7-16.7 16.7H72c-9.2 0-16.6-7.4-16.7-16.7v-50.4c0-9.2 7.5-16.7 16.7-16.7h68.3c9.2 0 16.7 7.5 16.7 16.7v50.4zm111.6 41.1v13.2h-66V145.4c0-5.8 4.7-10.5 10.5-10.5h45c5.8 0 10.5 4.7 10.5 10.5v98.9zm134.6-41.1c0 9.2-7.5 16.7-16.7 16.7h-58.2c-9.2 0-16.7-7.4-16.7-16.7v-50.4c0-9.2 7.5-16.7 16.7-16.7h58.2c9.2 0 16.7 7.5 16.7 16.7v50.4z'
    }
  ],
  name: 'caravan-house',
  type: 'solid'
}
