






import { faClock } from '@fortawesome/free-regular-svg-icons/faClock'
import {
  cappedTimeDistanceFromNow,
  adminTimeDistanceFromNow
} from '~/utils/date'

import { mapGetters } from 'vuex'
import { defineComponent } from '@nuxtjs/composition-api'

const customLocalizedReplacements = {
  el: [
    { subString: 'δευτερολεπτα', replacement: 'δευτ.' },
    { subString: 'πάνω από 2 μήνες πριν', replacement: 'πάνω από 2 μήνες' }
  ],
  en: [
    { subString: 'seconds', replacement: 'sec' },
    { subString: 'more than 2 months ago', replacement: 'more than 2 months' }
  ]
}

export default defineComponent({
  props: {
    lastModifiedDate: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      icons: {
        clock: faClock
      }
    }
  },
  computed: {
    ...mapGetters({
      userIsAdmin: 'user/isAdmin'
    }),
    lastModifiedStr() {
      if (this.userIsAdmin) {
        return adminTimeDistanceFromNow(this.lastModifiedDate)
      }
      let timeDistance = cappedTimeDistanceFromNow(this.lastModifiedDate, 60, {
        locale: this.$i18n.locale,
        addSuffix: true,
        addPrefix: false,
        includeSeconds: true
      })
      const localeReplacements = customLocalizedReplacements[this.$i18n.locale]
      localeReplacements &&
        localeReplacements.forEach(replacement => {
          timeDistance = timeDistance.replace(
            replacement.subString,
            replacement.replacement
          )
        })
      return timeDistance
    }
  }
})
