import { CIcon } from '~/icons/types'
export const ciRealEstateInactive: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'm48.8 238.8 37.5-29.4v197.7c0 28.8 23.4 52.2 52.2 52.2h235.1c28.8 0 52.2-23.4 52.2-52.2V209.4l37.5 29.4c1.9 1.5 4.2 2.2 6.5 2.2 3.1 0 6.2-1.4 8.3-4 3.6-4.6 2.8-11.2-1.8-14.7L262.5 54.9c-3.8-3-9.1-3-12.9 0l-55.2 43.2V63.2c0-5.8-4.7-10.5-10.5-10.5h-63.2c-5.8 0-10.5 4.7-10.5 10.5v100.9l-74.3 58.2c-4.6 3.6-5.4 10.2-1.8 14.7 3.5 4.5 10.1 5.4 14.7 1.8zm244.1 199.5H219V315.7c0-12.1 9.8-22 22-22h30c12.1 0 22 9.8 22 22v122.6h-.1zM131.1 73.7h42.2v40.9l-42.2 33.1v-74zM256 76.5 404.8 193c-.1.4-.1.9-.1 1.3v212.8c0 17.2-14 31.2-31.2 31.2h-59.6V315.7c0-23.7-19.3-43-43-43h-30c-23.7 0-43 19.3-43 43v122.6h-59.6c-17.2 0-31.2-14-31.2-31.2V194.3c0-.4 0-.9-.1-1.3L256 76.5z'
    }
  ],
  name: 'real-estate-inactive',
  type: 'regular'
}
