import { CIcon } from '~/icons/types'
export const ciAirConditioner: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M468 139q18 0 31 13t13 31v146q0 18-13 31t-31 13H44q-18 0-31-13T0 329V183q0-18 13-31t31-13h424zM96 352v-21H21q1 9 7.5 15t15.5 6h52zm85 0v-21h-64v21h64zm118 0v-21h-96v21h96zm96 0v-21h-75v21h75zm73 0q9 0 15.5-6t6.5-15h-74v21h52zm23-43V183q0-10-7-16.5t-16-6.5H44q-9 0-16 6.5T21 183v126h470zm-43-106q4 0 7.5 3t3.5 7.5-3.5 7.5-7.5 3h-53q-3 0-5.5-1.5t-4-4-1.5-5.5q0-4 3-7t8-3h53z',
      fill: 'currentColor'
    }
  ],
  name: 'air-conditioner',
  type: 'light'
}
