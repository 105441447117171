import { CIcon } from '~/icons/types'
export const ciWindow: CIcon = {
  name: 'window',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M486.794 454.344h-22.919V10.199C463.875 4.567 459.309 0 453.676 0H58.324c-5.633 0-10.199 4.567-10.199 10.199v444.145H25.206c-5.633 0-10.199 4.567-10.199 10.199v37.258c0 5.632 4.566 10.199 10.199 10.199h461.587c5.633 0 10.199-4.567 10.199-10.199v-37.258c.001-5.633-4.565-10.199-10.198-10.199zM68.523 20.398h374.953v433.946H68.523V20.398zm408.071 471.204H35.406v-16.859h441.189v16.859z'
    },
    {
      d:
        'M237.371 40.363H95.583c-5.633 0-10.199 4.567-10.199 10.199v375.687c0 5.632 4.566 10.199 10.199 10.199h141.788c5.633 0 10.199-4.567 10.199-10.199V50.563c0-5.632-4.566-10.2-10.199-10.2zM105.782 60.762h99.842c-3.257 61.391-19.51 215.545-99.842 300.493V60.762zm0 355.288v-26.456c43.97-38.766 76.845-98.796 97.742-178.539 15.624-59.618 20.82-116.633 22.529-150.293h1.118V416.05H105.782z'
    },
    {
      d:
        'M171.134 78.656c-5.633 0-10.199 4.567-10.199 10.199v31.048c0 5.632 4.566 10.199 10.199 10.199s10.199-4.567 10.199-10.199V88.855c.001-5.632-4.567-10.199-10.199-10.199zm-37.258-1.035c-5.633 0-10.199 4.567-10.199 10.199v133.508c0 5.632 4.566 10.199 10.199 10.199s10.199-4.567 10.199-10.199V87.82c0-5.632-4.566-10.199-10.199-10.199zm282.541-37.258H274.629c-5.633 0-10.199 4.567-10.199 10.199v375.687c0 5.632 4.566 10.199 10.199 10.199h141.788c5.633 0 10.199-4.567 10.199-10.199V50.563c0-5.632-4.566-10.2-10.199-10.2zm-10.2 375.687H284.828V60.762h1.118c1.709 33.66 6.906 90.675 22.529 150.293 20.898 79.742 53.773 139.772 97.742 178.539v26.456zm.001-54.795c-80.332-84.948-96.585-239.102-99.842-300.493h99.842v300.493z'
    },
    {
      d:
        'M340.866 78.656c-5.633 0-10.199 4.567-10.199 10.199v31.048c0 5.632 4.566 10.199 10.199 10.199s10.199-4.567 10.199-10.199V88.855c0-5.632-4.566-10.199-10.199-10.199zm37.258 127.3c-5.633 0-10.199 4.567-10.199 10.199v5.174c0 5.632 4.566 10.199 10.199 10.199s10.199-4.567 10.199-10.199v-5.174c0-5.633-4.566-10.199-10.199-10.199zm0-128.335c-5.633 0-10.199 4.567-10.199 10.199v95.215c0 5.632 4.566 10.199 10.199 10.199s10.199-4.567 10.199-10.199V87.82c0-5.632-4.566-10.199-10.199-10.199z'
    }
  ]
}
