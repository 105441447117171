import { CIcon } from '~/icons/types'
export const ciCarDoor1: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M506.5 10q7.5 10 4.5 22l-50 202v252q0 11-7.5 18.5T435 512H26q-11 0-18.5-7.5T0 486V230q0-10 8-18L212 8q8-8 18-8h256q13 0 20.5 10zM435 486V256H26v230h409zm0-256 51-204H230L26 230h409zm-102 77v-25h77v25h-77z',
      fill: 'currentColor'
    }
  ],
  name: 'car-door-1',
  type: 'regular'
}
