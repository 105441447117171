import { CIcon } from '~/icons/types'
export const ciTechnology: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M480 162q13 0 22.5 9.5T512 195v267q0 9-4.5 16t-12 11.5T480 494H32q-13 0-22.5-9.5T0 462V195q0-9 4.5-16.5t12-12T32 162h33v-40q0-5 3-8.5t8-3.5h57l309-92q12-3 15 8t-8 15l-303 89v32h334zM88 133v29h35v-29H88zm401 329V195q0-9-9-9H32q-9 0-9 9v267q0 9 9 9h448q9 0 9-9zM157.5 221q44.5 0 76 31.5t31.5 76-31.5 76-76 31.5T81 404.5t-32-76 32-76 76.5-31.5zm-.5 192q35 0 59.5-25t24.5-60-24.5-59.5T157 244t-59.5 24.5T73 328t24.5 60 59.5 25zm0-142q5 0 8.5 3.5t3.5 8.5v91q0 5-3.5 8.5T157 386t-8.5-3.5-3.5-8.5v-91q0-5 3.5-8.5t8.5-3.5zm47 18q3 0 5.5 1.5t4 4 1.5 5.5v56q0 5-3.5 8.5t-8 3.5-8-3.5-3.5-8.5v-56q0-4 3.5-7.5t8.5-3.5zm-93.5 0q4.5 0 8 3.5t3.5 7.5v56q0 5-3.5 8.5t-8 3.5-8-3.5T99 356v-56q0-4 3.5-7.5t8-3.5zM454 209q5 0 8 3.5t3 8.5v58q0 5-3 8t-8 3H293q-5 0-8-3t-3-8v-58q0-5 3-8.5t8-3.5h161zm-122 58v-35h-27v35h27zm110 0v-35h-87v35h87zm12 99q5 0 8 3.5t3 8.5v58q0 5-3 8.5t-8 3.5H293q-5 0-8-3.5t-3-8.5v-58q0-5 3-8.5t8-3.5h161zm-119 58v-35h-30v35h30zm54 0v-35h-31v35h31zm53 0v-35h-30v35h30zm-123-84h-13q-5 0-8.5-3.5T294 328t3.5-8 8.5-3h13q5 0 8.5 3t3.5 8-3.5 8.5-8.5 3.5zm122 0h-13q-3 0-5.5-1.5T418 334t-2-6 2-5.5 4.5-4 5.5-1.5h13q5 0 8.5 3t3.5 8-3.5 8.5-8.5 3.5z',
      fill: 'currentColor'
    }
  ],
  name: 'technology',
  type: 'regular'
}
