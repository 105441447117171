import { CIcon } from '~/icons/types'
export const ciRealEstate: CIcon = {
  width: 512,
  height: 406.7,
  paths: [
    {
      d:
        'M302.9 396.2c0 5.8 4.7 10.5 10.5 10.5h60.1c28.8 0 52.2-23.4 52.2-52.2V156.7l37.5 29.4c1.9 1.5 4.2 2.2 6.5 2.2 3.1 0 6.2-1.4 8.3-4 3.6-4.6 2.8-11.2-1.8-14.7L262.4 2.2c-3.8-3-9.1-3-12.9 0l-55.2 43.2V10.5c0-5.8-4.7-10.5-10.5-10.5h-63.2c-5.8 0-10.5 4.7-10.5 10.5v100.9l-74.3 58.2c-4.6 3.6-5.4 10.2-1.8 14.7 3.5 4.6 10.1 5.4 14.7 1.9l37.5-29.4v197.7c0 28.8 23.4 52.2 52.2 52.2h60.1c5.7 0 10.4-4.7 10.4-10.4V273.1c0-12.1 9.8-22 22-22h50c12.1 0 22 9.8 22 22v112.6h-.1v-.1l.1 10.6z'
    }
  ],
  name: 'real-estate',
  type: 'solid'
}
