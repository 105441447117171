import { CIcon } from '~/icons/types'
export const ciPickup: CIcon = {
  name: 'pickup',
  type: 'regular',
  width: 512,
  height: 250.7,
  paths: [
    {
      d:
        'M505.7 165.2c-1.6-2.3-3.4-5.5-3.7-6.9v-31.4c0-12.9-7.1-24.6-18.5-30.5-25.7-13.2-76.4-19.5-91.7-21.2l-38-45.8c-.2-.2-.3-.4-.5-.6C336.9 12 323.6 0 300.1 0h-98.9c-5.8 0-10.5 4.7-10.5 10.5v63.4H19.9c-5.8 0-10.5 4.7-10.5 10.5v62.9c0 1.7-.4 3.4-1.2 4.9-2.5 4.6-3.8 9.7-3.8 15v19.3c0 5.8 4.7 10.5 10.5 10.5h47.2c1.5 29.9 26.3 53.8 56.6 53.8s55.1-23.9 56.6-53.8h170.3c1.5 29.9 26.3 53.8 56.6 53.8s55.1-23.9 56.6-53.8h38.5c5.8 0 10.5-4.7 10.5-10.5v-15.1c-.2-2.3-.8-4.4-2.1-6.2zM337.8 43.1l26.1 31.4-97.5-.8L257 21h43.1c13.8 0 22 5.8 37.7 22.1zM118.6 229.7c-19.7 0-35.6-16-35.6-35.6s16-35.6 35.6-35.6 35.6 16 35.6 35.6-15.9 35.6-35.6 35.6zm283.5 0c-19.7 0-35.6-16-35.6-35.6s16-35.6 35.6-35.6 35.6 16 35.6 35.6-15.9 35.6-35.6 35.6zm84.5-53.7h-30.9c-7.6-22.4-28.8-38.5-53.7-38.5-24.9 0-46.1 16.1-53.7 38.5h-176c-7.6-22.4-28.8-38.5-53.7-38.5S72.6 153.6 65 176H25.4v-8.8c0-1.7.4-3.4 1.3-4.9 2.5-4.6 3.8-9.7 3.8-15V94.9h170.7c5.8 0 10.5-4.7 10.5-10.5V21h24l11.6 65c.9 5 5.2 8.6 10.3 8.7l128.3 1.1c5.5.5 62.9 6.5 88 19.4 4.4 2.3 7.1 6.8 7.1 11.8v31.8c0 5.5 3.2 11.7 5.7 15.7v1.5z'
    }
  ]
}
