import { CIcon } from '~/icons/types'
export const ciCommercialCar: CIcon = {
  name: 'commercial-car',
  type: 'regular',
  width: 512,
  height: 239.9,
  paths: [
    {
      d:
        'M505.1 150c-.2-.7-.4-2.5-.6-4.2-1-7.4-2.4-18.6-6.9-27.7-8.3-17-30.9-30.7-56.3-34.1-9.4-1.3-17.8-2.3-25.3-3.3-16.4-2.1-28.3-3.6-35.2-5.4-5.7-1.5-18.6-9.8-25.3-15.7l-38-39.1-.6-.6c-.7-.6-1.4-1.1-1.9-1.5-.3-.2-.7-.5-.8-.6C299.7 3.3 282.8.5 252.7.2c-71-.8-160.6 1.3-192.8 2.5-20.3.9-28.6 17.3-34.1 28.2-6.6 13.1-9 25-11.9 40.1-2 10-4.2 21.4-8.1 36-2.1 7.6-2.6 15.5-1.7 23.5L5.3 199c.1 5.7 4.8 10.3 10.5 10.3l45.8-.1c8.9 18.2 27.5 30.7 49.1 30.7 21.5 0 40.2-12.5 49.1-30.7h185.3c8.9 18.2 27.5 30.7 49.1 30.7s40.2-12.6 49.1-30.8l53-1.6c4.6-.1 8.6-3.3 9.8-7.8 4.3-16.2 1.8-36-1-49.7zM299.3 32.6c1.1 1.1 2.1 1.9 2.9 2.5.2.1.4.3.5.4l38 39.1.6.6c.5.5 1.1 1 1.8 1.6L262.7 75c-1.7-.9-13.1-8.6-14.7-53.8h4.5c30.9.3 39.1 3.6 46.8 11.4zM110.7 218.9c-18.5 0-33.6-15.1-33.6-33.6s15.1-33.6 33.6-33.6 33.6 15.1 33.6 33.6-15.1 33.6-33.6 33.6zm283.5 0c-18.5 0-33.6-15.1-33.6-33.6s15.1-33.6 33.6-33.6 33.6 15.1 33.6 33.6-15.1 33.6-33.6 33.6zm92.9-32.1-38.4 1.2c0-.9.1-1.8.1-2.7 0-30.1-24.5-54.6-54.6-54.6s-54.6 24.5-54.6 54.6c0 1 0 1.9.1 2.9H165.2c.1-1 .1-1.9.1-2.9 0-30.1-24.5-54.6-54.6-54.6s-54.6 24.5-54.6 54.6c0 1 0 2 .1 2.9l-30.1.1-1.1-58.7c0-.4 0-.8-.1-1.1-.7-5.5-.3-10.9 1-16 4.2-15.4 6.6-27.7 8.5-37.5 2.8-14.5 4.7-24.1 10-34.6C50.6 28.3 54 24 60.7 23.7 89 22.6 161.7 20.8 227 21c1.1 33.3 7.4 55.9 18.9 67.4 6.8 6.8 13.3 7.5 15.5 7.6l130 2.7c6.2.9 13.5 1.8 22 2.9 7.4.9 15.8 2 25.1 3.3 20.6 2.8 35.8 13.5 40.2 22.5 2.9 6 4.2 15.5 4.9 21.2.3 2.3.5 4.2.9 5.7 2.6 12.4 3.5 23.6 2.6 32.5z'
    }
  ]
}
