









import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min'
import 'vue-slider-component/theme/default.css'
import 'vue-slider-component/dist-css/vue-slider-component.css'
import { defineComponent } from '@nuxtjs/composition-api'
import { vue3Model } from '~/utils/nuxt3-migration'

export default defineComponent({
  inheritAttrs: true,
  model: vue3Model,
  components: { VueSlider },
  props: {
    modelValue: {
      type: Number,
      required: true
    }
  },
  setup(_, { emit }) {
    function onChange(value: number) {
      emit('change', value)
      emit('update:modelValue', value)
    }

    return {
      onChange
    }
  }
})
