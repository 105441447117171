import { CIcon } from '~/icons/types'
export const ciSettings: CIcon = {
  name: 'settings',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M354.643 290.995c13.462-37.866 4.248-80.596-24.404-109.233-31.172-31.201-79.087-39.346-119.209-20.273a14.996 14.996 0 0 0-8.335 11.001 14.982 14.982 0 0 0 4.175 13.154L241 219.789V241h-21.211l-34.146-34.131c-3.472-3.457-8.379-5.01-13.154-4.175a14.996 14.996 0 0 0-11.001 8.335c-19.072 40.122-10.928 88.022 20.273 119.209 28.623 28.608 71.323 37.852 109.233 24.404l152.919 152.904c2.93 2.93 6.768 4.395 10.605 4.395s7.676-1.465 10.605-4.395l42.422-42.422c5.859-5.859 5.859-15.352 0-21.211L354.643 290.995z'
    },
    {
      d:
        'm503.709 212.582-30.517-14.751c-5.068-18.94-12.612-37.119-22.5-54.214l10.459-31.362a15.032 15.032 0 0 0-3.633-15.352l-42.422-42.422c-4.014-4.014-9.976-5.391-15.352-3.633l-31.362 10.459c-17.095-9.888-35.273-17.432-54.214-22.5l-14.75-30.516A15.006 15.006 0 0 0 286 0h-60a15.006 15.006 0 0 0-13.418 8.291l-14.751 30.517c-18.94 5.068-37.119 12.612-54.214 22.5l-31.362-10.459c-5.391-1.758-11.338-.381-15.352 3.633L54.481 96.903a15.032 15.032 0 0 0-3.633 15.352l10.459 31.362c-9.888 17.095-17.432 35.273-22.5 54.214L8.291 212.582A15.006 15.006 0 0 0 0 226v60a15.006 15.006 0 0 0 8.291 13.418l30.517 14.751c5.068 18.94 12.612 37.119 22.5 54.214l-10.459 31.362a15.032 15.032 0 0 0 3.633 15.352l42.422 42.422a15.001 15.001 0 0 0 15.352 3.633l31.362-10.459c17.095 9.888 35.273 17.432 54.214 22.5l14.751 30.517A15.004 15.004 0 0 0 226 512h60a15.006 15.006 0 0 0 13.418-8.291l14.751-30.517c13.35-3.563 26.239-8.383 38.639-14.321l-46.097-46.093c-16.448 5.288-33.446 8.134-50.462 8.134-42.7 0-85.122-16.436-116.924-48.237-64.336-64.336-64.336-169.014 0-233.35s169.014-64.336 233.35 0c44.423 44.423 58.262 109.433 39.827 167.086l46.373 46.386c5.934-12.396 10.756-25.281 14.317-38.628l30.517-14.751A15.006 15.006 0 0 0 512 286v-60a15.006 15.006 0 0 0-8.291-13.418z'
    }
  ]
}
