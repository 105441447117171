import { CIcon } from '~/icons/types'
export const ciLimoSedan: CIcon = {
  name: 'limo-sedan',
  type: 'regular',
  width: 512,
  height: 197.1,
  paths: [
    {
      d:
        'M474.9 65c-19.2-7.9-45.1-8.9-75.2-10.2-9.1-.4-18.4-.8-28.2-1.3l-3.3-2.4C333.3 25.5 300.3 1.4 230.7.1c-.8-.1-1.7-.2-2.6-.1-.2 0-.3 0-.5.1h-2.8C150.2 0 124.7 17.4 92.4 39.5c-2.9 2-5.8 4-8.9 6l-49.1 3.1c-.8.1-1.6.2-2.4.4-12.4 3.7-21.5 16-22.7 30.5-.2 3.5-.3 7-.3 10.7 0 1.7 0 3.4-.1 5.2-4.3 5.2-5.9 12.7-5.9 18.7V135c0 19.1 11.2 31.9 27.7 31.9h47.4c8.1 17.8 26 30.3 46.8 30.3s38.7-12.4 46.8-30.3h173.8c8.1 17.8 26 30.3 46.8 30.3 20.8 0 38.8-12.5 46.8-30.3h4.5c2.8 0 5.4.1 8.1.1 26.4 0 44.5-2 52.7-10.1 3.1-3 4.8-7 4.8-11.1v-24.7c-.1-28.3-11.3-46.6-34.3-56.1zM219.5 21l2.8 32.2H178c-12.5 0-23.2-.1-27.5-1.8-1.3-.5-2.2-.9-4.8-7.8l-2.9-9.4c18-7.8 40.6-12.7 76.7-13.2zm23.8 32.3-2.7-31.8c43.6 2.5 70 15.3 94.6 31.8h-91.9zM124.9 176.1c-16.7 0-30.3-13.6-30.3-30.3s13.6-30.3 30.3-30.3 30.3 13.6 30.3 30.3-13.6 30.3-30.3 30.3zm267.4 0c-16.7 0-30.3-13.6-30.3-30.3s13.6-30.3 30.3-30.3 30.3 13.6 30.3 30.3-13.6 30.3-30.3 30.3zm95.8-33.5c-3.9 1.5-14.7 3.7-44.2 3.3h-.2v-.1c0-28.3-23-51.3-51.3-51.3-28.3 0-51.3 23-51.3 51.3v.1H176.2v-.1c0-28.3-23-51.3-51.3-51.3s-51.3 23-51.3 51.3v.1H30.7c-4.5 0-6.7-3.7-6.7-10.9v-20.8c0-2.1.6-4.2 1-5.1 2.8-1.8 4.5-4.9 4.7-8.2.2-3.7.2-7.2.3-10.4 0-3.4.1-6.6.3-9.3.4-5.4 3.2-10.1 6.8-11.7l50.5-3.2c1.8-.1 3.6-.7 5.1-1.7 4-2.7 7.8-5.2 11.5-7.7 6.7-4.6 13-8.9 19.5-12.8l1.9 6.1c.1.2.1.4.2.6 3.2 8.4 6.8 16.3 16.9 20.2 7.2 2.9 16.3 3.3 35.3 3.3h189.6c10.9.7 21.3 1.1 31.3 1.5 28.1 1.2 52.3 2.1 68.1 8.6 11 4.5 21.1 12.1 21.1 36.7v21.5z'
    }
  ]
}
