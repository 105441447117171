import { CIcon } from '~/icons/types'
export const ciMotorcycle: CIcon = {
  width: 512,
  height: 285.1,
  paths: [
    {
      d:
        'M73.4 218.9c-5.1 0-9.6-3.7-10.4-8.9-.9-5.7 3-11.1 8.7-12l59.9-9.5c-7.8-21.8-28.7-37.4-53.2-37.4-31.2 0-56.5 25.3-56.5 56.5s25.3 56.5 56.5 56.5c30.6 0 55.6-24.5 56.6-54.8l-59.9 9.5c-.6.1-1.2.1-1.7.1zM351.7 91.4c-3.3-.3-6.4-2-8.4-4.6-10.3-12.7-25.5-21-41.7-22.8l-8.7-1c-8.9-.4-30.2-.1-42.2 12.5L234 92.9c-2 2-4.7 3.2-7.6 3.2h-53.3c-3.2 0-6.3-1.5-8.3-4.1l-13.4-17.3c-6.3-8.1-16.2-12.6-26.5-12.2l-51.8 2.4 76.1 46.2c18.7 11.4 33.3 27.9 42.3 47.8s11.7 41.8 8 63.3l-1.8 10.3 125.8 8.6-2.1-44.3c-.9-17.4 3.2-34.5 11.6-49.7 8.5-15.1 21-27.6 36.2-36 6.6-3.7 13.5-6.6 20.7-8.8.2-.1.5-.2.8-.3 11.3-3.4 23.1-5.1 34.9-4.9l18.6.4-4.2-7.8c-7.9-15.1-19.6-27.7-34.1-36.9l-45.2 36c0 .1-.1.1-.2.2-2.5 1.9-5.7 2.7-8.8 2.4zm25.6 116.2c0 31.2 25.3 56.5 56.5 56.5s56.5-25.3 56.5-56.5-25.3-56.5-56.5-56.5c-4.1 0-8.1.5-12 1.3l22.5 53c2.2 5.4-.3 11.5-5.6 13.8-1.3.5-2.7.8-4.1.8-4.1 0-8-2.4-9.7-6.4l-22.5-53c-15.1 10.2-25.1 27.4-25.1 47z',
      fill: '#91D4F3'
    },
    {
      d:
        'm303.9 43.1-9-1h-.6c-11.6-.6-40.6-.1-58.8 18.9l-13.6 14.2h-43.7l-10.1-13.4c-10.5-13.5-27-21.1-44.1-20.3l-86.2 4c-4.6.2-8.5 3.4-9.7 7.9-1.1 4.5.8 9.2 4.7 11.6l105.5 64.1c19.8 12.1 33.4 30.9 39 52.2l-24.8 3.9c-9.6-31.9-39.2-55.1-74.2-55.1-42.7 0-77.5 34.8-77.5 77.5s34.8 77.5 77.5 77.5 77.5-34.8 77.5-77.5V206l24.3-3.8c.1 5.4-.4 11-1.3 16.5l-3.8 21.8c-.5 2.9.2 5.9 2.1 8.3 1.8 2.3 4.6 3.8 7.5 4L333.8 263h.7c2.7 0 5.4-1.1 7.3-3 2.1-2.1 3.3-5 3.1-8l-2.7-56.1c-1.3-27.6 12.9-53 37-66.3 2.7-1.5 5.4-2.8 8.2-4l6.6 15.5c-22.6 13.5-37.8 38.3-37.8 66.5 0 42.7 34.8 77.5 77.5 77.5s77.5-34.8 77.5-77.5-34.7-77.5-77.4-77.5c-7 0-13.9 1-20.3 2.7l-5.7-13.3c5.7-1 11.6-1.5 17.4-1.4l20.2.4c7.2.1 13.7-3.5 17.5-9.6 3.7-6.1 4-13.5.6-19.9l-4.9-9.1c-10.8-20.7-27.5-37.7-48.4-49L360.8 1.5c-5-3-11.4-1.3-14.4 3.7-1 1.7-1.5 3.5-1.5 5.4 0 3.6 1.8 7.1 5.1 9l36.5 21.7L354 67.2c-13.4-13.4-31.2-22.1-50.1-24.1zm-225.5 221c-31.2 0-56.5-25.3-56.5-56.5s25.3-56.5 56.5-56.5c24.5 0 45.4 15.6 53.2 37.4L71.7 198c-5.7.9-9.6 6.3-8.7 12 .8 5.2 5.3 8.9 10.4 8.9.5 0 1.1 0 1.7-.1l59.9-9.5c-1 30.3-26 54.8-56.6 54.8zm346.5-50.5c1.7 4 5.6 6.4 9.7 6.4 1.4 0 2.8-.3 4.1-.8 5.3-2.3 7.8-8.4 5.6-13.8l-22.5-53c3.9-.8 7.9-1.3 12-1.3 31.2 0 56.5 25.3 56.5 56.5s-25.3 56.5-56.5 56.5-56.5-25.3-56.5-56.5c0-19.6 10-36.8 25.1-47l22.5 53zM360.7 88.8l45.2-36c14.5 9.2 26.2 21.8 34.1 36.9l4.2 7.8-18.6-.4c-11.8-.2-23.6 1.5-34.9 4.9-.3.1-.6.2-.8.3-7.2 2.2-14.1 5.1-20.7 8.8-15.2 8.4-27.7 20.9-36.2 36-8.4 15.2-12.5 32.3-11.6 49.7l2.1 44.3-125.8-8.6 1.8-10.3c3.7-21.5 1-43.4-8-63.3s-23.6-36.4-42.3-47.8L73.1 64.9l51.8-2.4c10.3-.4 20.2 4.1 26.5 12.2L164.8 92c2 2.6 5.1 4.1 8.3 4.1h53.3c2.9 0 5.6-1.2 7.6-3.2l16.7-17.4c12-12.6 33.3-12.9 42.2-12.5l8.7 1c16.2 1.8 31.4 10.1 41.7 22.8 2 2.6 5.1 4.3 8.4 4.6 3.1.3 6.3-.5 8.8-2.4.1-.1.2-.1.2-.2z',
      fill: '#1976D2'
    }
  ],
  name: 'motorcycle',
  type: 'duotone'
}
