


















import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { Facet, PurchaseTypesEnum } from '~/models/search/types'
import { mapGetters } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'

import {
  emptyInstallmentsParams,
  emptyLeasingParams
} from '~/constants/search/facets'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  extends: BaseSearchHandler,
  data() {
    return {
      selectedValue: null
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getFacetByName: 'getFacetByName'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    financeFacet(): Facet {
      return this.getFacetByName('finance')
    },
    ...mapDeps({
      scrollService: ScrollService
    })
  },
  methods: {
    onBackendValues() {
      const selected = this.financeFacet?.values?.selected || []
      if (selected.includes(PurchaseTypesEnum.LEASING)) {
        this.selectedValue = true
      } else {
        this.selectedValue = null
      }
    },
    handleValueChange(value) {
      this.emitParams([
        {
          name: 'finance',
          value: value ? 'leasing' : null
        },
        ...emptyInstallmentsParams,
        ...emptyLeasingParams
      ])
    },
    handleOptionsClick() {
      if (!this.selectedValue) {
        this.emitParams([
          {
            name: 'finance',
            value: 'leasing'
          },
          ...emptyInstallmentsParams,
          ...emptyLeasingParams
        ])
      }
      const leasingOptionsEl = document.getElementById(
        'groupPriceSearchHandler'
      )

      let container = document.body
      if (this.isMobile) {
        container = document.getElementById('facets-mobile')
      }

      if (leasingOptionsEl) {
        this.scrollService.scrollTo(leasingOptionsEl, {
          container,
          duration: 300,
          easing: 'ease-out',
          offset: -100
        })
      }
    }
  }
})
