import { CIcon } from '~/icons/types'

export const ciCarService: CIcon = {
  width: '45',
  height: '45',
  paths: [
    {
      d:
        'M7.76 37.512c.01.008.018.026.027.035l.017.018c.01.008.01.008.018.017a.114.114 0 0 0 .035.026l.009.01c.14.113.37.192.756.236h.009c.026 0 .061.01.087.01h.036c.017 0 .035 0 .061.008.027 0 .053 0 .08.009h.026c.22.017.483.026.8.026 2.047 0 2.047-.325 2.047-1.046V35.71H9.272a5.938 5.938 0 0 1-1.608-.237v1.388c-.018.273-.018.484.096.65ZM3.252 15.979c-.606 0-1.292.852-1.292 2.074 0 .035 0 .07.009.105.026.237.123.37.167.413h3.076l.571-2.593H3.252Z',
      fill: '#91D4F3'
    },
    {
      d:
        'M13.403 28.934c-.966 0-2.012-.282-3.12-.844 1.099.562 2.154.844 3.12.844Zm-1.652 7.927c0 .721 0 1.046-2.048 1.046 2.057 0 2.057-.325 2.048-1.046l.009-1.151h-.009v1.151ZM9.264 35.71a5.95 5.95 0 0 1-.01 0h.01Zm-.457 2.17c-.018 0-.044 0-.062-.008a.17.17 0 0 1 .062.009Zm-.097-.008c-.026 0-.062-.009-.088-.009a.33.33 0 0 1 .088.01Zm-.905-.307-.018-.018c0 .009.009.009.018.018Zm.044.052c-.01-.009-.027-.017-.036-.026.018 0 .027.009.036.026ZM5.212 18.571h.009l.571-2.593h-.009l-.571 2.593Zm-1.96-2.592c-.615 0-1.292.852-1.292 2.074 0-1.222.686-2.075 1.292-2.075Zm-1.283 2.179c.026.229.114.37.167.413-.044-.035-.15-.176-.167-.413Zm6.855 15.68a7.394 7.394 0 0 1-.43-.07c.14.035.29.052.43.07ZM6.02 32.335c.097.105.203.21.308.316a3.481 3.481 0 0 1-.307-.316Zm2.374 1.433c-.141-.036-.282-.07-.414-.115.141.044.273.08.414.115ZM6.073 20.46v-.008l-1.265 8.85 1.274-8.841h-.009ZM6.02 32.335a3.665 3.665 0 0 1-.28-.343c.087.123.175.238.28.343Zm1.363 1.063Z',
      fill: '#1976D2'
    },
    {
      d:
        'M33.574 35.71v1.151c0 .721 0 1.046 2.057 1.046.624 0 1.054-.026 1.362-.096l-2.1-2.101h-1.319Zm.202-12.894 6.416 6.416-1.265-8.771h-4.843a8.302 8.302 0 0 1-.308 2.355Zm-14.15 3.736a8.472 8.472 0 0 1-2.47-6.091H6.082l-1.274 8.842a4.58 4.58 0 0 0 .685 2.329c.08.123.158.246.246.369.088.114.176.229.282.343.096.105.202.21.307.316.106.097.22.194.334.281.114.088.237.176.352.255.114.08.246.15.378.22.061.035.131.062.193.097.132.061.264.114.404.158s.273.088.413.114a3.4 3.4 0 0 0 .431.07c.14.018.29.027.44.027h23.774l-5.142-5.15c-2.935.8-6.126-.018-8.279-2.18Zm-6.223 2.382c-.966 0-2.021-.282-3.12-.844-1.292-.65-2.162-1.82-2.329-3.13-.097-.729.044-1.317.422-1.748.246-.281.703-.607 1.503-.607 1.16 0 3.032.282 4.57.686 2.426.642 3.516 1.406 3.516 2.479 0 1.775-2.004 3.164-4.562 3.164Zm29.461-10.363c.062-.052.176-.228.176-.518 0-.448-.088-.844-.237-1.17-.255-.562-.668-.904-1.055-.904h-2.531l.571 2.592h3.076Z',
      fill: '#91D4F3'
    },
    {
      d:
        'M14.45 23.291c-1.539-.404-3.42-.686-4.57-.686-.8 0-1.258.334-1.504.607-.378.43-.518 1.02-.422 1.749.167 1.3 1.046 2.47 2.33 3.129 1.107.562 2.153.844 3.12.844 2.557 0 4.552-1.39 4.561-3.165-.009-1.072-1.09-1.836-3.515-2.478Zm-1.046 3.797c-.66 0-1.45-.22-2.277-.642-.747-.378-1.248-1.02-1.336-1.722a.995.995 0 0 1-.009-.273c.027 0 .062-.009.106-.009.879 0 2.382.211 3.665.519 1.59.378 2.311.756 2.54.94-.158.537-1.178 1.187-2.69 1.187Z',
      fill: '#1976D2'
    },
    {
      d:
        'M13.403 28.934c2.558 0 4.562-1.39 4.562-3.165-.009 1.776-2.004 3.165-4.562 3.165Zm-3.524-6.329c1.151 0 3.032.282 4.57.686-1.538-.404-3.41-.686-4.57-.686Z',
      fill: '#1976D2'
    },
    {
      d:
        'M35.63 37.907c-.008 0-.008 0 0 0-2.065 0-2.065-.325-2.065-1.046V35.71h1.319l-1.846-1.846H9.264c-.15 0-.29-.008-.44-.026a7.394 7.394 0 0 1-.43-.07c-.141-.036-.282-.07-.414-.115a3.21 3.21 0 0 1-.404-.158 1.96 1.96 0 0 1-.193-.097 3.249 3.249 0 0 1-.378-.22 3.243 3.243 0 0 1-.352-.254 5.127 5.127 0 0 1-.334-.281 3.512 3.512 0 0 1-.307-.317 3.665 3.665 0 0 1-.282-.343 3.856 3.856 0 0 1-.246-.369 4.589 4.589 0 0 1-.685-2.329l1.265-8.85v.008h11.084a8.194 8.194 0 0 1 .228-1.845H7.093l1.582-7.216c2.98-4.29 6.407-4.298 13.816-4.298 7.41 0 10.837.009 13.817 4.298l1.582 7.216h-4.026c.14.606.211 1.221.211 1.845h4.852l1.265 8.78 1.635 1.635c.14-.536.22-1.09.22-1.643v-.07l-1.266-8.728H42.9c1.117 0 1.987-1.037 1.987-2.364 0-2.162-1.407-3.92-3.138-3.92h-2.935l-.765-3.48-.097-.15c-3.55-5.23-7.77-5.256-15.407-5.256h-.088c-7.638 0-11.856.018-15.398 5.256l-.097.15-.774 3.532v-.061H3.252c-1.731 0-3.138 1.758-3.138 3.92 0 1.327.87 2.364 1.987 2.364h2.118l-1.257 8.754v.07c0 1.706.677 3.323 1.907 4.562.29.299.607.554.94.791v2.267c0 2.892 2.54 2.892 3.903 2.892 1.362 0 3.902 0 3.902-2.892v-1.15h18.114v1.15c0 2.892 2.54 2.892 3.903 2.892.764 0 1.907 0 2.76-.518l-1.407-1.407c-.299.062-.73.088-1.353.088Zm3.587-21.928h2.531c.387 0 .8.342 1.055.905.15.325.246.72.246 1.169 0 .299-.123.474-.176.518h-3.094l-.562-2.593ZM2.136 18.57c-.053-.035-.15-.184-.167-.413 0-.035-.009-.07-.009-.105 0-1.222.677-2.075 1.292-2.075h2.54l-.571 2.593H2.136Zm9.624 18.29c0 .721 0 1.046-2.057 1.046h-.009c-.307 0-.571-.009-.79-.026h-.027c-.026 0-.053 0-.08-.009-.017 0-.043 0-.06-.009H8.7c-.026 0-.061-.008-.088-.008h-.009c-.386-.044-.615-.124-.755-.238l-.01-.009c-.008-.008-.026-.017-.034-.026l-.018-.017-.017-.018c-.01-.009-.018-.026-.027-.035-.105-.167-.105-.378-.105-.65v-1.39a5.95 5.95 0 0 0 1.617.238h2.496v1.151h.009Z',
      fill: '#1976D2'
    },
    {
      d:
        'M6.02 32.335c.097.105.203.21.308.316a3.481 3.481 0 0 1-.307-.316Zm36.844-13.764h.009c.053-.044.176-.22.176-.518 0-.448-.097-.844-.246-1.17.15.326.237.722.237 1.17 0 .29-.114.466-.176.518ZM31.86 22.764c.853-2.417.255-5.142-1.538-6.935-1.968-1.977-5-2.496-7.54-1.283-.045.018-.08.061-.08.105v.018a.153.153 0 0 0 .044.132l2.725 2.742v2.839h-2.83l-2.725-2.734a.136.136 0 0 0-.123-.043c-.061.008-.114.043-.132.087-1.213 2.55-.694 5.59 1.283 7.568 1.793 1.793 4.5 2.4 6.909 1.547l.553-.194 11.426 11.444c.009.008.044.044.114.044s.097-.036.115-.044l3.058-3.059a.162.162 0 0 0 .053-.114.146.146 0 0 0-.044-.106l-11.461-11.47.193-.544Z',
      fill: '#1976D2'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'car-service',
  type: 'duotone'
}
