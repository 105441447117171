import { CIcon } from '~/icons/types'
export const ciSlidingDoorLeft: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M340.6 266.2c0 9.2 6.4 15.6 16.1 15.7 8 .2 16.2.1 24.1.1h21.1c7.9 0 16 0 24-.1 9.2-.2 15.3-6.2 15.5-15.3.1-4.9-1.3-9.1-4-11.9-2.6-2.7-6.6-4.2-11.4-4.3-21.7-.3-44.4-.3-69.3 0-10.1.1-16.1 6-16.1 15.8z',
      fill: 'currentColor'
    },
    {
      d:
        'M8.6 318.5c0 9.2 4 17.9 10.9 23.9l81 76.2.8.8.9.7c5.6 4.5 12.7 6.9 19.9 6.9 1.6 0 3.1-.1 4.6-.3v26.2c0 35.9 20.8 59.1 52.9 59.1h271c32.1 0 52.9-23.2 52.9-59.1V178.1c0-45.7 0-72.9-.1-119.4C503.3 23.8 482.2.2 450.8 0H232.9c-30.6 0-22.4 0-53.6.1-31.4.1-52.5 23.7-52.6 58.6-.1 46.5-.1 73.7-.1 119.4v32.3c-1.5-.2-3-.3-4.6-.3-7.2 0-14.3 2.5-19.9 6.9l-.9.7-.8.8-81 76.2c-6.9 5.9-10.9 14.6-10.8 23.8zm113.5 82.6c-1.3 0-2.7-.4-3.8-1.3l-81.5-76.7c-1.4-1.1-2.2-2.8-2.2-4.5 0-1.8.8-3.4 2.2-4.5l81.5-76.7c1.1-.9 2.4-1.3 3.8-1.3.9 0 1.8.2 2.6.6 2.1.9 3.4 3 3.4 5.2v38c36.8 3.2 71.3 19.9 98.4 47.9 29.2 30.2 46 70.6 46 110.9 0 2.6-1.8 5-4.5 5.6-.5.1-1 .2-1.5.2-2.2 0-4.2-1.1-5.3-3-44.3-78.8-77.7-84.4-133.2-84.8v38.5c0 2.2-1.3 4.2-3.4 5.2-.8.4-1.7.6-2.5.7zm353.8-231c0 1.4-.3 2.6-.5 3.9-.1.6-.2 1.1-.3 1.8l-.5 3.5H155.4l-.5-3.5c-.1-.7-.2-1.2-.3-1.8-.3-1.3-.5-2.5-.5-3.9v-10.5c0-33.2 0-67.5.3-101.3.1-17.6 9-26.6 26.4-26.6 30.7-.1 21.4-.1 50.5-.1h127.4c29.1 0 59.8 0 90.5.1 17.4 0 26.3 9 26.4 26.6.3 33.8.3 68.1.3 101.3v10.5zM154.1 448.7v-65.4c30.6 2.7 52.9 14.5 84.5 70.7 5.7 10.1 16.4 16.3 28 16.3 2.6 0 5.1-.3 7.6-.9 14.4-3.5 24.4-16.2 24.4-30.9 0-46.9-19.5-94-53.4-129-15.6-16-33.4-29-52.9-38.5-12.3-6-25.1-10.5-38.2-13.5v-44.8l3.8-.2c1.5-.1 2.9-.2 4.2-.3 2.5-.2 4.7-.4 7-.4H461c2.2 0 4.4.2 7 .4 1.3.1 2.6.2 4.2.3l3.8.2v236.2c0 22.9-7.8 31.4-28.7 31.4H182.8c-20.9 0-28.7-8.5-28.7-31.6z',
      fill: 'currentColor'
    }
  ],
  name: 'sliding-door-left',
  type: 'regular'
}
