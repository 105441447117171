import { CIcon } from '~/icons/types'
export const ciTractor2: CIcon = {
  name: 'tractor-2',
  type: 'light',
  width: 128,
  height: 128,
  paths: [
    {
      d:
        'M116 105.641H97.892a23.263 23.263 0 0 0 10.928-18h5.788a1.75 1.75 0 0 0 1.75-1.75 30.621 30.621 0 0 0-9.558-22.229V20.609a1.75 1.75 0 0 0-1.75-1.75H65.393a1.75 1.75 0 0 0-1.69 1.3l-10.7 39.79L39.478 62.2V46.871a1.75 1.75 0 0 0-1.75-1.75h-2.67v-4.289h2.67a1.75 1.75 0 0 0 0-3.5h-4.42a1.75 1.75 0 0 0-1.75 1.75v6.039h-2.671a1.75 1.75 0 0 0-1.75 1.75v17.385L18.1 65.761a1.75 1.75 0 0 0-1.462 1.726v12.284a17.19 17.19 0 0 0 1.873 25.87H12a1.75 1.75 0 0 0 0 3.5h104a1.75 1.75 0 0 0 0-3.5zm-30.355 0A19.771 19.771 0 0 1 65.9 85.9v-.013a19.748 19.748 0 0 1 39.5 0v.013a19.771 19.771 0 0 1-19.755 19.741zm27.157-21.5h-3.982a23.241 23.241 0 0 0-46.351 0h-3.983a27.214 27.214 0 0 1 54.315 0zM69.5 59.737h-5.068l8.1-29.842h23.277v27.02a30.63 30.63 0 0 0-26.251 2.827c-.02 0-.038-.005-.058-.005zm-2.766-37.378H103.3v37.378h-1.581q-1.172-.723-2.413-1.339V28.146a1.75 1.75 0 0 0-1.75-1.75H71.2a1.75 1.75 0 0 0-1.688 1.292l-8.7 32.05h-4.12zm-36.1 26.262h5.341v14.161l-5.341.89zm23.687 14.652a1.716 1.716 0 0 0 .416-.036h7.1a1.8 1.8 0 0 0 .3.04 1.69 1.69 0 0 0 .24-.04h2.558a30.637 30.637 0 0 0-10 22.656 1.75 1.75 0 0 0 1.75 1.75h5.789a23.175 23.175 0 0 0 .324 2.5H46.042a17.207 17.207 0 0 0-25.9-13.087V68.97zM15.143 91.9a13.744 13.744 0 0 1 27.488-.006v.006a13.744 13.744 0 0 1-27.488 0zm24.115 13.745a17.226 17.226 0 0 0 6.784-11.995h17.689a23.365 23.365 0 0 0 9.669 11.991z'
    },
    {
      d:
        'M85.645 74.822a11.071 11.071 0 1 0 11.071 11.071 11.084 11.084 0 0 0-11.071-11.071zm0 18.642a7.571 7.571 0 1 1 7.571-7.571 7.58 7.58 0 0 1-7.571 7.571zM28.887 83.429a8.468 8.468 0 1 0 8.467 8.471 8.478 8.478 0 0 0-8.467-8.471zm0 13.436a4.968 4.968 0 1 1 4.967-4.965 4.974 4.974 0 0 1-4.967 4.965z'
    }
  ]
}
