import { CIcon } from '~/icons/types'
export const ciBoxes: CIcon = {
  name: 'boxes',
  type: 'light',
  width: 64,
  height: 64,
  paths: [
    {
      d:
        'M5.518 64h2.986a1 1 0 0 0 1-1v-2.021h44.992V63a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V2.5c0-1.378-1.121-2.5-2.5-2.5s-2.5 1.122-2.5 2.5v15.504h-2.998V4.008a1 1 0 0 0-1-1H34.502a1 1 0 0 0-1 1v13.995H30.5V4.008a1 1 0 0 0-1-1H13.504a1 1 0 0 0-1 1v13.995h-3V2.5c0-1.378-1.121-2.5-2.5-2.5s-2.5 1.122-2.5 2.5L4.518 63a1 1 0 0 0 1 1zM56.496 2.5a.5.5 0 0 1 1 0V62h-1zM41.498 5.008H43.5v1.008h-2.002zm-5.996 0h3.996v2.008a1 1 0 0 0 1 1H44.5a1 1 0 0 0 1-1V5.008h3.998v12.995H35.502zm-15.002 0h2v1.008h-2zm-5.996 0H18.5v2.008a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5.008h4v12.995H14.504zm-5 14.995h44.992v1H9.504zm0 3h44.992v13.971H51.5v-10.99a1 1 0 0 0-1-1h-37a1 1 0 0 0-1 1v10.991H9.504zM29.5 29.984h5a1 1 0 0 0 1-1v-2h14v9.991h-35v-9.991h14v2a1 1 0 0 0 1 1zm1-2v-1h3v1zM9.504 38.975h44.992v1.002H9.504zm0 3.002h44.992v14h-2.994v-11.99a1 1 0 0 0-1-1H36.5a1 1 0 0 0-1 1v11.99h-2.994v-8.988a1 1 0 0 0-1-1h-6.994l-.01-.002-.01.002H20.51l-.01-.002-.01.002h-6.986a1 1 0 0 0-1 1v8.988h-3zM41.5 47.994h4.002a1 1 0 0 0 1-1v-2.008h3v10.99H37.5v-10.99h3v2.008a1 1 0 0 0 1 1zm1-2v-1.008h2.002v1.008zm-22 5h4.002a1 1 0 0 0 1-1v-2.006h5.004v7.988H14.504v-7.988H19.5v2.006a1 1 0 0 0 1 1zm1-2v-1.006h2.002v1.006zM9.504 57.977h44.992v1.002H9.504zM7.004 2a.5.5 0 0 1 .5.5V62h-.986L6.504 2.5a.5.5 0 0 1 .5-.5z',
      fill: 'currentColor'
    }
  ]
}
