import { CIcon } from '~/icons/types'
export const ciSatelliteTv: CIcon = {
  name: 'satellite-tv',
  type: 'light',
  width: 455.034,
  height: 455.034,
  paths: [
    {
      d:
        'm250.679 441.841-65.242-55.964-.019-.016-45.849-39.329v-63.298a7.5 7.5 0 0 0-7.5-7.5 7.5 7.5 0 0 0-7.5 7.5v63.298L13.46 441.841a7.498 7.498 0 0 0-.81 10.575 7.499 7.499 0 0 0 10.575.81l61.222-52.516 40.121 21.33v25.494a7.5 7.5 0 0 0 7.5 7.5 7.5 7.5 0 0 0 7.5-7.5v-25.493l40.122-21.33 61.221 52.515a7.468 7.468 0 0 0 4.879 1.808 7.483 7.483 0 0 0 5.696-2.617 7.496 7.496 0 0 0-.807-10.576zM96.675 390.222l27.895-23.928v38.758l-27.895-14.83zm42.895 14.83v-38.758l27.895 23.928-27.895 14.83zM377.76 240.46h-.004a7.5 7.5 0 0 0-7.496 7.503c.008 16.695-4.595 29.767-13.681 38.856l-.003.003-.009.009c-15.832 15.821-43.947 18.068-79.166 6.329-36.979-12.327-76.664-38.434-111.743-73.513-35.079-35.079-61.187-74.763-73.513-111.743-11.743-35.228-9.492-63.346 6.338-79.176 15.829-15.829 43.947-18.08 79.175-6.338 36.98 12.327 76.665 38.434 111.743 73.513 27.482 27.482 49.584 57.944 63.916 88.094a7.5 7.5 0 0 0 13.547-6.44c-15.059-31.679-38.177-63.583-66.855-92.261-36.694-36.694-78.462-64.088-117.608-77.136-40.897-13.633-74.467-10.096-94.525 9.961l-.02.021-7.051 7.05c-25.453 25.453-34.4 64.046-25.192 108.67 8.975 43.488 34.243 87.766 71.153 124.676 53.364 53.363 105.406 68.216 139.667 71.278 4.977.445 9.931.663 14.824.663 32.591 0 62.521-9.645 78.855-25.979l7.062-7.063.009-.008c11.835-11.837 18.086-28.944 18.076-49.472a7.498 7.498 0 0 0-7.499-7.497zm-109.992 74.415c-31.787-2.842-80.213-16.763-130.395-66.944-54.597-54.597-79.437-123.323-67.35-173.05.791 11.65 3.406 24.308 7.891 37.766 13.049 39.146 40.442 80.913 77.136 117.606 36.694 36.694 78.461 64.088 117.607 77.137 9.757 3.252 19.092 5.519 27.931 6.826-9.993 1.361-21.017 1.714-32.82.659z'
    },
    {
      d:
        'm441.995 141.3-33.588-33.587a7.5 7.5 0 0 0-10.607 10.606l1.769 1.768-15.91 15.909a7.498 7.498 0 0 0-.001 10.606 7.476 7.476 0 0 0 5.304 2.197 7.474 7.474 0 0 0 5.303-2.197l15.911-15.91 15.911 15.91-104.866 104.864a7.5 7.5 0 0 0 5.304 12.803 7.478 7.478 0 0 0 5.304-2.197l110.166-110.166a7.5 7.5 0 0 0 0-10.606z'
    }
  ]
}
