import { CIcon } from '~/icons/types'
export const ciTelevision: CIcon = {
  name: 'television',
  type: 'light',
  width: 383.563,
  height: 383.563,
  paths: [
    {
      d:
        'M359.399 62.563H239.697a48.133 48.133 0 0 0-5.852-16.592l99.926-31.312a7.5 7.5 0 0 0 4.915-9.399 7.496 7.496 0 0 0-9.399-4.914L223.562 33.475c-8.519-7.41-19.632-11.912-31.783-11.912s-23.263 4.502-31.782 11.912L54.271.346a7.499 7.499 0 0 0-9.399 4.914 7.498 7.498 0 0 0 4.914 9.399l99.926 31.312a48.133 48.133 0 0 0-5.852 16.592H24.164c-12.445 0-22.57 10.125-22.57 22.569v253.861c0 12.445 10.125 22.57 22.57 22.57h3.617v14.5a7.5 7.5 0 0 0 15 0v-14.5h298v14.5c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-14.5h3.617c12.444 0 22.569-10.125 22.569-22.57V85.132c.002-12.444-10.123-22.569-22.568-22.569zm-167.62-26c15.893 0 29.227 11.128 32.644 26h-65.287c3.416-14.871 16.749-26 32.643-26zm175.19 302.43c0 4.174-3.396 7.57-7.569 7.57H24.164c-4.174 0-7.57-3.396-7.57-7.57V85.132c0-4.174 3.396-7.569 7.57-7.569h335.235c4.174 0 7.569 3.396 7.569 7.569v253.861z'
    },
    {
      d:
        'M346.972 103.687a7.5 7.5 0 0 0-6.764-6.208c-98.491-8.285-198.365-8.285-296.854 0a7.5 7.5 0 0 0-6.764 6.208c-11.912 69.591-11.912 140.162 0 209.752a7.5 7.5 0 0 0 6.764 6.208 1771.726 1771.726 0 0 0 148.427 6.214c49.591 0 99.182-2.071 148.427-6.214a7.501 7.501 0 0 0 6.764-6.208c11.912-69.591 11.912-140.161 0-209.752zM333.14 305.179c-93.832 7.576-188.886 7.576-282.716 0-10.354-64.139-10.354-129.093 0-193.232 93.831-7.576 188.884-7.576 282.716 0 10.353 64.139 10.353 129.093 0 193.232z'
    },
    {
      d:
        'M321.094 168.689a7.5 7.5 0 0 0-7.073 7.904 580.301 580.301 0 0 1-1.054 79.266 7.5 7.5 0 0 0 7.482 8.113 7.501 7.501 0 0 0 7.467-6.887 595.165 595.165 0 0 0 1.081-81.323 7.493 7.493 0 0 0-7.903-7.073zm-1.422-9.047a7.5 7.5 0 0 0 7.473-8.223 599.088 599.088 0 0 0-2.698-23.165c-.56-4.105-4.355-6.977-8.444-6.418a7.5 7.5 0 0 0-6.419 8.444 586.476 586.476 0 0 1 2.632 22.583 7.5 7.5 0 0 0 7.456 6.779zm37.963 166.19c-5.754-3.788-13.351 1.804-11.339 8.411.913 2.997 3.672 5.151 6.801 5.309 3.269.165 6.321-1.891 7.431-4.962 1.147-3.176-.069-6.899-2.893-8.758z'
    }
  ]
}
