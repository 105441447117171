import { CIcon } from '~/icons/types'
export const ciDrone: CIcon = {
  width: 512,
  height: 237.2,
  paths: [
    {
      d:
        'M498.6 83.1h-25.4v-1.9c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v1.9h-25.4c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h25.4v19.7l-118.8-8.7V71.7h45v3.5c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5V43.3H411c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-11.9c-1.1-4.6-5.3-8-10.2-8-4.9 0-9.1 3.4-10.2 8h-11.9c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h11.6v7.4h-45v-17C333.4 15.1 318.9 0 301 0h-90.3c-17.9 0-32.4 15.1-32.4 33.7v17h-48.8v-7.4h11.6c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-11.9c-1.1-4.6-5.3-8-10.2-8-4.9 0-9.1 3.4-10.2 8H96.9c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h11.6v31.9c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-3.5h48.8v43.4l-118.7 8.7v-19.7H85c5.8 0 10.5-4.7 10.5-10.5S90.8 83.1 85 83.1H59.1c-1.3-4.3-5.3-7.4-10-7.4s-8.7 3.1-10 7.4H13.2c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h25.4v62.1c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-4.5h118.7v1.1c0 12.7 6.8 23.8 16.8 29.5-4.3 5.7-7.9 13.4-9.4 23.9h-10.8c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h40.6c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5H207c1.5-6.9 4.5-12 9.2-15.5 4.7-3.4 9.6-4.1 10.5-4.2h67.9c3.3.9 8.7 6 10.5 19.7H296c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h40.6c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-10.3c-1.1-11.2-4.1-19.3-7.6-25.1 8.9-6 14.8-16.4 14.8-28.2v-1.2h118.8v4.5c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-62.1h25.4c5.8 0 10.5-4.7 10.5-10.5s-4.8-10.5-10.6-10.5zm-242.7 61.5c-25.6 0-46.4-20.8-46.4-46.4 0-25.6 20.8-46.4 46.4-46.4 25.6 0 46.4 20.8 46.4 46.4.1 25.6-20.7 46.4-46.4 46.4z'
    },
    {
      d:
        'M255.9 71.3c-14.8 0-26.9 12.1-26.9 26.9 0 14.8 12.1 26.9 26.9 26.9 14.8 0 26.9-12.1 26.9-26.9.1-14.8-12-26.9-26.9-26.9z'
    }
  ],
  name: 'drone',
  type: 'solid'
}
