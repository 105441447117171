import { CIcon } from '~/icons/types'
export const ciDiscBrake1: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M231.5 413q-54.5 0-93.5-39t-39-93.5 39-93 93.5-38.5 93 38.5 38.5 93-38.5 93.5-93 39zm-.5-248q-48 0-81.5 34T116 281t33.5 81.5T231 396t82-33.5 34-81.5-34-82-82-34zm0 157q-17 0-29-12t-12-29 12-29.5 29-12.5 29.5 12.5T273 281t-12.5 29-29.5 12zm0-66q-6 0-12 3.5t-9.5 9T206 281q0 10 7.5 17.5T231 306t17.5-7.5T256 281t-7.5-17.5T231 256zm0-25q-10 0-17.5-7t-7.5-18q0-6 3.5-12t9.5-9 12-3q10 0 17.5 7t7.5 17q0 7-3.5 13t-9 9-12.5 3zm0-33q-8 0-8 8.5t8 8.5 8-8.5-8-8.5zm0 132q10 0 17.5 7.5T256 355q0 5-2 9.5t-5.5 8-8 5.5-9.5 2q-10 0-17.5-7.5T206 355q0-7 3.5-12.5t9.5-9 12-3.5zm0 33q8 0 8-8t-8-8-8 8 8 8zm74.5-57q-10.5 0-17.5-7.5t-7-17.5 7-17.5 18-7.5q6 0 12 3.5t9 9 3 12.5q0 10-7 17.5t-17.5 7.5zm0-33q-8.5 0-8.5 8t8.5 8 8.5-8-8.5-8zM182 281q0 6-3.5 12t-9 9.5T157 306q-10 0-17.5-7.5T132 281q0-7 3.5-12.5t9-9T157 256q10 0 17.5 7.5T182 281zm-33 0q0 8 8 8t8-8-8-8-8 8zM290 57q60 16 104 60t60 104l-16 4q-15-55-55.5-96T286 73zM231 0q116 0 198.5 82.5T512 281v8h-50q-2 61-33.5 112.5t-83.5 81T231 512q-95 0-163-68T0 281q0-94 65-161t158-70V0h8zm0 495q87 0 149-60t66-146h-32q7 29 7 58h-16q0-29-8-58h-25v-8q0-38-19-70.5T301.5 159 231 140h-8v-20q-32 11-53 27l-10-13q23-19 63-32V66q-86 4-146 66T17 281q0 89 62.5 151.5T231 495zm157-222h107q-3-105-77-179T239 17v107q60 3 103 46t46 103zm66-17v-17h17v17h-17zm-33 0v-17h17v17h-17zM256 50V33h17v17h-17zm68 381q5-6 10-13l14 9q-6 8-11 15zm19-28q5-7 8-15l15 7-8 16zm15-31 6-16 15 5q-2 9-6 17zM139 130q-6 7-11 14l-13-9q5-8 11-15zm-20 28q-4 8-8 15l-15-7q4-8 9-16zm-30 25 15 6q-3 8-5 16l-16-4zm45 240 6-15q8 3 16 5l-5 16q-9-3-17-6zm-64-37 11-13q6 6 13 11l-9 13q-8-5-15-11zm31 21 8-14q7 4 15 8l-7 15q-8-4-16-9zm64 47q22 0 43-4t35-9.5 26.5-12T286 419t6-4l10 13q-6 5-17.5 12.5t-47 19T165 471v-17zM41 215h17q0 34 10.5 67T86 325.5L97 342l-13 10q-5-7-12.5-18.5t-19-46.5T41 215z',
      fill: 'currentColor'
    }
  ],
  name: 'disc-brake-1',
  type: 'light'
}
