





























import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: true
    },
    forceMobileView: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { fullWidth, show } = toRefs(props)

    const showSidebarOverlay = computed(() => show.value && !fullWidth.value)

    function handleOverlayClick() {
      emit('overlay-click')
    }

    return { showSidebarOverlay, handleOverlayClick }
  }
})
