





















import KeyFeatureIcon from '~/components/car/classifieds/view/partials/key-features/KeyFeatureIcon.vue'
import { extrasIcons } from '~/constants/classified/extra'
import { solidKeyFeaturesIcons } from '~/constants/classified/key-feature/icon/solid'
import CMakeLogo from '~/components/shared/configurable/make/CMakeLogo.vue'
import { MakeLogoType } from '~/models/make'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { CategoryId } from '~/models/category/types'

export default defineComponent({
  components: { CMakeLogo, KeyFeatureIcon },
  props: {
    arg: {
      type: Object,
      required: true
    },
    suggestionCategoryId: {
      type: Number as PropType<CategoryId>,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    hasMakeLogo() {
      return (
        [CategoryId.CARS, CategoryId.BIKES].includes(
          this.suggestionCategoryId
        ) &&
        this.arg.name === 'makemodel' &&
        this.arg.values.filter(a => a.type === 'make').length
      )
    },
    makeLogoType() {
      if (this.suggestionCategoryId === CategoryId.BIKES) {
        return MakeLogoType.BIKES
      }
      return MakeLogoType.CARS
    },
    isFreeText() {
      return ['mein', 'admin_mein', 'long_mein'].includes(this.arg.name)
    },
    argIcon() {
      if (['makemodel'].includes(this.arg.name)) {
        return null
      }

      if (this.arg.name === 'condition' && this.arg.values[0] !== 'new') {
        return null
      }

      if (this.arg.name === 'features' && this.arg.values[0]?.name) {
        const name = this.arg.values[0].name
        return extrasIcons[name]
      }
      return solidKeyFeaturesIcons[this.arg.name]
    },
    makeLogoId() {
      if (this.hasMakeLogo) {
        return this.arg.values.filter(a => a.type === 'make')[0].value
      }
      return ''
    }
  }
})
