import { CIcon } from '~/icons/types'
export const ciContainer: CIcon = {
  width: 512,
  height: 395.5,
  paths: [
    {
      d:
        'M490.2 182.2h-47.5L283 124.1c6.1-6.6 9.9-15.4 9.9-25.1 0-16.7-11.2-30.9-26.5-35.4V54h17c8.5 0 15.4-6.9 15.4-15.4V15.4C299 6.9 292 0 283.5 0h-54.9c-8.5 0-15.4 6.9-15.4 15.4v23.1c0 8.5 6.9 15.4 15.4 15.4h17v18.6c0 5.8 4.7 10.5 10.5 10.5 8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5c0 9.7 3.8 18.5 9.9 25.1L69.5 182.2H22c-11.6 0-21 9.4-21 21v171.3c0 11.6 9.4 21 21 21h468c11.6 0 21-9.4 21-21V203.1c0-11.5-9.3-20.9-20.8-20.9zM234.1 32.9v-12H278v12h-43.9zM256 136.5l125.3 45.6H130.8L256 136.5zm234.2 238H22V203.2h468.1v171.3z'
    },
    {
      d:
        'M71.3 358.2c5.8 0 10.5-4.7 10.5-10.5V229.8c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v117.9c0 5.8 4.7 10.5 10.5 10.5zm61.7 0c5.8 0 10.5-4.7 10.5-10.5V229.8c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v117.9c0 5.8 4.6 10.5 10.5 10.5zm61.5 0c5.8 0 10.5-4.7 10.5-10.5V229.8c0-5.8-4.7-10.5-10.5-10.5S184 224 184 229.8v117.9c0 5.8 4.6 10.5 10.5 10.5zm61.5 0c5.8 0 10.5-4.7 10.5-10.5V229.8c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v117.9c0 5.8 4.7 10.5 10.5 10.5zm61.7 0c5.8 0 10.5-4.7 10.5-10.5V229.8c-.1-5.8-4.8-10.5-10.5-10.5-5.8 0-10.5 4.7-10.5 10.5v117.9c0 5.8 4.7 10.5 10.5 10.5zm61.5 0c5.8 0 10.5-4.7 10.5-10.5V229.8c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v117.9c0 5.8 4.7 10.5 10.5 10.5zm61.6 0c5.8 0 10.5-4.7 10.5-10.5V229.8c-.1-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v117.9c-.1 5.8 4.7 10.5 10.5 10.5z'
    }
  ],
  name: 'container',
  type: 'regular'
}
