import { CIcon } from '~/icons/types'
export const ciStairs: CIcon = {
  name: 'stairs',
  type: 'regular',
  width: 368,
  height: 368,
  paths: [
    {
      d:
        'M360 0h-96c-4.424 0-8 3.584-8 8v40h-40a7.994 7.994 0 0 0-5.168 1.896l-208 176A8.01 8.01 0 0 0 0 232v128c0 4.416 3.576 8 8 8h352c4.424 0 8-3.584 8-8V8c0-4.416-3.576-8-8-8zM208 73.248 218.928 64H256v80h-48V73.248zM144 127.4l48-40.616V192h-48v-64.6zm-64 54.16 48-40.616V240H80v-58.44zm-64 54.152 48-40.616V248c0 .088.048.16.048.248s-.048.168-.048.256V288H16v-52.288zM352 352H16v-48h56c4.424 0 8-3.584 8-8v-40h56c4.424 0 8-3.584 8-8v-40h56c4.424 0 8-3.584 8-8v-40h56c4.424 0 8-3.584 8-8v-40h80v240zm0-256h-80V64h80v32zm0-48h-80V16h80v32z',
      fill: 'currentColor'
    }
  ]
}
