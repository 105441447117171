import { CIcon } from '~/icons/types'
export const ciJobs: CIcon = {
  name: 'jobs',
  type: 'brand',
  width: 1079,
  height: 868,
  paths: [
    {
      d:
        'M710.26 0h9.33c11.82.56 23.76 1.56 35.05 5.34 19.2 5.9 37.34 15.39 52.89 28.13 23.27 18.9 40.49 45.31 47.57 74.51 1.98 7.49 2.49 15.38 5.41 22.62 2.32 6.07 9.33 7.89 12.94 12.94 9.47 11.83 10.56 28.28 7.68 42.65-3.17 14.81-11.36 29.61-25.12 36.9-3.39 2.01-7.42 2.35-10.91 4.05-3.93 2.43-6.09 6.71-8.3 10.59-4.32 8.16-8.98 16.15-14.16 23.79-16.02 23.67-37.11 44.94-63.62 56.5-10.02 4.64-21.1 6.13-32 6.91-11.64.73-23.42.58-34.92-1.48-11.66-2.31-22.89-6.59-33.39-12.13-26.14-13.94-47.81-35.62-63.03-60.93-4.24-6.91-7.51-14.38-11.93-21.19-1.46-2.13-3.09-4.45-5.63-5.33-2.99-.68-6.12-.4-9.07-1.3-5.31-1.6-9.89-5.02-13.66-9-9.36-10.13-14.73-23.77-15.28-37.51-.42-11.24 1.08-23.48 8.62-32.37 4.17-5.49 11.68-8.24 13.92-15.17.72-4.46.59-9.03 1.77-13.43 3.43-14.79 9.17-29.02 16.47-42.31 17.17-29.68 45.56-52.19 77.45-64.31 16.48-6.5 34.37-8 51.92-8.47Z',
      fill: 'currentColor'
    },
    {
      d:
        'M262.54 7.75c17.07-1.39 34.43-1.48 51.31 1.74 31.2 7.22 60.28 24.17 81.2 48.53 15.65 18.14 26.54 40.4 30.94 63.97 1.09 5.39 1.85 10.94 4.1 16 1.34 3.13 4.12 5.24 6.88 7.07 7.38 4.98 11.67 13.4 13.57 21.91 2.86 13.34 1 27.57-4.65 39.95-4.59 9.98-11.97 19.14-22.13 23.79-3.45 1.82-7.76 1.67-10.8 4.31-3.32 2.86-5.31 6.86-7.41 10.62-11.16 21.08-25.16 40.93-43 56.91-11.97 10.69-25.74 19.58-40.92 24.93-8.26 2.68-16.95 3.68-25.58 4.31-16.56 1.04-33.56.25-49.28-5.49-35.46-12.42-64.64-39.48-83.3-71.71-4.38-7.28-7.47-15.43-13.03-21.93-2.65-3.14-7.04-2.06-10.58-2.88-6.88-1.57-12.6-6.23-17.05-11.53-9.18-11.16-13.96-25.79-13.4-40.22.11-9.8 2.27-20.13 8.85-27.72 3.66-4.7 9.51-7.24 12.61-12.42 2.26-3.57 1.26-7.98 2.13-11.93 2.08-12.01 6.38-23.52 11.32-34.63 13.1-29.8 37.85-53.49 66.44-68.48 16.02-8.47 33.66-13.89 51.78-15.1Z',
      fill: 'currentColor'
    },
    {
      d:
        'M76.35 351.23c16.06-10.82 33.64-19.07 50.94-27.66 9.04 13.46 17.29 27.43 25.97 41.12 25.67 40.99 51.63 81.8 77.3 122.78 5.07 7.81 9.7 15.95 15.61 23.17 4.63-16.99 7.14-34.48 10.3-51.79 1.31-7.93 2.85-15.84 3.54-23.86.13-2.68.41-6.03-2.1-7.77-5.95-4.82-9.59-11.93-11.71-19.16-2.95-10.57 1.46-22.2 9.7-29.16 12.22-10.93 32.14-12.13 45.23-2.06 11.66 8.62 16.49 25.81 9.65 38.87-2.89 6.06-8.52 9.95-13.52 14.13 5.08 27.35 8.96 54.96 15.49 82.01 2.83-2.56 5.03-5.69 7.17-8.83 8.24-12.23 15.78-24.93 23.7-37.37 23.65-37.7 47.3-75.41 71.07-113.03 5.79-8.83 11.02-18.07 17.25-26.58 4.97-.41 9.53 2.19 13.99 4.02 16.51 7.83 32.03 17.59 47.07 27.93 11.15 7.94 22.51 16.02 31.21 26.73 11.2 13.39 21 28.12 27.71 44.28 2.22 5.77 4.54 11.7 4.54 17.98.89 27.32 1.14 54.66 1.52 82 .62 54.34 1.1 108.69 1.02 163.04-.16 12.44.26 24.95-.63 37.36-3.65 4.56-9.39 6.78-14.44 9.46-17.76 8.74-37.41 12.39-56.75 15.81-43.08 7.12-86.67 10.63-130.24 12.95-51.61 2.57-103.36 2.94-154.95-.2-43.1-2.51-86.26-5.7-128.86-12.93-18.15-3.27-36.4-7.01-53.5-14.1-7.06-2.81-13.34-7.13-19.63-11.3V452.94c4.16-11.06 7.74-22.37 12.9-33.02 5.98-12.65 14.06-24.25 23.62-34.45 11.99-12.8 25.18-24.56 39.83-34.24Z',
      fill: 'currentColor'
    },
    {
      d:
        'M517.62 337.9c10.27-4.64 20.45-9.51 30.87-13.8 32.98 52.34 66.21 104.53 99 156.99-10.05 17.93-16.28 37.72-20.23 57.83-3.57 18.71-5.76 37.99-3.04 56.97 4.67 38.07 18.78 75.23 42.3 105.67 10.94 14.28 23.75 27.09 37.87 38.22 11.41 9.16 24.04 16.59 36.06 24.88 1.64 1.26 3.89 2.03 4.73 4.09-8.26 1.82-16.8 1.15-25.2 1.13-32.44-.97-64.83-3.43-97.06-7.24-9.57-1.36-19.32-2.27-28.59-5.1-1.76-2.8-1.58-6.33-2.01-9.49-1.36-19.98-1.4-40.01-1.8-60.01-1.01-76.69-1.62-153.39-2.45-230.08-.24-5.14.26-10.34-.44-15.44-4.9-14.1-9.18-28.56-16.75-41.53-9.88-17.66-23.07-33.25-37.53-47.33-5.22-5.28-10.73-10.28-15.73-15.76Z',
      fill: 'currentColor'
    },
    {
      d:
        'M846.72 334.66c2.21-3.22 4.26-7.22 8.25-8.46 5.45-.66 10.23 2.75 15 4.89 25.11 12.25 49.12 27.68 68.25 48.21-1.52-.26-3-.61-4.4-1.2-17.4-7.2-36.03-11.08-54.72-12.92-16.47-1.77-33.08-.76-49.55-2.42 5.21-9.67 11.44-18.74 17.17-28.1Z',
      fill: 'currentColor'
    },
    {
      d:
        'M686.42 371.51c10.77-3.13 22.94-2.06 32.9 3.22 7.06 3.69 12.27 10.39 14.55 17.98-13.08 8.4-26.11 17.04-37.68 27.48-4.44 4.03-8.77 8.18-13.23 12.18-6.06-7.98-14.43-15.03-16.64-25.22-2.84-14.82 7.25-29.23 20.1-35.64Z'
    },
    {
      d:
        'M834.59 399.81c59.34-5.56 121.03 17.07 161.59 60.96 19.6 21.06 33.89 47.09 40.6 75.1 11.69 48.2 3.66 101.91-25.29 142.67-3.6 5.37-8.55 10.24-10.7 16.34 6.54 11.09 14.1 21.55 21.33 32.21 12.97 18.64 26.02 37.22 39.04 55.83 5.22 7.94 10.23 16.07 14.19 24.72 2.25 5.74 4.45 12.02 2.96 18.23-1.78 7.56-4.45 15.05-9.07 21.38-11.19 15.8-32.52 23.83-51.29 18.78-8.79-2.26-17.54-6.51-23.15-13.86-22.86-27.55-42.27-57.67-63.16-86.68-3.47-4.58-6.49-9.55-10.35-13.79-5.19-2.48-11.09.17-16.43.96-27.92 7.2-57.31 8.44-85.74 3.69-31.68-5.21-62.45-17.66-87.65-37.75-32.75-25.48-57.39-61.95-66.2-102.72-2.64-11.07-2.83-22.52-2.75-33.84.3-14.7 1.19-29.47 4.07-43.91 11.2-44.73 40.25-84.76 79.58-108.92 26.64-16.52 57.27-26.38 88.42-29.4m8.64 63.59c-26.71 1.37-52.4 12.85-72.23 30.61-12.6 11.31-24 24.45-30.91 40.05-7.56 17.21-11.82 36.22-9.76 55.05 3.45 35.16 24.96 67.23 54.45 86.13 26.05 16.94 58.64 23.34 89.19 17.68 29.12-5.21 56.24-21.07 74.88-44.07 15.57-19.11 25.2-43.2 26.23-67.88.45-29.19-13.01-57.3-32.64-78.38-11.01-11.76-24.01-21.92-39.02-28.02-19.02-7.7-39.58-12.38-60.19-11.17Z',
      fill: 'currentColor'
    }
  ]
}
