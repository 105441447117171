import { CIcon } from '~/icons/types'
export const ciExchangeBoatAccepted: CIcon = {
  width: 512,
  height: 411.6,
  paths: [
    {
      d:
        'M12.1 121.4h456.1l-13.6 12.2-31.2 28-27 24.2c-4.4 3.9-8.8 7.8-13.1 11.8l-.2.2c-4.7 4.8-4.7 12.4 0 17.2 2.3 2.3 5.4 3.5 8.6 3.5 3.2-.1 6.2-1.4 8.6-3.6 3.6-3.2 7.2-6.4 10.8-9.7l26-23.2 31.2-28 27-24.2c4.1-3.7 8.2-7.3 12.2-11 .4-.3.7-.6 1.1-1 .8-.7 1.4-1.6 1.9-2.5 1.1-1.8 1.7-3.9 1.6-6.1 0-1.3-.2-2.6-.7-3.8-.5-1.8-1.5-3.4-2.9-4.7L497.6 91l-25.9-23.2-31.2-28-27-24.2c-4.4-3.9-8.7-7.9-13.1-11.8l-.2-.2c-2.3-2.2-5.3-3.5-8.5-3.6-3.2 0-6.3 1.3-8.6 3.6s-3.5 5.4-3.5 8.6c.1 3.1 1.1 6.4 3.5 8.6l10.8 9.7 25.9 23.2c10.4 9.3 20.8 18.7 31.2 28 5.7 5.2 11.5 10.3 17.2 15.5H32c-6.3 0-12.6-.1-19 0h-.8c-6.4 0-12.4 5.6-12.2 12.2s5.3 12 12.1 12zm487.8 168.8H43.8L57.4 278l31.2-28 27-24.2c4.4-3.9 8.8-7.8 13.1-11.8l.2-.2c4.7-4.7 4.7-12.4 0-17.2-2.3-2.3-5.4-3.6-8.6-3.6-3.2.1-6.2 1.4-8.6 3.6-3.6 3.2-7.2 6.4-10.8 9.7l-26 23.2-31.2 28-27 24.2c-4.1 3.7-8.2 7.3-12.2 11-.4.3-.7.6-1.1 1-.8.7-1.4 1.6-1.9 2.5-1.1 1.8-1.7 3.9-1.6 6 0 1.3.2 2.6.7 3.9.5 1.8 1.5 3.4 2.9 4.7l10.8 9.7 25.9 23.2 31.2 28 27 24.2c4.4 3.9 8.7 7.9 13.1 11.8l.2.2c2.3 2.2 5.4 3.5 8.6 3.6 3.2 0 6.3-1.3 8.6-3.6s3.5-5.4 3.6-8.6c-.1-3.1-1.1-6.4-3.6-8.6l-10.8-9.7-25.9-23c-10.4-9.3-20.8-18.7-31.2-28-5.7-5.2-11.5-10.3-17.2-15.5H480c6.3 0 12.6.1 19 0h.8c6.4 0 12.4-5.6 12.1-12.1s-5.3-12.2-12-12.2z'
    }
  ],
  name: 'exchange-boat-accepted',
  type: 'regular'
}
