import { getStaticUrl } from '~/utils/static'

export const FALLBACK_MAKE_LOGO_IMG_SOURCE = getStaticUrl(
  '/nuxt-static/img/makes/fallback.png'
)

export const MAKE_LOGOS_BASE_STATIC_PATH = getStaticUrl(
  '/nuxt-static/img/makes'
)

export const CAR_MAKES_IDS_WITH_LOGO: number[] = [
  12522,
  12525,
  12559,
  12560,
  12562,
  12564,
  12565,
  12566,
  12567,
  12569,
  12570,
  12572,
  12590,
  12591,
  12592,
  12594,
  12595,
  12596,
  12598,
  12599,
  12600,
  12603,
  12605,
  12608,
  12609,
  12610,
  12611,
  12614,
  12617,
  12652,
  12733,
  12781,
  12786,
  12848,
  12849,
  12858,
  13007,
  13089,
  13151,
  13198,
  13214,
  13272,
  13277,
  13288,
  13289,
  13290,
  13291,
  13299,
  13300,
  13301,
  13302,
  13303,
  13333,
  13334,
  13417,
  13435,
  13480,
  13526,
  13617,
  13641,
  13685,
  13686,
  13687,
  13690,
  13694,
  13696,
  13697,
  13701,
  13717,
  13718,
  13720,
  13772,
  13858,
  13869,
  13880,
  13881,
  13882,
  13883,
  13884,
  13885,
  13886,
  13887,
  13888,
  13889,
  13890,
  13892,
  13893,
  13894,
  13895,
  13896,
  13897,
  13932,
  13996,
  14093,
  14094,
  14123,
  20709,
  20712,
  20714,
  20736,
  20777,
  20783,
  21654,
  32132,
  32186,
  32199,
  32202,
  32260,
  32365,
  32373,
  32374,
  32375,
  32413,
  32414,
  32415,
  32416,
  32456,
  32458,
  32459,
  32460,
  32461,
  32462,
  32466,
  32469,
  32471,
  32473,
  32475,
  32477,
  32478,
  32481,
  32483,
  32492,
  32493,
  35156,
  35217,
  35262,
  35364,
  35365,
  35548,
  35802,
  37075,
  38374,
  38375,
  38376,
  38377,
  40238,
  40471,
  40474,
  40545,
  40546,
  40703,
  40704,
  40705,
  40706
]
export const BIKES_MAKES_IDS_WITH_LOGO: number[] = [
  12506,
  12510,
  12511,
  12512,
  12514,
  12518,
  12520,
  12526,
  12527,
  12530,
  12561,
  12568,
  12587,
  12609,
  12617,
  12620,
  12629,
  12631,
  12733,
  12858,
  13161,
  13170,
  13185,
  13194,
  13198,
  13199,
  13207,
  13212,
  13222,
  13229,
  13269,
  13277,
  13329,
  13496,
  13670,
  13681,
  13687,
  13881,
  13926,
  13992,
  14058,
  14124,
  20813,
  20819,
  32097,
  32106,
  32344,
  32394,
  32409,
  32422,
  32426,
  32445,
  32464,
  32472,
  32474,
  32479,
  32480,
  32484,
  32493,
  32494,
  32499,
  35022,
  35097,
  35111,
  35259,
  35300,
  35335,
  35415,
  35486,
  37250,
  37303,
  40479
]
