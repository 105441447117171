































import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch
} from '@nuxtjs/composition-api'
import { Option } from '~/models/shared/types'
import { vue3Model } from '~/utils/nuxt3-migration'

type ButtonGroupValueType = string | number

export default defineComponent({
  model: vue3Model,
  props: {
    options: {
      type: Array as PropType<Option[]>,
      default: () => []
    },
    modelValue: {
      type: [Number, String],
      default: null
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg'>,
      default: 'sm'
    },
    buttonVariant: {
      type: String,
      default: 'secondary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const selected = ref(props.modelValue)

    watch(
      () => props.modelValue,
      newValue => {
        selected.value = newValue
      }
    )
    watch(selected, newValue => emit('update:modelValue', newValue))

    function changeSelectedValue(value: ButtonGroupValueType) {
      if (selected.value !== value) {
        selected.value = value
      }
    }

    const roundnessClass = computed(() => {
      switch (props.size) {
        case 'xs':
          return 'tw-rounded-md'
        case 'sm':
          return 'tw-rounded-lg'
        case 'lg':
          return 'tw-rounded-2xl'
        case 'md':
        default:
          return 'tw-rounded-xl'
      }
    })

    return {
      selected,
      roundnessClass,
      changeSelectedValue
    }
  }
})
