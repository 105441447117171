












import { mapActions, mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons/faHeart'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import SaveSearchModal from '../../facets/search-saving/SaveSearchModal.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { SaveSearchModal },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    icons: () => ({
      solidHeart: faHeart,
      regularHeart: farHeart
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      isFavorite: state => state.isFavorite,
      searchId: state => state.searchId
    }),
    icon() {
      const { solidHeart, regularHeart } = this.icons
      return this.isFavorite ? solidHeart : regularHeart
    },
    tooltipTitle() {
      return this.isFavorite
        ? this.$t('remove from saved')
        : this.$t('save search')
    },
    searchId() {
      return this.$store.state.classifieds.search.searchId
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      addToFavorites: 'addToFavorites',
      removeFromFavorites: 'removeFromFavorites'
    }),
    toggle() {
      if (this.isFavorite) {
        this.removeFromFavorites()
      } else {
        this.addToFavorites()
        this.toggleModal(true)
      }
      this.$root.$emit('bv::hide::tooltip')
    },
    toggleModal(show: boolean) {
      this.showModal = show
    }
  }
})
