import { CIcon } from '~/icons/types'
export const ciHardDisk: CIcon = {
  name: 'hard-disk',
  type: 'solid',
  width: 448,
  height: 448,
  paths: [
    {
      d:
        'M151.2 304.8a7.997 7.997 0 0 0-4.938-7.382 8.007 8.007 0 0 0-8.711 1.727l-67.887 67.87c-3.125 3.126-3.125 8.196 0 11.32s8.195 3.126 11.32 0l67.871-67.878a7.93 7.93 0 0 0 2.344-5.656zM192 224c0 13.254-10.746 24-24 24s-24-10.746-24-24 10.746-24 24-24 24 10.746 24 24zm120 192a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm0-384a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm0 0'
    },
    {
      d:
        'm83.559 330.504 42.664-42.672c9.5-9.066 24.445-9.066 33.945 0 9.375 9.371 9.375 24.57 0 33.945L128 353.937c67.215 20.774 139.191-13.046 166.102-78.05 26.91-65-.102-139.801-62.332-172.614S92.559 90.43 54.125 149.355s-25.68 137.422 29.434 181.149zM168 184c22.09 0 40 17.91 40 40s-17.91 40-40 40-40-17.91-40-40c.027-22.082 17.918-39.973 40-40zM40 416a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm0 0'
    },
    {
      d:
        'M8 448h320a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H8a8 8 0 0 0-8 8v432a8 8 0 0 0 8 8zm296-8c-13.254 0-24-10.746-24-24s10.746-24 24-24 24 10.746 24 24-10.746 24-24 24zm0-432c13.254 0 24 10.746 24 24s-10.746 24-24 24-24-10.746-24-24 10.746-24 24-24zM168 72c83.95 0 152 68.05 152 152s-68.05 152-152 152a150.537 150.537 0 0 1-52.504-9.55l-23.2 23.198c-9.374 9.372-24.57 9.372-33.944 0-9.372-9.375-9.372-24.57 0-33.945l13.816-13.808C22.234 301.335 3.254 233.73 24.781 173.109 46.305 112.49 103.671 71.99 168 72zM32 8c13.254 0 24 10.746 24 24S45.254 56 32 56 8 45.254 8 32 18.746 8 32 8zm0 384c13.254 0 24 10.746 24 24s-10.746 24-24 24-24-10.746-24-24 10.746-24 24-24zm0 0'
    },
    { d: 'M40 32a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm0 0' }
  ]
}
