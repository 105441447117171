import { CIcon } from '~/icons/types'
export const ciBigTruck: CIcon = {
  width: 512,
  height: 351.8,
  paths: [
    {
      d:
        'm157.5 256.1.6.3 2.3 2.2c4.9 4.7 8.9 10 12.1 15.8 4.2 7.7 6.9 16.1 7.8 24.8.1.7.2 1.5.2 2.2.4 6.5 0 12.7-1.2 18.7h46.3v-66.8h-71.7c1.2.9 2.4 1.8 3.6 2.8zm351.9-.7h-24.7c-4.3 0-9.2-5.5-9.2-13.4s4.9-13.4 9.2-13.4h24.5l-.1-5.4c0-.6-.1-1.1-.1-1.7L477.3 33.8c-.2-1.2-.6-2.3-1.2-3.4-2-3.5-8-14.3-45.1-23.4-18.4-4.5-37.6-7-46.3-7H255.4c-2.8 0-5.5 1.1-7.4 3.1-2 2-3.1 4.6-3.1 7.4L245 74v246.1h72.3c-.9-4.4-1.4-8.9-1.4-13.5 0-32.1 23-59 53.3-65 4.2-.8 8.5-1.3 12.9-1.3 34 0 62.1 25.8 65.8 58.8.3 2.4.4 4.9.4 7.4 0 4.6-.5 9.1-1.4 13.5h52.3c2.8 0 5.5-1.1 7.5-3.1s3.1-4.7 3-7.5l-.3-54zm-136.2-87.3c-9 0-16.3-7.3-16.3-16.3V84.7c0-9 7.3-16.3 16.3-16.3h88.6l16.9 99.7H373.2zM75.3 253.3H12.5C6.7 253.3 2 258 2 263.8v45.8c0 5.8 4.7 10.5 10.5 10.5h37.4c-.8-4.1-1.3-8.3-1.4-12.5v-.2c0-2.8.1-5.5.4-8.2 1-8.8 3.6-17.2 7.8-24.8 3-5.4 6.8-10.4 11.3-14.8l.3-.3.3-.4 1.5-1.4c1.7-1.5 3.4-2.9 5.2-4.2zm70.5 20.4c-.3-.1-.4-.3-.5-.4-7.5-7-17.5-11.5-28.3-12.1h-2.4c-11.3 0-21.9 4.2-30.3 11.8-.4.5-.9 1-1.4 1.4-8.8 8.7-13.4 20.3-13.3 32.7.1 4.5.8 8.8 2.1 12.9 5.6 17.8 21.8 30.8 41.2 31.6h1.8c19.7 0 37.1-12.7 43-31.6.1-.3.2-.5.3-.8 1.6-5.1 2.1-10.6 1.7-16.4-.9-11.3-6.2-21.6-13.9-29.1z'
    },
    {
      d:
        'M412.4 272.8c-8-7.1-18.6-11.5-30.1-11.5-25 0-45.3 20.3-45.3 45.3 0 4.7.7 9.3 2.1 13.5 5.8 18.4 22.9 31.7 43.2 31.7 20.3 0 37.5-13.4 43.2-31.8 1.3-4.3 2-8.8 2-13.5 0-2.5-.2-5-.6-7.4-1.7-10.4-7-19.6-14.5-26.3z'
    }
  ],
  name: 'big-truck',
  type: 'solid'
}
