
























import { computed, defineComponent } from '@nuxtjs/composition-api'
import { faCogs } from '@fortawesome/free-solid-svg-icons'
import { defineComponentTranslations } from '~/utils/i18n'
import { useI18n } from '~/compositions/i18n'
import MyClassifiedsLinks from '~/components/car/widgets/MyClassifiedsLinks.vue'
import { useNamespacedStore } from '~/compositions/store'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'

export default defineComponent({
  components: { MyClassifiedsLinks },
  setup() {
    const { t } = useI18n()
    const { state } = useNamespacedStore<SearchState>(CLASSIFIED_SEARCH_NS)

    const mySearchLinks = computed(
      () =>
        state.mySearchLinks?.filter(
          l => l.type !== 'all' && l.type !== 'deleted' && !l.selected
        ) || null
    )
    return { faCogs, t, mySearchLinks }
  },
  i18n: defineComponentTranslations({
    mass_actions_category_select_title: {
      en: 'Choose a category for mass actions',
      el: 'Διάλεξε μια κατηγορία για μαζικές ενέργειες'
    }
  })
})
