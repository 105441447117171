import { CIcon } from '~/icons/types'
export const ciGps: CIcon = {
  width: 511.21,
  height: 512,
  paths: [
    {
      d:
        'M503.47 1A9.48 9.48 0 0 0 496 .61L483 5.7l-35.48 13.86-52.44 20.49-64 25L261 92.44l-70.74 27.64-66 25.78-55.69 21.78-40.06 15.65c-6.32 2.47-12.65 4.92-19 7.41l-.8.31C4 192.88-.24 196.92 0 202.49c.22 5 3.5 10.43 8.74 11.48l24.75 4.95 58.94 11.79L163.92 245l61.79 12.36c7.83 1.57 15.67 3.36 23.53 4.85q1.76 8.79 3.56 17.57l12 59.22q7.26 35.82 14.51 71.65l12.54 62c2 10 3.61 20.27 6.11 30.19 0 .14.06.29.09.43 1 4.89 6.55 9 11.48 8.74 5.63-.25 9.49-3.62 11.48-8.74q2.53-6.54 5.09-13.09 6.89-17.75 13.79-35.5L360.33 402l24.88-64q13.62-35.06 27.25-70.11 13.74-35.38 27.5-70.76l25.65-66q10.86-28 21.74-55.94Q495.15 55.09 503 35c2.3-5.93 4.7-11.82 6.92-17.77a12.73 12.73 0 0 0 .59-1.57c0-.13.11-.27.16-.41 2.08-5.43-1.92-12.32-7.2-14.25ZM468.8 57.51l-20.46 52.65q-12.43 32-24.88 64l-27.24 70.11-27.51 70.76-25.64 66Q332.2 409 321.33 437q-4.11 10.54-8.2 21.1l-9.12-45-14.5-71.66q-6.28-31-12.55-62c-2-10-3.74-20.21-6.11-30.18l-.09-.44a11.58 11.58 0 0 0-8.31-8.31l-24.76-5-58.93-11.79-71.48-14.3-53.35-10.63 9.63-3.79L116 174.53l64-25 70.09-27.39 70.72-27.64 66-25.78 55.69-21.78 35.85-14q-4.77 12.29-9.55 24.57Z'
    }
  ],
  name: 'gps',
  type: 'regular'
}
