import { CIcon } from '~/icons/types'
export const ciShipment: CIcon = {
  name: 'shipment',
  type: 'solid',
  width: 64,
  height: 64,
  paths: [
    { d: 'M9 27h2v2H9zm8-2h4v-8h-2v4h-6v-4h-2v8h4z' },
    {
      d:
        'M15 17h2v2h-2zm3 30c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 7a2 2 0 1 1-.001-3.999A2 2 0 0 1 18 54zm-3-27h-2v4H7v-4H5v8h10zm6-20h2v2h-2z'
    },
    {
      d:
        'M23 15h4V7h-2v4h-6V7h-2v8h4zm-6 12v8h10v-8h-2v4h-6v-4zm8-10h-2v8h10v-8h-2v4h-6z'
    },
    {
      d:
        'M21 27h2v2h-2zM5 43h6.281l-1.5 6H5v1a1 1 0 0 0 1 1h5.08c.488-3.386 3.401-6 6.92-6s6.432 2.614 6.92 6H37V41H5zm22-26h2v2h-2zm16 12v8h4.556A3.959 3.959 0 0 1 47 35c0-1.103.897-2 2-2h3.795l-.749-4z'
    },
    {
      d:
        'M51 37h2v-2h-4c0 1.103.897 2 2 2zm-1 10c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 7a2 2 0 1 1-.001-3.999A2 2 0 0 1 50 54z'
    },
    {
      d:
        'm59.342 37.789-2.244-1.122-1.527-9.16A2.992 2.992 0 0 0 52.611 25H42c-1.654 0-3 1.346-3 3v23h2V29c0-1.103.897-2 2-2h9.047c.963 0 1.79.688 1.966 1.634L55 34v13.111A6.977 6.977 0 0 1 56.92 51H60a1 1 0 0 0 1-1v-2.382l-4-2V41h4v-.528a2.986 2.986 0 0 0-1.658-2.683z'
    },
    {
      d:
        'm59 44.382 2 1V43h-2zM31 27h-2v8h8v-4h-6zm22 18.685V39H43v12h.08c.488-3.386 3.401-6 6.92-6 1.074 0 2.089.251 3 .685zM5 45v2h3.219l.5-2zm-2-8h34v2H3z'
    },
    { d: 'M33 29h4v-1c0-.342.035-.677.101-1H33z' }
  ]
}
