import { CIcon } from '~/icons/types'

export const ciParkingP: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12.638 2h-7.46C4.589 2 4 2.6 4 3.2v17.5c0 .7.589 1.3 1.178 1.3h2.454c.687 0 1.276-.6 1.276-1.2V17h3.73C16.663 17 20 13.6 20 9.5S16.663 2 12.638 2Zm0 10h-3.73V7h3.73c1.374 0 2.454 1.1 2.454 2.5S13.914 12 12.638 12Z'
    }
  ],
  name: 'parking-p',
  type: 'solid'
}
