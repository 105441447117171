











import { defineComponent } from '@nuxtjs/composition-api'
import Category from '~/components/car/classifieds/search/results/rows/main/partials/Category.vue'

export default defineComponent({
  components: {
    Category
  },
  props: {
    keyFeatures: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    isVehicle: {
      type: Boolean,
      required: false,
      default: false
    },
    category: {
      type: String,
      required: false,
      default: ''
    }
  }
})
