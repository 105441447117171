import { CIcon } from '~/icons/types'
export const ciRim: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M256 282q-11 0-18.5-7.5T230 256t7.5-18.5T256 230t18.5 7.5T282 256t-7.5 18.5T256 282zm230-26q0 95-67.5 162.5T256 486 93.5 418.5 26 256 93.5 93.5 256 26t162.5 67.5T486 256zm-14 0q0-89-63.5-152.5T256 40t-152.5 63.5T40 256t63.5 152.5T256 472t152.5-63.5T472 256zm-10 0q0 69-41 124-3 5-7 9-31 36-73 55-7 4-15 6-34 12-70 12t-70-12q-6-2-11-4-43-19-74-54-4-5-8-9-43-56-43-127v-15q4-47 27-88l6-10q41-62 112-84 8-3 15-4 23-5 46-5t46 5q6 1 11 3 72 20 114 82l6 10q24 41 29 87v19zm-43-97-83 46-6 4q-13 10-8 26 5 14 19 16l13-2 91-10q-4-43-26-80zm-121 25v1q-1 5 3.5 8t9.5 1l100-47q-37-53-100-72zm-10 72q0-13-9.5-22.5T256 224t-22.5 9.5T224 256t9.5 22.5T256 288t22.5-9.5T288 256zm-32 39q-9 0-9 9t9 9 9-9-9-9zm-28-69q9 0 9-9t-9-9-9 9 9 9zm-17.5 36q-9.5 0-9.5 9t9.5 9 9.5-9-9.5-9zm82.5-45q0-9-9-9t-9 9 9 9 9-9zm-1 54q0 9 9.5 9t9.5-9-9.5-9-9.5 9zM214 71l18 91 2 10q7 14 22.5 14t21.5-14l2-10 18-91q-20-4-41.5-4T214 71zM99 150l101 45q5 2 9.5-1t3.5-8v-1L198 76q-62 20-99 74zm-32 92 93 9 9 1q16-1 20-17 5-14-7-24l-9-5-81-44q-22 37-25 80zm113 47h1q3-4 1.5-9t-6.5-6h-1L67 253v3q0 64 38 114zm40 56 4-8q4-15-9-25-12-8-25-1l-9 7-68 63q29 32 69 49zm42 109q0-6-6-6t-6 6 6 6 6-6zm56-19-54-96v-1q-3-5-8-5t-8 5v1l-54 96q30 10 62 10t62-10zm83-57-70-62-7-5q-13-9-26 1-12 9-9 24l5 10 39 83q40-18 68-51zm44-122v-7l-108 23q-5 2-6.5 7t1.5 9h1l76 79q36-49 36-111z',
      fill: 'currentColor'
    }
  ],
  name: 'rim',
  type: 'regular'
}
