










import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    noResultsMessage: {
      type: String,
      required: false,
      default: ''
    }
  }
})
