import { CIcon } from '~/icons/types'
export const ciAirsport: CIcon = {
  name: 'airsport',
  type: 'light',
  width: 512,
  height: 237.3,
  paths: [
    {
      d:
        'M498.7 83.1h-25.4v-1.9c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v1.9h-25.4c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h25.4v10.7l-118.8-8.7V71.7h45v3.5c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5V43.3h11.6c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-11.9c-1.1-4.6-5.3-8-10.2-8s-9.1 3.4-10.2 8h-11.9c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h11.6v7.4h-45v-17C333.5 15.1 319 0 301.1 0h-90.3c-17.9 0-32.4 15.1-32.4 33.7v17h-48.8v-7.4h11.6c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-11.9c-1.1-4.6-5.3-8-10.2-8s-9.1 3.4-10.2 8H97c-5.8 0-10.5 4.7-10.5 10.5S91.2 43.3 97 43.3h11.6v31.9c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-3.5h48.8v34.4l-118.7 8.7v-10.7h25.4c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5H59.2c-1.3-4.3-5.3-7.4-10-7.4s-8.7 3.1-10 7.4H13.3c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h25.4v62.1c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-4.5h118.7v1.1c0 12.7 6.8 23.8 16.8 29.5-4.3 5.7-7.9 13.4-9.4 23.9H175c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h19.2c.5.1.9.1 1.4.1s.9 0 1.4-.1h18.6c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-8.5c1.5-6.9 4.5-12 9.2-15.5 4.7-3.4 9.6-4.1 10.5-4.2h67.9c3.3.9 8.7 6 10.5 19.7h-9.1c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5H315c.5.1.9.1 1.4.1s.9 0 1.4-.1h18.9c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-10.3c-1.1-11.2-4.1-19.3-7.6-25.1 8.9-6 14.8-16.4 14.8-28.2v-1.2h118.8v4.5c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-62.1h25.4c5.8 0 10.5-4.7 10.5-10.5s-4.8-10.5-10.6-10.5zm-320.3 57.6H59.7v-4.8l118.7-8.7v13.5zm134.1 22.2c0 6.9-5.1 12.6-11.4 12.6h-90.3c-6.3 0-11.4-5.7-11.4-12.7v-10.2c.1-.5.1-.9.1-1.4s0-.9-.1-1.4v-32.5c.1-.7.1-1.5.1-2.2 0-.2 0-.4-.1-.6V33.7c0-7 5.1-12.7 11.4-12.7h90.3c6.3 0 11.4 5.7 11.4 12.7v129.2zm139.8-22.2H333.5v-13.5l118.8 8.7v4.8z',
      fill: 'currentColor'
    },
    {
      d:
        'M256 51.8c-25.6 0-46.4 20.8-46.4 46.4 0 25.6 20.8 46.4 46.4 46.4s46.4-20.8 46.4-46.4c0-25.6-20.8-46.4-46.4-46.4zm0 71.8c-14 0-25.4-11.4-25.4-25.4S242 72.8 256 72.8s25.4 11.4 25.4 25.4-11.4 25.4-25.4 25.4z',
      fill: 'currentColor'
    }
  ]
}
