import { CIcon } from '~/icons/types'
export const ciDoubleArrow: CIcon = {
  name: 'double-arrow',
  type: 'solid',
  width: 511.984,
  height: 511.984,
  paths: [
    {
      d:
        'm508.792 246.401-96-128c-4.128-5.504-11.36-7.808-17.888-5.568a16.014 16.014 0 0 0-10.912 15.168v64h-256v-64c0-6.88-4.416-12.992-10.944-15.168-6.56-2.208-13.728.064-17.856 5.568l-96 128a16.032 16.032 0 0 0 0 19.2l96 128c4.128 5.536 11.264 7.712 17.856 5.6 6.528-2.208 10.944-8.32 10.944-15.2v-64h256v64c0 6.88 4.416 12.992 10.944 15.2 1.664.544 3.36.8 5.056.8 4.928 0 9.728-2.272 12.8-6.4l96-128a16.032 16.032 0 0 0 0-19.2z',
      fill: 'currentColor'
    }
  ]
}
