import { CIcon } from '~/icons/types'
export const ciServiceBook: CIcon = {
  width: 512,
  height: 400.86,
  paths: [
    {
      d:
        'M66.47 312.28a527.07 527.07 0 0 1 75.84 5 485.48 485.48 0 0 1 73.6 16.65q9.12 2.81 18.12 6c6 2.12 11.93 4.59 17.95 6.7 5.76 2 11.38-1.27 16.67-3.24q8.79-3.29 17.7-6.24a491 491 0 0 1 73.55-18.27 485.69 485.69 0 0 1 76-6.53h9.67a3.23 3.23 0 0 0 3.18-3.18V3.47a3.25 3.25 0 0 0-3.22-3.18c-6.06-.53-12.26-.2-18.34 0q-9.39.18-18.76.71-18.75 1.13-37.38 3.6A499 499 0 0 0 297.68 20q-18 5.22-35.48 11.8c-2.24.84-4.91 2.22-7.4 1.77a21.94 21.94 0 0 1-3.61-1.37q-2.31-.88-4.63-1.74-9-3.32-18-6.25A502.89 502.89 0 0 0 154.6 6.64a507.5 507.5 0 0 0-78-6.55H66.51a3 3 0 0 0-2 .77 2.9 2.9 0 0 0-1.13 2.41V309.1a3.22 3.22 0 0 0 3.09 3.18Zm203.1-95.54V54.82a480.43 480.43 0 0 1 57.64-17.3 496 496 0 0 1 62.34-10.35q16.49-1.67 33-2.3V289.3a555.07 555.07 0 0 0-60.92 5.55 499.24 499.24 0 0 0-62 13.2c-10.12 2.84-20.14 6-30.05 9.53ZM89.73 186.62V24.78c13.07.82 26.12 1.49 39.14 3q20.28 2.36 40.33 6.32t39.68 9.56q9.74 2.78 19.35 6 5 1.63 9.86 3.39a18.9 18.9 0 0 1 3 1.25l.59.35.35.27.33.32.11.12.1.18c.07.12.13.24.19.37l.09.28a6.57 6.57 0 0 1 .2 1.08c.13 1.73 0 3.49 0 5.23v32.47q0 21.81-.05 43.6v179.11a498.15 498.15 0 0 0-57.09-16.32 518.38 518.38 0 0 0-63.16-9.89q-16.45-1.56-33-2.09Z'
    },
    {
      d:
        'M508.82 29.24H487a3.23 3.23 0 0 0-3.18 3.18v305.09c-14.39.8-28.78 2-43.16 3-15.9 1.11-31.8 2.33-47.65 4.07a658.8 658.8 0 0 0-90 16.13q-11.14 2.83-22.2 6.06c-7.35 2.15-14.58 4.84-22 6.86-3 .83-5.79 0-8.68-.93q-5.91-1.92-11.86-3.72-10.5-3.18-21.12-6a727.44 727.44 0 0 0-89.83-17.63c-33.11-4.44-66.29-6.31-99.62-8V32.47a3.23 3.23 0 0 0-3.18-3.18H3.18A3.23 3.23 0 0 0 0 32.47v326.75a3.25 3.25 0 0 0 3.18 3.18c8.36.43 16.76.16 25.13.29q13 .21 26 .86 25.91 1.27 51.68 4.33a752.65 752.65 0 0 1 98.24 18q12 3.08 23.8 6.64c7.89 2.39 15.61 5.28 23.48 7.7a17 17 0 0 0 10-.27c4.07-1.21 8.1-2.56 12.13-3.9 8-2.66 16.09-5.17 24.27-7.38a742.56 742.56 0 0 1 98-19.56A787.36 787.36 0 0 1 496 362.62h12.87a3.22 3.22 0 0 0 3.18-3.18v-327a3.23 3.23 0 0 0-3.23-3.2Z'
    }
  ],
  name: 'service-book',
  type: 'regular'
}
