import { CIcon } from '~/icons/types'
export const ciRim: CIcon = {
  name: 'rim',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M298.662 256.006c0-23.531-19.136-42.667-42.667-42.667s-42.667 19.136-42.667 42.667 19.136 42.667 42.667 42.667 42.667-19.136 42.667-42.667zm31.382-104.207c-15.403-10.987-33.323-18.581-52.715-21.867v43.776a84.191 84.191 0 0 1 21.739 9.045l30.976-30.954zm-147.286 61.135-30.976-30.976c-10.987 15.403-18.581 33.323-21.867 52.715h43.776a83.553 83.553 0 0 1 9.067-21.739zm146.475 86.144 30.976 30.976c10.987-15.403 18.581-33.323 21.867-52.715H338.3a83.539 83.539 0 0 1-9.067 21.739zM173.692 277.34h-43.776c3.285 19.392 10.88 37.312 21.867 52.715l30.976-30.976a83.499 83.499 0 0 1-9.067-21.739zm8.256 82.88c15.403 10.987 33.323 18.581 52.715 21.867V338.31a83.484 83.484 0 0 1-21.739-9.067l-30.976 30.977z'
    },
    {
      d:
        'M256 0C114.837 0 0 114.859 0 256c0 141.163 114.837 256 256 256s256-114.837 256-256C512 114.859 397.163 0 256 0zm121.003 376.213c-.149.149-.192.341-.32.469-.128.128-.32.171-.469.299-30.87 30.699-73.366 49.686-120.214 49.686-46.869 0-89.365-18.987-120.235-49.685-.128-.128-.32-.171-.448-.299-.149-.128-.192-.32-.32-.469C104.32 345.344 85.333 302.848 85.333 256s18.987-89.323 49.664-120.213c.128-.128.171-.32.32-.448.128-.149.32-.192.448-.32 30.87-30.678 73.366-49.686 120.235-49.686 46.848 0 89.344 19.008 120.213 49.685.149.128.341.171.469.32.128.128.171.32.32.448 30.656 30.891 49.664 73.365 49.664 120.213.001 46.849-19.007 89.345-49.663 120.214z'
    },
    {
      d:
        'M329.239 212.932c3.968 6.741 7.04 13.995 9.067 21.739h43.755a126.93 126.93 0 0 0-21.845-52.715l-30.977 30.976zm-94.583-39.234v-43.755a127.208 127.208 0 0 0-52.715 21.845l30.976 30.976c6.742-3.967 13.995-7.039 21.739-9.066zm42.679 164.617v43.755a127.208 127.208 0 0 0 52.715-21.845l-30.976-30.976c-6.741 3.967-13.995 7.039-21.739 9.066z'
    }
  ]
}
