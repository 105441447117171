import { CIcon } from '~/icons/types'
export const ciRightHandDriveCar: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M500 225.84a25.66 25.66 0 0 0-14.14 4.2 27 27 0 0 0-10.47 13.55 25.49 25.49 0 0 0 7.26 27.1 27.07 27.07 0 0 0 15.79 6.58 8.26 8.26 0 0 0 1.56.09 11.83 11.83 0 0 0 10.76-6.68c.11-.23.23-.46.34-.7l.06-.17c.12-.29.23-.57.33-.86a10.29 10.29 0 0 0 .54-3.55v-27.6A12.15 12.15 0 0 0 500 225.84Z'
    },
    {
      d:
        'M362.75 150.78a173.81 173.81 0 0 1 69.92-53.57 187 187 0 0 1 44.09-11.95A191.4 191.4 0 0 1 500 83.81c6.29 0 12.32-5.49 12-12s-5.29-12-12-12a198.46 198.46 0 0 0-60.47 9.4 195.89 195.89 0 0 0-52.87 26.23 204.58 204.58 0 0 0-44 42.36 194.76 194.76 0 0 0-7.9 225.2 196.19 196.19 0 0 0 38.89 43.44 204.5 204.5 0 0 0 52.67 31.35 195.52 195.52 0 0 0 59.18 13.68c4.84.38 9.68.55 14.53.56 6.29 0 12.32-5.51 12-12a12.21 12.21 0 0 0-12-12 191.4 191.4 0 0 1-23.2-1.45 4.44 4.44 0 0 1-3.8-4.39v-94.2c0-7.36.13-14.64-1.93-21.83A51.28 51.28 0 0 0 455.68 282c-7.42-6.45-16.54-11.29-26.35-12.83a77.33 77.33 0 0 0-12-.94h-85.9a4.46 4.46 0 0 1-4.45-4.28 187.13 187.13 0 0 1 1.28-31c.36-2.48.76-5 1.21-7.42a4.44 4.44 0 0 1 3.62-3.54l3.83-.66 30.64-5.33 36.37-6.25 30.15-5.18c5.12-.88 10.23-1.8 15.36-2.62q3.56-.57 7.13-1.06a342.11 342.11 0 0 1 43.39-2.8 12.45 12.45 0 0 0 8.51-3.5 12 12 0 0 0-8.47-20.38 369.13 369.13 0 0 0-62.16 5.59l-23.74 4.08-39.18 6.74-30 5.16a4.45 4.45 0 0 1-4.88-6.05c.09-.21.18-.41.26-.62a186 186 0 0 1 22.45-38.33ZM331 292.16h88.76c1.16 0 2.31.08 3.45.19a3.93 3.93 0 0 1 .48.06 43 43 0 0 1 7.37 2 4.89 4.89 0 0 1 .48.2 43.25 43.25 0 0 1 6.14 3.57c.14.1.28.2.41.31a42.28 42.28 0 0 1 4.91 4.81c.11.13.22.27.32.41a41.78 41.78 0 0 1 3.36 5.7c.07.17.15.33.21.5a40.63 40.63 0 0 1 1.79 6.59 3.33 3.33 0 0 1 .07.54c.34 4.19.14 8.47.14 12.64v83.75L442 418.3q-4.68-1.64-9.28-3.51a173.81 173.81 0 0 1-69.92-53.57 186 186 0 0 1-22.52-38.33 184.32 184.32 0 0 1-9.63-30.73Z'
    },
    {
      d:
        'M500 488.08q-9.81 0-19.59-.77-4.74-.36-9.47-.92l-2.28-.28c-1.52-.21-3-.44-4.55-.68a245.57 245.57 0 0 1-36.81-8.81q-8.75-2.82-17.24-6.29l-.68-.28-1.26-.54q-2.24-1-4.46-2-4.14-1.89-8.2-3.93a246.07 246.07 0 0 1-31.28-18.75c-2.37-1.68-4.7-3.41-7-5.16l-.09-.06-1.76-1.39q-1.89-1.5-3.74-3-6.93-5.73-13.42-11.95t-12.71-13.13q-3-3.33-5.88-6.78-1.44-1.72-2.85-3.47l-1.4-1.76-.16-.21a253 253 0 0 1-19.86-30.26q-4.39-7.92-8.18-16.15-1-2.06-1.87-4.14l-.53-1.23c-.14-.34-.28-.67-.41-1q-1.74-4.22-3.31-8.5a241 241 0 0 1-10.1-35.75q-.94-4.74-1.71-9.51c-.22-1.4-.43-2.79-.63-4.19-.11-.87-.22-1.74-.32-2.6q-1.11-9.45-1.48-18.94a245.84 245.84 0 0 1 1.56-38.84c.07-.65.16-1.29.24-1.93.23-1.62.47-3.24.73-4.86q.75-4.61 1.68-9.18 1.86-9.23 4.45-18.3t5.81-17.74q1.57-4.28 3.33-8.48a3.45 3.45 0 0 1 .14-.34l.55-1.29 2-4.44a243.21 243.21 0 0 1 17.73-31.82c3.31-5 6.91-9.8 10.46-14.65l.08-.1 1.4-1.76q1.41-1.74 2.85-3.47 2.9-3.44 5.89-6.77A247.1 247.1 0 0 1 352 76.4c1.08-.89 2.16-1.76 3.25-2.63l1.75-1.39.23-.17q3.54-2.67 7.16-5.23 7.51-5.3 15.41-10t16.2-8.86q4.06-2 8.21-3.91 2.09-.95 4.17-1.85l1-.41 1-.4a245.14 245.14 0 0 1 35.4-11.34 246.59 246.59 0 0 1 23-4.33l2.52-.31c3.26-.37 6.53-.69 9.81-.94q9.43-.7 18.91-.71c6.29 0 12.32-5.49 12-12S506.7 0 500 0a259.47 259.47 0 0 0-79.1 12.32 256.14 256.14 0 0 0-69.12 34.37 264.57 264.57 0 0 0-56.95 54.74 254 254 0 0 0-36.94 67.29 245.78 245.78 0 0 0-10.35 36.73H12.08A12.52 12.52 0 0 0 3.54 209a12 12 0 0 0 8.54 20.49H239a4.42 4.42 0 0 1 4.42 4.8q-.51 6.06-.76 12.13a255.06 255.06 0 0 0 92.61 206.34 264.75 264.75 0 0 0 68.07 40.55 256.26 256.26 0 0 0 77.39 18q9.62.74 19.25.74c6.29 0 12.32-5.51 12-12A12.21 12.21 0 0 0 500 488.08ZM358 71.59l-.37.29Z'
    },
    {
      d:
        'M141.64 276.38a85.48 85.48 0 0 0-15.06-1.38H12.08a12.52 12.52 0 0 0-8.54 3.51A12 12 0 0 0 12.08 299H115.9c4.32 0 8.64-.07 13 0 1.83 0 3.65.18 5.46.38l.37.05a55.47 55.47 0 0 1 9.64 2.56 3.25 3.25 0 0 1 .43.18 39.18 39.18 0 0 1 5.92 3.35l.5.38a28 28 0 0 1 2.06 2 5.29 5.29 0 0 1 .51.66c.21.32.4.65.59 1a4 4 0 0 1 .39.91l.08.29a3.92 3.92 0 0 1 .14 1v163.31l-.16 1.24-.06.2a4.38 4.38 0 0 1-.39.92c-.19.33-.38.66-.59 1a5.29 5.29 0 0 1-.51.66 26.33 26.33 0 0 1-2.07 2c-.15.13-.32.26-.49.38a39.18 39.18 0 0 1-5.92 3.35 3.25 3.25 0 0 1-.43.18 54.93 54.93 0 0 1-9.77 2.58c-.13 0-.27 0-.41.06-5.2.52-10.46.36-15.68.36H27.12c-4.94 0-9.89-.07-14.83 0h-.21a12.52 12.52 0 0 0-8.54 3.51A12 12 0 0 0 12.08 512h114.6c14.86 0 29.86-3.9 41.29-13.69a31.21 31.21 0 0 0 11.12-23V311.43c-.38-19.63-19.91-31.88-37.45-35.05Z'
    }
  ],
  name: 'right-hand-drive-car',
  type: 'regular'
}
