import { CIcon } from '~/icons/types'

export const ciClock: CIcon = {
  width: '16',
  height: '16',
  paths: [
    {
      d:
        'M8 1.467C4.4 1.467 1.467 4.4 1.467 8c0 3.6 2.933 6.534 6.533 6.534 3.6 0 6.534-2.934 6.534-6.534 0-3.6-2.934-6.533-6.534-6.533Zm3 7.066H8A.526.526 0 0 1 7.467 8V4c0-.267.2-.533.533-.533s.533.2.533.533v3.467h2.534c.267 0 .533.2.533.533s-.333.533-.6.533Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'clock',
  type: 'solid'
}
