import { CIcon } from '~/icons/types'
export const ciExcavator: CIcon = {
  width: 512,
  height: 434,
  paths: [
    {
      d:
        'M406.5 413c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h91.8c5.8 0 10.5-4.7 10.5-10.5V313.6c0-.2 0-.4-.1-.7v-.2L465.9 9c-.7-5.2-5.2-9-10.4-9h-82.7c-2.9 0-5.6 1.2-7.6 3.3L234 141.7h-15.2c-5.8 0-10.5 4.7-10.5 10.5v32.9H41.2c-5.8 0-10.5 4.7-10.5 10.5v113.1c0 5.8 4.7 10.5 10.5 10.5H76v21H50.1c-25.9 0-46.9 21-46.9 46.9s21 46.9 46.9 46.9h289.1c25.9 0 46.9-21 46.9-46.9s-21-46.9-46.9-46.9h-25.9v-21h34.8c5.8 0 10.5-4.7 10.5-10.5v-65.9c0-1.4-.3-2.7-.8-4l-24-59.1 62.8-104.4 68.5 271-42.7 66.7h-15.9zm-356.4 0c-14.3 0-25.9-11.6-25.9-25.9s11.6-26.2 25.9-26.2S76 372.8 76 387.1 64.4 413 50.1 413zm168.7-93.8h73.5v21H97v-21h121.8zm-167.1-21v-92.1h156.6v92.1H51.7zM300.1 413h-211c4.9-7.4 7.8-16.3 7.8-25.9s-2.9-18.5-7.8-25.9h211c-4.9 7.4-7.8 16.3-7.8 25.9 0 9.6 2.9 18.5 7.8 25.9zm39.1-51.9c14.3 0 25.9 11.7 25.9 26S353.5 413 339.2 413s-25.9-11.6-25.9-25.9 11.6-26 25.9-26zm-1.6-62.9H229.3V162.7h25.4v68.9c0 19.9 16.1 36 36 36h46.9v30.6zm0-53.4v1.8h-46.9c-8.3 0-15-6.7-15-15v-68.9h28.5l7.9 19.4.6 1.5 24.9 61.2zm-13.7-89.3-2.9-7.3c-1.6-4-5.5-6.5-9.7-6.5h-48.4L377.3 21h27.6l-81 134.5zm88-105.4L429.4 21h16.9l41 290.8-6.7 10.4-68.7-272.1zM487.8 350v63h-40.5l40.5-63z',
      fill: '#1976D2'
    },
    {
      d:
        'M89.1 361.2c4.9 7.4 7.8 16.3 7.8 25.9S94 405.6 89.1 413h211c-4.9-7.4-7.8-16.3-7.8-25.9s2.9-18.5 7.8-25.9h-211zm165.6-129.6v-68.9h-25.4v135.5h108.3v-30.6h-46.9c-19.9 0-36-16.1-36-36zm84.5 129.5c14.3 0 25.9 11.7 25.9 26S353.5 413 339.2 413s-25.9-11.6-25.9-25.9 11.6-26 25.9-26zm-287.5-155h156.6v92.1H51.7zm435.6 105.7L446.3 21h-16.9l-17.5 29.1 68.7 272.1zM97 340.2h195.3v-21H97zm165.9-198.5h48.4c4.2 0 8.1 2.5 9.7 6.5l2.9 7.3 81-134.5h-27.6L262.9 141.7zM50.1 360.9c14.3 0 25.9 11.9 25.9 26.2S64.4 413 50.1 413s-25.9-11.6-25.9-25.9 11.6-26.2 25.9-26.2zM487.8 350l-40.5 63h40.5z',
      fill: '#91D4F3'
    }
  ],
  name: 'excavator',
  type: 'duotone'
}
