import { CIcon } from '~/icons/types'
export const ciArea: CIcon = {
  name: 'area',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'm442.914 373.547-28.258 28.262 21.84 21.84h-95.351L471.75 293.042v52.816l39.965 39.965V55.887C511.715 25.07 486.645 0 455.832 0H124.891l39.968 39.965h54.817L88.07 171.575V76.222l21.84 21.84L138.168 69.8 69.086.715 0 69.8l28.262 28.261 19.84-19.843V407.73c0 30.817 25.07 55.887 55.886 55.887h330.508l-19.84 19.84 28.258 28.262L512 442.633zm-158.293 50.101h-55.887L471.75 180.637v55.883zm-196.55-84.644L387.104 39.969h56.383L88.07 395.387zM471.75 68.23v55.883L172.215 423.648h-55.887zM276.195 39.97h54.387L88.07 282.48v-54.386zm0 0',
      fill: 'currentColor'
    }
  ]
}
