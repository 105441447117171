import { CIcon } from '~/icons/types'
export const ciTransport: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M317 320q8 0 8 8t-8 8h-29q-8 0-8-8t8-8h29zm-216 58q10 0 17.5 7.5T126 403q0 7-3.5 13t-9 9-12.5 3q-10 0-17.5-7T76 403q0-6 3-12t9-9.5 13-3.5zm-.5 35q9.5 0 9.5-9.5t-9.5-9.5-9.5 9.5 9.5 9.5zm336-35q10.5 0 17.5 7.5t7 17.5q0 5-2 10t-5 8.5-8 5-10 1.5q-10 0-17.5-7t-7.5-17.5 7.5-18 18-7.5zm0 35q9.5 0 9.5-9.5t-9.5-9.5-9.5 9.5 9.5 9.5zm74.5-58q1 5 1 10v51q0 8-8 8h-20q-6 14-19 22.5t-29 8.5-28.5-8.5T389 424H149q-6 14-19 22.5t-29 8.5-29-8.5T53 424H23q-12 0-18.5-9.5T1 394l1-2q0-2 1-5.5t2-6.5 2-5q6-19 7-21l4-10q13-24 38-34 12-5 25-5l28-2 55-32q6-4 10 3t-3 11l-29 17 194-8-7-46h-40q-47 0-90 21-7 3-10.5-4t3.5-10q46-23 97-23h61q42 0 72 29l31 30q23 5 39 22 16 18 19 42zm-37-37v9q0 6 3 11t7.5 8.5T495 351q-5-20-20-32zm-124-70h-5l6 46 79-3-19-19q-26-24-61-24zM30 356l-1 1q10-1 16-8t6-17v-2q-13 9-20 24-1 1-1 2zm100 68q7-9 7-21 0-15-10.5-25.5T101 367t-25.5 10.5T65 403q0 5 1 9 3 13 13 20 10 8 22 8 8 0 15-4 9-4 14-12zm23-16h11q-16-36-7-91l-46 2-29 1q-8 1-15 3v9q0 17-12 29t-28 12h-3l-8 25q-1 4 1 7t6 3h26v-5q0-21 15-36.5t36.5-15.5 37 15.5T153 403v5zm127 0q24-1 39-18 21-26 18-80l-164 6q-10 60 8 92h99zm192-2q1-1 1-3 0-15-11-25.5T436 367t-25.5 10.5T400 403t11 26 25 11q10 0 18-5t13-12.5 5-16.5zm24 2v-41q-15-1-26-12.5T459 327v-9q0-4 1-9-6-2-11-3l-96 4q4 59-22 90l-8 8h62q-1-2-1-5 0-21 15.5-36.5T436 351h3q22 2 36.5 18.5T488 408h8zM297.5 95.5q5.5 5.5.5 10.5l-65 65q-2 2-6 2h-1q-4-1-6-5l-11-28q-3-8 4.5-10.5T224 134l6 18 57-57q5-5 10.5.5zm-43-38.5q31.5 0 54 22.5t22.5 54-22.5 54-54 22.5-54-22.5-22.5-54 22.5-54 54-22.5zm-.5 137q25 0 43-18t18-43-18-43-43-18-42.5 18-17.5 43 17.5 43 42.5 18z',
      fill: 'currentColor'
    }
  ],
  name: 'transport',
  type: 'regular'
}
