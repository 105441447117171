import { CIcon } from '~/icons/types'
export const ciDeals: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M490.6 236.5 132 75.3c-12.9-5.8-27.7-4.6-38.5 3.2l-76.6 44.6c-10.7 7.7-16.4 20.2-15 33.3l16.7 88.8c1.3 13.3 10.2 25.1 23.1 30.9l358.6 161.1c16.3 7.4 35.1 1.1 42-14.1l65.6-146c6.8-15.1-1-33.3-17.3-40.6zm-442.1-55c-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23-10.3 23-23 23zm77.5 74c-12.8 7.9-24.8 9.4-35.6 4.6L69 250.5c-4.4-2.1-5.2-10-1.6-18l49.7-110.7c3.6-7.9 10.1-12.7 14.6-10.6l21.4 9.6c10.8 4.8 17.7 14.6 20.3 29.4 2.6 15.5-.3 33.1-8.5 51.3l-6.1 13.5c-8.2 18.3-19.6 32.3-32.8 40.5zm83.6 18.8c4.5 2 5.3 10 1.7 18l-1.9 4.2c-3.6 7.9-10.1 12.7-14.6 10.6l-47-21.1c-4.4-2.1-5.2-10.1-1.6-18.1L196 157.3c3.6-7.9 10.1-12.7 14.6-10.6l47 21.1c4.5 2 5.3 10 1.7 18l-1.8 4c-3.6 7.9-10.1 12.7-14.6 10.6L207 184.2l-8.2 18.3 30.5 13.7c4.5 2 5.3 10 1.7 18l-1.9 4.2c-3.6 7.9-10.1 12.7-14.6 10.6L184 235.3l-10.3 22.9 35.9 16.1zm85.1 60.4c-3.6 7.9-10.1 12.7-14.6 10.6l-3.2-1.4c-3.2-1.4-4.7-5.9-3.6-11.7l4.6-27.5-27.3-12.3-17.5 21.7c-3.5 4.4-7.9 6.5-11.1 5l-3.2-1.4c-2.7-1.2-4.3-4.8-4-9.7.4-4.8 2.5-10.1 5.7-13.9l78.5-97.7c3.7-4.6 8.1-6.4 11.2-5l3.5 1.6c3.2 1.4 4.7 5.9 3.6 11.7l-20.6 123c-.3 2.2-1 4.6-2 7zm68.2 25.7-1.9 4.2c-3.6 7.9-10.2 12.6-14.6 10.6L303.9 356c-4.5-2-5.3-10-1.7-18l49.7-110.7c3.6-7.9 10.1-12.7 14.6-10.6l2.9 1.3c4.5 2.1 5.3 10 1.7 18l-41.4 92.2 31.5 14.2c4.4 2 5.3 10 1.7 18zm105.9-60c-4.1 5.8-9.3 8.5-13.3 6.7-2.7-1.2-4.7-4.3-6.1-9.4-1.1-4.8-4.2-8.6-9.2-10.8-5.3-2.4-10.2-2.7-14.3-.9-1.7.7-3.6 2-4.1 3-.4.8-.9 2-.2 4.7.6 2.4 2.8 7.5 11.3 15.8 7.9 7.9 13.2 15.5 15.8 23.6 3.5 10.4 2.1 23.2-4 36.9-4.4 9.7-12.6 22.5-26.4 27.8-9.1 3.6-18.2 3.3-27.1-.7-9.1-4.1-15.7-10.9-19.6-20.3-1-2.4-1.6-4.6-2-6.9-1.1-7.9 2.5-14.6 4.1-17.3 3.5-5.4 9-9.8 14-7.6 2.4 1.1 4.6 3.5 6.3 7.4 2.4 5.4 6.4 9.3 11.9 11.8 5.2 2.3 10.2 2.7 14.7.9 3.7-1.5 4.4-3.1 4.7-3.8.7-1.6 1.1-2.8.4-5.5-.8-2.3-3.2-7.7-11.6-16.4-8.1-8-13.3-15.5-15.8-23.1-3.4-9.7-2-22.4 3.9-35.7 4.2-9.3 12.1-21.8 25.7-27.1 9.1-3.7 18-3.2 26.9.7 9.3 4.2 15.4 11.5 18 22.2.8 2.8 1.2 5.3 1.1 7.9 0 7.6-3.3 13.3-5.1 16.1z',
      fill: '#EF5350'
    },
    {
      d:
        'm138.4 153.4-10.3-4.6-33.2 73.9 10.3 4.6c6.1 2.7 12.4 2 19.3-2.3 6.4-4 11.2-9.8 15.1-18.5l6.2-13.7c3.8-8.3 4.9-15.7 3.6-23.2-1.3-8-4.9-13.4-11-16.2z',
      fill: '#EF5350'
    },
    {
      d:
        'M164.9 201.5c8.2-18.2 11.1-35.8 8.5-51.3-2.6-14.8-9.5-24.6-20.3-29.4l-21.4-9.6c-4.5-2.1-11 2.7-14.6 10.6L67.4 232.5c-3.6 8-2.8 15.9 1.6 18l21.4 9.6c10.8 4.8 22.8 3.3 35.6-4.6 13.2-8.2 24.6-22.2 32.8-40.5l6.1-13.5zm-19.1-8.7-6.2 13.7c-3.9 8.7-8.7 14.5-15.1 18.5-6.9 4.3-13.2 5-19.3 2.3l-10.3-4.6 33.2-73.9 10.3 4.6c6.1 2.8 9.7 8.2 11 16.2 1.3 7.5.2 14.9-3.6 23.2z',
      fill: '#FFF'
    },
    {
      d:
        'M214.5 249c4.5 2.1 11-2.7 14.6-10.6l1.9-4.2c3.6-8 2.8-16-1.7-18l-30.5-13.7 8.2-18.3 35.9 16.2c4.5 2.1 11-2.7 14.6-10.6l1.8-4c3.6-8 2.8-16-1.7-18l-47-21.1c-4.5-2.1-11 2.7-14.6 10.6l-49.8 110.6c-3.6 8-2.8 16 1.6 18.1l47 21.1c4.5 2.1 11-2.7 14.6-10.6l1.9-4.2c3.6-8 2.8-16-1.7-18l-35.9-16.1 10.3-22.9 30.5 13.7z',
      fill: '#FFF'
    },
    {
      d:
        'm313.7 193-3.5-1.6c-3.1-1.4-7.5.4-11.2 5l-78.5 97.7c-3.2 3.8-5.3 9.1-5.7 13.9-.3 4.9 1.3 8.5 4 9.7l3.2 1.4c3.2 1.5 7.6-.6 11.1-5l17.5-21.7 27.3 12.3-4.6 27.5c-1.1 5.8.4 10.3 3.6 11.7l3.2 1.4c4.5 2.1 11-2.7 14.6-10.6 1-2.4 1.7-4.8 2-7l20.6-123c1.1-5.8-.4-10.3-3.6-11.7zm-29.5 87.3-19.5-8.8 26.5-33.2-7 42z',
      fill: '#FFF'
    },
    {
      d:
        'm361.2 342.4-31.5-14.2 41.4-92.2c3.6-8 2.8-15.9-1.7-18l-2.9-1.3c-4.5-2.1-11 2.7-14.6 10.6L302.2 338c-3.6 8-2.8 16 1.7 18l42.5 19.2c4.4 2 11-2.7 14.6-10.6l1.9-4.2c3.6-8 2.7-16-1.7-18z',
      fill: '#FFF'
    },
    {
      d:
        'M454.8 254.2c-8.9-3.9-17.8-4.4-26.9-.7-13.6 5.3-21.5 17.8-25.7 27.1-5.9 13.3-7.3 26-3.9 35.7 2.5 7.6 7.7 15.1 15.8 23.1 8.4 8.7 10.8 14.1 11.6 16.4.7 2.7.3 3.9-.4 5.5-.3.7-1 2.3-4.7 3.8-4.5 1.8-9.5 1.4-14.7-.9-5.5-2.5-9.5-6.4-11.9-11.8-1.7-3.9-3.9-6.3-6.3-7.4-5-2.2-10.5 2.2-14 7.6-1.6 2.7-5.2 9.4-4.1 17.3.4 2.3 1 4.5 2 6.9 3.9 9.4 10.5 16.2 19.6 20.3 8.9 4 18 4.3 27.1.7 13.8-5.3 22-18.1 26.4-27.8 6.1-13.7 7.5-26.5 4-36.9-2.6-8.1-7.9-15.7-15.8-23.6-8.5-8.3-10.7-13.4-11.3-15.8-.7-2.7-.2-3.9.2-4.7.5-1 2.4-2.3 4.1-3 4.1-1.8 9-1.5 14.3.9 5 2.2 8.1 6 9.2 10.8 1.4 5.1 3.4 8.2 6.1 9.4 4 1.8 9.2-.9 13.3-6.7 1.8-2.8 5.1-8.5 5.1-16.1.1-2.6-.3-5.1-1.1-7.9-2.6-10.7-8.7-18-18-22.2z',
      fill: '#FFF'
    }
  ],
  name: 'deals',
  type: 'duotone'
}
