import { CIcon } from '~/icons/types'
export const ciWatersport: CIcon = {
  width: 512,
  height: 477.9,
  paths: [
    {
      d:
        'm350.8 465.6 8.5 9c2 2.1 4.8 3.3 7.6 3.3 1.5 0 3.1-.3 4.5-1 4.1-1.9 6.5-6.3 5.9-10.8-1.5-11.3-.8-19.8 0-31.3h-59.6l33.1 30.8zm134.8-97.1c-1.7-3.6-5.4-6-9.5-6H225.7c54.6-48.6 123.8-75.3 195.5-75.3h.5c3.5 0 6.8-1.8 8.7-4.7 1.9-2.9 2.3-6.6.9-9.9l-91-216.5c-1.3-3.2-4.2-5.5-7.6-6.2l-62.9-12.6c3.5-7.4 7.2-14.7 11-21.9 2.7-5.1.8-11.5-4.3-14.2-5.1-2.7-11.5-.8-14.2 4.3-6 11.3-11.7 22.8-16.9 34.5-.2.4-.4.8-.6 1.3-9.1 20.5-17 41.6-23.5 63h69.9c5.8 0 10.5 4.7 10.5 10.5s-4.7 10.5-10.5 10.5h-75.9c-3.9 14.9-7.2 29.9-9.8 45h125.7c5.8 0 10.5 4.7 10.5 10.5s-4.7 10.5-10.5 10.5h-129c-1.5 11.2-2.7 22.4-3.5 33.7-.2 3.1-.4 6.2-.6 9.4h111c5.8 0 10.5 4.7 10.5 10.5s-4.7 10.5-10.5 10.5H197.4c-.7 34.7 1.3 69.6 6.4 104.4.1.9.3 1.8.7 2.7H35.9c-4.7 0-8.8 3.1-10.1 7.6s.6 9.3 4.6 11.8c42.1 26.2 89.3 40 136.4 40h234.7c31 0 61.1-15.4 82.8-42.3 2.5-3.2 3-7.5 1.3-11.1z'
    }
  ],
  name: 'watersport',
  type: 'solid'
}
