













































import { mapActions, mapGetters } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import { Option } from '~/models/shared/types'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import { defineComponent } from '@nuxtjs/composition-api'

const ELECTRIC_SEARCHABLE_VALUE = 3

export default defineComponent({
  components: { CCustomSelect, COptionWithCount },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedValues: null
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getFacetByName: 'getFacetByName'
    }),
    options() {
      return (this.searchables || []).map(mapBackendSearchableToOption)
    },
    icons: () => ({
      electric: faBolt
    })
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      loadSeoUrlSearch: 'loadSeoUrlSearch'
    }),
    onClear() {
      this.selectedValues = null
      this.emitParams([{ name: this.defaultUrlArgName, value: null }])
    },
    onBackendValues({ selected }) {
      this.selectedValues = selected
    },
    handleValueChange(value: any) {
      this.emitParams([{ name: this.defaultUrlArgName, value }])
    },
    optionIsElectric(option: Option) {
      return option.value === ELECTRIC_SEARCHABLE_VALUE
    },
    optionIsDisabled(option: Option) {
      return option.count === 0 && !this.selectedValues?.includes(option.value)
    },
    handleRedirectUrlClick(redirectUrl: string) {
      this.loadSeoUrlSearch({
        seoUrl: redirectUrl
      })
    }
  }
})
