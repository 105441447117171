import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { toSnakeCase } from '../utils/snake-case'
import { Upload as TusUpload } from 'tus-js-client'

import {
  ReactionResponse,
  Reactions,
  ShortFilters,
  ShortVideo,
  ShortVideoEditFormSchema,
  ShortVideoReport,
  ShortVideoStatus,
  ShortVideosData,
  ShortsClassified
} from '~/models/short-video'
import { removeKeysWithNoValues, toCamelCase } from '~/utils/object'
import { SchemaResult } from '~/models/report/message/schema'
import { Pagination } from '~/models/search/types'

@containerScoped()
export default class ShortVideosService {
  private upload: any
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  public adminFetchShortVideoObject(
    videoId: number
  ): Promise<{ schema: ShortVideoEditFormSchema; short: ShortVideo }> {
    return this.requestBuilder
      .request('get', `/api/admin/shorts/${videoId}/`)
      .validate(body => body && body.data && body.data.schema)
      .send()
  }

  public fetchShortVideoReportSchema(videoId: number): Promise<any> {
    return this.requestBuilder
      .request('get', `/api/shorts/${videoId}/report/`)
      .validate(body => body && body.data && body.data.schema)
      .map(body => this.formatSchemaResponse(toCamelCase(body.data)))
      .send()
  }

  public fetchClassifiedShortVideos(
    classifiedId: string
  ): Promise<{ shorts: ShortVideosData; classified: ShortsClassified }> {
    return this.requestBuilder
      .request('get', `/api/shorts/manage/${classifiedId}/`)
      .validate(body => body?.data?.shorts)
      .send()
  }

  public adminFetchShortVideos(
    params: object
  ): Promise<{
    shorts: {
      pagination: Pagination
      rows: ShortVideo[]
    }
    filters: ShortFilters
  }> {
    const queryParams = toSnakeCase(removeKeysWithNoValues(params)) as {
      [key: string]: any
    }

    return this.requestBuilder
      .request('get', '/api/admin/shorts/')
      .params(queryParams)
      .validate(body => body?.data?.shorts?.pagination)
      .send()
  }

  fetchReportedVideos(params: {
    pg: string
    username: string
    phone: string
  }): Promise<{
    pagination: Pagination
    rows: ShortVideoReport[]
  }> {
    const queryParams = removeKeysWithNoValues(params) as { [key: string]: any }
    return this.requestBuilder
      .request('get', '/api/admin/shorts/reports/')
      .params(queryParams)
      .validate(body => body?.reports?.pagination)
      .map(body =>
        toCamelCase({
          pagination: body.reports.pagination,
          rows: body.reports.rows.map((r: any) => ({
            ...r,
            reporter: {
              ...r.reporter,
              telephone: r.reporter?.telephone?.telephone
            },
            reported: {
              ...r.reported,
              telephone: r.reported?.telephone?.telephone
            }
          }))
        })
      )
      .send()
  }

  fetchShortVideosByClassifiedId(
    classifiedId: string
  ): Promise<{
    classified: ShortsClassified
    shorts: ShortVideo[]
  }> {
    return this.requestBuilder
      .request('get', `/api/shorts/classified/${classifiedId}/`)
      .validate(body => body.data?.classified)
      .send()
  }

  fetchVideoById(
    videoId: number
  ): Promise<{
    classified: ShortsClassified
    shorts: ShortVideo
  }> {
    return this.requestBuilder
      .request('get', `/api/shorts/${videoId}/info/`)
      .validate(body => body.data?.short)
      .map(body => {
        return toCamelCase({
          shorts: body.data.short,
          classified: body.data.short.classified
        })
      })
      .send()
  }

  adminChangeVideoStatus(
    videoId: number,
    status: ShortVideoStatus,
    hideReason: string
  ): Promise<ShortVideo> {
    return this.requestBuilder
      .request('put', `/api/admin/shorts/${videoId}/status/`)
      .validate(body => body?.data?.short)
      .data(
        toSnakeCase({
          status,
          hideReason
        })
      )
      .map(body => toCamelCase(body.data.short))
      .send()
  }

  editShortVideo(videoId: number, data: any): Promise<{ short: ShortVideo }> {
    return this.requestBuilder
      .request('put', `/api/shorts/${videoId}/`)
      .data(toSnakeCase(data))
      .send()
  }

  adminEditShortVideo(
    videoId: number,
    data: any
  ): Promise<{ short: ShortVideo }> {
    return this.requestBuilder
      .request('put', `/api/admin/shorts/${videoId}/`)
      .validate(body => body.data?.short)
      .data(toSnakeCase(data))
      .map(body => toCamelCase(body.data.short))
      .send()
  }

  updateOrder(classifiedId: number, order: number[]) {
    return this.requestBuilder
      .request('put', `/api/shorts/manage/${classifiedId}/order/`)
      .data({ order })
      .send()
  }

  viewedReport(reportId: number): Promise<ReactionResponse> {
    return this.requestBuilder
      .request('put', `/api/admin/shorts/reports/${reportId}/viewed/`)
      .send()
  }

  deleteVideo(videoId: number): Promise<{ message: string }> {
    return this.requestBuilder
      .request('delete', `/api/shorts/${videoId}/`)
      .validate(body => body.data?.message)
      .send()
  }

  adminDeleteVideo(videoId: number): Promise<{ message: string }> {
    return this.requestBuilder
      .request('delete', `/api/admin/shorts/${videoId}/`)
      .validate(body => body.data?.message)
      .send()
  }

  deleteReaction(
    videoId: number,
    reaction: Reactions
  ): Promise<{ message: string }> {
    return this.requestBuilder
      .request('delete', `/api/shorts/${videoId}/reaction/`)
      .data({ reaction })
      .validate(body => body?.message)
      .map(body => body)
      .send()
  }

  reportShortVideo(videoId: number, data: any) {
    return this.requestBuilder
      .request('post', `/api/shorts/${videoId}/report/`)
      .data(this.formatReportData(data))
      .send()
  }

  createReaction(videoId: number, data: any): Promise<ReactionResponse> {
    return this.requestBuilder
      .request('post', `/api/shorts/${videoId}/reaction/`)
      .data(toSnakeCase(data))
      .send()
  }

  uploadShortVideo(
    file: File,
    onProgress: Function,
    onPostResponse: Function,
    classifiedId?: number
  ) {
    return new Promise((resolve, reject) => {
      const classifiedParam = classifiedId ? `?classified=${classifiedId}` : ''
      const upload = new TusUpload(file, {
        endpoint: `/api/shorts/request-for-upload/${classifiedParam}`,
        retryDelays: [0, 3000, 5000, 10000],
        uploadSize: file.size,
        chunkSize: 5 * 1024 * 1024,

        metadata: {
          filename: file.name,
          filetype: file.type,
          fileSize: file.size.toString()
        },
        onError: error => {
          reject(error)
        },
        onShouldRetry: error => {
          if (!error) {
            return false
          }
          const data = JSON.parse(
            // @ts-ignore
            error?.originalResponse?._xhr?.response || null
          )
          if (data) {
            reject(
              new Error(
                data?.error || data?.data?.error?.message || data?.data?.error
              )
            )
          } else {
            reject(error)
          }
          return false
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          onProgress(parseInt(`${(bytesUploaded / bytesTotal) * 100}`, 10))
        },
        onAfterResponse: (req, res) => {
          if (req.getMethod() === 'POST') {
            onPostResponse(JSON.parse(res.getBody())?.data?.short)
          }
        },
        onSuccess: () => {
          resolve(upload)
        }
      })

      this.upload = upload
      upload.start()
    })
  }

  cancelUpload() {
    return this.upload.abort()
  }

  private formatSchemaResponse(r: any): SchemaResult {
    const { details, reasonId: reason } = r.schema
    return {
      schema: {
        details,
        reason: {
          ...reason,
          values: reason.options.map((o: { value: object; label: string }) => ({
            value: o.value,
            name: o.label
          }))
        }
      }
    }
  }

  private formatReportData(data: { details: string; reason: number }) {
    return {
      details: data.details,
      reason_id: data.reason
    }
  }
}
