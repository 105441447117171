import { CIcon } from '~/icons/types'
export const ciRoller: CIcon = {
  name: 'roller',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M500.504 86.395h-50.481V10.199C450.023 4.567 445.457 0 439.824 0H11.497C5.864 0 1.298 4.567 1.298 10.199v169.139c0 5.632 4.566 10.199 10.199 10.199h428.326c5.633 0 10.199-4.567 10.199-10.199v-72.545h40.282v124.835l-266.423 47.183a10.199 10.199 0 0 0-8.42 10.043v43.341h-17.787c-5.633 0-10.199 4.567-10.199 10.199v131.419c0 21.056 17.131 38.186 38.186 38.186 21.056 0 38.187-17.131 38.187-38.186V342.395c0-5.632-4.566-10.199-10.199-10.199h-17.788v-34.789l266.423-47.182a10.199 10.199 0 0 0 8.42-10.043V96.595c-.001-5.632-4.568-10.2-10.2-10.2zM21.696 169.14v-30.708h309.667c5.633 0 10.199-4.567 10.199-10.199s-4.566-10.199-10.199-10.199H21.696V20.398h407.927V169.14H21.696zm221.752 183.455v121.22c0 9.808-7.98 17.787-17.788 17.787-9.809 0-17.787-7.98-17.787-17.787v-121.22h35.575z'
    },
    {
      d:
        'M381.255 118.033h-8.518c-5.633 0-10.199 4.567-10.199 10.199s4.566 10.199 10.199 10.199h8.518c5.633 0 10.199-4.567 10.199-10.199s-4.566-10.199-10.199-10.199z'
    }
  ]
}
