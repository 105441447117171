import { CIcon } from '~/icons/types'
export const ciCompactHatchback: CIcon = {
  name: 'compact-hatchback',
  type: 'regular',
  width: 512,
  height: 238.6,
  paths: [
    {
      d:
        'M506.1 149.6c-1-4.8-4.1-18.7-6.5-29.5-3.7-16.5-33-36.8-58-40.3-51.2-7.1-82.2-13.6-90-15.3l-48.7-34.1c-19.2-15.2-42-24.5-66-27C140.3-6.8 35 9 30.6 9.7c-3.6.5-6.6 2.9-8 6.2-1.4 3.3-1.1 7.1.9 10.1l9.2 13.6c-12.2 17.5-21.8 47.6-28 71.3-2 7.8-2.6 15.9-1.6 23.9l4.4 35.7c0 .4.1.7.2 1.1l6.8 29.1c1.1 4.8 5.3 8.1 10.2 8.1l44.7-.1c8.8 17.8 27.1 30 48.2 30s39.4-12.3 48.2-30.1h183.1c8.8 17.8 27.1 30.1 48.2 30.1 21.2 0 39.5-12.3 48.2-30.1L497 207c4.6-.1 8.6-3.3 9.8-7.8 4.5-16.3 2-36-.7-49.6zM290 47.1c.2.1.3.3.5.4l28.6 20c-15.3.9-33.5 2-49.6 2.8-7.9.4-47.6.3-86.1-.6l-4.3-48.6c18.4.3 37.2 1.2 55.5 3.2 20.1 2.1 39.3 10 55.4 22.8zM108.6 65.6c.3-7.6 5.5-25.6 12.5-43.3 11.7-.6 24.1-1 36.9-1.2l4.3 48c-24.1-.8-45.1-1.9-53.7-3.5zm9.2 152c-18 0-32.7-14.7-32.7-32.7 0-18 14.7-32.7 32.7-32.7s32.7 14.7 32.7 32.7c-.1 18.1-14.7 32.7-32.7 32.7zm279.4 0c-18 0-32.7-14.7-32.7-32.7 0-18 14.7-32.7 32.7-32.7s32.7 14.7 32.7 32.7c0 18.1-14.6 32.7-32.7 32.7zm90.8-31.4-37.1 1.2c0-.8.1-1.6.1-2.4 0-29.6-24.1-53.7-53.7-53.7s-53.7 24.1-53.7 53.7c0 .9 0 1.7.1 2.6H171.4c0-.9.1-1.7.1-2.6 0-29.6-24.1-53.7-53.7-53.7S64.1 155.4 64.1 185c0 .9 0 1.8.1 2.7l-31 .1-4.8-20.4-4.4-35.2c-.7-5.4-.3-10.8 1-16 8.8-33.9 19.4-59.6 28.2-68.8 3.4-3.6 3.9-9.1 1.1-13.1l-4-5.9c11.4-1.4 28-3.1 47.7-4.6-1.5 4.2-3.2 9.1-4.8 14.1-7.9 25.3-7.7 38.3.8 44.6 3.7 2.8 8.8 6.6 89.9 8.4 22.9.5 46.8.7 63.8.7 11.6 0 20-.1 22.7-.2 32.9-1.7 74.5-4.3 82.7-4.9 13.3 2.7 42 8.2 85.3 14.2 19 2.6 38.7 18.7 40.4 24.1 3.5 15.4 5.7 25.4 6.5 29 2.7 12.3 3.5 23.5 2.7 32.4z'
    }
  ]
}
