import { CIcon } from '~/icons/types'
export const ciWindowAlt: CIcon = {
  name: 'window-alt',
  type: 'light',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M502 0H10C4.477 0 0 4.478 0 10v492c0 5.522 4.477 10 10 10h492c5.523 0 10-4.478 10-10V10c0-5.522-4.477-10-10-10zm-10 128H90c-5.523 0-10 4.478-10 10s4.477 10 10 10h402v28H20v-76h472v28zM20 196h226v185.357l-79.679-79.679c-3.905-3.904-10.237-3.904-14.143 0L20 433.857V196zm246 0h172v59.667c0 5.522 4.477 10 10 10s10-4.478 10-10V196h34v220.858L369.196 294.054c-3.904-3.904-10.237-3.904-14.142 0L266 383.108V196zM20 20h472v60H20V20zm0 442.143 139.25-139.25 86.75 86.75V492H20v-29.857zm246-32.5L328.357 492H266v-62.357zM356.643 492l-85.625-85.625 91.107-91.107L492 445.143V492H356.643z'
    },
    {
      d:
        'M448 305.67c2.63 0 5.21-1.07 7.07-2.93 1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07a10.033 10.033 0 0 0-7.07-2.93c-2.63 0-5.21 1.06-7.07 2.93-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.2 2.93 7.07c1.86 1.86 4.44 2.93 7.07 2.93zm-255.737-34.895c0-23.375-19.018-42.393-42.393-42.393a42.488 42.488 0 0 0-18.378 4.202c-9.664-9.075-22.574-14.302-35.992-14.302-28.944 0-52.493 23.548-52.493 52.492 0 5.522 4.477 10 10 10h129.256c5.523.001 10-4.476 10-9.999zm-127.681-10c4.227-13.039 16.489-22.492 30.918-22.492 10.39 0 19.908 4.796 26.113 13.158a10 10 0 0 0 14.281 1.847c4.008-3.209 8.84-4.905 13.976-4.905 8.756 0 16.354 5.052 20.034 12.393H64.582zM50 148c2.63 0 5.21-1.07 7.07-2.931C58.93 143.21 60 140.63 60 138s-1.07-5.21-2.93-7.07S52.63 128 50 128s-5.21 1.069-7.07 2.93C41.07 132.79 40 135.37 40 138s1.07 5.21 2.93 7.069A10.072 10.072 0 0 0 50 148z'
    }
  ]
}
