import { CIcon } from '~/icons/types'
export const ciRadioPlayer: CIcon = {
  width: 512,
  height: 451.14,
  paths: [
    {
      d:
        'M511.9 140.09v-.51a12.32 12.32 0 0 0-12.14-12.14h-348.4l4.39-1.49L201 110.57l49.69-16.91 50-17 46.58-15.86 39.53-13.45 28.4-9.66.23-.08a20.72 20.72 0 1 0-7.82-22.94l-19.33 6.58-37.09 12.61L306 49.24l-49.72 16.91-50 17L159.67 99l-39.52 13.45-28.4 9.66c-4.46 1.51-8.92 3-13.37 4.55l-.59.2c-.5.17-1 .36-1.45.57H32c-6.32 0-12.64-.07-19 0h-.83A12.32 12.32 0 0 0 .1 139.58v262.58c0 12.1-.23 24.22 0 36.31v.5a12.31 12.31 0 0 0 12.14 12.14H480c6.32 0 12.64.08 19 0h.83c6.35 0 12.43-5.58 12.14-12.14V176.4c-.07-12.1.16-24.22-.07-36.31Zm-24.28 29.27v232.8c0 8.22-.09 16.45-.09 24.68H24.35V176.4c0-8.22.15-16.45.14-24.68h463.16Z'
    },
    {
      d:
        'M434.8 207.14a125 125 0 0 0-14.07-12.24 117.06 117.06 0 0 0-66.5-23.38 117.27 117.27 0 0 0-94.13 42 127.38 127.38 0 0 0-11 15.33 125.07 125.07 0 0 0-8.34 17.1 116 116 0 0 0-8 35.14 117.41 117.41 0 0 0 16.58 69c12 19.87 30.15 36.47 51.37 46A112.56 112.56 0 0 0 338 406.41 117.36 117.36 0 0 0 435.26 371c16.26-17 27.67-39 31.24-62.32a129.33 129.33 0 0 0 1.65-19.34c0-23.9-7.35-48-21.27-67.48a127.57 127.57 0 0 0-12.08-14.72Zm-141.68 8a93.23 93.23 0 0 1 44.63-18.67A93.75 93.75 0 0 1 386.7 203a89.51 89.51 0 0 1 28.88 19.3H285.06a105 105 0 0 1 8.06-7.15Zm114.39 148.28a93.14 93.14 0 0 1-44.62 18.67 107.85 107.85 0 0 1-25.14 0 105.79 105.79 0 0 1-23.81-6.5 106.88 106.88 0 0 1-20.82-12.17q-3.93-3.14-7.54-6.62h129.48q-3.62 3.48-7.55 6.62Zm35.61-61.57a105.87 105.87 0 0 1-6.5 23.82q-1.53 3.48-3.32 6.86h-166c-1.18-2.24-2.29-4.53-3.32-6.85a106.79 106.79 0 0 1-6.5-23.82v-.43h185.7l-.06.42Zm-13.18-24.7H257.47v-.43A106.94 106.94 0 0 1 264 252.9q1.42-3.22 3.06-6.35h166.5q1.62 3.12 3.05 6.34a106 106 0 0 1 6.5 23.82c0 .15 0 .29.05.43Z'
    }
  ],
  name: 'radio-player',
  type: 'regular'
}
