import { CIcon } from '~/icons/types'
export const ciGearshift: CIcon = {
  name: 'gearshift',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M469.333 410.368V101.623C493.508 97.545 512 76.518 512 51.2 512 22.963 489.037 0 460.8 0c-28.237 0-51.2 22.963-51.2 51.2 0 25.318 18.492 46.345 42.667 50.423v145.843H332.8V101.623c24.175-4.079 42.667-25.105 42.667-50.423 0-28.237-22.963-51.2-51.2-51.2-28.237 0-51.2 22.963-51.2 51.2 0 25.318 18.492 46.345 42.667 50.423v145.843H196.267l.009-145.843c24.175-4.079 42.658-25.105 42.658-50.423 0-28.237-22.963-51.2-51.2-51.2s-51.2 22.963-51.2 51.2c0 25.318 18.492 46.353 42.675 50.432l-.009 145.835H59.725l.009-145.843C83.908 97.545 102.4 76.518 102.4 51.2 102.4 22.963 79.437 0 51.2 0 22.963 0 0 22.963 0 51.2c0 25.318 18.492 46.345 42.667 50.423L42.658 256a8.536 8.536 0 0 0 8.533 8.533H179.2v145.835c-24.175 4.079-42.667 25.105-42.667 50.432 0 28.228 22.963 51.2 51.2 51.2s51.2-22.972 51.2-51.2c0-25.327-18.492-46.353-42.667-50.432V264.533h119.467v145.835c-24.175 4.079-42.667 25.105-42.667 50.432 0 28.228 22.963 51.2 51.2 51.2 28.237 0 51.2-22.972 51.2-51.2 0-25.327-18.492-46.353-42.667-50.432V264.533h119.467v145.835c-24.175 4.079-42.667 25.105-42.667 50.432 0 28.228 22.963 51.2 51.2 51.2 28.237 0 51.2-22.972 51.2-51.2.001-25.327-18.491-46.353-42.666-50.432z',
      fill: 'currentColor'
    }
  ]
}
