import { CIcon } from '~/icons/types'
export const ciTrailer: CIcon = {
  name: 'trailer',
  type: 'regular',
  width: 512,
  height: 231,
  paths: [
    {
      d:
        'M496 161.4h-49.6V10.5c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5V67H26.5V46.2c0-5.8-4.7-10.5-10.5-10.5S5.5 40.4 5.5 46.2v125.7c0 5.8 4.7 10.5 10.5 10.5h34.7c5 27.6 29.1 48.6 58.1 48.6s53.2-21 58.1-48.6h27.7c5 27.6 29.1 48.6 58.1 48.6s53.2-21 58.2-48.6h174.5v15.1c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-25.6c.1-5.8-4.6-10.5-10.4-10.5zM108.8 210c-21 0-38.1-17.1-38.1-38.1s17.1-38.1 38.1-38.1c20.6 0 37.4 16.5 38.1 36.9 0 .4-.1.8-.1 1.2s0 .8.1 1.2c-.7 20.4-17.5 36.9-38.1 36.9zm144 0c-20.6 0-37.4-16.5-38.1-36.9 0-.4.1-.8.1-1.2s0-.8-.1-1.2c.6-20.5 17.5-36.9 38.1-36.9 21 0 38.1 17.1 38.1 38.1S273.8 210 252.8 210zm0-97.2c-29 0-53.2 21-58.1 48.6H167c-5-27.6-29.1-48.6-58.1-48.6s-53.2 21-58.1 48.6H26.5V88h398.9v73.4H311c-5-27.6-29.2-48.6-58.2-48.6z',
      fill: 'currentColor'
    }
  ]
}
