import { CIcon } from '~/icons/types'
export const ciShield: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M436 21q10 3 10 12v286q0 59-49 93-46 32-134 98-3 2-7 2t-7-2q-88-66-134-97-49-35-49-94V33q0-9 10-12 180-43 360 0zm-15 298V43q-82-18-165-18T91 43v276q0 46 39 73 45 32 126 92 81-60 127-92 38-27 38-73zM386 62q10 1 10 12v245q0 33-28 52-42 30-105 76-3 3-7 3t-7-3q-63-46-105-76-28-19-28-52V74q0-11 10-12 65-12 130-12t130 12zm-32 289q17-12 17-32V84q-57-9-115-9t-115 9v235q0 20 17 32 41 28 98 71 57-43 98-71zm-14-176q9 8 1 18l-89 98q-4 4-9.5 4t-8.5-4l-49-49q-8-9 1-17.5t17 .5l39 39 81-88q8-9 17-1z',
      fill: 'currentColor'
    }
  ],
  name: 'shield',
  type: 'light'
}
