import { CIcon } from '~/icons/types'
export const ciCamper: CIcon = {
  width: 512,
  height: 320.5,
  paths: [
    {
      d:
        'M264.6 210.1v62h-71.2v-62H2.6v72.5c0 5.8 4.7 10.5 10.5 10.5h37.2c-.4-2.7-.7-5.5-.7-8.2-.1-4.4.4-8.6 1.2-12.8 2.3-11.1 7.9-21.2 16.2-29.3 10.8-10.5 25-16.3 40.2-16.3h2.8c1.8.1 3.6.3 5.4.5 2.6.4 5.1.9 7.5 1.6 20.3 5.8 36 22.5 40.4 43.5.5 2.4.9 4.8 1.1 7.3l.1.8v.6c.1 4.2-.1 8.3-.7 12.2h130.9c5.7 0 10.4-4.6 10.5-10.3l.1-5.8v-5.1l.1-61.8h-40.8zM435.6 75h1.8c5.4.2 10.7 1.4 15.7 3.4L467.7 59c8.5-11.2 9.9-26 3.6-38.6C465.1 7.8 452.4 0 438.4 0H13.1C7.3 0 2.6 4.7 2.6 10.5v178.6h190.7V85.2c0-5.8 4.7-10.5 10.5-10.5H254c5.8 0 10.5 4.7 10.5 10.5v103.9h40.8v-4.6l.1-96.8c0-4.6 2.5-8.7 6.2-10.9 1.9-1.1 4.1-1.8 6.4-1.8h117.6zm-283.2 76.5c0 5.8-4.7 10.5-10.5 10.5h-81c-5.7 0-10.5-4.7-10.5-10.5V85.2c0-5.8 4.7-10.5 10.5-10.5H142c5.8 0 10.5 4.7 10.5 10.5v66.3z'
    },
    {
      d:
        'M143.5 281.1c-1.4-18.3-16.3-32.7-34.4-33.6h-1.8c-9.5 0-18.7 3.7-25.6 10.4-7.3 7-11.2 16.6-11.1 26.7.3 19.1 16 35.2 35 35.9h1.6c16.1 0 30.2-10.5 34.8-25.9 1.3-4.5 1.7-9 1.5-13.5zm365.2-57.5c-.1-11.1-3.3-33.5-11.4-46.3l-38-66c-1.9-3.4-4.6-6.3-7.8-8.6-6.2-4.4-13.6-6.7-21.1-6.7H326.1v180.9H354c1.6-13 7.5-24.9 17-34.2 8.2-8.1 18.5-13.3 29.7-15.4 3.4-.6 6.9-1 10.5-1h2.8c20.3.9 37.9 12.5 47.3 29.4 3.5 6.4 5.9 13.5 6.8 21h30.6c2.8 0 5.5-1.1 7.5-3.1s3.1-4.7 3-7.5l-.5-42.5zm-118.8-49.8c-1.4 0-2.4-.1-3.1-.2v-52.5c2.9-.1 7-.1 11.3-.1h41.2l31.8 52.8h-81.2z'
    },
    {
      d:
        'M447.5 281.1c-1.4-18.3-16.3-32.7-34.4-33.6h-1.8c-9.5 0-18.7 3.7-25.6 10.4-7.3 7-11.2 16.6-11.1 26.7.3 19.1 16 35.2 35 35.9h1.6c16.1 0 30.2-10.5 34.8-25.9 1.3-4.5 1.7-9 1.5-13.5z'
    }
  ],
  name: 'camper',
  type: 'solid'
}
