import { CIcon } from '~/icons/types'
export const ciSnowsport: CIcon = {
  name: 'snowsport',
  type: 'regular',
  width: 512,
  height: 249.4,
  paths: [
    {
      d:
        'm509 207.1-3.4-8c-2.3-5.3-8.4-7.8-13.8-5.6-5.3 2.3-7.8 8.4-5.6 13.8l3.4 8c1 2.4.8 4.8-.8 7.1-1.9 2.9-6.4 6.1-13.1 6.1h-20.8L403.5 160h65.1c11 0 20.5-6.3 24.8-16.3 4.3-10.1 2.3-21.3-5.3-29.3l-.6-.6-124-111c-4.3-3.9-11-3.5-14.8.8-3.9 4.3-3.5 11 .8 14.8l42.2 37.8-74.6 24.7h-62.5l-117-40.8c-9.1-3.4-19.4-1.5-26.4 4.7-6 5.4-8.4 13.1-6.4 20.6.3 1 .7 2 1.2 2.9l8.1 13.1C87 84.7 63.5 99.7 52.5 121.6c-.2.4-.4.8-.5 1.3l-11 31.4C18.2 158 .8 177.8.8 201.6c0 26.4 21.5 47.9 47.9 47.9h176.7c25.6 0 46.6-20.2 47.8-45.5h73.7c3 0 5.8-1.3 7.8-3.5l28.9-32.3 45.3 60.3H299.1c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h176.7c12.7 0 24.1-5.8 30.6-15.4 5.4-8.3 6.4-18.1 2.6-27zM130.3 59.8c.1 0 .2.1.3.1l60.2 20.9h-52.3l-12.8-20.6c.9-.6 2.6-1.1 4.6-.4zm95 168.6H48.6c-14.8 0-26.9-12.1-26.9-26.9 0-14.8 12-26.9 26.9-26.9h176.7c14.8 0 26.9 12.1 26.9 26.9 0 14.8-12 26.9-26.9 26.9zm116.8-45.5h-72.7c-7.3-17.2-24.3-29.3-44.1-29.3H63.4l8.1-23.2c9.1-17.4 30.1-28.6 53.7-28.6h193.7c1.1 0 2.2-.2 3.3-.5l87.6-29 63.4 56.8c2.2 2.5 1.4 5.2 1 6.3-.5 1.1-1.9 3.6-5.5 3.6h-82.5c-3 0-5.8 1.3-7.8 3.5l-1.1 1.2c-.7.6-1.2 1.2-1.7 1.9l-33.5 37.3z',
      fill: 'currentColor'
    }
  ]
}
