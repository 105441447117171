import { CIcon } from '~/icons/types'
export const ciBuilder: CIcon = {
  name: 'builder',
  type: 'regular',
  width: 512,
  height: 434,
  paths: [
    {
      d:
        'M508.8 314v-.4c0-.2 0-.4-.1-.7v-.2L465.9 9c-.7-5.2-5.2-9-10.4-9h-82.7c-2.9 0-5.6 1.2-7.6 3.3L234 141.7h-15.2c-5.8 0-10.5 4.7-10.5 10.5v32.9H41.2c-5.8 0-10.5 4.7-10.5 10.5v113.1c0 5.8 4.7 10.5 10.5 10.5H76v21H50.1c-25.9 0-46.9 21-46.9 46.9s21 46.9 46.9 46.9h289.1c25.9 0 46.9-21 46.9-46.9s-21-46.9-46.9-46.9h-25.9v-21h34.8c5.8 0 10.5-4.7 10.5-10.5v-65.9c0-1.4-.3-2.7-.8-4l-24-59.1 62.8-104.4 68.5 271-42.7 66.7h-15.9c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5h91.8c5.8 0 10.5-4.7 10.5-10.5V314zM51.7 206.1h156.6v92.1H51.7v-92.1zm248.4 155.1c-4.9 7.4-7.8 16.3-7.8 25.9s2.9 18.5 7.8 25.9h-211c4.9-7.4 7.8-16.3 7.8-25.9s-2.9-18.5-7.8-25.9h211zM24.2 387.1c0-14.3 11.6-25.9 25.9-25.9S76 372.8 76 387.1 64.4 413 50.1 413s-25.9-11.6-25.9-25.9zm340.9 0c0 14.3-11.6 25.9-25.9 25.9s-25.9-11.6-25.9-25.9 11.6-25.9 25.9-25.9 25.9 11.6 25.9 25.9zm-72.8-46.9H97v-21h195.3v21zm-63-42V162.7h25.4v68.9c0 19.9 16.1 36 36 36h46.9v30.6H229.3zm108.3-51.6h-46.9c-8.3 0-15-6.7-15-15v-68.9h28.5l7.9 19.4.6 1.5 24.9 61.2v1.8zM321 148.2c-1.6-4-5.5-6.5-9.7-6.5h-48.4L377.3 21h27.6l-81 134.5-2.9-7.3zm90.9-98.1L429.4 21h16.9l41 290.8-6.7 10.4-68.7-272.1zM487.8 413h-40.5l40.5-63v63z',
      fill: 'currentColor'
    }
  ]
}
