import { RowsAds } from '~/models/ads'

export const plotDesktopListRowsAds: RowsAds = {
  4: {
    google: {
      id: 'div-gpt-ad-1631534256617-0',
      path: '/297119706/plot_search_dt_top_list',
      sizes: [728, 90],
      componentProps: {
        lazy: false
      }
    }
  },
  14: {
    google: {
      id: 'div-gpt-ad-1631534282076-0',
      path: '/297119706/plot_search_dt_top2_list',
      sizes: [728, 90]
    }
  }
}

export const plotDesktopGalleryRowsAds: RowsAds = {
  4: {
    google: {
      id: 'div-gpt-ad-1631534234700-0',
      path: '/297119706/plot_search_dt_top_gal',
      sizes: [250, 250],
      componentProps: {
        lazy: false
      }
    }
  }
}

export const plotMobileRowsAds: RowsAds = {
  4: {
    google: {
      path: '/297119706/plot_search_mb_top_gal',
      id: 'div-gpt-ad-1631534609604-0',
      sizes: [
        [360, 300],
        [336, 280],
        [300, 250]
      ],
      sizesMappings: [
        [[360, 300], [[360, 300]]],
        [[336, 280], [[336, 280]]],
        [[300, 250], [[300, 250]]],
        [[0, 0], []]
      ],
      componentProps: {
        lazy: false
      }
    }
  },
  14: {
    google: {
      path: '/297119706/plot_search_mb_mid_gal',
      id: 'div-gpt-ad-1631534584101-0',
      sizes: [
        [360, 300],
        [336, 280],
        [300, 250]
      ],
      sizesMappings: [
        [[360, 300], [[360, 300]]],
        [[336, 280], [[336, 280]]],
        [[300, 250], [[300, 250]]],
        [[0, 0], []]
      ]
    }
  }
}
