import {
  faArrowsAlt,
  faCalendar,
  faCar,
  faCheck,
  faCheckDouble,
  faClock,
  faCogs,
  faEuroSign,
  faLayerGroup,
  faPercent,
  faRoad,
  faSort,
  faStar,
  faTachometerAlt,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons'
import { Icon } from '~/models/common/types'

export const fallbackIcon: Icon = {
  isFaIcon: true,
  icon: faCheck
}

export const sortOptionsIcons = Object.freeze({
  pra: {
    isFaIcon: true,
    icon: faEuroSign
  },
  prd: {
    isFaIcon: true,
    icon: faEuroSign
  },
  cr: {
    isFaIcon: true,
    icon: faClock
  },
  dm: {
    isFaIcon: true,
    icon: faClock
  },
  dld: {
    isFaIcon: true,
    icon: faClock
  },
  dla: {
    isFaIcon: true,
    icon: faClock
  },
  spa: {
    isFaIcon: true,
    icon: faArrowsAlt
  },
  spd: {
    isFaIcon: true,
    icon: faArrowsAlt
  },
  gsa: {
    isFaIcon: true,
    icon: faUserFriends
  },
  gsd: {
    isFaIcon: true,
    icon: faUserFriends
  },
  pqa: {
    isFaIcon: true,
    icon: faSort
  },
  pqd: {
    isFaIcon: true,
    icon: faSort
  },
  fla: {
    isFaIcon: true,
    icon: faLayerGroup
  },
  fld: {
    isFaIcon: true,
    icon: faLayerGroup
  },
  relevance: {
    isFaIcon: true,
    icon: faCheckDouble
  },
  popularity: {
    isFaIcon: true,
    icon: faStar
  },
  di: {
    isFaIcon: true,
    icon: faRoad
  },
  nd: {
    isFaIcon: true,
    icon: faClock
  },
  ds: {
    isFaIcon: true,
    icon: faStar
  },
  red: {
    isFaIcon: true,
    icon: faCalendar
  },
  rea: {
    isFaIcon: true,
    icon: faCalendar
  },
  ma: {
    isFaIcon: true,
    icon: faCar
  },
  mid: {
    isFaIcon: true,
    icon: faTachometerAlt
  },
  mia: {
    isFaIcon: true,
    icon: faTachometerAlt
  },
  pod: {
    isFaIcon: true,
    icon: faCogs
  },
  poa: {
    isFaIcon: true,
    icon: faCogs
  },
  grd: {
    isFaIcon: true,
    icon: faCogs
  },
  gra: {
    isFaIcon: true,
    icon: faCogs
  },
  'price-per-liter': {
    isFaIcon: true,
    icon: faEuroSign
  },
  ira: {
    isFaIcon: true,
    icon: faPercent
  },
  ird: {
    isFaIcon: true,
    icon: faPercent
  }
})
