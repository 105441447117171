import { CIcon } from '~/icons/types'
export const ciCheck: CIcon = {
  width: 706.3,
  height: 512,
  paths: [
    {
      d:
        'M688.7 19.6c-23.4-23.4-61.5-23.4-84.9 0L256.4 367 101.8 212.4c-23.4-23.4-61.5-23.4-84.9 0-23.4 23.4-23.4 61.5 0 84.9L214 494.4c11.3 11.3 26.4 17.6 42.5 17.6 16 0 31.1-6.2 42.5-17.6l389.9-389.9c23.2-23.4 23.2-61.5-.2-84.9zM673.1 89 283.3 478.8c-7.2 7.2-16.7 11.1-26.9 11.1s-19.7-4-26.9-11.1l-197.1-197c-14.8-14.8-14.8-38.9 0-53.8 7.4-7.4 17.1-11.1 26.9-11.1 9.7 0 19.5 3.7 26.9 11.1l170.2 170.2 363-363c14.8-14.8 38.9-14.8 53.8 0 14.7 14.8 14.7 38.9-.1 53.8z',
      fill: 'currentColor'
    }
  ],
  name: 'check',
  type: 'regular'
}
