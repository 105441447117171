import { CIcon } from '~/icons/types'
export const ciProfits: CIcon = {
  name: 'profits',
  type: 'light',
  width: 494.3,
  height: 494.3,
  paths: [
    {
      d:
        'M416.163 313.3V136.2c0-5.5-4.5-9.3-10-9.3h-38.7c-5.5 0-10.3 3.8-10.3 9.3V296c-6.4.2-12.8 1-19 2.5V188.4c0-5.5-4.7-9.5-10.2-9.5h-38.7c-5.5 0-10.1 3.9-10.1 9.5v149.3a98.158 98.158 0 0 0-18.2 57.4c0 54.8 44.4 99.2 99.1 99.2 54.8 0 99.2-44.4 99.2-99.1 0-32.8-16.1-63.4-43.1-81.9zm-39-166.4h19v155.8c-6.1-2.4-12.5-4.2-19-5.2V146.9zm-78 52h19v106.5c-6.7 3.1-13.1 6.9-19 11.4V198.9zm129.1 236.5c-14.3 24.1-40.2 38.8-68.1 38.8-43.7 0-79.1-35.5-79.1-79.1 0-32 19.3-60.9 48.9-73.1 1.2-.2 2.3-.7 3.3-1.3 8.6-3.2 17.8-4.8 27-4.8 2.1 0 4.1.1 6.2.3h.1c12 .9 23.7 4.6 34 10.8 37.599 22.3 50 70.8 27.7 108.4z'
    },
    {
      d:
        'M360.263 387c-7 0-12.6-5.6-12.6-12.6s5.5-12.6 12.5-12.7c7 0 12.6 5.7 12.6 12.6 0 5.5 4.5 10 10 10 5.6 0 10.1-4.4 10.2-10 0-13.4-8.2-25.5-20.7-30.4v-8.3c0-5.5-4.5-10-10-10s-10 4.5-10 10v7.2c-17.4 4.5-27.9 22.3-23.4 39.7 3.6 14.4 16.5 24.4 31.3 24.4 6.9 0 12.5 5.7 12.5 12.6 0 6.9-5.7 12.5-12.6 12.5-6.9 0-12.5-5.7-12.5-12.6 0-5.5-4.5-10-10-10s-9.9 4.4-9.8 9.9v.1c.1 14.8 10.1 27.8 24.4 31.5v4.5c0 5.5 4.5 10 10 10s10-4.5 10-10v-5.5c16.8-6.6 25-25.5 18.5-42.2-4.9-12.5-17-20.7-30.4-20.7zm-189.1-128.1h-39c-5.5 0-10 4.5-10 10v157c0 5.5 4.5 10 10 10h39c5.5 0 10-4.5 10-10v-157c0-5.5-4.5-10-10-10zm-10 158h-19v-138h19v138zm89-181h-39c-5.5 0-10 4.5-10 10v181c0 5.5 4.5 10 10 10h39c5.5 0 10-4.5 10-10v-181c0-5.5-4.5-10-10-10zm-10 181h-19v-161h19v161zm-148-72h-39c-5.5 0-10 4.5-10 10v71c0 5.5 4.5 10 10 10h39c5.5 0 10-4.5 10-10v-71c0-5.5-4.5-10-10-10zm-10 72h-19v-52h19v52zM414.663 0l-68.4.1c-5.5-.1-10 4.4-10.1 9.9s4.4 10 9.9 10.1h.2l44.3-.1-108.5 108.1-37.9-37.6c-1.9-1.8-4.5-2.7-7.1-2.6-2.6-.1-5.2.8-7.1 2.6l-192 192c-3.9 3.9-3.9 10.2 0 14 1.9 1.9 4.4 2.9 7.1 2.9s5.2-1 7.1-2.9l185-185 37.9 38.2c1.8 2 4.4 3.1 7.1 3.2 2.7-.1 5.2-1.3 7.1-3.2l115.4-115.5-.1 44.5c0 5.6 4.4 10.1 10 10.3 5.6-.1 10-4.7 10-10.3l.1-68.6c0-5.5-4.5-10.1-10-10.1z'
    }
  ]
}
