import { CIcon } from '~/icons/types'
export const ciSafeBox: CIcon = {
  name: 'safe-box',
  type: 'light',
  width: 60,
  height: 52,
  paths: [
    {
      d:
        'M13 52H5a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-7-2h6v-2H6zm49 2h-8a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-7-2h6v-2h-6z'
    },
    {
      d:
        'M59 48H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h58a1 1 0 0 1 1 1v46a1 1 0 0 1-1 1zM2 46h56V2H2z'
    },
    {
      d:
        'M59 14h-8a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-7-2h6v-2h-6zm7 28h-8a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-7-2h6v-2h-6z'
    },
    {
      d:
        'M54.003 44H5.997A2 2 0 0 1 4 42.003V5.997A2 2 0 0 1 5.997 4h48.005A2 2 0 0 1 56 5.997V9a1 1 0 0 1-1 1h-3v2h3a1 1 0 0 1 1 1v22a1 1 0 0 1-1 1h-3v2h3a1 1 0 0 1 1 1v3.003A2 2 0 0 1 54.003 44zM54 5.997 5.997 6 6 42.003 54.003 42l-.002-2H51a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h3V14h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3z'
    },
    {
      d:
        'M13 25H9a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2zm18 0h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2zm-11-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 18a1 1 0 0 1-1-1v-4a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm28-14H36a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-11-2h10v-4H37zm-17 8c-2.206 0-4-1.794-4-4s1.794-4 4-4a1 1 0 0 1 0 2c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2a1 1 0 0 1 2 0c0 2.206-1.794 4-4 4z'
    },
    {
      d:
        'M20 32c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-14c-3.308 0-6 2.691-6 6s2.692 6 6 6 6-2.691 6-6-2.692-6-6-6z'
    },
    {
      d:
        'M12 33a.999.999 0 0 1-.707-1.707l3.05-3.05a.999.999 0 1 1 1.414 1.414l-3.05 3.05A.997.997 0 0 1 12 33zm12.95-12.95a.999.999 0 0 1-.707-1.707l3.05-3.05a.999.999 0 1 1 1.414 1.414l-3.05 3.05a.993.993 0 0 1-.707.293zm-9.9 0a.997.997 0 0 1-.707-.293l-3.05-3.05a.999.999 0 1 1 1.414-1.414l3.05 3.05a.999.999 0 0 1-.707 1.707zM28 33a.997.997 0 0 1-.707-.293l-3.05-3.05a.999.999 0 1 1 1.414-1.414l3.05 3.05A.999.999 0 0 1 28 33zm9-7h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm11 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-5 0h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zm-6 4h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm11 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-5 0h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zm-6 4h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm11 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-5 0h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2z'
    }
  ]
}
