import { CIcon } from '~/icons/types'
export const ciCarSteeringWheel: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M328 256q0 30-21 51t-51 21-51-21-21-51 21-51 51-21 51 21 21 51zm-72 56q23 0 39.5-16.5T312 256t-16.5-39.5T256 200t-39.5 16.5T200 256t16.5 39.5T256 312zM437 75q75 75 75 181t-75 181-181 75-181-75T0 256 75 75 256 0t181 75zm-11.5 350.5Q496 355 496 256T425.5 86.5 256 16 86.5 86.5 16 256t70.5 169.5T256 496t169.5-70.5zm35-110q2.5 3.5 1.5 6.5-16 50-53 87t-87 53h-2q-3 0-5-2-3-2-3-6v-6q0-8-8-8h-96q-8 0-8 8v6q0 4-3.5 6.5T190 462q-50-16-87-53t-53-87q-1-3 1.5-6.5T58 312h6q8 0 8-8v-96q0-8-8-8h-6q-4 0-6.5-3.5T50 190q22-66 78-108 57-42 128-42t128 42q56 42 78 108 1 3-1.5 6.5T454 200h-6q-8 0-8 8v96q0 8 8 8h6q4 0 6.5 3.5zM69 185q8 1 13 7h92q31-40 82-40t82 40h92q5-6 13-7-21-55-68-90-53-39-119-39T137 95q-47 35-68 90zm116 258q1-8 7-13v-86q0-10-7-17t-17-7H82q-5 6-13 7 15 40 45.5 70.5T185 443zm23-19h96v-80q0-8 3-15.5t8.5-13 13-8.5 15.5-3h80v-96h-90q-4 0-7-3-26-37-71-37t-71 37q-3 3-7 3H88v96h80q17 0 28.5 11.5T208 344v80zm119 19q40-15 70.5-45.5T443 327q-8-1-13-7h-86q-10 0-17 7t-7 17v86q6 5 7 13zM120 248q8 0 8 8t-8 8h-16q-8 0-8-8t8-8h16zm48 0q8 0 8 8t-8 8h-16q-8 0-8-8t8-8h16zm-32 16q8 0 8 8v16q0 8-8 8t-8-8v-16q0-8 8-8zm0-16q-8 0-8-8v-16q0-8 8-8t8 8v16q0 8-8 8zm240 48q-12 0-12-12t12-12 12 12-12 12zm0-56q-12 0-12-12t12-12 12 12-12 12zm28 28q-12 0-12-12t12-12 12 12-12 12zm-56 0q-12 0-12-12t12-12 12 12-12 12zm-108 76q8 0 8 8t-8 8h-16q-8 0-8-8t8-8h16zm48 0q8 0 8 8t-8 8h-16q-8 0-8-8t8-8h16zm-32-128q17 0 28.5 11.5T296 256q0 8-8 8t-8-8q0-10-7-17t-17-7q-8 0-8-8t8-8z',
      fill: 'currentColor'
    }
  ],
  name: 'car-steering-wheel',
  type: 'light'
}
