import { CIcon } from '~/icons/types'
export const ciCar: CIcon = {
  width: 512,
  height: 221.3,
  paths: [
    {
      d:
        'M459.6 78.1c-25-9.9-58.5-15.7-99.7-17.4-5.9-4.4-11.4-8.7-16.8-13C312.1 23.2 282.7 0 212 0 134 0 105.3 20.1 72.1 43.4c-4.9 3.4-9.9 6.9-15.2 10.5l-25.2 7.6c-13.1 3.9-22.8 17-24 32.4-.3 3.5-.3 7.2-.4 11.1 0 2.1-.1 4.3-.1 6.6-4.7 5.5-6.5 13.6-6.5 20.1v22.7c0 20.2 11.8 33.8 29.3 33.8h32.3c8.5 19.5 27.9 33.1 50.5 33.1 22.5 0 41.9-13.6 50.5-33h181.9c8.5 19.4 27.9 33 50.5 33s42-13.6 50.5-33.1c.5.1 1 .1 1.5.1 2.9 0 18.3-.1 32.6-2.6 7.7-1.3 31-5.5 31-23v-23.3c-.3-18.7-9.2-44.5-51.7-61.3zM325.9 60.9H230l-6.4-39.7c52.2 2.1 75.5 18.6 102.3 39.7zM202.3 21.1l6.4 39.8h-52.4c-9.7 0-19.4-3.7-27.1-10.4l-12.4-10.7c20.3-10.6 44.8-17.7 85.5-18.7zm-89.6 179.1c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.3 34.1-34.3 34.1 15.5 34.1 34.3c0 18.8-15.3 34.1-34.1 34.1zm282.8 0c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.5 34.1-34.5 34.1 15.7 34.1 34.5-15.3 34.1-34.1 34.1zm94.7-39.4h-.2c-1.7 1-6.5 3.2-18.2 4.8-8.5 1.2-16.8 1.5-21.3 1.6v-1.1c0-30.4-24.7-55.1-55.1-55.1s-55.1 24.7-55.1 55.1v1.1H167.7v-1.1c0-30.4-24.7-55.1-55.1-55.1s-55.1 24.7-55.1 55.1v1H29.9c-6.9 0-8.3-7-8.3-12.8v-22.7c0-2.6.8-5.2 1.3-6.2 2.9-1.8 4.8-4.9 5-8.3.3-4.3.3-8.3.4-11.9 0-3.5.1-6.8.3-9.7.5-6.8 4.3-12.5 9.1-14l26.7-8c1-.3 1.9-.7 2.8-1.3 6-4 11.6-7.9 17-11.7C88.9 57.2 93.4 54 98 51l17.6 15.3c11.6 10 26.1 15.6 40.9 15.6h199.8c.7 0 1.4-.1 2.1-.2 41.5 1.7 131.8 10.8 131.8 57.6v21.5z',
      fill: '#1976D2'
    },
    {
      d:
        'M358.4 81.7c-.7.1-1.4.2-2.1.2H156.5c-14.8 0-29.3-5.6-40.9-15.6L98 51c-4.6 3-9.1 6.2-13.8 9.5-5.4 3.8-11 7.7-17 11.7-.9.6-1.8 1-2.8 1.3l-26.7 8c-4.8 1.5-8.6 7.2-9.1 14-.2 2.9-.3 6.2-.3 9.7-.1 3.6-.1 7.6-.4 11.9-.2 3.4-2.1 6.5-5 8.3-.5 1-1.3 3.6-1.3 6.2v22.7c0 5.8 1.4 12.8 8.3 12.8h27.6v-1c0-30.4 24.7-55.1 55.1-55.1s55.1 24.7 55.1 55.1v1.1h172.6v-1.1c0-30.4 24.7-55.1 55.1-55.1s55.1 24.7 55.1 55.1v1.1c4.5-.1 12.8-.4 21.3-1.6 11.7-1.6 16.5-3.8 18.2-4.8h.2v-21.5c0-46.8-90.3-55.9-131.8-57.6z',
      fill: '#91D4F3'
    },
    {
      d:
        'M112.7 200.2c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.3 34.1-34.3 34.1 15.5 34.1 34.3c0 18.8-15.3 34.1-34.1 34.1zm282.8 0c-18.8 0-34.1-15.3-34.1-34.1s15.3-34.5 34.1-34.5 34.1 15.7 34.1 34.5-15.3 34.1-34.1 34.1z',
      fill: '#91D4F3'
    }
  ],
  name: 'car',
  type: 'duotone'
}
