import { computed, ComputedRef, ref, Ref } from '@nuxtjs/composition-api'
import { PaginationAppearance, PaginationItem } from '~/models/pagination'
import { range } from '~/utils/array'

export function usePagination(
  page: Ref<number>,
  count: Ref<number> = ref(1),
  boundaryCount: Ref<number> = ref(1),
  siblingCount: Ref<number> = ref(1),
  siblingOffset: Ref<number> = ref(2),
  disabled: Ref<boolean> = ref(false),
  appearance: Ref<PaginationAppearance> = ref(PaginationAppearance.BUTTONS)
) {
  const startPages = computed(() =>
    range(1, Math.min(boundaryCount.value, count.value))
  )
  const endPages = computed(() =>
    range(
      Math.max(count.value - boundaryCount.value + 1, boundaryCount.value + 1),
      count.value
    )
  )
  const siblingsStart = computed(() =>
    Math.max(
      Math.min(
        // Natural start
        page.value - siblingCount.value,
        // Lower boundary when page is high
        count.value -
          boundaryCount.value -
          siblingCount.value * siblingOffset.value -
          1
      ),
      // Greater than startPages
      boundaryCount.value + 2
    )
  )
  const siblingsEnd = computed(() =>
    Math.min(
      Math.max(
        // Natural end
        page.value + siblingCount.value,
        // Upper boundary when page is low
        boundaryCount.value + siblingCount.value * 2 + siblingOffset.value
      ),
      // Less than endPages
      endPages.value.length > 0 ? endPages.value[0] - 2 : count.value - 1
    )
  )

  const items: ComputedRef<PaginationItem[]> = computed(() => [
    ...startPages.value,
    ...(siblingsStart.value > boundaryCount.value + 2
      ? ['ellipsis']
      : boundaryCount.value + 1 < count.value - boundaryCount.value
      ? [boundaryCount.value + 1]
      : []),
    // Sibling pages
    ...range(siblingsStart.value, siblingsEnd.value),
    // End ellipsis
    ...(siblingsEnd.value < count.value - boundaryCount.value - 1
      ? ['ellipsis']
      : count.value - boundaryCount.value > boundaryCount.value
      ? [count.value - boundaryCount.value]
      : []),
    ...endPages.value
  ]) as any

  const canGoToPreviousPage = computed(() => page.value - 1 > 0)
  const canGoToNextPage = computed(() => page.value + 1 <= count.value)

  const enabledChevronClasses = computed(() => {
    return ['tw-text-grey-600', 'hover:tw-bg-grey-50']
  })
  const disabledChevronClasses = computed(() => {
    return [
      'tw-text-grey-300',
      '!tw-cursor-not-allowed',
      'tw-pointer-events-none'
    ]
  })
  const chevronClasses = computed(() => {
    switch (appearance.value) {
      case PaginationAppearance.BUTTONS:
      default: {
        return [
          'tw-relative',
          'tw-inline-flex',
          'tw-items-center',
          'tw-px-3.5',
          'tw-py-2',
          'tw-border',
          'tw-border-grey-300',
          'tw-bg-white',
          'tw-text-base',
          'lg:tw-text-sm',
          'tw-font-medium',
          'tw-border-solid'
        ]
      }
      case PaginationAppearance.PLAIN: {
        return [
          'tw-relative',
          'tw-inline-flex',
          'tw-items-center',
          'tw-px-5',
          'tw-py-2',
          'tw-text-base',
          'lg:tw-text-sm',
          'tw-font-medium',
          'tw-mx-1',
          'lg:tw-mx-0',
          'tw-border',
          'tw-border-solid',
          'tw-border-grey-300',
          'tw-rounded-md'
        ]
      }
    }
  })

  const previousItemClasses = computed(() => {
    const stateClasses = () => {
      if (canGoToPreviousPage.value && !disabled.value) {
        return enabledChevronClasses.value
      }
      return disabledChevronClasses.value
    }

    const appearanceClasses = () => {
      if (appearance.value === PaginationAppearance.BUTTONS) {
        return ['tw-rounded-l-md']
      }
      return []
    }

    return [...chevronClasses.value, ...stateClasses(), ...appearanceClasses()]
  })
  const nextItemClasses = computed(() => {
    const stateClasses = () => {
      if (canGoToNextPage.value && !disabled.value) {
        return enabledChevronClasses.value
      }
      return disabledChevronClasses.value
    }
    const appearanceClasses = () => {
      if (appearance.value === PaginationAppearance.BUTTONS) {
        return ['tw-rounded-r-md']
      }
      return []
    }
    return [...chevronClasses.value, ...stateClasses(), ...appearanceClasses()]
  })

  function itemIsActive(item: PaginationItem) {
    return page.value === item
  }

  function getNumericItemClasses(item: PaginationItem) {
    switch (appearance.value) {
      case PaginationAppearance.BUTTONS:
      default: {
        if (itemIsActive(item)) {
          return [
            'tw-z-10',
            'tw-bg-primary',
            'tw-border',
            'tw-border-solid',
            'tw-text-white',
            'tw-border-primary-500',
            'hover:tw-text-white',
            'tw-font-medium',
            ...(disabled.value
              ? ['tw-opacity-60', 'tw-pointer-events-none']
              : [''])
          ]
        }
        return [
          'tw-bg-white',
          'tw-border',
          'tw-border-solid',
          'hover:tw-bg-grey-50',
          'hover:tw-text-grey-600',
          'tw-border-grey-300',
          'tw-text-grey-600',
          'tw-font-medium',
          ...(disabled.value
            ? ['tw-text-grey-400', 'tw-pointer-events-none']
            : [''])
        ]
      }
      case PaginationAppearance.PLAIN: {
        if (itemIsActive(item)) {
          return [
            'tw-z-10',
            'tw-text-primary',
            'tw-font-bold',
            'tw-bg-white',
            'tw-rounded',
            'tw-mx-1',
            'lg:tw-mx-0',
            'tw-shadow-sm',
            ...(disabled.value
              ? ['tw-opacity-60', 'tw-pointer-events-none']
              : [''])
          ]
        }
        return [
          'hover:tw-text-primary',
          'hover:tw-bg-grey-50',
          'tw-text-grey-600',
          'tw-font-medium',
          'tw-mx-1',
          'lg:tw-mx-0',
          ...(disabled.value
            ? ['tw-text-grey-400', 'tw-pointer-events-none']
            : [''])
        ]
      }
    }
  }

  const ellipsisClasses = computed(() => {
    switch (appearance.value) {
      case PaginationAppearance.BUTTONS:
      default: {
        return [
          'tw-border',
          'tw-border-solid',
          'tw-border-grey-300',
          'tw-bg-white',
          'tw-text-sm',
          'tw-font-medium',
          'tw-text-grey-700'
        ]
      }
      case PaginationAppearance.PLAIN: {
        return ['tw-text-sm', 'tw-font-medium', 'tw-text-grey-700']
      }
    }
  })

  return {
    canGoToPreviousPage,
    canGoToNextPage,
    items,
    previousItemClasses,
    nextItemClasses,
    getNumericItemClasses,
    ellipsisClasses
  }
}
