







































import CPrice from '~/components/shared/configurable/CPrice.vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

interface LeasingOptionProperty {
  value: string
  suffix: string
}

export default defineComponent({
  components: { CPrice },
  props: {
    downpayment: {
      type: Object as PropType<LeasingOptionProperty>,
      required: true
    },
    duration: {
      type: Object as PropType<LeasingOptionProperty>,
      required: true
    },
    isWithVat: {
      type: Boolean,
      default: false
    },
    lease: {
      type: Object as PropType<LeasingOptionProperty>,
      required: true
    },
    mileage: {
      type: Object as PropType<LeasingOptionProperty>,
      required: true
    },
    hideExtraInfo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasDownpayment() {
      return this.downpayment.value !== '0'
    },
    vatText(): string {
      return (this.isWithVat ? this.$t('with vat') : this.$t('without vat'))
        .toString()
        .toLowerCase()
    }
  }
})
