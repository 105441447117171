import { CIcon } from '~/icons/types'
export const ciSeat: CIcon = {
  name: 'seat',
  type: 'solid',
  width: 1045,
  height: 1024,
  paths: [
    {
      d:
        'M18 5Q-2 12 .5 42.5T30 154q20 64 32 80.5t33 8.5q14-3 31-6l28-6q40-5 47.5-28.5T187 128q-12-28-27-53.5T136 38Q113 17 77.5 6.5T18 5zm81 265q-18 5-5 69.5T172 628q42 143 71 240t33 104q2 1 9-12t15-35.5 25.5-51.5 37.5-51q42-39 43-87t-38-180q-39-141-65.5-201.5T247 276q-34-21-77-23t-71 17zm635 472q-3 2-25 5.5t-54 6.5q-96 11-161 32t-97.5 53.5T340 919t-25 80q4 25 42 22.5t61-30.5q6-21 48.5-26t188-5 188 5 48.5 26q36 48 82.5 24t50.5-92q1-91-46.5-134.5T828 742q-38-2-64-1.5t-30 1.5z',
      fill: 'currentColor'
    }
  ]
}
