








import { mapActions, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CViewTypeSelector from '~/components/shared/configurable/CViewTypeSelector.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    CViewTypeSelector
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      modifier: state => state.config.settings.modifier,
      searchName: state => state.config.settings.searchName,
      viewType: state => state.viewType
    })
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      viewTypeChanged: 'viewTypeChanged'
    })
  }
})
