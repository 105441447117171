import { Option } from '~/models/shared/types'

export interface TreeSelectOption {
  name: string
  value: number
  count: number
  children: TreeSelectOption[]
}

export interface OptionGroup {
  label: string
  options: Option[]
}

export enum DropdownDirection {
  Below = 'below',
  Above = 'above'
}

export enum KYCDocumentTypes {
  IDENTITY_PROOF = 'IDENTITY_PROOF',
  REGISTRATION_PROOF = 'REGISTRATION_PROOF',
  ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION'
}
