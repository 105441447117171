import { CIcon } from '~/icons/types'
export const ci360Logo: CIcon = {
  name: '360Logo',
  type: 'solid',
  width: 461,
  height: 265,
  paths: [
    {
      d:
        'M381.43 2.48c13.26-3.27 28.34 6.91 29.53 20.64 1.2 11.3-6.6 22.71-17.9 24.96-12.23 3.93-28.13-3.77-30.07-17.07-3.86-12.4 5.99-26.31 18.44-28.53m.95 11.88c-6.06 2.07-9.15 9.24-7 15.24 1.02 6.74 9.78 10.52 15.67 7.47 8.1-2.61 8.26-14.04 3.78-19.89-2.91-3.71-8.26-3.78-12.45-2.82zm-70.01-8.97c13.47-.33 26.65 7.29 32.81 19.36 8.28 16.55 9.08 35.98 5.48 53.89-1.24 7.86-4.68 15.27-9.3 21.71-13.76 18.56-46.93 17.23-58.31-3.34-8.21-15.11-10.12-33.16-8.17-50.04 2.43-14 8.03-29.62 21.16-36.92 4.89-3.1 10.65-4.16 16.33-4.66m-1.04 18.96c-5.55.86-7.9 6.7-9.79 11.25-4.41 14.03-3.88 29.09-1.43 43.42.46 4.88 2.57 9.47 5.61 13.26 3.89 4.06 11.68 4.58 15.05-.5 7.21-10.45 6.7-23.8 6.56-35.9-.82-8.68-.36-18.25-5.42-25.82-1.95-3.79-6.38-6.16-10.58-5.71zM105.78 14.11c12.59-5.95 26.84-7.79 40.62-6.52 7.44.69 14.36 4.21 19.93 9.08 6.96 7.33 8.93 19.49 3.53 28.24-3 6.65-9.63 9.6-15.85 12.28 7.76 2.5 15.6 6.79 19.74 14.07 3.86 8.38 3.7 19.06-1.9 26.65-7.97 12.4-23.83 16.89-37.84 16.25-10.73.55-21.49-1.83-30.94-6.9 1.77-6.14 3.51-12.29 5.23-18.44 9.9 3.47 20.21 7.62 30.9 5.52 4.52-1.47 9.88-3.81 11.41-8.76.31-4.05 1.51-9.16-1.88-12.33-6.77-8-18.47-6.29-27.71-7.53-.01-5.82-.01-11.64-.02-17.46 5.31-.01 10.61-.19 15.9-.53 4.63-.78 8.03-4.36 11.78-6.88-.22-5.11-1.31-11.74-7-13.54-10.33-3.07-21.55-.43-30.92 4.42-1.61-5.89-3.32-11.75-4.98-17.62zm100.01 5.68C219.11 9.33 236.48 6.4 253.01 6.8c-.02 5.94-.02 11.88-.03 17.82-11.23.6-23.42 1.54-32.65 8.75-5.63 3.87-8.2 10.42-10.71 16.49 7.65-5.13 16.73-8.42 26.08-7.65 12.79 1.19 24.86 10.9 27.32 23.75 3.83 14.43-.71 31.08-12.99 40.06-18.49 14.66-49.73 8.77-60.06-13.01-10.79-24.23-4.95-55.99 15.82-73.22m13.66 40.71c-13.06 3.47-13.58 22.56-4.78 30.83 5.15 6.32 16.54 6.6 21.02-.61 4.79-5.52 3.47-13.49 2.7-20.09-1.58-8.18-11.15-12.88-18.94-10.13z'
    },
    {
      d:
        'M45.98 82.95c15.94-9.32 33.46-15.44 50.96-21.08.01 3.74.01 11.21.02 14.95-12.13 6.15-24.56 13.38-31.93 25.22-3.37 5.02-3.46 11.6-2.48 17.35 5.57 15.62 21.33 23.97 35.47 30.54 30.13 13.25 63.21 17.86 95.75 20.63-.56-7.96-1.34-15.91-1.81-23.88 32.44 16.53 65.15 32.53 97.29 49.61-23.52 18.47-47.12 36.83-70.58 55.37-5.21 3.93-10.18 8.22-15.79 11.59-.95-8.09-1.77-16.19-2.82-24.27-51.95-3.51-104.84-12.94-151.04-38.06-13.88-8.19-27.54-17.6-37.25-30.7-12.31-15.02-14.78-37.77-3.68-54.13 8.79-14.73 23.31-24.76 37.89-33.14zm322.06-17.29c24.44 7.88 48.33 19.41 67.2 37.1 14.13 13.33 21.39 34.97 14.15 53.59-8.04 18.69-24.32 32.41-41.5 42.47-30.25 17.52-64.28 27.56-98.57 33.43-1.37-22.42-4.5-44.66-6.26-67.05 25.07-4.95 50.5-11.72 71.87-26.3 9.79-6.46 18.59-17.32 16.89-29.77-2.85-12.24-13.59-20.18-23.69-26.48-.04-5.67-.07-11.33-.09-16.99z'
    }
  ]
}
