import { render, staticRenderFns } from "./PeriodSearchHandler.vue?vue&type=template&id=4f98f419&scoped=true&"
import script from "./PeriodSearchHandler.vue?vue&type=script&lang=ts&"
export * from "./PeriodSearchHandler.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f98f419",
  null
  
)

export default component.exports