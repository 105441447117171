import { CIcon } from '~/icons/types'
export const ciSportsCar: CIcon = {
  name: 'sports-alt',
  type: 'regular',
  width: 512,
  height: 185.8,
  paths: [
    {
      d:
        'M509.3 142.9c-.9-1.6-22.3-38.8-65.1-42.5h-1.3C433.3 80.6 413 67 389.6 67c-11.5 0-22.3 3.3-31.4 9-38-15.8-54.6-31.7-54.7-31.8-3.5-3.4-8.8-4-13-1.4l-33.3 20.9c-5.8 3.6-12.6 4.1-18.8 1.3-6.2-2.8-10.4-8.3-11.4-15l-5.5-34.7c-.9-5.7-6.2-9.6-11.9-8.7l-29 4.3c-31.8 4.7-62.2 16.4-88.9 34.1l9.8-31.1c1-3.2.4-6.7-1.5-9.4S94.7 0 91.3 0H11.8c-4.2 0-8 2.5-9.7 6.4-1.6 3.9-.8 8.4 2.1 11.4l13.3 13.7C28.7 43 34.9 58.1 35 74.1l.1 7.8C22.7 92.8 15 108.7 15 126.4c0 32.8 26.7 59.4 59.4 59.4 20.9 0 39.3-10.9 49.9-27.2h215.3c10.6 16.4 29 27.2 49.9 27.2s39.3-10.9 49.9-27.2h60.7c3.7 0 7.2-2 9.1-5.2 1.9-3.3 1.9-7.2.1-10.5zM36.4 21H77L62.1 68.2c-2.1.5-4.2 1-6.3 1.7-1-18-7.8-35.1-19.4-48.9zm38 143.8c-21.2 0-38.4-17.2-38.4-38.4S53.2 88 74.4 88s38.4 17.2 38.4 38.4-17.2 38.4-38.4 38.4zm57.6-52.9h68.1c20.3 0 39.2 9.7 51.1 25.7H132.7c.7-3.6 1.1-7.4 1.1-11.2 0-5-.6-9.9-1.8-14.5zm143.9 25.7-1.2-2.3c-14.8-27.4-43.4-44.4-74.5-44.4H122c-7.3-9.8-17.6-17.2-29.5-21.1 26.6-20.2 57.9-33.5 91-38.4l18.7-2.8 3.9 24.5c2.2 13.6 11 25.1 23.5 30.8 12.5 5.7 27 4.7 38.7-2.6l26.8-16.8c7.8 6.1 22.7 16.4 46.3 27-7.1 9.8-11.3 21.8-11.3 34.8 0 3.8.4 7.6 1.1 11.2h-55.3zm113.7 27.2c-21.2 0-38.4-17.2-38.4-38.4S368.4 88 389.6 88s38.4 17.2 38.4 38.4-17.3 38.4-38.4 38.4zm58.3-27.2c.7-3.6 1.1-7.4 1.1-11.2 0-1.4-.1-2.8-.2-4.1 12.3 2.6 22 9 29 15.3h-29.9z'
    }
  ]
}
