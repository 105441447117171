import { CIcon } from '~/icons/types'
export const ciBreakfast: CIcon = {
  name: 'breakfast',
  type: 'light',
  width: 480,
  height: 480,
  paths: [
    {
      d:
        'M240 80c-88.366 0-160 71.634-160 160s71.634 160 160 160 160-71.634 160-160c-.101-88.324-71.676-159.899-160-160zm0 304c-79.529 0-144-64.471-144-144S160.471 96 240 96s144 64.471 144 144c-.093 79.491-64.509 143.907-144 144z'
    },
    {
      d:
        'M467.864 49.52a19.585 19.585 0 0 0-21.408 4.256A103.344 103.344 0 0 0 416 127.32V232a24 24 0 0 0 16 22.528V408c0 13.255 10.745 24 24 24s24-10.745 24-24V67.672a19.584 19.584 0 0 0-12.136-18.152zM464 408a8 8 0 0 1-16 0V256h16v152zm0-168h-24a8 8 0 0 1-8-8V127.32a87.45 87.45 0 0 1 25.776-62.224A3.456 3.456 0 0 1 460.264 64a3.923 3.923 0 0 1 1.488.312A3.48 3.48 0 0 1 464 67.68V240z'
    },
    {
      d:
        'M176 184c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm0 48c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16z'
    },
    {
      d:
        'm240.688 189.376-14.064-14.064a8.9 8.9 0 0 1-2.624-6.344c-.018-13.782-11.186-24.95-24.968-24.968h-32a25.126 25.126 0 0 0-17.656 7.312l-30.064 30.064A24.8 24.8 0 0 0 112 199.032v33.936a24.8 24.8 0 0 0 7.312 17.656l14.064 14.064a8.9 8.9 0 0 1 2.624 6.344c.018 13.782 11.186 24.95 24.968 24.968h16a25.13 25.13 0 0 0 17.656-7.312l22.064-22.064a9.048 9.048 0 0 1 6.344-2.624c13.782-.018 24.95-11.186 24.968-24.968v-32a24.8 24.8 0 0 0-7.312-17.656zM232 239.032a8.976 8.976 0 0 1-8.968 8.968 25.126 25.126 0 0 0-17.656 7.312l-22.064 22.064a9.048 9.048 0 0 1-6.344 2.624h-16a8.976 8.976 0 0 1-8.968-8.968 24.8 24.8 0 0 0-7.312-17.656l-14.064-14.064a8.9 8.9 0 0 1-2.624-6.344v-33.936a8.905 8.905 0 0 1 2.624-6.344l30.064-30.064a9.048 9.048 0 0 1 6.344-2.624h32a8.976 8.976 0 0 1 8.968 8.968 24.8 24.8 0 0 0 7.312 17.656l14.064 14.064a8.9 8.9 0 0 1 2.624 6.344v32z'
    },
    {
      d:
        'm317.656 258.344-32-32A8 8 0 0 0 272 232v16a8 8 0 0 1-8 8c-13.255 0-24 10.745-24 24a8 8 0 0 1-8 8c-13.255 0-24 10.745-24 24a8 8 0 0 1-8 8h-16a8 8 0 0 0-5.656 13.656l32 32A8 8 0 0 0 216 368h16c13.255 0 24-10.745 24-24a8 8 0 0 1 8-8c13.255 0 24-10.745 24-24a8 8 0 0 1 8-8c13.255 0 24-10.745 24-24v-16a8 8 0 0 0-2.344-5.656zM304 280a8 8 0 0 1-8 8c-13.255 0-24 10.745-24 24a8 8 0 0 1-8 8c-13.255 0-24 10.745-24 24a8 8 0 0 1-8 8h-12.688l-16.2-16.2c11.951-1.563 20.889-11.747 20.888-23.8a8 8 0 0 1 8-8c13.255 0 24-10.745 24-24a8 8 0 0 1 8-8c12.053.001 22.237-8.937 23.8-20.888l16.2 16.2V280z'
    },
    {
      d:
        'm359.032 195.712-1.376-1.368-40-40-33.376-33.368c-11.963-11.958-31.354-11.955-43.312.008-11.958 11.962-11.954 31.354.008 43.312l1.376 1.368 40 40 33.376 33.368c11.963 11.958 31.354 11.954 43.312-.008 11.958-11.963 11.955-31.354-.008-43.312zm-105.768-64.32a14.63 14.63 0 0 1 9.328-3.392h.032a14.754 14.754 0 0 1 10.344 4.28l19.72 19.72h-41.296c-5.174-6.208-4.336-15.434 1.872-20.608zM267.312 168h41.376l24 24h-41.376l-24-24zm79.424 60.608a14.63 14.63 0 0 1-9.328 3.392h-.032a14.737 14.737 0 0 1-10.344-4.288L307.312 208h41.296c5.174 6.208 4.336 15.434-1.872 20.608z'
    },
    {
      d:
        'M64 48v64H48V48H32v64H16V48H0v104a39.831 39.831 0 0 0 16 31.792V408c0 13.255 10.745 24 24 24s24-10.745 24-24V183.792A39.831 39.831 0 0 0 80 152V48H64zM48 408a8 8 0 0 1-16 0V192h16v216zm16-256c0 13.255-10.745 24-24 24s-24-10.745-24-24v-24h48v24z'
    }
  ]
}
