var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"tw-mt-4",class:{ 'tw-flex tw-justify-center tw-items-center': _vm.showFullAd }},[(_vm.isPc)?[(_vm.showFullAd)?[(_vm.showAdmanAds)?_c('CAdmanAd',{attrs:{"ad":{
          ws: '513',
          size: '970x250',
          passbackId: 'gpt-passback-13608'
        }}}):_c('CGoogleAd',{attrs:{"ad":_vm.fullAd,"key-values":_vm.adsKeyValues}})]:[_c('CRow',[_c('CCol',{staticClass:"bottom-ad-container",attrs:{"cols":"12","md":"6"}},[(_vm.showAdmanAds)?_c('CAdmanAd',{attrs:{"ad":{
              ws: '512',
              size: '320x250',
              passbackId: 'gpt-passback-13617'
            }}}):_c('CPosting',{attrs:{"ad":_vm.leftAd,"key-values":_vm.adsKeyValues,"unit":"in_house_search_dt_btm1_gal"}})],1),_vm._v(" "),_c('CCol',{staticClass:"bottom-ad-container",attrs:{"cols":"12","md":"6"}},[(_vm.showAdmanAds)?_c('CAdmanAd',{attrs:{"ad":{
              ws: '510',
              size: '320x250',
              passbackId: 'gpt-passback-137313'
            }}}):_c('CPosting',{attrs:{"ad":_vm.rightAd,"key-values":_vm.adsKeyValues,"unit":"in_house_search_dt_btm2_gal"}})],1)],1)]]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }