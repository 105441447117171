import { CIcon } from '~/icons/types'
export const ciElevator: CIcon = {
  width: 1020,
  height: 1000,
  paths: [
    {
      d:
        'M950 0H50Q29 0 14.5 14.5T0 50v900q0 21 14.5 35.5T50 1000h900q21 0 35.5-14.5T1000 950V50q0-21-14.5-35.5T950 0zM483 967H233V250q0-8 5-12.5t12-4.5h233v734zm34 0V233h233q8 0 12.5 4.5T767 250v717H517zm450-17q0 7-4.5 12t-12.5 5H800V250q0-21-14.5-35.5T750 200H250q-21 0-35.5 14.5T200 250v717H50q-7 0-12-5t-5-12V50q0-7 5.5-12T50 33h900q8 0 12.5 4.5T967 50v900zm-50-433h-67q-8 0-12.5 4.5T833 533v134q0 7 4.5 11.5T850 683h67q8 0 12-4.5t4-11.5V533q0-8-4-12t-12-4zm-50 33h33v33h-33v-33zm33 100h-33v-33h33v33zM617 67H383q-7 0-11.5 4T367 83v67q0 8 4.5 12.5T383 167h234q7 0 11.5-4.5T633 150V83q0-8-4-12t-12-4zm-17 66H400v-33h200v33z',
      fill: 'currentColor'
    }
  ],
  name: 'elevator',
  type: 'regular'
}
