import { CIcon } from '~/icons/types'
export const ciFullScreen: CIcon = {
  name: 'full-screen',
  type: 'solid',
  width: 472.615,
  height: 472.615,
  paths: [
    {
      d:
        'M0 0v472.615h472.615V0H0zm406.445 220.038H387.54V98.46L239.835 246.166 226.468 232.8 374.175 85.093H252.596V66.189h153.849v153.849z',
      fill: 'currentColor'
    }
  ]
}
