import { CIcon } from '~/icons/types'
export const ciMap: CIcon = {
  name: 'map',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M256 75c-24.813 0-45 20.188-45 45s20.188 45 45 45 45-20.188 45-45-20.188-45-45-45zm0 0'
    },
    {
      d:
        'M256 0c-66.168 0-120 53.832-120 120 0 22.691 6.371 44.797 18.43 63.926L256 346l101.57-162.074C369.63 164.796 376 142.69 376 120 376 53.832 322.168 0 256 0zm0 195c-41.352 0-75-33.648-75-75s33.648-75 75-75 75 33.648 75 75-33.648 75-75 75zm-73.004 317h145.957l-11.535-91H194.242zm14.996-121h50.914l-42.488-67.387zm145.836 0h118.176l-37.5-90h-92.219zm-293.832 0h117.766l11.25-90H87.496zm213.098 0h50.476l-8.527-66.523zm-99.082 30H37.496L0 512h152.766zm310.492 0H347.672l11.539 91H512zm0 0'
    }
  ]
}
