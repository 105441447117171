import { CIcon } from '~/icons/types'
export const ciEsp: CIcon = {
  width: 512,
  height: 398.68,
  paths: [
    {
      d:
        'M510.22 171.65a228.39 228.39 0 0 0-6.4-32 228.65 228.65 0 0 0-11-30.1 218.23 218.23 0 0 0-32.05-51.18q-2.28-2.76-4.66-5.43a12.05 12.05 0 0 0-20.57 8.52 13.26 13.26 0 0 0 3.52 8.54q4.24 4.8 8.19 9.84a209.67 209.67 0 0 1 25.43 43.5 208.1 208.1 0 0 1 13.67 50.27 212 212 0 0 1 0 51.83 208.26 208.26 0 0 1-13.65 50.16 209.13 209.13 0 0 1-25.53 43.66c-2.38 3-4.82 6-7.36 8.89a12.78 12.78 0 0 0-3.53 8.52 12.27 12.27 0 0 0 3.53 8.52 12.14 12.14 0 0 0 8.52 3.53c3.08-.14 6.43-1.13 8.53-3.53a220.57 220.57 0 0 0 33.52-50.44 217.45 217.45 0 0 0 18.62-59 221.05 221.05 0 0 0 1.22-64.1ZM64.71 319.11a209.19 209.19 0 0 1-25.5-43.65 208.27 208.27 0 0 1-13.62-50.23 211.44 211.44 0 0 1 0-51.58 208.19 208.19 0 0 1 13.65-50.27 208.77 208.77 0 0 1 25.41-43.52q4-5.07 8.24-9.91a12.73 12.73 0 0 0 3.53-8.52 12.05 12.05 0 0 0-12-12c-3.08.14-6.4 1.14-8.52 3.53a220 220 0 0 0-34 50.71A217.2 217.2 0 0 0 3 163a224.16 224.16 0 0 0-1 65.83 219.1 219.1 0 0 0 6.29 30.85 228.16 228.16 0 0 0 11.24 30.48 219.17 219.17 0 0 0 32.61 51.54q2.32 2.76 4.73 5.44a12.06 12.06 0 1 0 17.05-17.05q-4.8-5.32-9.21-10.98Z'
    },
    {
      d:
        'M455.32 199.35a199.65 199.65 0 0 0-9.56-61.12 197.24 197.24 0 0 0-26.67-53.42 206.64 206.64 0 0 0-43-44.49 199 199 0 0 0-273.28 31.51A206.3 206.3 0 0 0 71 125.08a199.34 199.34 0 0 0 101.89 255.45 205 205 0 0 0 61.17 16.89 199.3 199.3 0 0 0 200.12-108.7 196.36 196.36 0 0 0 18.55-57.61 211.26 211.26 0 0 0 2.59-31.76Zm-25.58 23.47a187.59 187.59 0 0 1-12.16 44.61 188.93 188.93 0 0 1-22.78 39 190.63 190.63 0 0 1-31.74 31.74 189.42 189.42 0 0 1-39 22.79 188 188 0 0 1-44.61 12.15 191.08 191.08 0 0 1-46.95 0A187.83 187.83 0 0 1 187.9 361a189.49 189.49 0 0 1-39-22.79 190.63 190.63 0 0 1-31.74-31.74 188.93 188.93 0 0 1-22.78-39 188.54 188.54 0 0 1-12.16-44.61 191.08 191.08 0 0 1 0-46.95 188.46 188.46 0 0 1 12.16-44.6 188.67 188.67 0 0 1 22.78-39 190.25 190.25 0 0 1 31.74-31.74 189 189 0 0 1 39-22.79 188.46 188.46 0 0 1 44.6-12.16 192.07 192.07 0 0 1 46.95 0 188.54 188.54 0 0 1 44.61 12.16 189 189 0 0 1 39 22.79 190.25 190.25 0 0 1 31.74 31.7 188.67 188.67 0 0 1 22.78 39 187.59 187.59 0 0 1 12.16 44.61 186.83 186.83 0 0 1 0 46.94Z'
    },
    {
      d:
        'M193.1 249.43a46.21 46.21 0 0 0-8.8-.54h-48.47v-41.28h42.87q9.53 0 11.8-4a15.5 15.5 0 0 0 2.26-7.49 12.13 12.13 0 0 0-2-7 8 8 0 0 0-4.31-3.54 32.88 32.88 0 0 0-7.79-.64h-42.83v-35.15h46.73a39.45 39.45 0 0 0 8.31-.59 7.26 7.26 0 0 0 4.28-3.61 13.47 13.47 0 0 0 1.85-7.17 13 13 0 0 0-1.89-7.18 7.49 7.49 0 0 0-4.24-3.51 39.49 39.49 0 0 0-8.31-.59h-50.29q-11 0-13.15 2.12a44.83 44.83 0 0 0-3.86 4.38q-1.74 2.27-1.74 18v95.45q0 14.38 1.48 17.14a29.38 29.38 0 0 0 3.36 5q1.89 2.27 13.91 2.26h52a36 36 0 0 0 8.39-.69 7.65 7.65 0 0 0 4.42-3.76 14.73 14.73 0 0 0 .08-14.15q-1.75-2.89-4.06-3.46Zm92.52-55.61q-8.1-5.7-24.81-10.89T240 173.15a15.52 15.52 0 0 1-4.12-10.71 15.23 15.23 0 0 1 5.6-12.34 20.86 20.86 0 0 1 13.76-4.64q14.07 0 19.81 15a47.7 47.7 0 0 0 4.76 10.1 7.11 7.11 0 0 0 6.2 3c3 0 5.42-1.44 7.34-4.33a16.51 16.51 0 0 0 2.87-9.26q0-11.82-10-23.54t-29.64-11.72q-19.27 0-30.47 11t-11.19 29.4q0 12.51 4.84 20.88a38.17 38.17 0 0 0 11.34 12.81q6.5 4.42 22.62 9.28t20.64 10.27a19.67 19.67 0 0 1 4.53 13q0 9.77-6.54 15.49a23.59 23.59 0 0 1-16.07 5.72q-16 0-22.16-18.62-2.87-8.76-4.91-12a6.82 6.82 0 0 0-6.2-3.25 8.7 8.7 0 0 0-7.37 3.89 14.58 14.58 0 0 0-2.84 8.81q0 14.88 11 28.76T257 274q20.34 0 32.1-12.8t11.76-33a53.26 53.26 0 0 0-3.59-19.4 32.24 32.24 0 0 0-11.65-14.98Zm77.5-66.68h-27.07q-6.66 0-10 1.57c-2.24 1.06-3.79 3.09-4.65 6.11s-1.29 8.64-1.29 16.84v98.11q0 10.64.72 14.68a12 12 0 0 0 3.63 6.8 9.4 9.4 0 0 0 6.62 2.75 9.87 9.87 0 0 0 8.16-4.08q3.18-4.1 3.18-20.15V215.3h20.72q41.36 0 41.36-45.61 0-18.12-8.58-30.34t-32.8-12.21Zm13.53 59.7q-5.07 5.81-19 5.8H342.4v-42.85h15.27q8.85 0 13.61 1.63t7.6 7.12a27.08 27.08 0 0 1 2.84 12.58q0 9.93-5.07 15.72Z'
    }
  ],
  name: 'esp',
  type: 'regular'
}
