import { CIcon } from '~/icons/types'
export const ciCarDoor: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M503 65q9 0 9 9v364q0 9-9 9H9q-9 0-9-9V239q0-5 4-7L234 67q2-2 5-2h264zm-8 365V82H241L17 243v187h478zm-26-330q8 0 8 8v131q0 8-8 8H78q-6 0-8-6t3-9l173-130q3-2 6-2h217zm-9 130V117H255L104 230h356zm0 69q9 0 9 9t-9 9h-52v43q0 9-8.5 9t-8.5-9v-52q0-9 8-9h61z',
      fill: 'currentColor'
    }
  ],
  name: 'car-door',
  type: 'light'
}
