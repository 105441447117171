import { CIcon } from '~/icons/types'
export const ciOven: CIcon = {
  name: 'oven',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M456.832 0H55.168C24.746 0 0 24.746 0 55.164v73.367h512V55.164C512 24.746 487.254 0 456.832 0zM122.004 74.867a15.073 15.073 0 0 1-10.61 4.399 15.075 15.075 0 0 1-10.597-4.399 15.103 15.103 0 0 1-4.402-10.601c0-3.95 1.609-7.82 4.402-10.61 2.789-2.789 6.648-4.39 10.598-4.39s7.82 1.601 10.609 4.39a15.109 15.109 0 0 1 4.402 10.61c0 3.941-1.61 7.812-4.402 10.601zm96.402 0c-2.789 2.801-6.66 4.399-10.61 4.399a15.084 15.084 0 0 1-10.6-4.399 15.09 15.09 0 0 1-4.391-10.601c0-3.95 1.601-7.82 4.39-10.61a15.11 15.11 0 0 1 10.602-4.39c3.95 0 7.82 1.601 10.61 4.39s4.39 6.66 4.39 10.61a15.11 15.11 0 0 1-4.39 10.601zm96.399 0a15.073 15.073 0 0 1-10.61 4.399 15.082 15.082 0 0 1-10.597-4.399 15.071 15.071 0 0 1-4.403-10.601c0-3.95 1.602-7.82 4.403-10.61a15.087 15.087 0 0 1 10.597-4.39c3.95 0 7.82 1.601 10.61 4.39a15.109 15.109 0 0 1 4.402 10.61c0 3.941-1.61 7.812-4.402 10.601zm96.402 0a15.079 15.079 0 0 1-10.61 4.399 15.084 15.084 0 0 1-10.6-4.399 15.097 15.097 0 0 1-4.4-10.601c0-3.95 1.61-7.82 4.4-10.61a15.09 15.09 0 0 1 10.6-4.39c3.95 0 7.817 1.601 10.61 4.39 2.79 2.79 4.39 6.66 4.39 10.61a15.11 15.11 0 0 1-4.39 10.601zm6.527 162.933H94.266v194.934h323.468zm-49.265 64.266H143.53c-8.281 0-15-6.714-15-15s6.719-15 15-15h224.94c8.281 0 15 6.715 15 15s-6.719 15-15 15zm0 0'
    },
    {
      d:
        'M0 456.832C0 487.254 24.746 512 55.168 512h401.664C487.254 512 512 487.254 512 456.832v-298.3H0zm64.266-234.031c0-8.285 6.718-15 15-15h353.468c8.286 0 15 6.715 15 15v224.933c0 8.286-6.714 15-15 15H79.266c-8.282 0-15-6.714-15-15zm0 0'
    }
  ]
}
