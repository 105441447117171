import { CIcon } from '~/icons/types'
export const ciBoat: CIcon = {
  width: 512,
  height: 288.1,
  paths: [
    {
      d:
        'M39.5 133.9h53.6l-11 19.1 86.9-2.6 72.7-37.4H68.4v-.1H39.5c-5.8 0-10.5 4.7-10.5 10.5s4.7 10.5 10.5 10.5zM323.2 69.4a72.348 72.348 0 0 0-53.5-23.7h-61.5l-8.4-37.5c-1.3-5.7-6.9-9.2-12.5-7.9-5.7 1.3-9.2 6.9-7.9 12.5l7.5 33.4c-16.4 2.3-30.8 12.3-38.6 27.4L136.1 97h212.1l-25-27.6z'
    },
    {
      d:
        'M508.6 118.7c-1.8-3.6-5.4-5.8-9.4-5.8h-20.9v.1H274.5l-101.6 52.3-1.7.1L46 169.1c-1.9.6-3.7 1.7-5 3.3L4.7 216.1c-2.1 2.5-2.9 5.9-2.1 9.1L15.4 280c1.1 4.8 5.3 8.1 10.2 8.1h210c1.1 0 2.4 0 3.8-.1l79.9-6.2c22.8-1.7 47.6-10.2 71.9-24.5 22.6-13.3 43.5-31.2 58.7-50.2.1-.1.1-.2.2-.3l57.6-77.1c2.3-3.1 2.7-7.4.9-11zm-138.9 92.4H229.5c-5.8 0-10.5-4.7-10.5-10.5s4.8-10.5 10.5-10.5h140.2c5.8 0 10.5 4.7 10.5 10.5s-4.7 10.5-10.5 10.5z'
    }
  ],
  name: 'boat',
  type: 'solid'
}
