import { CIcon } from '~/icons/types'
export const ciLightBulb1: CIcon = {
  width: 522,
  height: 512,
  paths: [
    {
      d:
        'M210 449q-9 0-9-8t9-8h93q8 0 8 8t-8 8h-93zm10 31q-8 0-8-8t8-8h72q8 0 8 8t-8 8h-72zm28-110q0 8-7.5 8t-7.5-8q-1-54-6-82-7-34-28-71-3-7 3.5-11t10.5 3q23 40 30 75 5 30 5 86zm31 0q0 8-7.5 8t-7.5-8q0-56 5-86 7-35 30-75 4-7 10.5-3t3.5 11q-21 37-28 71-5 28-6 82zm-75-149q-8-3-5.5-10.5T209 205q5 2 15 7 13 6 17 3l10-8q6-5 11 1t-1 11l-10 8q-12 10-34-1h-1q-8-4-12-5zm60-182q0 8-8 8t-8-8V8q0-8 8-8t8 8v31zm39 166q8-2 10.5 5.5T308 221q-4 1-12 5h-1q-22 11-34 1l-10-8q-6-5-1-11t11-1l10 8q4 3 17-3 10-5 15-7zm54-81q41 42 41 101 0 23-7 44.5T371 309q-15 20-36 43-14 16-19 25.5t-5 28.5q0 8-8 8h-93q-8 0-8-8 0-19-5-28t-19-25h-1q-21-23-36-44-13-18-20-39.5t-7-44.5q0-28 10-54l1-1q11-26 30.5-46t46-30.5T256 83t54.5 10.5T357 124zm25 101q0-52-37-89-18-18-41-27.5T256 99t-48 9q-23 10-41 28-18 17-28 40v1q-9 23-9 48 0 20 6 39t18 35q14 20 35 43 11 12 15.5 18t8.5 15.5 4 22.5h78q1-19 7-29t21-27q21-23 35-43 12-16 18-35t6-39zM230 512q-8 0-8-8t8-8h52q8 0 8 8t-8 8h-52zM354 67q-4 7-11 3t-3-11l16-26q4-7 11-3t3 11zm-182-8q4 7-3 11t-11-3l-16-26q-4-7 3-11t11 3zm-70 64q7 4 3 11t-11 3l-27-15q-7-4-3-11t11-3zm316 14q-7 4-11-3t3-11l27-15q7-4 11 3t-3 11zM173 257q3 7-4.5 10t-10.5-4q-4-10-6-20-2-11-2-21 0-8 8-8t8 8q0 9 1.5 17.5T173 257zm0-71q-3 8-10.5 5t-4.5-11q4-9 10-18 6-8 13-15.5t15.5-13T215 124t20-6.5 21-2.5q8 0 8 8t-8 8q-9 0-18 2t-17 5q-8 4-15 9-7 4-13.5 10.5T181 171t-8 15z',
      fill: 'currentColor'
    }
  ],
  name: 'light-bulb-1',
  type: 'light'
}
