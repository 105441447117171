import { CIcon } from '~/icons/types'
export const ciForklift: CIcon = {
  width: 512,
  height: 381.9,
  paths: [
    {
      d:
        'M363.3 210.5h26.5v82.8h-26.5zm-242.9 81.2c18.9 0 34.2 16.2 34.2 35.1S139.3 361 120.4 361s-34.2-15.3-34.2-34.2 15.3-35.1 34.2-35.1zm153.1 0c18.9 0 34.2 16.2 34.2 35.1S292.4 361 273.5 361s-34.2-15.3-34.2-34.2 15.3-35.1 34.2-35.1z',
      fill: '#91D4F3'
    },
    {
      d:
        'M286.6 236.6c-1.9 3-5.2 4.8-8.8 4.8H171.7c-3.2 0-6.2-1.4-8.2-3.9l-37.5-47H56.4c-17.1 0-31.1 14-31.1 31.1v94.6h40.9c4.9-25.4 27.4-44.7 54.2-44.7 26.9 0 49.3 19.3 54.2 44.7h44.7c4.9-25.4 27.4-44.7 54.2-44.7 26.9 0 49.3 19.3 54.2 44.8v.1h14.6v-11.1c-.1-.5-.1-.9-.1-1.4s0-1 .1-1.4V201.4c-.1-.5-.1-.9-.1-1.4s0-1 .1-1.4v-8.1h-25.9l-29.8 46.1z',
      fill: '#91D4F3'
    },
    {
      d:
        'M400.3 357.6h96.9c5.8 0 10.5-4.7 10.5-10.5s-4.7-10.5-10.5-10.5h-86.4V21.1c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v168.4h-26.5V180c0-5.8-4.7-10.5-10.5-10.5H320l-11-95.7v-.1C303.7 31.7 267.7 0 225.3 0h-60c-24.6 0-44.7 20.1-44.7 44.7v124.8H56.4c-28.7 0-52.1 23.4-52.1 52.1v105.1c0 5.8 4.7 10.5 10.5 10.5h51.4c4.9 25.4 27.3 44.7 54.2 44.7 26.8 0 49.3-19.3 54.2-44.7h44.7c4.9 25.4 27.3 44.7 54.2 44.7 26.8 0 49.2-19.2 54.2-44.6h25.1c5.8 0 10.5-4.7 10.5-10.5v-12.5h26.5v32.8c0 5.8 4.7 10.5 10.5 10.5zM141.6 44.8c-.1-13.1 10.5-23.8 23.6-23.8h60c31.8 0 58.8 23.8 62.9 55.3l11.7 101.3-8.6 13.2-30.6-32 9.3-7c4.6-3.5 5.6-10.1 2.1-14.7-3.5-4.6-10.1-5.6-14.7-2.1l-38.7 29.1c-4.6 3.5-5.6 10.1-2.1 14.7 2.1 2.7 5.2 4.2 8.4 4.2 2.2 0 4.4-.7 6.3-2.1l12.5-9.4 35.8 37.4-7.4 11.4h-95.3l-35.2-44.1V44.8zM120.4 361c-18.9 0-34.2-15.3-34.2-34.2 0-18.9 15.3-35.1 34.2-35.1s34.2 16.2 34.2 35.1-15.3 34.2-34.2 34.2zm153.1 0c-18.9 0-34.2-15.3-34.2-34.2 0-18.9 15.3-35.1 34.2-35.1s34.2 16.2 34.2 35.1-15.3 34.2-34.2 34.2zm68.8-162.4c-.1.4-.1.9-.1 1.4s0 .9.1 1.4v101.1c-.1.4-.1.9-.1 1.4s0 .9.1 1.4v11.1h-14.6v-.1c-4.9-25.5-27.3-44.8-54.2-44.8-26.8 0-49.3 19.3-54.2 44.7h-44.7c-4.9-25.4-27.3-44.7-54.2-44.7-26.8 0-49.3 19.3-54.2 44.7H25.3v-94.6c0-17.1 14-31.1 31.1-31.1H126l37.5 47c2 2.5 5 3.9 8.2 3.9h106.1c3.6 0 6.9-1.8 8.8-4.8l29.8-46.1h25.9v8.1zm21 94.7v-82.8h26.5v82.8h-26.5z',
      fill: '#1976D2'
    }
  ],
  name: 'forklift',
  type: 'duotone'
}
