import { CIcon } from '~/icons/types'
export const ciDoor: CIcon = {
  name: 'door',
  type: 'regular',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M263.046 2.936c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93c-1.859 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.07a10.077 10.077 0 0 0 7.07 2.93c2.63 0 5.21-1.07 7.07-2.93 1.859-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07zm-66.818 134.587a10.092 10.092 0 0 0-7.07-2.93c-2.63 0-5.21 1.07-7.08 2.93a10.07 10.07 0 0 0-2.92 7.07c0 2.63 1.061 5.21 2.92 7.08a10.09 10.09 0 0 0 7.08 2.92c2.631 0 5.21-1.06 7.07-2.92 1.86-1.87 2.93-4.45 2.93-7.08s-1.069-5.21-2.93-7.07zm-27.627 27.627a9.998 9.998 0 0 0-14.142 0l-89.123 89.123c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.952 4.512 2.929 7.071 2.929s5.118-.977 7.071-2.929l89.123-89.123c3.905-3.905 3.905-10.237 0-14.143z'
    },
    {
      d:
        'M472.001 471.932H502c5.522 0 10-4.478 10-10V66.005c0-.091-.011-.18-.014-.271V10.006c0-5.523-4.478-10-10-10h-195.01c-5.522 0-10 4.477-10 10s4.478 10 10 10h185.01v35.999H19.999V20.006h188.619c5.522 0 10-4.477 10-10s-4.478-10-10-10H10c-5.522 0-10 4.477-10 10v451.925c0 5.522 4.478 10 10 10h338.005v18.313c0 .59.03 1.172.077 1.75H144.593c-5.522 0-10 4.478-10 10s4.478 10 10 10H502c5.522 0 10-4.478 10-10s-4.478-10-10-10h-30.076c.046-.578.077-1.16.077-1.75v-18.312zm-225.994-20H19.999v-25.083l186.304-186.304c3.905-3.905 3.905-10.237 0-14.143-3.906-3.905-10.236-3.905-14.143 0L19.999 398.564V76.004h226.008v375.928zm205.995 38.311c0 .965-.785 1.75-1.75 1.75h-80.498c-.965 0-1.75-.785-1.75-1.75v-98.247h83.998v98.247zm-60.118-118.247h-8.948c-2.692-13.068-6.387-25.833-10.991-37.945 10.587 9.642 17.806 23.257 19.939 37.945zm-11.827-96.972c18.758 13.458 30.461 35.328 30.823 58.778a163.325 163.325 0 0 0-3.754 17.812 85.417 85.417 0 0 0-10.05-18.995c-3.562-19.836-9.253-39.115-17.019-57.595zm47.573 18.678a164.46 164.46 0 0 0-3.529 6.401 94.354 94.354 0 0 0-4.899-10.295c.024-.226.053-.451.062-.682.036-.901.867-17.632 12.111-30.493-2.562 10.987-3.881 22.27-3.881 33.499.001.535.055 1.057.136 1.57zm22.319 2.035c-7.215 24.608-11.948 51.803-12.91 76.259H425.33c.806-27.456 9.524-53.905 24.619-76.259zm12.052 76.259h-4.945c1.702-39.2 14.039-87.071 30.032-114.952.136-.236.292-.542.408-.789a10.002 10.002 0 0 0-14.937-12.337 162.384 162.384 0 0 0-21.609 18.873c1.948-8.237 4.693-16.283 8.216-23.934a10.002 10.002 0 0 0-13.565-13.122l-10.739 5.382c-18.435 9.248-27.317 24.87-31.603 37.486-7.357-7.355-15.972-13.545-25.612-18.153l-15.632-7.471a10 10 0 0 0-13.074 13.843l1.044 1.897a253.063 253.063 0 0 1 20.934 50.138 72.069 72.069 0 0 0-3.941-2.049l-13.109-6.265a10.001 10.001 0 0 0-13.071 13.846l.874 1.588c9.184 16.695 16.29 35.88 20.808 56.017h-4.479c-5.522 0-10 4.478-10 10v69.936h-81.998V76.004h225.994v375.928h-19.999v-69.936c.003-5.522-4.475-10-9.997-10z'
    },
    {
      d:
        'M105.211 494.923a10.09 10.09 0 0 0-7.07-2.93c-2.63 0-5.21 1.069-7.07 2.93-1.87 1.86-2.93 4.44-2.93 7.07s1.06 5.21 2.93 7.069a10.077 10.077 0 0 0 7.07 2.931c2.63 0 5.21-1.07 7.07-2.931a10.076 10.076 0 0 0 2.93-7.069c0-2.63-1.07-5.21-2.93-7.07z'
    }
  ]
}
