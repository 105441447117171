import { CIcon } from '~/icons/types'
export const ciCaravanHouse: CIcon = {
  width: 512,
  height: 334.4,
  paths: [
    {
      d:
        'M71.8 219.9h68.3c9.2 0 16.7-7.5 16.7-16.7v-50.4c0-9.2-7.5-16.7-16.7-16.7H71.8c-9.2 0-16.7 7.5-16.7 16.7v50.4c.1 9.3 7.5 16.7 16.7 16.7zm4.4-62.7h59.5v41.7H76.2v-41.7zm251.9 62.7h58.2c9.2 0 16.7-7.5 16.7-16.7v-50.4c0-9.2-7.5-16.7-16.7-16.7h-58.2c-9.2 0-16.7 7.5-16.7 16.7v50.4c0 9.3 7.5 16.7 16.7 16.7zm4.4-62.7H382v41.7h-49.5v-41.7z'
    },
    {
      d:
        'M501.3 277.5h-11.1v-6.8c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v6.8h-17.5V97.1c0-15-10-28.2-24.3-32.3l-180-63-.7-.2c-7.5-2.1-15.3-2.1-22.8 0l-.2.1-199.4 63C9.9 68.8-.2 82.1-.2 97.1v168.4c0 18.2 14.8 33 33 33h108.9c4.8 19.9 22.7 34.7 44 34.7s39.2-14.8 44-34.7h9.4c4.8 19.9 22.7 34.7 44 34.7s39.2-14.8 44-34.7h142v25.4c0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5v-25.4h11.1c5.8 0 10.5-4.7 10.5-10.5.1-5.8-4.6-10.5-10.4-10.5zM30.1 85l.2-.1 199.3-63.1c3.6-1 7.5-1 11.1 0l179.9 63 .7.2c1.3.4 2.5.9 3.6 1.6H26.5c1.1-.7 2.3-1.3 3.6-1.6zm155.6 227.2c-9.6 0-17.9-5.6-21.8-13.7h43.6c-3.9 8.1-12.2 13.7-21.8 13.7zm74.6-34.7h-50v-118c0-1.2 1-2.2 2.2-2.2h45.6c1.2 0 2.2 1 2.2 2.2v118zm22.8 34.7c-9.6 0-17.9-5.6-21.8-13.7h43.6c-3.9 8.1-12.2 13.7-21.8 13.7zm147.5-34.7H281.3v-118c0-12.8-10.4-23.3-23.3-23.3h-45.6c-12.8 0-23.3 10.4-23.3 23.3v118H32.8c-6.6 0-12-5.4-12-12V107.6h409.8v169.9z'
    }
  ],
  name: 'caravanHouse',
  type: 'regular'
}
