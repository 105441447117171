import { CIcon } from '~/icons/types'
export const ciTruck: CIcon = {
  name: 'truck',
  type: 'regular',
  width: 512,
  height: 302.8,
  paths: [
    {
      d:
        'M510.5 223.7c-.1-12.3-3.6-36.8-12.4-50.7l-50.7-88.1c-.1-.2-.2-.3-.3-.5-9.2-14.1-24.7-22.6-41.5-22.6h-96.7V10.5c0-5.8-4.7-10.5-10.5-10.5h-287C5.6 0 .9 4.7.9 10.5v278.1c0 5.8 4.7 10.5 10.5 10.5H71c7.6 17.3 24.5 29.5 44.5 30.3h1.9c20.3 0 38.6-12 46.6-30.3h134.5c5.7 0 10.4-4.6 10.5-10.3l.1-7.5h22.8c1.4 26.8 23.5 48.1 50.6 48.1s49.3-21.3 50.6-48.1h67.5c2.8 0 5.5-1.1 7.5-3.1s3.1-4.7 3-7.5l-.6-47zm-364.6 63.7c-3.9 13-16.1 21.6-29.7 21.1-15.5-.6-28.3-13.7-28.5-29.2-.1-8.4 3.1-16.2 9.1-21.9 5.6-5.4 12.8-8.3 20.5-8.3h1.6c14.9.7 26.9 12.5 28.1 27.3.3 3.6-.1 7.3-1.1 11zm142.4-19.2-.2 10h-120c0-1.1-.1-2.3-.1-3.4-1.9-25.3-22.5-45.4-47.9-46.7-14.2-.7-27.6 4.3-37.8 14.1-9.8 9.4-15.4 22.5-15.6 36H21.9V21.1h266v51.4c0 .5 0 .9.1 1.4l.4 193.1c-.1.4-.1.8-.1 1.2zm201.2-44.3v2.8h-12.3c-1.3 0-3.1-2-3.1-5.1s1.8-5.1 3.1-5.1h11.9c.2 2.6.4 5.1.4 7.4zm-45.9-103.5 22.1 38.4h-85.3c-2.2 0-3.6-.2-4.5-.4v-37.8c3.3-.2 8.3-.2 13.5-.2h54.2zm-61.1 188.1c-16.4 0-29.7-13.3-29.7-29.7s13.3-29.7 29.7-29.7 29.7 13.3 29.7 29.7-13.3 29.7-29.7 29.7zm47.2-48.1c-7.4-18.9-25.8-32.3-47.2-32.3s-39.8 13.4-47.2 32.3h-25.9L309 82.9h96.6c9.6 0 18.4 4.8 23.7 12.8l2.1 3.7h-42.1c-14.4 0-20.4 0-25.5 2.4-9 4.3-9 13.1-9 19.5v36.6c0 10 4.4 21.9 25.5 21.9h97.3l2.3 3.9c.1.2.2.3.3.5 1.8 2.7 3.4 6.7 4.7 11.3h-7.8c-13.3 0-24.1 11.7-24.1 26.1s10.8 26.1 24.1 26.1h12.6l.2 12.7h-60.2z',
      fill: 'currentColor'
    }
  ]
}
