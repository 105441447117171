






































































import * as CTreeSelectNode from '~/components/shared/configurable/form/select/tree/CTreeSelectNode.vue'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import { ciExternalLink } from '~/icons/source/regular/external-link'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CTreeSelectNode',
  components: {
    COptionWithCount,
    // @ts-ignore
    CTreeSelectNode
  },
  props: {
    isChild: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    hasChildren: {
      type: Boolean,
      default: false
    },
    name: { type: String, default: '' },
    count: { type: Number, default: 0 },
    children: {
      type: Array,
      default() {
        return []
      }
    },
    id: { type: [Number, String], default: null },
    countFunc: {
      type: Function,
      default() {
        return null
      }
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    valueCol: { type: String, default: 'value' },
    nameCol: { type: String, default: 'name' },
    countCol: { type: String, default: 'count' },
    childrenCol: { type: String, default: 'children' },
    onlyExisting: { type: Boolean, default: false },
    showCount: { type: Boolean, default: true },
    redirectUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    icons: {
      chevronRight: faChevronRight,
      externalLink: ciExternalLink
    }
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(newSelectedValue: any) {
        this.$emit('change', newSelectedValue)
      }
    },
    isDisabled() {
      const { isSelected, showCount, count } = this
      return !isSelected && showCount && count <= 0
    },
    isSelected() {
      return this.value.includes(this.id)
    }
  }
})
