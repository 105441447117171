import { CIcon } from '~/icons/types'
export const ciLaneAssist: CIcon = {
  width: 512,
  height: 351.94,
  paths: [
    {
      d:
        'M512 339.53a11.42 11.42 0 0 0-.42-3.08c-.8-2.91-1.6-5.83-2.41-8.74l-6.57-23.81q-4.85-17.61-9.71-35.22l-11.85-43-13-47.07-13.1-47.52Q448.8 109 442.69 86.82q-5.16-18.73-10.33-37.46-3.71-13.47-7.43-26.94c-1.17-4.25-2.25-8.55-3.52-12.78-.06-.18-.1-.36-.16-.55A12.43 12.43 0 0 0 409.5.14H140.22c-12.38 0-24.82-.32-37.2 0h-.54a12.43 12.43 0 0 0-11.75 9l-2.42 8.75Q85 29.75 81.75 41.65L72 76.86l-11.85 43q-6.49 23.54-13 47.07l-13.06 47.49Q28 236.58 21.87 258.73q-5.18 18.72-10.33 37.45-3.72 13.47-7.43 26.94c-1.18 4.26-2.43 8.51-3.53 12.78l-.15.55a12.32 12.32 0 0 0 11.75 15.44H480c6.26 0 12.54.07 18.81 0a10.49 10.49 0 0 0 4.22-.44 14.31 14.31 0 0 0 1.34-.52c4.42-1.88 7.83-6.28 7.61-11.23a1 1 0 0 0 .02-.17Zm-60.72-12H267.67v-70.1h-23.36v70.1H28.22q2.36-8.55 4.72-17.11 4.86-17.61 9.71-35.21l11.85-43 13-47.07q6.56-23.76 13.1-47.52 6.09-22.16 12.2-44.32 5.2-18.73 10.33-37.46 3.72-13.47 7.43-26.94c.4-1.46.82-2.92 1.24-4.38h260c9.48 0 19 .19 28.49.14l6.48 23.47q4.84 17.61 9.71 35.21l11.85 43q6.49 23.53 13 47.07l13.11 47.52q6.12 22.15 12.22 44.31L477 302.69q3.42 12.42 6.85 24.85Z',
      fill: 'currentColor'
    },
    {
      d: 'M244.31 142.62h23.36v67.05h-23.36zm0-93.46h23.36v50.79h-23.36z',
      fill: 'currentColor'
    }
  ],
  name: 'lane-assist',
  type: 'regular'
}
